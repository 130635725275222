import React, { useEffect, useState, memo } from "react";
import Icon from "../../../components/Icon";
import emojis from "../../../../../../a.json"

const EmojiTray = ({ showEmojis, newMessage, setNewMessage, caretPossition }) => {
	const addEmoji = (emoji) => {
		if (caretPossition === null || caretPossition === undefined) {
			setNewMessage(newMessage + emoji);
		}
		else {
			let message = newMessage.slice(0, caretPossition) + emoji + newMessage.slice(caretPossition);
			setNewMessage(message);
		}
	};
	// let cats = ['Smileys & Emotion', 'People & Body', 'Animals & Nature', 'Food & Drink', 'Travel & Places', 'Activities', 'Objects', 'Symbols', 'Flags']

	const emojiTabs = [
		{ icon: "emojiPeople", label: "Smileys & Emotion", active: false },
		{ icon: "emojiNature", label: "Animals & Nature", active: false },
		{ icon: "emojiFood", label: "Food & Drink", active: false },
		{ icon: "emojiActivity", label: "Activities", active: false },
		{ icon: "emojiTravel", label: "Travel & Places", active: false },
		{ icon: "emojiObjects", label: "Objects", active: false },
		{ icon: "emojiSymbols", label: "Symbols", active: false },
		{ icon: "emojiFlags", label: "Flags", active: false },
	];
	useEffect(() => {

	}, [])
	const [activeEmojis, setActive] = useState({ icon: "emojiPeople", label: "Smileys & Emotion", active: false })
	return (
		<div
			className={`emojis__wrapper ${showEmojis ? "emojis__wrapper--active" : ""
				}`}
		>
			<div className="emojis__tabs">
				{emojiTabs.map((tab, index) => (
					<div onClick={() => setActive(tab)}
						className={`emojis__tab ${tab.label === activeEmojis.label ? "emojis__tab--active" : ""}`}
						key={index}
					>
						<button aria-label={tab.label}>
							<Icon id={tab.icon} className="emojis__tab-icon" />
						</button>
					</div>
				))}
			</div>
			<div className="emojis__content">
				<input className="emojis__search" placeholder="Search Emoji" />
				<div className="emojis__grid">

					{emojis.filter((em) => em.category === activeEmojis.label).map((emoj, index) => (
						<>
							{
								<div key={index}
									role="img"
									aria-label="emoji"
									// onClick={() => addEmoji("emoji")}
									className="emoji emojis__emoji"
									style={{ background: "none", fontSize: "22px", fontFamily: "Noto Emoji" }}
									onClick={() => addEmoji(emoj.emoji)}
								>
									{
										emoj.emoji
									}
								</div>}
						</>
					))
					}

				</div>


			</div>
		</div>
	);
};

export default memo(EmojiTray);
