export default function Person() {
    return (<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
        <g id="Mask_Group_184" data-name="Mask Group 184">
            <g id="avatar" transform="translate(1.579 0)">
                <g id="Group_7853" data-name="Group 7853" transform="translate(2.566)">
                    <g id="Group_7852" data-name="Group 7852">
                        <circle id="Ellipse_329" data-name="Ellipse 329" cx="3.355" cy="3.355" r="3.355" fill="#95bdff" />
                    </g>
                </g>
                <g id="Group_7855" data-name="Group 7855" transform="translate(0 7.5)">
                    <g id="Group_7854" data-name="Group 7854">
                        <path id="Path_19144" data-name="Path 19144" d="M6.974,5a5.921,5.921,0,0,0-5.921,5.921A1.579,1.579,0,0,0,2.632,12.5h8.684a1.579,1.579,0,0,0,1.579-1.579A5.921,5.921,0,0,0,6.974,5Z" transform="translate(-1.053 -5)" fill="#95bdff" />
                    </g>
                </g>
            </g>
        </g>
    </svg>

    )
}