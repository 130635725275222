export default function Person() {
    return (<svg id="_10x10_flag_photo" data-name="10x10 flag photo" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="10" height="10" viewBox="0 0 10 10">
        <g id="Mask_Group_184" data-name="Mask Group 184" >
            <g id="avatar">
                <g id="Group_11680" data-name="Group 11680">
                    <g id="Group_7853" data-name="Group 7853">
                        <g id="Group_7852" data-name="Group 7852">
                            <circle id="Ellipse_329" data-name="Ellipse 329" cx="2.237" cy="2.237" r="2.237" transform="translate(2.763 0)" fill="#8e8e8e" />
                        </g>
                    </g>
                    <g id="Group_7855" data-name="Group 7855">
                        <g id="Group_7854" data-name="Group 7854">
                            <path id="Path_19144" data-name="Path 19144" d="M5,5A3.947,3.947,0,0,0,1.053,8.947,1.053,1.053,0,0,0,2.105,10H7.895A1.053,1.053,0,0,0,8.947,8.947,3.947,3.947,0,0,0,5,5Z" fill="#8e8e8e" />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
    )
}