import InsructionsIcon from "./svg/instructionIcon";
import StepDot from "./svg/stepDot";

export default function Insructions({
    ticket_processing_flow,
    setOpenInstructions
}) {
    return <div className="employee_bulk_element_usdt instructions_ticket" style={{ backgroundColor: "#FFFFFF" }}>
        <div className="instructions_title">
            <InsructionsIcon onMouseLeave={() => setOpenInstructions(false)} onMouseOver={() => setOpenInstructions(true)} /> Procedure Required For This Ticket
        </div>
        {ticket_processing_flow.flow_steps?.map((element, index) => <div key={index} className="instructions_step">
            <StepDot /> {element}
        </div>)}
    </div>
}