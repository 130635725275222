export default function Copy() {
  return (<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
    <g id="Mask_Group_262" data-name="Mask Group 262">
      <g id="copying" transform="translate(-0.5 -0.5)">
        <rect id="Rectangle_4879" data-name="Rectangle 4879" width="10" height="13" rx="1.5" transform="translate(1.5 2.5)" fill="#404040" />
        <path id="Path_21181" data-name="Path 21181" d="M14.5,2V12A1.5,1.5,0,0,1,13,13.5h-.5V4A2.5,2.5,0,0,0,10,1.5H4.59A1.5,1.5,0,0,1,6,.5h7A1.5,1.5,0,0,1,14.5,2Z" fill="#404040" />
      </g>
    </g>
  </svg>
  )
}
