import React from 'react'

function Card() {
  return (
    <svg id="_15x15_photo_back" data-name="15x15 photo back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
    <g id="Mask_Group_256" data-name="Mask Group 256">
      <g id="identity-card" transform="translate(-0.313 -0.313)">
        <path id="Path_21178" data-name="Path 21178" d="M4.375,1.688A1.88,1.88,0,0,0,2.5,3.563v9.875a1.842,1.842,0,0,0,1.875,1.875H11.25a1.88,1.88,0,0,0,1.875-1.875V3.563A1.842,1.842,0,0,0,11.25,1.687H9.938V2.75a2.125,2.125,0,1,1-4.25,0V1.687ZM7.813,6.875A1.375,1.375,0,1,1,6.438,8.25,1.387,1.387,0,0,1,7.813,6.875ZM6.188,10.688c.125-.125.313-.062.437,0a2.536,2.536,0,0,0,1.188.25,4.041,4.041,0,0,0,1.25-.25.534.534,0,0,1,.438,0,3.136,3.136,0,0,1,.875.938.879.879,0,0,1-.75,1.313H6.062a.833.833,0,0,1-.75-1.25,2.274,2.274,0,0,1,.875-1Z" fill="#8e8e8e"/>
        <path id="Path_21179" data-name="Path 21179" d="M8.75,2.75V1.25a.938.938,0,0,0-1.875,0v1.5a.938.938,0,0,0,1.875,0Z" fill="#8e8e8e"/>
      </g>
    </g>
  </svg>
  
  )
}

export default Card