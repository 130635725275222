const initialState = {
  templates: []
}

export const NotificationTempReducer = (state = initialState, { type, payload, message }) => {
  switch (type) {
    case "GET_TEMPLATES_REDUCER": {
      return {
        ...state,
        templates: payload
      }
    }
    case "UPD_TEMPLATES_REDUCER": {
      const modified = state.templates.map(temp => {
        if (temp.id === payload.id) {
          return payload
        } else {
          return temp
        }
      })
      return {
        ...state,
        templates: modified
      }
    }
    default:
      return state
  }
}
