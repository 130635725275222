export default function IsMore({ color }) {
    return (<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="25" height="37" viewBox="0 0 25 37">
        <defs>
            <filter id="Ellipse_355" x="0" y="0" width="25" height="25" filterUnits="userSpaceOnUse">
                <feOffset dy="3" input="SourceAlpha" />
                <feGaussianBlur stdDeviation="3" result="blur" />
                <feFlood flood-opacity="0.161" />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
            </filter>
            <filter id="Ellipse_420" x="1" y="9" width="23" height="23" filterUnits="userSpaceOnUse">
                <feOffset dy="3" input="SourceAlpha" />
                <feGaussianBlur stdDeviation="3" result="blur-2" />
                <feFlood flood-opacity="0.161" />
                <feComposite operator="in" in2="blur-2" />
                <feComposite in="SourceGraphic" />
            </filter>
            <filter id="Ellipse_421" x="2" y="16" width="21" height="21" filterUnits="userSpaceOnUse">
                <feOffset dy="3" input="SourceAlpha" />
                <feGaussianBlur stdDeviation="3" result="blur-3" />
                <feFlood flood-opacity="0.161" />
                <feComposite operator="in" in2="blur-3" />
                <feComposite in="SourceGraphic" />
            </filter>
        </defs>
        <g id="Group_11935" data-name="Group 11935" transform="translate(-302 -993)">
            <g transform="matrix(1, 0, 0, 1, 302, 993)" filter="url(#Ellipse_355)">
                <circle id="Ellipse_355-2" data-name="Ellipse 355" cx="3.5" cy="3.5" r="3.5" transform="translate(9 6)" fill={color} />
            </g>
            <g transform="matrix(1, 0, 0, 1, 302, 993)" filter="url(#Ellipse_420)">
                <circle id="Ellipse_420-2" data-name="Ellipse 420" cx="2.5" cy="2.5" r="2.5" transform="translate(10 15)" fill={color} />
            </g>
            <g transform="matrix(1, 0, 0, 1, 302, 993)" filter="url(#Ellipse_421)">
                <circle id="Ellipse_421-2" data-name="Ellipse 421" cx="1.5" cy="1.5" r="1.5" transform="translate(11 22)" fill={color} />
            </g>
        </g>
    </svg>
    )
}