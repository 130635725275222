export default function Remind() {
  return (<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
    <g id="Mask_Group_248" data-name="Mask Group 248">
      <g id="Group_8323" data-name="Group 8323" transform="translate(-156.843 -116.691)">
        <g id="Group_9803" data-name="Group 9803" transform="translate(157.475 116.71)">
          <path id="Path_20949" data-name="Path 20949" d="M281.2,18.947a.628.628,0,0,1-.628-.628,6.585,6.585,0,0,0-1.941-4.687.631.631,0,0,1,.892-.892,7.837,7.837,0,0,1,2.31,5.579.628.628,0,0,1-.634.628Zm0,0" transform="translate(-268.096 -12.08)" fill="#404040" />
          <path id="Path_20950" data-name="Path 20950" d="M.628,18.947A.628.628,0,0,1,0,18.319a7.838,7.838,0,0,1,2.31-5.579.631.631,0,0,1,.892.892,6.584,6.584,0,0,0-1.94,4.683.628.628,0,0,1-.635.631Zm0,0" transform="translate(0 -12.08)" fill="#404040" />
          <path id="Path_20951" data-name="Path 20951" d="M28.825,10.673a4.249,4.249,0,0,1-1.483-3.235V5.68A4.4,4.4,0,0,0,23.6,1.313V.628a.624.624,0,1,0-1.247,0V1.31a4.4,4.4,0,0,0-3.742,4.367V7.44A4.253,4.253,0,0,1,17.12,10.68a1.107,1.107,0,0,0,.706,1.943H28.121a1.1,1.1,0,0,0,1.091-1.1,1.113,1.113,0,0,0-.387-.845Zm0,0" transform="translate(-16.109)" fill="#404040" />
          <path id="Path_20952" data-name="Path 20952" d="M124.968,353.465a2.371,2.371,0,0,0,2.32-1.894H122.65A2.371,2.371,0,0,0,124.968,353.465Zm0,0" transform="translate(-118.1 -338.483)" fill="#404040" />
        </g>
      </g>
    </g>
  </svg>)
}

