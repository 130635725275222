export default function Waicon() {
  return (<svg xmlns="http://www.w3.org/2000/svg" width="10.071" height="10.001" viewBox="0 0 10.071 10.001">
    <g id="Group_10416" data-name="Group 10416" transform="translate(-345.003 -298.999)">
      <path id="background" d="M8.211,9.829a30.259,30.259,0,0,1-6.42,0A1.822,1.822,0,0,1,.17,8.211a30.259,30.259,0,0,1,0-6.42A1.822,1.822,0,0,1,1.788.17a30.259,30.259,0,0,1,6.42,0,1.822,1.822,0,0,1,1.62,1.618,30.259,30.259,0,0,1,0,6.42,1.822,1.822,0,0,1-1.618,1.62Z" transform="translate(345.075 299)" fill="#29a71a" />
      <g id="icon" transform="translate(346.975 300.876)">
        <path id="Path_21373" data-name="Path 21373" d="M9.1,4.672A3.1,3.1,0,0,0,4.138,8.258l-.329,1.6a.118.118,0,0,0,.019.093A.12.12,0,0,0,3.955,10L5.52,9.626A3.1,3.1,0,0,0,9.1,4.672Zm-.488,3.9a2.428,2.428,0,0,1-2.8.455L5.6,8.913l-.96.227,0-.012.2-.966-.107-.211A2.427,2.427,0,0,1,8.616,5.135l.012.012A2.427,2.427,0,0,1,8.616,8.567Z" transform="translate(-3.801 -3.753)" fill="#fff" />
        <path id="Path_21374" data-name="Path 21374" d="M10.069,9.462a1.008,1.008,0,0,1-.537.468,2.032,2.032,0,0,1-1.818-.725L7.7,9.2A1.959,1.959,0,0,1,6.882,7.61a1,1,0,0,1,.39-.6.223.223,0,0,1,.349.08l.253.568a.222.222,0,0,1-.028.227l-.128.166A.22.22,0,0,0,7.7,8.3a2.389,2.389,0,0,0,.433.481,2.53,2.53,0,0,0,.6.43.217.217,0,0,0,.238-.05l.148-.149a.227.227,0,0,1,.222-.057l.6.17a.227.227,0,0,1,.127.336Z" transform="translate(-5.338 -5.357)" fill="#fff" />
      </g>
      <rect id="Rectangle_5116" data-name="Rectangle 5116" width="10" height="10" transform="translate(345.003 299)" fill="none" />
    </g>
  </svg>)
}

