import { useEffect, useState } from "react"
import OdooHash from "./odoo_hash"
import PackageFilter from "./package_filter"

export default function BulkList(props) {
    const [loading, setLoading] = useState(false)
    const [filter, setFilter] = useState(null)
    const [list, setList] = useState([])
    const [renders, setRenders] = useState(0)

    const detectMissedElement = ({ newData, oldData }) => {
        let missed = null
        oldData.map(item => {
            if (newData.filter(one => one.id === item.id).length === 0) {
                missed = item
            }
        })
        return missed
    }
    useEffect(() => {
        if ((renders > 0) && !props.fromSelectedChat) {
            if (props.data?.length < list.length) {
                let element = detectMissedElement({
                    newData: props.data,
                    oldData: list
                })
                if (document.getElementById(`${element?.id}-list-item`)) {
                    let height = document.getElementById(`${element?.id}-list-item`).clientHeight
                    if (height < 150) {
                        document.getElementById(`${element?.id}-list-item`).style.animationName = "collapseItem100"
                    } else if (height >= 150 && height < 250) {
                        document.getElementById(`${element?.id}-list-item`).style.animationName = "collapseItem200"
                    } else if (height >= 250 && height < 350) {
                        document.getElementById(`${element?.id}-list-item`).style.animationName = "collapseItem300"
                    } else if (height >= 350 && height <= 450) {
                        document.getElementById(`${element?.id}-list-item`).style.animationName = "collapseItem400"
                    } else if (height > 450) {
                        document.getElementById(`${element?.id}-list-item`).style.animationName = "collapseItem500"
                    }
                    document.getElementById(`${element?.id}-list-item`)?.classList?.add("removedItem")
                }
                setTimeout(() => {
                    setList(props.data)
                }, [1100])
            } else if (props.data?.length > list.length) {
                setTimeout(() => {
                    let element = detectMissedElement({
                        newData: list,
                        oldData: props.data
                    })
                    setList(props.data)
                    if (document.getElementById(`${element?.id}-list-item`)) {
                        document.getElementById(`${element?.id}-list-item`)?.classList?.add("addedItem")
                        document.getElementById(`${element?.id}-list-item`)?.classList?.add("addedItemGrean")
                        setTimeout(() => {
                            document.getElementById(`${element?.id}-list-item`)?.classList?.remove("addedItemGrean")
                        }, [10000])
                    }
                }, [1200])
            } else {
                setList(props.data)
            }
        } else {
            setList(props.data)
            setRenders(renders + 1)
        }
    }, [props.data])
    const getDataByFilter = (tickets) => {
        let recieved = []
        let waiting_approve = []
        let approved = []
        let applied = []
        tickets.map(one => {
            if (one.applied_at) {
                applied.push(one)
            }
            else if (one.approved_at) {
                approved.push(one)
            }
            else if (one.received_at && one.hash_transaction_id) {
                waiting_approve.push(one)
            }
            else {
                recieved.push(one)
            }
        })
        if (props.expanded) {
            return tickets
        } else {
            if (filter === 1) {
                return recieved
            } else if (filter === 4) {
                return applied
            } else if (filter === 3) {
                return approved
            } else if (filter === 2) {
                return waiting_approve
            } else {
                return tickets
            }
        }
    }
    const getTotalCount = ({ chat, status }) => {
        if (props.expanded) {
            return props.total_count
        } else {
            if (status === null) {
                return props.total_count
            } else if (status === 2) {
                return chat.waiting_approve_tickets_count
            } else if (status === 3) {
                return chat.approved_tickets_count
            } else if (status === 4) {
                return chat.applied_tickets_count
            } else {
                return chat.received_tickets_count
            }
        }
    }
    const getLastId = () => {
        return getDataByFilter(list)[getDataByFilter(list).length - 1]?.id ?? null
    }
    return (<>
        {!props.expanded && <PackageFilter chat={props.chat} setFilter={(e) => setFilter(e)} filter={filter} />}
        <div id={`${props.index}-list-tickets`} className={`${props.expanded ? 'bulk_list_parent_expanded' : 'bulk_list_parent'}`}>
            {getDataByFilter(list).map((ticket, index) => {
                return (<div key={index} id={`${ticket?.id}-list-item`}>
                    <OdooHash
                        ticket={ticket}
                        whatsapp_chat_name={props.whatsapp_chat_name}
                        editTicket={props.editTicket}
                        finishEditTicket={props.finishEditTicket}
                    />
                </div>)
            })}
            {getTotalCount({ chat: props.chat, status: filter }) > getDataByFilter(list).length && <div className="load_more_tickets_container">
                <div className={loading ? "load_more_tickets_loading" : "load_more_tickets"} onClick={() => {
                    if (!loading) {
                        setLoading(true)
                        props.getPage({
                            chat_id: props.chat.whatsapp_chat_id,
                            last_ticket_id: getLastId(),
                            status: props.expanded ? (props.status ? props.status : null) : filter
                        }).then((e) => {
                            setLoading(false)
                        }).catch(e => {
                            setLoading(false)
                            console.log(e)
                        })
                    }
                }}>
                    {loading ? "Loading ..." : "Load More"}
                </div>
            </div>}
        </div>
    </>)
}