import { useEffect, useState } from "react"
import BulkList from "./bulks_list"

export default function BulkExpandedList(props) {
    const [data, setdata] = useState([{
        id: 1,
        data: []
    }, {
        id: 2,
        data: []
    }, {
        id: 3,
        data: []
    }, {
        id: 4,
        data: []
    }])

    useEffect(() => {
        getDataSorted(props.data)
    }, [props.data])

    const getDataSorted = (tickets) => {
        let recieved = []
        let waiting_approve = []
        let approved = []
        let applied = []
        tickets.map(one => {
            if ((one.ticket_status_id === 4) || (one.ticket_status_id === 7)) {
                applied.push(one)
            }
            else if (one.ticket_status_id === 3) {
                approved.push(one)
            }
            else if (one.ticket_status_id === 2) {
                waiting_approve.push(one)
            }
            else {
                recieved.push(one)
            }
        })
        setdata([
            {
                id: 1,
                data: recieved
            }, {
                id: 2,
                data: waiting_approve
            }, {
                id: 3,
                data: approved
            }, {
                id: 4,
                data: applied
            }
        ])
    }
    const getTotalCount = ({ chat, status }) => {
        if (status === 1) {
            return chat.received_tickets_count
        } else if (status === 2) {
            return chat.waiting_approve_tickets_count
        } else if (status === 3) {
            return chat.approved_tickets_count
        } else if (status === 4) {
            return chat.applied_tickets_count
        } else {
            return chat.tickets_count
        }
    }
    return (<div className="bulk_expanded_list" style={{ overflowX: "scroll" }}>
        {data.map((filter, index) => <div key={index} className="bulk_expanded_list_column">
            <BulkList
                fromSelectedChat={props.fromSelectedChat}
                data={filter.data}
                currentBulk={props.currentBulk}
                setCurrentBulk={(e) => props.setCurrentBulk(e)}
                whatsapp_chat_name={props.whatsapp_chat_name}
                countries={props.countries}
                chat={props.chat}
                expanded={true}
                status={filter.id}
                getPage={(e) => props.getPage(e)}
                total_count={getTotalCount({ chat: props.chat, status: filter.id })}
                editTicket={props.editTicket}
                finishEditTicket={props.finishEditTicket}
                index={index}
            />
        </div>)}
    </div>)
}