export default function Refresh() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20.001" viewBox="0 0 20 20.001">
            <g id="Group_11681" data-name="Group 11681" transform="translate(0 0.002)">
                <path id="Path_21695" data-name="Path 21695" d="M4.1,2.732,4.222.006A12.1,12.1,0,0,0,0,.619L1.056,3.186A8.66,8.66,0,0,1,4.1,2.732Z" transform="matrix(0.998, -0.07, 0.07, 0.998, 3.654, 0.293)" fill="#78efa6" />
                <path id="Path_21698" data-name="Path 21698" d="M5.126,1.12A16.149,16.149,0,0,0,.41,0L0,2.564a11.483,11.483,0,0,1,3.427.819Z" transform="matrix(0.999, 0.035, -0.035, 0.999, 9.012, 0)" fill="#4deb8a" />
                <path id="Path_21699" data-name="Path 21699" d="M35.974,17.127A10.794,10.794,0,0,0,32.395,13.8l-1.737,2.294a9.659,9.659,0,0,1,1.289.918A8.3,8.3,0,0,1,33.263,18.5a6.789,6.789,0,0,1,1.079,3.6,7.138,7.138,0,0,1-4.132,6.264,9.358,9.358,0,0,1-7.605.321l1-.895L17.5,26.419l1.684,5.3,1.105-.987a12.606,12.606,0,0,0,5.763,1.377,12.853,12.853,0,0,0,5.737-1.354,9.869,9.869,0,0,0,5.711-8.65A9.024,9.024,0,0,0,35.974,17.127Z" transform="translate(-17.5 -12.109)" fill="#30d871" />
            </g>
        </svg>

    )
}