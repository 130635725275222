const initialState = {
    en: {
        you_have: "You Have",
        attempts: "Attempts",
        clear_login: "Clear Login",
        change_user: "Change User",
        change_pass: "Change pass",
        forget_pass: "Forget Password"
    },
    ar: {
        you_have: "لديك",
        attempts: "محاولات",
        clear_login: "مسح بيانات الدخول",
        change_user: "تغيير المستخدم",
        change_pass: "تغيير كلمة المرور",
        forget_pass: "نسيت كلمة المرور"
    }
};

export const translationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "store_lang_file": return {
            ...state, [action.payload.lang]: {
                ...state[action.payload.lang],
                ...action.payload.file
            }
        };
        default: return { ...state };
    }
}