import Icon from "../../../components/Icon";
import React, { memo, useCallback } from "react";
import Cons from "../../../assets/images/cons";
import { useDispatch, useSelector } from "react-redux";
import ProfileNo from "../../../assets/images/profileNo.png";
import Group from "../../../assets/images/group";
import vcard from "vcard-parser";
import Spinner from "../../../../Spinner";
const Search = ({ search, onChange, searchedMessages, c, close }) => {
	const dispatch = useDispatch()
	let is_fetch = useSelector(state => state.whatsapp.is_fetch)
	const goToMessage = useCallback((i) => {
		if (!is_fetch) {
			dispatch({ type: "clearQuotedMessage" })
			var numb = i.id;
			let ids = numb;
			if (activeChat?.messages?.filter((one) =>
				one.id === ids
			).length > 0) {
				close();
				dispatch({ type: "SEARCH_END" })
				setTimeout(() => {
					document.querySelector("#s" + ids)?.scrollIntoView({ block: "center", inline: "end" })
					setTimeout(() => {
						document.querySelector("#s" + ids)?.classList.add("backdrop_msg")
					}, 200)
					setTimeout(() => {
						document.querySelector("#s" + ids)?.classList.remove("backdrop_msg")
					}, 800)
				}, 600);
			} else {
				dispatch({ type: "GET_MES", qid: activeChat?.messages[0]?.id, sid: ids, cid: c.id })
			}
		}
	}, [searchedMessages])

	const getVcardName = (card) => {
		var myVCard = vcard?.parse(card);
		return (myVCard?.fn[0]?.value)
	}
	const activeChat = useSelector((state) => state.whatsapp.activeChat)
	const contacts = useSelector((state) => state.whatsapp.contacts)
	const search_progress = useSelector((state) => state.whatsapp.search_progress)
	return (
		<>
			<div className="search-wrapper">
				<div className="search-icons">
					{search_progress ? <Spinner no={true} /> : <Icon id="search" className="search-icon" />}
					<button className="search__back-btn">
						<Icon id="back" />
					</button>
				</div>
				<input onChange={(e) => onChange(e)} className="search" placeholder="Search..." />
			</div>
			<div className="chat-sidebar__search-results">
				{searchedMessages.length == 0 ? <p> Search for messages</p> :
					searchedMessages.map((mes) => (<>
						{mes.body && <div className="sidebar-contact" onClick={() => goToMessage(mes)}>
							<div className="sidebar-contact__avatar-wrapper">
								{activeChat?.isGroup && activeChat.isGroup === 1 ? (
									<Group />
								) : (
									<img
										src={(mes?.fromMe !== 1) ? activeChat?.profile_photo ? activeChat.profile_photo : ProfileNo : ProfileNo}
										alt={""}
										className="avatar"
										onError={({ currentTarget }) => {
											currentTarget.onerror = null; // prevents looping
											currentTarget.src = ProfileNo;
										}}
									/>
								)}
							</div>
							<div className="sidebar-contact__content" style={{ justifyContent: "flex-start", textAlign: "start" }}>
								<div className="sidebar-contact__topcontent">
									<h2 className="sidebar-contact__name">
										{mes?.fromMe === 1
											? "You"
											: contacts.filter((s) => s.id === mes?.received_from_contact_id)[0]?.name
												? contacts.filter((s) => s.id === mes?.received_from_contact_id)[0]?.name :
												("+" + contacts.filter((s) => s.id === mes?.received_from_contact_id)[0]?.number ||
													activeChat?.whatsapp_contact?.name && activeChat?.whatsapp_contact?.name)}
									</h2>

								</div>
								<div className="sidebar-contact__bottom-content" style={{ justifyContent: "flex-start" }}>
									{mes?.ack && (
										<Icon
											id={
												mes?.ack === "device"
													? "singleTick"
													: "doubleTick"
											}
											aria-label={mes?.ack}
											className={`sidebar-contact__message-icon ${mes?.ack === "read"
												? "sidebar-contact__message-icon--blue"
												: ""
												}`}
										/>
									)}
									{mes.type === "vcard" ? <> <Cons />  {getVcardName(mes?.body)}</> :
										mes?.body}</div>
							</div>
						</div>}

					</>

					))
				}
			</div>
		</>
	);
};

export default memo(Search);
