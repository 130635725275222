import React, { useEffect, useState } from 'react'
// import Package from "./svg/package"
// import SmallPackage from './svg/small_package'
// import Product from './svg/products'
// import Deposit from './svg/deposit'
// import Withdrawal from './svg/withdrowal'
// import Topup from './svg/topup'
// import Settlement from './svg/settelment'
// import PackageFilter from './package_filter'
import Whatsapp from './svg/whatsapp'
import TicketsBlue from './svg/tickets_blue'
import TetherSmall from './svg/tetherSmall'
import BulkList from './bulks_list'
import { countries } from './fakeData'
import PackageFilterExpand from './package_filter_expand'
import BulkExpandedList from './bulk_expanded'
import { useSelector } from 'react-redux'
import Rf from './svg/rf'

export default function MainStatuses(props) {
    const whatsapp_chats = useSelector(state => state.ticketsReducer.whatsapp_chats)
    const [currentBulk, setCurrentBulk] = useState(null)
    const [selectedChat, setSelectedChat] = useState(null)
    const [fromSelectedChat, setFromSelectedChat] = useState(false)
    useEffect(() => {
        if (selectedChat) {
            setFromSelectedChat(true)
            setTimeout(() => {
                setFromSelectedChat(false)
            }, [2000])
        }
    }, [selectedChat])
    if (!selectedChat) {
        return (<div className="shipment_main_statuses">
            {whatsapp_chats.map((chat, index) => <div>
                <div key={index} className='status_normal' style={{ backgroundColor: "#F4F5F5" }} onClick={() => {
                    setSelectedChat(chat.whatsapp_chat_id ? chat.whatsapp_chat_id : "other")
                }}>
                    <div className='status_icon'>
                        <Whatsapp />
                    </div>
                    <div className='status_info'>
                        <div className='status_info_name'>{chat.whatsapp_chat_name}</div>
                        <div className='status_info_info'>
                            <div className='status_info_info_packages'>
                                <div className='status_info_info_packages_num'>
                                    <TicketsBlue />
                                    {chat.tickets_count}
                                </div>
                            </div>
                            <div className='status_info_info_products'>
                                <div className='status_info_info_products_num'>
                                    <TetherSmall />
                                    {chat.tickets_usdt_amount}
                                </div>
                                <div className='status_info_info_products_num'>
                                    <Rf />
                                    {chat.tickets_usd_amount}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <BulkList
                    fromSelectedChat={fromSelectedChat}
                    currentBulk={currentBulk}
                    setCurrentBulk={(e) => setCurrentBulk(e)}
                    data={chat.tickets}
                    chat={chat}
                    whatsapp_chat_name={chat.whatsapp_chat_name}
                    countries={countries}
                    expanded={false}
                    getPage={(e) => props.getPage(e)}
                    total_count={chat.tickets_count}
                    editTicket={props.editTicket}
                    finishEditTicket={props.finishEditTicket}
                // mainStatuses={mainStatuses}
                // selectedFilter={fakeFilterSelected[index]}
                />
            </div>)}
        </div>)
    }
    else {
        return (<div className="shipment_main_statuses_with_selected">
            <div className="shipment_main_statuses">
                {whatsapp_chats.map((chat, index) =>
                    <div key={index} className='status_normal' style={(selectedChat === chat.whatsapp_chat_id) || ((selectedChat === "other") && (chat.whatsapp_chat_id === null)) ? { boxShadow: "none" } : {}} onClick={() => {
                        if ((selectedChat === chat.whatsapp_chat_id) || ((selectedChat === "other") && (chat.whatsapp_chat_id === null))) {
                            setSelectedChat(null)
                        } else {
                            setSelectedChat(chat.whatsapp_chat_id ? chat.whatsapp_chat_id : "other")
                        }
                    }}>
                        <div className='status_icon'>
                            <Whatsapp />
                        </div>
                        <div className='status_info'>
                            <div className='status_info_name'>{chat.whatsapp_chat_name}</div>
                            <div className='status_info_info'>
                                <div className='status_info_info_packages'>
                                    <div className='status_info_info_packages_num'>
                                        <TicketsBlue />
                                        {chat.tickets_count}
                                    </div>
                                </div>
                                <div className='status_info_info_products'>
                                    <div className='status_info_info_products_num'>
                                        <TetherSmall />
                                        {chat.tickets_usdt_amount}
                                    </div>
                                    <div className='status_info_info_products_num'>
                                        <Rf />
                                        {chat.tickets_usd_amount}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <PackageFilterExpand chat={(whatsapp_chats.filter(one => (one.whatsapp_chat_id === selectedChat) || ((selectedChat === "other") && one.whatsapp_chat_id === null)).length > 0) && whatsapp_chats.filter(one => (one.whatsapp_chat_id === selectedChat) || ((selectedChat === "other") && one.whatsapp_chat_id === null))[0]} />
            <BulkExpandedList
                fromSelectedChat={fromSelectedChat}
                currentBulk={currentBulk}
                getPage={(e) => props.getPage(e)}
                setCurrentBulk={(e) => setCurrentBulk(e)}
                data={whatsapp_chats.filter(one => (one.whatsapp_chat_id === selectedChat) || ((selectedChat === "other") && one.whatsapp_chat_id === null))[0].tickets}
                whatsapp_chat_name={whatsapp_chats.filter(one => (one.whatsapp_chat_id === selectedChat) || ((selectedChat === "other") && one.whatsapp_chat_id === null))[0].whatsapp_chat_name}
                chat={(whatsapp_chats.filter(one => (one.whatsapp_chat_id === selectedChat) || ((selectedChat === "other") && one.whatsapp_chat_id === null)).length > 0) && whatsapp_chats.filter(one => (one.whatsapp_chat_id === selectedChat) || ((selectedChat === "other") && one.whatsapp_chat_id === null))[0]}
                countries={countries}
                editTicket={props.editTicket}
                finishEditTicket={props.finishEditTicket} />
        </div>)
    }

}