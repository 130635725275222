import { kaReducer } from 'ka-table';
import { DataType, EditingMode, PagingPosition, SortingMode } from 'ka-table/enums';

const initialTableOption = {
    columns: [
        { key: 'id', title: 'ID', dataType: DataType.String },
        { key: 'EN', title: 'EN', dataType: DataType.String },
        { key: 'AR', title: 'AR', dataType: DataType.String },
        { key: 'TR', title: 'TR', dataType: DataType.String },
    ],
    data: [{
        EN: `0`,
        AR: `0`,
        TR: `0`,
        id: 0,
    }],
    paging: {
        enabled: true,
        pageIndex: 0,
        pageSize: 10,
        pageSizes: [5, 10, 15,50,100],
        position: PagingPosition.Top
    },
    sortingMode: SortingMode.None,
    editingMode: EditingMode.Cell,
    rowKeyField: "id"
};
export const tablePropsInit = (state = initialTableOption, action) => {
    switch (action.type) {
        case "colomns_change": return {
            ...state, columns: action.payload
        };
        case "data_change": return {
            ...state, data: action.payload
        };
        default: return kaReducer(state, action);
    }
}