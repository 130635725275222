export default function Withdrawal() {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
        <g id="Group_8034" data-name="Group 8034" transform="translate(-369.568 -161.911)">
            <path id="Path_19269" data-name="Path 19269" d="M3.883,4.317a.764.764,0,0,0,.808-.661L7.14-4.69h.078L9.665,3.657a.764.764,0,0,0,.808.661c.42,0,.653-.21.785-.668L14-6.088a1.328,1.328,0,0,0,.062-.389.64.64,0,0,0-.692-.637.67.67,0,0,0-.692.56l-2.207,8.7h-.062L8.041-6.36c-.148-.528-.4-.754-.855-.754-.474,0-.723.225-.878.785L3.945,2.149H3.883l-2.207-8.7a.672.672,0,0,0-.692-.56.64.64,0,0,0-.692.637,1.328,1.328,0,0,0,.062.389L3.1,3.649C3.231,4.108,3.464,4.317,3.883,4.317Z" transform="translate(374.89 176.582)" fill="#404040" />
            <g id="Group_8030" data-name="Group 8030" transform="translate(189.923 -506)">
                <g id="Group_8027" data-name="Group 8027" transform="translate(188.645 669.91)">
                    <path id="Path_19268" data-name="Path 19268" d="M126.031,63.612c-2.292,0-4.129-1.066-2.713-.723a12.428,12.428,0,0,0,12.5-4.387,11.9,11.9,0,0,0-1.025-15.969,13.223,13.223,0,0,0-1.87-1.5.2.2,0,0,1-.051-.273.209.209,0,0,1,.277-.064,13.634,13.634,0,0,1,1.936,1.548,12.321,12.321,0,0,1,2.764,13.633,12.816,12.816,0,0,1-11.811,7.734Zm0,0" transform="translate(-122.822 -40.611)" fill="#5d5d5d" />
                    <path id="Path_19277" data-name="Path 19277" d="M380.717,42.172a.213.213,0,0,1-.213-.213v-2.1a.211.211,0,0,1,.064-.152.226.226,0,0,1,.154-.061l2.348.054a.213.213,0,0,1,.208.218.217.217,0,0,1-.218.208l-2.13-.049v1.884A.213.213,0,0,1,380.717,42.172Zm0,0" transform="translate(-370.545 -39.646)" fill="#5d5d5d" />
                </g>
                <g id="Group_8028" data-name="Group 8028" transform="translate(179.645 667.911)">
                    <path id="Path_19268-2" data-name="Path 19268" d="M135.612,40.67c2.292,0,4.129,1.066,2.713.723a12.428,12.428,0,0,0-12.5,4.387,11.9,11.9,0,0,0,1.025,15.969,13.222,13.222,0,0,0,1.87,1.5.2.2,0,0,1,.051.273.209.209,0,0,1-.277.064,13.634,13.634,0,0,1-1.936-1.548A12.321,12.321,0,0,1,123.8,48.4a12.816,12.816,0,0,1,11.811-7.734Zm0,0" transform="translate(-122.822 -40.67)" fill="#fff8f2" />
                    <path id="Path_19277-2" data-name="Path 19277" d="M383.065,39.646a.213.213,0,0,1,.213.213v2.1a.211.211,0,0,1-.064.152.226.226,0,0,1-.154.061l-2.348-.054a.213.213,0,0,1-.208-.218.217.217,0,0,1,.218-.208l2.13.049V39.857A.213.213,0,0,1,383.065,39.646Zm0,0" transform="translate(-377.237 -19.171)" fill="#fff8f2" />
                </g>
            </g>
        </g>
    </svg>
    )
}