export default function OptionsList() {
    return (<svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="50"
        height="50"
        viewBox="0 0 50 50"
    >
        <g
            id="Group_7964"
            data-name="Group 7964"
            transform="translate(-190 -520)"
        >
            <g
                id="Rectangle_4719"
                data-name="Rectangle 4719"
                transform="translate(190 520)"
                fill="#f7f7f7"
                stroke="#ddd"
                strokeWidth="0.5"
            >
                <rect width="50" height="50" rx="15" stroke="none" />
                <rect
                    x="0.25"
                    y="0.25"
                    width="49.5"
                    height="49.5"
                    rx="14.75"
                    fill="none"
                />
            </g>
            <g
                id="_15x15_photo_back"
                data-name="15x15 photo back"
                transform="translate(208 538)"
            >
                <g id="Mask_Group_215" data-name="Mask Group 215">
                    <g
                        id="Layer_2"
                        data-name="Layer 2"
                        transform="translate(-0.682 -0.682)"
                    >
                        <path
                            id="Path_19238"
                            data-name="Path 19238"
                            d="M3.409,6.136A2.727,2.727,0,1,1,6.136,3.409,2.727,2.727,0,0,1,3.409,6.136Zm0-4.091A1.364,1.364,0,1,0,4.773,3.409,1.364,1.364,0,0,0,3.409,2.045Z"
                            fill="#8e8e8e"
                        />
                        <path
                            id="Path_19239"
                            data-name="Path 19239"
                            d="M12.955,10.909a2.727,2.727,0,1,1,2.727-2.727A2.727,2.727,0,0,1,12.955,10.909Zm0-4.091a1.364,1.364,0,1,0,1.364,1.364A1.364,1.364,0,0,0,12.955,6.818Z"
                            fill="#8e8e8e"
                        />
                        <path
                            id="Path_19240"
                            data-name="Path 19240"
                            d="M3.409,15.682a2.727,2.727,0,1,1,2.727-2.727A2.727,2.727,0,0,1,3.409,15.682Zm0-4.091a1.364,1.364,0,1,0,1.364,1.364A1.364,1.364,0,0,0,3.409,11.591Z"
                            fill="#8e8e8e"
                        />
                        <path
                            id="Path_19241"
                            data-name="Path 19241"
                            d="M14.318,4.091H5.455V2.727h8.864a.682.682,0,0,1,0,1.364Z"
                            fill="#8e8e8e"
                        />
                        <path
                            id="Path_19242"
                            data-name="Path 19242"
                            d="M10.909,8.864H2.045a.682.682,0,1,1,0-1.364h8.864Z"
                            fill="#8e8e8e"
                        />
                        <path
                            id="Path_19243"
                            data-name="Path 19243"
                            d="M14.318,13.636H5.455V12.273h8.864a.682.682,0,0,1,0,1.364Z"
                            fill="#8e8e8e"
                        />
                        <path
                            id="Path_19244"
                            data-name="Path 19244"
                            d="M0,2.045Z"
                            transform="translate(10.909 6.136)"
                            fill="#8e8e8e"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>)
}