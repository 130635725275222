import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function PWAOfflineStatus(props) {
    const [isOnline, setOnlineStatus] = useState(true);
    const [showOnline, setOnline] = useState(false)
    const [weGotLeak, setWeGotLeak] = useState(false)
    const dispatch = useDispatch();
    const roles = useSelector(state => state.auth.roles)
    useEffect(() => {
        const setFromEvent = function (event) {
            if (event.type === 'online') {
                setOnlineStatus(true);
            }
            else if (event.type === 'offline') {
                setOnlineStatus(false);
                setWeGotLeak(true)
            }

        }

        window.addEventListener("online", setFromEvent);
        window.addEventListener("offline", setFromEvent);

        return () => {
            window.removeEventListener("online", setFromEvent);
            window.removeEventListener("offline", setFromEvent);
        }
    });
    useEffect(() => {
        if (isOnline && weGotLeak) {
            setOnline(true)
            const timeout = setTimeout(() => {
                setOnline(false)
                if (roles.includes("USE_WHATSAPP")) {
                    dispatch({ type: "GET_WA_CHATS" })
                    dispatch({ type: "GET_WA_ADMIN_CHATS" })
                }
            }, 2000)
            return () => clearTimeout(timeout)
        }
    }, [isOnline])

    return !isOnline ? (
        <>
            <h5 className='pwa-warning'>
                You are currently offline, Access to the application might be limited.
            </h5>
        </>) : (showOnline ? <h5 className='pwa-warning_restore'>
            connection restored.
        </h5> : <></>)
};

export default PWAOfflineStatus;