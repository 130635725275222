import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import App from '../WhatsApp/App'

function Whatsapp(props) {
  const dispatch = useDispatch()
  const viewDisplay = useSelector((state) => state.whatsapp.viewDisplay)
  const callInProgress = useSelector((state) => state.chat.callInProgress)
  const WAwidth = useSelector((state) => state.whatsapp.WAwidth)
  const [close, setClose] = useState(false)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (!viewDisplay) {
      setClose(true)
      const timeOut = setTimeout(() => {
        setClose(false)
      }, 600)
      return () => {
        clearTimeout(timeOut)
      }
    }
  }, [viewDisplay])

  useEffect(() => {
    if (viewDisplay) {
      setOpen(true)
      const timeOut = setTimeout(() => {
        setOpen(false)
      }, 600)
      return () => {
        clearTimeout(timeOut)
      }
    }
  }, [viewDisplay])

  const setWAwidth = (s) => {
    localStorage.setItem("WA-WIDTH", s)
    dispatch({ type: "WA-WIDTH", payload: s })
  }
  const setViewDisply = useCallback((s) => {
    dispatch({ type: "VIEW_DISPLAY", payload: s })
  }, [])
  return (
    <>
      {<div onClick={(e) => {
        if (!callInProgress) {
          props.close(); dispatch({ type: "CHAT_ID", payload: null })
        }
      }} className={`lang-modalDisable ${props.open ? "open" : ""}`}></div>}
      <div id='whats-modal' className={`whats-modal ${props.open ? "open" : ""} ${"nopad"} ${viewDisplay ? "wide-view" : ""} ${close ? "hywan" : ""} ${open ? "hywana" : ""}`}>
        <div className='whats-page'>
          <App setOpenExternal={props.setOpenExternal} openExternal={props.openExternal} WAwidth={WAwidth} setWAwidth={(s) => setWAwidth(s)} viewDisplay={viewDisplay} make={(e) => setViewDisply(e)} change={() => setViewDisply(!viewDisplay)} />
        </div>
      </div>
    </>
  )
}

export default Whatsapp