
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage, deleteToken } from "firebase/messaging";
import { toast } from 'react-toastify';
var firebaseConfig = {
  apiKey: "AIzaSyDjgDmdv9S_jwFjWe40QPkRqhWS851CHGc",
  authDomain: "vcg-ddd93.firebaseapp.com",
  projectId: "vcg-ddd93",
  storageBucket: "vcg-ddd93.appspot.com",
  messagingSenderId: "1081274223991",
  appId: "1:1081274223991:web:70e73921369464b9852572"
};
const firebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(firebaseApp);

export const requestFirebaseNotificationPermission = async () => {
  (await indexedDB.databases()).forEach(one => {
    indexedDB.deleteDatabase(one.name)
  })

  return deleteToken(messaging).then(async () => {
    return getToken(messaging).then((currentToken) => {
      if (currentToken) {

        return (currentToken)
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {


        // shows on the UI that permission is required 
      }
    }).catch((err) => {

      // catch error while creating client token
    })
  }).catch(async () => {
    return getToken(messaging).then((currentToken) => {
      if (currentToken) {

        return (currentToken)
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {


        // shows on the UI that permission is required 
      }
    }).catch((err) => {
      console.log(err)
      toast.error("error in notifications system!")
      // catch error while creating client token
    })
  })
}
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });