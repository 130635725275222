import { all } from 'redux-saga/effects';
import { AuthSaga } from './auth/saga'
import allCategoriesSaga from './categories/saga';
import { TransferSaga } from './Transfer/saga';
import { JobsSaga } from './HRM/JobTitle/saga';
import { DepartmentsSaga } from './HRM/departments/saga';
import { NumsSaga } from './Currenciess/numcats/saga';
import { CurrSaga } from './Currenciess/currencies/saga';
import { CountriesSaga } from './countries/countries/saga';
import { CompaniesSaga } from './countries/companies/saga';
import { OfficesSaga } from './countries/offices/saga';
import { LiscensesSaga } from './countries/Liscense/saga';
import { ChatSaga } from './chat/saga';
import { EmployeeSaga } from './HRM/employee/saga';
import { languagesSaga } from './LinkedLanguages/saga';
import WhatsappSaga from './whatsapp/saga';
import TranslateSaga from './translate/saga'
import NotificationTemplateSaga from './notiTemp/saga'
import TicketSettingSaga from './ticketSetting/saga'
import TicketsSaga from './statics/saga'
import EmpTicketsSaga from './statics_emp/saga'
import BrokerTicketsSaga from './statics_broker/saga'
export default function* rootSaga(getState: any): any {
    yield all([
        AuthSaga(),
        allCategoriesSaga(),
        languagesSaga(),
        TransferSaga(),
        JobsSaga(),
        DepartmentsSaga(),
        NumsSaga(),
        CurrSaga(),
        CountriesSaga(),
        CompaniesSaga(),
        OfficesSaga(),
        LiscensesSaga(),
        ChatSaga(),
        EmployeeSaga(),
        WhatsappSaga(),
        TranslateSaga(),
        NotificationTemplateSaga(),
        TicketSettingSaga(),
        TicketsSaga(),
        EmpTicketsSaga(),
        BrokerTicketsSaga()
    ]);

}
