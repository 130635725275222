import PackageCart from "./svg/package_cart"
import Time from "./svg/time"
import WaitingIcon from "./svg/usdt_right"
import ProfileNo from "../../WhatsApp/assets/images/profileNo.png"
import { useSelector } from "react-redux"
import date from "date-and-time"
import SmallFail from "./svg/smallFail"
import EditIcon from "./svg/edit"
import { REACT_APP_BASE_FILE_URL } from "../../../../urls"

export default function HashTrack({
    created_at,
    rejected_at,
    rejected_reason,
    approved_at,
    approved_by_user_id,
    approved_by_user_full_name,
    approved_by_contact_id,
    approved_by_contact_name,
    approved_by_contact_profile_photo,
    received_at,
    received_by_user_id,
    received_by_user_full_name,
    applied_at,
    applied_by_contact_id,
    applied_by_contact_name,
    applied_by_contact_profile_photo,
    openOrders,
    setOpenDetail,
    ticket_status_id,
    ticket_logs,
    comment
}) {
    const employees = useSelector(state => state.employee.employee)

    const getTime = (t) => {
        let diff = new Date().getTime() - new Date(t).getTime()
        if (diff / 1000 < 60) {
            return (`${(diff / 1000).toFixed(0)} s`)
        } else if (diff / 1000 < 3600) {
            return (`${(diff / 60000).toFixed(0)} m`)
        } else if (diff / 1000 < (3600 * 24)) {
            return (`${(diff / 3600000).toFixed(0)} h`)
        } else if (diff / 1000 > (3600 * 24)) {
            return (`${(diff / (3600000 * 24)).toFixed(0)} d`)
        }
    }
    const logEvents = [{
        id: 2,
        name: "Edit Name"
    }, {
        id: 3,
        name: "Edit Rate"
    }, {
        id: 4,
        name: "Edit Account Number"
    }, {
        id: 5,
        name: "Edit Documents"
    }, {
        id: 6,
        name: "Edit Trading Number"
    }]
    // const example_ticket_logs = [
    //     {
    //         event: 2,
    //         created_by_user_id: 2,
    //         created_by_user_name: "ahmad",
    //         created_at: "02-02-2020",
    //         old_value: "abbas",
    //         new_value: "abdo"
    //     }
    // ]
    const getDiffBetween = ({ date1, date2 }) => {
        let first = new Date(date1)
        let second = date2 ? new Date(date2) : new Date()
        // console.log(date1, first)
        // console.log(date2, second)
        let diff = (second.getTime() / 1000) - (first.getTime() / 1000)
        diff = (diff > 0) ? diff : -diff
        let days = parseInt(diff / (60 * 60 * 24)).toFixed(0)
        let hrs = parseInt(diff / (60 * 60) - days * 24).toFixed(0)
        let min = parseInt((diff / (60) - hrs * 60 - days * 24 * 60)).toFixed(0)
        let seconds = parseInt((diff - min * 60 - hrs * 60 - days * 24 * 60)).toFixed(0)

        if (days > 0) {
            return `${days} d`
        } else if (hrs > 0) {
            return `${hrs} h`
        }
        else if (min > 0) {
            return `${min} m`
        } else {
            return `${seconds} s`
        }
    }
    return (<div
        className={`${openOrders ? "employee_status_area_all_in_with_order" : "employee_status_area_all_in"}`}
        onClick={() => setOpenDetail(false)}
        style={{
            backgroundColor: "#EFEFEF"
        }}

    >
        <div className='employee_status_area_all_element'>
            <>
                <div className='employee_status_area_all_element_row'>
                    <div className="employee_status_area_element_name_row">
                        <div className="employee_status_area_element_name_icon_row">
                            <PackageCart color1={"#404040"} color2={"#404040"} />
                        </div>
                        <div className="employee_status_area_element_name_name_row">
                            <div className="employee_status_area_element_name_name_row_statusname">
                                Recieved &nbsp;
                            </div>
                            <div className="employee_status_area_element_name_name_row_username">
                                <img
                                    style={{
                                        width: "15px", height: "15px", borderRadius: "20px"
                                    }}
                                    alt=""
                                    src={received_by_user_id ? REACT_APP_BASE_FILE_URL + employees.filter(one => one.id === received_by_user_id)[0]?.avatar : (approved_by_contact_profile_photo || ProfileNo)} />
                                "{received_by_user_full_name}"
                            </div>
                        </div>
                    </div>
                    <div className="employee_status_area_element_time_row">
                        <div className="employee_status_area_element_time_time_row">
                            {getTime(received_at)}
                        </div>
                        <div className="employee_status_area_element_time_icon_row">
                            <Time color={"#8E8E8E"} />
                        </div>
                    </div>
                </div>
                <div className='employee_status_area_all_element_row'>
                    <div className="employee_status_area_element_name_row">
                        <div className="employee_status_area_element_name_icon_row"></div>
                        <div className="employee_status_area_element_name_name_row_current">
                            <WaitingIcon size={10} /> Waiting Approve
                        </div>
                    </div>
                    <div className="employee_status_area_element_time_row">
                        <div className="employee_status_area_element_time_time_row">
                            {getDiffBetween({ date1: received_at, date2: approved_at })}
                        </div>
                        <div className="employee_status_area_element_time_icon_row">
                            <Time color={"#000000"} />
                        </div>
                    </div>
                </div>
                {ticket_logs?.map((log, index) => {
                    return (<><div className='employee_status_area_all_element_row'>
                        <div className="employee_status_area_element_name_row">
                            <div className="employee_status_area_element_name_icon_row">
                                <EditIcon />
                            </div>
                            <div className="employee_status_area_element_name_name_row">
                                <div className="employee_status_area_element_name_name_row_statusname">
                                    {logEvents.filter(one => one.id === parseInt(log.event))[0]?.name} &nbsp;
                                </div>
                                <div className="employee_status_area_element_name_name_row_username">
                                    <img
                                        style={{
                                            width: "15px", height: "15px", borderRadius: "20px"
                                        }}
                                        alt=""
                                        src={log.created_by_user_id ? REACT_APP_BASE_FILE_URL + employees.filter(one => one.id === log.created_by_user_id)[0]?.avatar : ProfileNo} />
                                    "{log.created_by_user_name}"
                                </div>
                            </div>
                        </div>
                        <div className="employee_status_area_element_time_row">
                            <div className="employee_status_area_element_time_time_row">
                                {getTime(log.created_at)}
                            </div>
                            <div className="employee_status_area_element_time_icon_row">
                                <Time color={"#8E8E8E"} />
                            </div>
                        </div>
                    </div>
                        <div className='employee_status_area_all_element_row'>
                            <div className="employee_status_area_element_name_row">
                                <div className="employee_status_area_element_name_icon_row"></div>
                                <div className="employee_status_area_element_name_name_row_current">
                                    {log.old_value} {` => `} {log.new_value}
                                </div>
                            </div>
                        </div>
                    </>)
                })}
                {(ticket_status_id === 3 || ticket_status_id === 4 || ticket_status_id === 7) && <>
                    <div className='employee_status_area_all_element_row'>
                        <div className="employee_status_area_element_name_row">
                            <div className="employee_status_area_element_name_icon_row">
                                <PackageCart color1={"#007CFF"} color2={"#007CFF"} />
                            </div>
                            <div className="employee_status_area_element_name_name_row">
                                <div className="employee_status_area_element_name_name_row_statusname">
                                    Approved &nbsp;
                                </div>
                                <div className="employee_status_area_element_name_name_row_username">
                                    <img
                                        style={{
                                            width: "15px", height: "15px", borderRadius: "20px"
                                        }}
                                        alt=""
                                        src={approved_by_user_id ? REACT_APP_BASE_FILE_URL + employees.filter(one => one.id === approved_by_user_id)[0]?.avatar : (approved_by_contact_profile_photo || ProfileNo)} />
                                    "{approved_by_contact_name || approved_by_user_full_name}"
                                </div>
                            </div>
                        </div>
                        <div className="employee_status_area_element_time_row">
                            <div className="employee_status_area_element_time_time_row">
                                {getTime(approved_at)}
                            </div>
                            <div className="employee_status_area_element_time_icon_row">
                                <Time color={"#8E8E8E"} />
                            </div>
                        </div>
                    </div>
                    <div className='employee_status_area_all_element_row'>
                        <div className="employee_status_area_element_name_row">
                            <div className="employee_status_area_element_name_icon_row"></div>
                            <div className="employee_status_area_element_name_name_row_current">
                                <WaitingIcon size={10} /> Waiting Apply
                            </div>
                        </div>
                        <div className="employee_status_area_element_time_row">
                            <div className="employee_status_area_element_time_time_row">
                                {getDiffBetween({ date1: approved_at, date2: applied_at })}
                            </div>
                            <div className="employee_status_area_element_time_icon_row">
                                <Time color={"#000000"} />
                            </div>
                        </div>
                    </div>
                </>}
                {(ticket_status_id === 4 || ticket_status_id === 7) && <>
                    <div className='employee_status_area_all_element_row'>
                        <div className="employee_status_area_element_name_row">
                            <div className="employee_status_area_element_name_icon_row">
                                <PackageCart color1={"#15A80F"} color2={"#15A80F"} />
                            </div>
                            <div className="employee_status_area_element_name_name_row">
                                <div className="employee_status_area_element_name_name_row_statusname">
                                    Applied &nbsp;
                                </div>
                                <div className="employee_status_area_element_name_name_row_username">
                                    <img
                                        style={{
                                            width: "15px", height: "15px", borderRadius: "20px"
                                        }}
                                        alt=""
                                        src={applied_by_contact_profile_photo || ProfileNo} />
                                    "{applied_by_contact_name}"
                                </div>
                            </div>
                        </div>
                        <div className="employee_status_area_element_time_row">
                            <div className="employee_status_area_element_time_time_row">
                                {getTime(applied_at)}
                            </div>
                            <div className="employee_status_area_element_time_icon_row">
                                <Time color={"#8E8E8E"} />
                            </div>
                        </div>
                    </div>
                    <div className='employee_status_area_all_element_row'>
                        <div className="employee_status_area_element_name_row">
                            <div className="employee_status_area_element_name_icon_row"></div>
                            <div className="employee_status_area_element_name_name_row_current">
                                <WaitingIcon size={10} /> Done
                            </div>
                        </div>
                        <div className="employee_status_area_element_time_row">
                            <div className="employee_status_area_element_time_time_row">
                                {getTime(applied_at)}
                            </div>
                            <div className="employee_status_area_element_time_icon_row">
                                <Time color={"#000000"} />
                            </div>
                        </div>
                    </div>
                </>}
            </>
        </div>
    </div >)
}