import React from 'react'

function Filter() {
  return (
    <>
    <svg className='normal' id="_15x15_photo_back" data-name="15x15 photo back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
  <g id="Mask_Group_166" data-name="Mask Group 166">
    <g id="Layer_28" data-name="Layer 28" transform="translate(-1.426 -1.428)">
      <path id="Path_19051" data-name="Path 19051" d="M1.814,3.571h8.9v.357A1.071,1.071,0,0,0,11.786,5h1.429a1.071,1.071,0,0,0,1.071-1.071V3.571H15.95a.357.357,0,0,0,0-.714H14.286V2.5a1.071,1.071,0,0,0-1.071-1.071H11.786A1.071,1.071,0,0,0,10.714,2.5v.357h-8.9a.357.357,0,0,0,0,.714ZM11.429,2.5a.357.357,0,0,1,.357-.357h1.429a.357.357,0,0,1,.357.357V3.929a.357.357,0,0,1-.357.357H11.786a.357.357,0,0,1-.357-.357Z" fill="#8e8e8e"/>
      <path id="Path_19052" data-name="Path 19052" d="M16,14.286H12.857v-.357a1.071,1.071,0,0,0-1.071-1.071H10.357a1.071,1.071,0,0,0-1.071,1.071v.357H1.821a.357.357,0,1,0,0,.714H9.286v.357a1.071,1.071,0,0,0,1.071,1.071h1.429a1.071,1.071,0,0,0,1.071-1.071V15H16a.357.357,0,1,0,0-.714Zm-3.857,1.071a.357.357,0,0,1-.357.357H10.357A.357.357,0,0,1,10,15.357V13.929a.357.357,0,0,1,.357-.357h1.429a.357.357,0,0,1,.357.357Z" fill="#8e8e8e"/>
      <path id="Path_19053" data-name="Path 19053" d="M6.071,10.714H7.5A1.071,1.071,0,0,0,8.571,9.643V9.286h7.464a.357.357,0,1,0,0-.714H8.571V8.214A1.071,1.071,0,0,0,7.5,7.143H6.071A1.071,1.071,0,0,0,5,8.214v.357H1.857a.357.357,0,0,0,0,.714H5v.357A1.071,1.071,0,0,0,6.071,10.714Zm-.357-2.5a.357.357,0,0,1,.357-.357H7.5a.357.357,0,0,1,.357.357V9.643A.357.357,0,0,1,7.5,10H6.071a.357.357,0,0,1-.357-.357Z" fill="#8e8e8e"/>
    </g>
  </g>
</svg>
<svg className='active-svg' id="_15x15_photo_back" data-name="15x15 photo back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
  <g id="Mask_Group_167" data-name="Mask Group 167">
    <g id="Layer_28" data-name="Layer 28" transform="translate(-1.426 -1.428)">
      <path id="Path_19054" data-name="Path 19054" d="M1.814,3.571h8.9v.357A1.071,1.071,0,0,0,11.786,5h1.429a1.071,1.071,0,0,0,1.071-1.071V3.571H15.95a.357.357,0,0,0,0-.714H14.286V2.5a1.071,1.071,0,0,0-1.071-1.071H11.786A1.071,1.071,0,0,0,10.714,2.5v.357h-8.9a.357.357,0,0,0,0,.714Z" fill="#8e8e8e"/>
      <path id="Path_19055" data-name="Path 19055" d="M16,14.286H12.857v-.357a1.071,1.071,0,0,0-1.071-1.071H10.357a1.071,1.071,0,0,0-1.071,1.071v.357H1.821a.357.357,0,1,0,0,.714H9.286v.357a1.071,1.071,0,0,0,1.071,1.071h1.429a1.071,1.071,0,0,0,1.071-1.071V15H16a.357.357,0,1,0,0-.714Z" fill="#8e8e8e"/>
      <path id="Path_19056" data-name="Path 19056" d="M6.071,10.714H7.5A1.071,1.071,0,0,0,8.571,9.643V9.286h7.464a.357.357,0,1,0,0-.714H8.571V8.214A1.071,1.071,0,0,0,7.5,7.143H6.071A1.071,1.071,0,0,0,5,8.214v.357H1.857a.357.357,0,0,0,0,.714H5v.357A1.071,1.071,0,0,0,6.071,10.714Z" fill="#8e8e8e"/>
    </g>
  </g>
</svg>

    </>
  )
}

export default Filter