import React from 'react'
import { useState } from 'react'
import X from "./xicon.svg"

function SelectInput(props) {
    const {lang, setLang,data} = props
    const [focused,setFocused]=useState(false)
const getValue=()=>{
if(lang)
if(data[0]?.translation){
return data?.filter((a)=>a.id===lang||(lang.id&&(a.id===lang.id)))[0].translation.name

}
else if(data[0]?.translations)
return data?.filter((a)=>a.id===lang)[0]?.translations?.filter((d)=>d.language_code==="en")[0].name
else
return ""
}
  return (
    <>
    {props.code&&
    <svg className='lang-input-icon' xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14">
      <text id="AR" transform="translate(0 11)" fill="#CECDCD" font-size="10" font-family="SegoeUI, Segoe UI" letter-spacing="0.025em"><tspan x="0" y="0">{props.code.toUpperCase()}</tspan></text>
      </svg>}
<input onBlur={()=>{setTimeout(() => {
    setFocused(false)
}, 600);}} onClick={(e)=>{setFocused(true)}} onFocus={(e)=>{props.onFocus?props.onFocus(e):console.log()}} id={props.id} placeholder={props.lang&&props.lang.length===0?props.placeholder:""}  className={`${props.pad&&"pad20"}`} style={props.pad?{paddingLeft:"20px",cursor:"pointer"}:{paddingLeft:"0px",cursor:"pointer"}} readOnly={props.disabled}  value={getValue()} inputMode={props.numeric?props.numeric[0]:""} type={props.numeric?props.numeric[0]:"text"}/>
{props.clear&&<img  style={{bottom:"6px"}} onClick={()=>props.clear()} alt="close" src={X}/>}
 {focused&& <div className="input-sync" style={{top:"30px",width:"120px",overflow:"scroll",maxHeight:"200px"}}>
  {data.map((a)=>(
 <div
 className="input-sync-item" onClick={()=>{setLang(a.id); setFocused(false)}}>
     <div className="input-sync-text" style={{margin:"0px"}}>{a?.translations?a.translations.filter((d)=>d.language_code==="en")[0].name:a?.translation.name}</div>
 </div>
))}
  </div>}

    </>
  )
}

export default SelectInput