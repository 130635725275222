export default function Delt() {
  return (<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
    <g id="Mask_Group_264" data-name="Mask Group 264" >
      <g id="trash">
        <path id="Path_21182" data-name="Path 21182" d="M6.174,2.156a1.875,1.875,0,0,1,3.123.79H5.7a1.875,1.875,0,0,1,.471-.79Zm-2.115.79a3.482,3.482,0,0,1,6.881,0h2.988a.8.8,0,1,1,0,1.607H12.857v8.839A1.607,1.607,0,0,1,11.25,15H3.75a1.607,1.607,0,0,1-1.607-1.607V4.554H1.071a.8.8,0,1,1,0-1.607Zm1.3,3.35a.67.67,0,0,1,.67.67v4.287a.67.67,0,1,1-1.339,0V6.966A.67.67,0,0,1,5.357,6.3Zm4.955.67a.67.67,0,1,0-1.339,0v4.287a.67.67,0,1,0,1.339,0Z" fill="#404040" fillRule="evenodd" />
      </g>
    </g>
  </svg>)
}

