import React from 'react'
import Sidebar from './Sidebar'

function Dashboard(props) {
  return (
    <div>

       <Sidebar  {...props}/> 
    </div>
  )
}

export default Dashboard