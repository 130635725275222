import React, { memo } from "react"
import Icon from "../../../components/Icon"
import TicketIcon from "./TicketIcon";
import Cam from "../../../assets/images/cam";
import Rep from "../../../components/svgs/return";
import Voc from "../../../assets/images/voc";
import Cons from "../../../assets/images/cons";
import Vid from "../../../assets/images/vid";
import File from "../../../assets/images/fl";

const ChatSidebar = ({ active, isForward, closeSidebar, heading, children, message, value, onChange, searchedMessages, isStatics, count, setCount, withBody, setWithBody }) => {
	return (
		<aside className={` ${isStatics ? " chat-sidebar--statics" : `chat-sidebar ${active ? "chat-sidebar--active" : ""}`}`}>
			<header className="header chat-sidebar__header">
				<button onClick={() => {
					closeSidebar()
					if (isStatics) {
						setCount(null)
					}
				}}>
					<Icon id="cancel" className="chat-sidebar__header-icon" />
				</button>
				<h2 className="chat-sidebar__heading"> {heading}</h2>
				{isStatics ? <div className="top_options_tickets_count">
					<div className="top_options_tickets_count_text">{count}</div>
					<TicketIcon />
				</div> : <></>}
			</header>
			{isForward ? <>
				{(message && message?.id && (message.type === "ptt" || message.type === "audio")) ? <>
					<div className="forward_image_preview">
						<div className="forward_image_preview_text">
							<Voc /> Voice
						</div>
					</div>
				</> : <></>}
				{(message && message?.id && message.type === "ticket") ? <>
					<div className="forward_image_preview">
						<div className="forward_image_preview_text">
							Ticket Message {message.ticket?.request_number}
						</div>
					</div>
				</> : <></>}
				{(message && message?.id && message.type === "document") ? <>
					<div className="forward_image_preview">
						<div className="forward_image_preview_text">
							<File />File
						</div>
					</div>
				</> : <></>}
				{(message && message?.id && message.type === "vcard") ? <>
					<div className="forward_image_preview">
						<div className="forward_image_preview_text">
							<Cons /> Contact
						</div>
					</div>
				</> : <></>}
				{(message && message?.id && message.type === "chat") ? <>
					<div className="forward_image_preview">
						<div className="forward_image_preview_text">
							{message.body}
						</div>
					</div>
				</> : <></>}
				{(message && message?.id && (message.type === "image" || message.type === "sticker")) ? <>
					<div className="forward_image_preview">
						<div className="forward_image_preview_image"> <img
							alt="forward message"
							src={message.media}
						/></div>
						<div className="forward_image_preview_text">
							{withBody && message.body ? <>{message.body}
								<button onClick={() => {
									setWithBody(false)
								}} className="forward_image_preview_text_cancel">
									<Icon id="cancel" className="chat-sidebar__header-icon" />
								</button></> : <>
								<button onClick={() => {
									setWithBody(true)
								}} className="forward_image_preview_text_cancel">
									{message.body && <Rep />}
								</button>
								<Cam /> Image
							</>}
						</div>
					</div>
				</> : <></>}
				{(message && message?.id && message.type === "video") ? <>
					<div className="forward_image_preview">
						<div className="forward_image_preview_image"> <video
							alt="forward message"
							src={message.media}
							autoPlay
							muted
							loop
						/></div>
						<div className="forward_image_preview_text">
							{withBody && message.body ? <>{message.body}
								<button onClick={() => {
									setWithBody(false)
								}} className="forward_image_preview_text_cancel">
									<Icon id="cancel" className="chat-sidebar__header-icon" />
								</button></> : <>
								<button onClick={() => {
									setWithBody(true)
								}} className="forward_image_preview_text_cancel">
									{message.body && <Rep />}
								</button>
								<Vid /> Video
							</>}
						</div>
					</div>
				</> : <></>}
			</> : <></>}
			<div className="chat-sidebar__content">{children}</div>
		</aside>
	)
}

export default memo(ChatSidebar);
