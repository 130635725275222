export default function Check() {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
        <g id="Group_11715" data-name="Group 11715" transform="translate(-508 -519)">
            <g id="Group_10478" data-name="Group 10478" transform="translate(508 519)">
                <g id="check">
                    <path id="Path_20962" data-name="Path 20962" d="M6.037,14.014a.435.435,0,0,1-.722,0L2.432,10.3a1.2,1.2,0,0,1,0-1.395l.361-.465a.652.652,0,0,1,1.083,0l1.8,2.318L10.539,4.5a.652.652,0,0,1,1.083,0l.361.465a1.2,1.2,0,0,1,0,1.395Z" transform="translate(-2.208 -4.207)" fill="#47c40e" />
                </g>
            </g>
        </g>
    </svg>
    )
}