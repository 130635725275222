export default function EnterUser() {
    return (<svg id="_20x20" data-name="20x20" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 20 20">
        <g id="Mask_Group_470" data-name="Mask Group 470">
            <g id="arrow-2" transform="translate(-0.666 -0.666)">
                <path id="Path_22008" data-name="Path 22008" d="M1.569,12.865a.667.667,0,0,0-.433.837,10,10,0,1,0,0-6.071.667.667,0,0,0,1.271.4,8.667,8.667,0,1,1,0,5.262.667.667,0,0,0-.837-.433Z" fill="#3066cc" />
                <path id="Path_22009" data-name="Path 22009" d="M9.222,15a.667.667,0,0,0,1.132.477L14.8,11.144a.667.667,0,0,0,0-.955L10.354,5.856a.667.667,0,0,0-1.132.477V10H1.333a.667.667,0,1,0,0,1.333H9.222Z" fill="#3066cc" />
            </g>
        </g>
    </svg>
    )
}