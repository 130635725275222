const initialState = {
  jobs: [],
  data: { translations: [{ name: "", language_code: "en" }, { name: "", language_code: "ar" }, { name: "", language_code: "tr" }], icon: null },
  mode: "read",
  open: false,
  modalData: { name: "", icon: null, code: "" },
  loading: false,
  Active_Ids: [],
  request_running: false,
  request_data: null,
  message: "",
  worktypes: [],
  lis_loading: false,
  error: false,
  lis: [],
  img_upload: { type: "", status: false },
  workoffices: []

}

export const JobReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "GET_WORKOFF_RED": {
      let arr = []
      payload.map((a) => {
        if (arr.filter((aer) => aer.id !== a.id)) {
          arr.push(a)
        }
      })
      return ({
        ...state,
        workoffices: arr,

      })
    }
    case "ASYNC_LOAD_REG": {
      return ({
        ...state,
        lis_loading: true
      })
    }

    case "ASYNC_DONE_LIS": {
      return ({
        ...state,
        lis: payload,
        lis_loading: false
      })
    }
    case "GET-WORK": {
      return ({
        ...state,
        worktypes: payload
      })
    }
    case "IMG-UPLOAD-JOB": {
      return ({
        ...state,
        img_upload: { ...payload }
      })
    }
    case "request-running-job": {
      return ({
        ...state,
        request_running: true,
        request_data: payload
      })
    }
    case "GENERAL-ERR-job": {
      return ({
        ...state,
        request_running: false,
        request_data: payload.data,
        error: true,
        message: payload.message
      })
    }
    case "request-running-job-red": {

      return ({
        ...state,
        request_running: false,
        request_data: payload,
        error: false,
        message: ""
      })
    }

    case "GENERAL-LOADING-JOB": {
      return ({
        ...state,
        loading: true
      })
    }
    case "VIEW-JOB": {
      return ({
        ...state,
        view_language: payload
      })
    }
    case "GENERAL-DONE-JOB": {
      return ({
        ...state,
        loading: false,
        open: false,


      })
    }
    case "LEVEL-DONE-JOB": {
      return ({
        ...state,
        loading: false
      })
    }
    case "GET-JOB-REDUCER": {
      return ({ ...state, jobs: [...payload], request_data: null, request_running: false, error: false, message: "" })
    }
    case "Active-add": {
      return ({
        ...state,
        Active_Ids: payload
      })
    }
    case "NEW-JOB": {
      return {
        ...state,
        data: { ...payload }
      }
    }
    case "OPEN-JOB": {
      return ({
        ...state,
        open: payload
      })
    }
    case "MODE-JOB": {
      return ({
        ...state,
        mode: payload
      })
    }
    case "MOD-DATA-JOB": {
      return ({
        ...state,
        modalData: { ...payload }
      })
    }
    default:
      return state
  }
}
