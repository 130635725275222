export default function HashLastTrack({
    status
}) {
    if (status === 2) {
        return "Waiting Approve"
    }
    else if (status === 3) {
        return "Waiting Apply"
    }
    else {
        return "done"
    }
}