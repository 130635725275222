import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import Spinner from '../../../Spinner'
import SearchIcon from "../../assets/images/search"
import Edit from "../../assets/images/edit"

const SearchComponent = (props) => {
  const loading = useSelector((state) => state.whatsapp.search_progress)
  const loading_contacts = useSelector((state) => state.whatsapp.search_progress_contact)
  return (
    <div className="search-component">
      {
        props.svg === "search" ? ((loading && props.value.length > 0) ? <Spinner /> : <SearchIcon />) :
          ((loading_contacts && props.value.length > 0) ? <Spinner /> : <Edit />)
      }
      <input onChange={(e) => { props.onChange(e); }} placeholder={props.placeholder} value={props.value} />
    </div>
  )
}
export default memo(SearchComponent)