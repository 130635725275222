import React from 'react'
import { trans } from "../../../../../Internationalization"
function OcassionsIcon(props) {
  return (
    <div style={{ marginRight: "20px" }} className='icon-lang' onClick={() => props.change()}>

      <div className='icon-lang-desc'>
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
          <defs>
            <filter id="Ellipse_319" x="0" y="0" width="24" height="24" filterUnits="userSpaceOnUse">
              <feOffset dy="3" input="SourceAlpha" />
              <feGaussianBlur stdDeviation="3" result="blur" />
              <feFlood floodOpacity="0.2" />
              <feComposite operator="in" in2="blur" />
              <feComposite in="SourceGraphic" />
            </filter>
          </defs>
          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Ellipse_319)">
            <circle id="Ellipse_319-2" data-name="Ellipse 319" cx="3" cy="3" r="3" transform="translate(9 6)" fill="#404040" />
          </g>
        </svg>
        <div className='icon-lang-desc-bu hst contries'>{trans("form_occ")}</div>
      </div>

      <svg className={`${props.active && "activated-hrm"}`} id="_15x15_photo_back" data-name="15x15 photo back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">

        <g id="Mask_Group_129" data-name="Mask Group 129" >
          <g id="party-popper" transform="translate(-1.068 -1.025)">
            <path id="Path_18929" data-name="Path 18929" d="M8.577,13.41c-.586.216-1.33.489-2.271.838C4.7,14.024,3.009,13.5,2.279,12.3c.266-.716.5-1.344.7-1.9A11,11,0,0,0,8.577,13.41Z" fill="#8e8e8e" />
            <path id="Path_18930" data-name="Path 18930" d="M9.044,8.049c-1.564-1.563-3.578-2.718-4.4-1.9-.175.175-.122.071-1.34,3.374,1.3,1.873,4.578,3.181,6.862,3.3.683-.257.667-.27.772-.376C11.947,11.441,10.05,9.055,9.044,8.049Zm1.329,3.832c-.076.077-.432.081-1.062-.23a8.891,8.891,0,0,1-2.2-1.665c-1.7-1.7-2.1-3.061-1.9-3.266a.251.251,0,0,1,.177-.049,5.867,5.867,0,0,1,3.09,1.945,8.894,8.894,0,0,1,1.666,2.2C10.455,11.449,10.45,11.8,10.373,11.881Z" fill="#8e8e8e" />
            <path id="Path_18931" data-name="Path 18931" d="M4.839,14.793c-.925.344-1.992.742-3.231,1.207a.4.4,0,0,1-.515-.515c.312-.835.593-1.591.85-2.28A5.612,5.612,0,0,0,4.839,14.793Z" fill="#8e8e8e" />
            <path id="Path_18932" data-name="Path 18932" d="M10.506,2.081a2.59,2.59,0,0,0-.654.166c.246.377.759.857.445,1.484-.28.561-.863.528-1.369.545.31.424.669.854.393,1.408-.287.574-1.039.693-1.483.765a.266.266,0,0,1-.3-.215l-.1-.525A.268.268,0,0,1,7.654,5.4a2.566,2.566,0,0,0,.654-.166c-.247-.374-.758-.859-.446-1.484.28-.559.862-.528,1.369-.545-.311-.424-.669-.854-.393-1.408.287-.574,1.039-.693,1.482-.765a.265.265,0,0,1,.3.215l.1.525a.265.265,0,0,1-.215.311Z" fill="#8e8e8e" />
            <path id="Path_18933" data-name="Path 18933" d="M15.324,6.37l.525.1a.265.265,0,0,1,.215.3c-.072.443-.192,1.2-.765,1.482-.554.277-.984-.082-1.408-.393-.018.507.014,1.09-.545,1.369-.624.312-1.109-.2-1.484-.446a2.566,2.566,0,0,0-.166.654.267.267,0,0,1-.312.217l-.525-.1a.265.265,0,0,1-.215-.3c.072-.443.192-1.2.765-1.483.553-.277.984.082,1.408.393.018-.506-.015-1.089.545-1.369.627-.314,1.107.2,1.484.445a2.59,2.59,0,0,0,.166-.654.267.267,0,0,1,.311-.215Z" fill="#8e8e8e" />
            <path id="Path_18934" data-name="Path 18934" d="M13.728,11.217H12.713a.16.16,0,0,1-.16-.16v-.481a.16.16,0,0,1,.16-.16h1.015a.16.16,0,0,1,.16.16v.481A.16.16,0,0,1,13.728,11.217Z" fill="#8e8e8e" />
            <path id="Path_18935" data-name="Path 18935" d="M14.843,1.748l-.718.718a.16.16,0,0,1-.226,0l-.34-.34a.16.16,0,0,1,0-.226l.718-.718a.16.16,0,0,1,.226,0l.34.34a.161.161,0,0,1,0,.227Z" fill="#8e8e8e" />
            <path id="Path_18936" data-name="Path 18936" d="M6.517,3.472H6.036a.16.16,0,0,1-.16-.16V2.3a.16.16,0,0,1,.16-.16h.481a.16.16,0,0,1,.16.16V3.312A.16.16,0,0,1,6.517,3.472Z" fill="#8e8e8e" />
            <path id="Path_18937" data-name="Path 18937" d="M10.353,7.08l-.4-.267a.161.161,0,0,1-.045-.226,7.539,7.539,0,0,1,5.416-3.246.164.164,0,0,1,.167.164v.481a.157.157,0,0,1-.153.156,6.794,6.794,0,0,0-4.769,2.9.155.155,0,0,1-.215.041Z" fill="#8e8e8e" />
          </g>
        </g>
      </svg>
    </div>
  )
}

export default OcassionsIcon