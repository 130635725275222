export default function MaskedPhone() {
    return (<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
        <g id="Mask_Group_231" data-name="Mask Group 231">
            <g id="XMLID_7_" transform="translate(0.197)">
                <path id="XMLID_8_" d="M10.262,5.657A4.536,4.536,0,0,0,6.247,1.335C6.2,1.33,6.154,1.322,6.1,1.313a2.087,2.087,0,0,0-.34-.037.571.571,0,0,0-.612.513.593.593,0,0,0,.089.465.879.879,0,0,0,.632.272c.063.009.122.017.172.028C8.048,3,8.724,3.707,9.054,5.695c.008.049.012.108.016.172.014.238.044.732.576.732h0a.944.944,0,0,0,.141-.011c.5-.075.48-.527.472-.744a1.1,1.1,0,0,1,0-.157A.211.211,0,0,0,10.262,5.657Z" transform="translate(2.373 0.638)" fill="#95bdff" />
                <path id="XMLID_9_" d="M5.711,1.2c.059,0,.116.008.162.016C9.166,1.719,10.681,3.279,11.1,6.6a1.94,1.94,0,0,1,.009.2c0,.259.013.8.592.81h.018a.568.568,0,0,0,.43-.163.907.907,0,0,0,.158-.694c0-.055,0-.107,0-.153A6.711,6.711,0,0,0,6.016.009a.235.235,0,0,0-.041,0,.284.284,0,0,1-.04,0c-.034,0-.075,0-.12-.006S5.7,0,5.64,0a.6.6,0,0,0-.656.613C4.954,1.141,5.465,1.179,5.711,1.2Z" transform="translate(2.295)" fill="#95bdff" />
                <path id="XMLID_10_" d="M13.367,10.638c-.07-.053-.142-.109-.21-.163-.36-.289-.742-.556-1.112-.814l-.23-.161a2.3,2.3,0,0,0-1.3-.495,1.688,1.688,0,0,0-1.4.89.747.747,0,0,1-.641.389,1.273,1.273,0,0,1-.516-.13A6.212,6.212,0,0,1,4.7,6.994c-.3-.678-.2-1.122.327-1.483A1.5,1.5,0,0,0,5.856,4.2,7.515,7.515,0,0,0,3.211.588a1.5,1.5,0,0,0-1.021,0A2.955,2.955,0,0,0,.351,2.1a2.812,2.812,0,0,0,.04,2.269,18.309,18.309,0,0,0,4.018,6.116A19.475,19.475,0,0,0,10.5,14.531a3.375,3.375,0,0,0,.6.179c.056.013.1.023.139.033a.234.234,0,0,0,.059.008h.019A3.458,3.458,0,0,0,14.2,12.566C14.556,11.547,13.9,11.043,13.367,10.638Z" transform="translate(-0.131 0.249)" fill="#95bdff" />
                <path id="XMLID_11_" d="M6.055,2.6a.693.693,0,0,0-.729.42.563.563,0,0,0,.023.489A.732.732,0,0,0,5.9,3.8,1.305,1.305,0,0,1,7.114,5.1.617.617,0,0,0,7.7,5.71h0a.579.579,0,0,0,.07,0c.373-.044.554-.319.538-.815a2.327,2.327,0,0,0-.726-1.574A2.166,2.166,0,0,0,6.055,2.6Z" transform="translate(2.445 1.297)" fill="#95bdff" />
            </g>
        </g>
    </svg>
    )
}