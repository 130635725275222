import React from 'react'

function Translated() {
  return (
    <>
      <svg className='normal' id="_15x15_photo_back" data-name="15x15 photo back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
        <g id="Mask_Group_161" data-name="Mask Group 161">
          <g id="translate-2">
            <g id="g825" transform="translate(0 15)">
              <g id="g827">
                <g id="g829" clipPath="url(#clipPath-2)">
                  <g id="g835" transform="translate(10.967 -3.934)">
                    <path id="path837" d="M0,0-.41-1.445-.819,0ZM-1.718.974A.273.273,0,0,1-1.709.927L-.8-2.033A.382.382,0,0,1-.41-2.241c.179,0,.353.066.4.207L.894.927A.208.208,0,0,1,.9.974c0,.146-.221.254-.386.254-.1,0-.184-.033-.207-.122L.132.48H-.946l-.179.626c-.024.089-.1.122-.207.122-.165,0-.386-.1-.386-.254" fill="#8e8e8e" />
                  </g>
                  <g id="g839" transform="translate(13.384 -8.441)">
                    <path id="path841" d="M0,0H-5.648A1.18,1.18,0,0,0-6.825,1.177V4.472L-8.473,5.648l1.647,1.177A1.18,1.18,0,0,0-5.648,8H0A1.18,1.18,0,0,0,1.177,6.825V1.177A1.18,1.18,0,0,0,0,0Z" fill="none" stroke="#8e8e8e" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.879" />
                  </g>
                  <g id="g843" transform="translate(6.559 -6.559)">
                    <path id="path845" d="M0,0H-4.942A1.18,1.18,0,0,1-6.119-1.177V-6.825A1.18,1.18,0,0,1-4.942-8H.706A1.18,1.18,0,0,1,1.883-6.825L3.53-5.648,1.883-4.472v2.589" fill="none" stroke="#8e8e8e" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.879" />
                  </g>
                  <g id="g847" transform="translate(4.95 -10.949)">
                    <path id="path849" d="M0,0A1.871,1.871,0,0,1-.509,1.053,1.871,1.871,0,0,1-1.018,0ZM.841,1.628A1.864,1.864,0,0,1-.12,1.363,2.364,2.364,0,0,0,.5,0H.841a.248.248,0,0,0,.248-.248A.248.248,0,0,0,.841-.5h-1.1v-.6a.248.248,0,0,0-.248-.248A.248.248,0,0,0-.757-1.1v.6h-1.1a.248.248,0,0,0-.248.248A.248.248,0,0,0-1.859,0h.342A2.364,2.364,0,0,0-.9,1.363a1.865,1.865,0,0,1-.961.266.248.248,0,0,0-.248.248.248.248,0,0,0,.248.248A2.358,2.358,0,0,0-.509,1.7a2.358,2.358,0,0,0,1.35.423.248.248,0,1,0,0-.5" fill="#8e8e8e" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
      <svg className='active-svg' id="_15x15_photo_back" data-name="15x15 photo back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
        <g id="Mask_Group_159" data-name="Mask Group 159">
          <g id="translate">
            <path id="Path_19045" data-name="Path 19045" d="M13.384,6.119H7.735A1.618,1.618,0,0,0,6.119,7.735V10.8L4.656,11.849a.439.439,0,0,0,0,.715l1.481,1.058A1.619,1.619,0,0,0,7.735,15h5.648A1.618,1.618,0,0,0,15,13.384V7.735A1.618,1.618,0,0,0,13.384,6.119Zm-1.9,6.175c-.1,0-.184-.033-.207-.122l-.179-.626H10.021l-.179.626c-.024.089-.1.122-.207.122-.165,0-.386-.1-.386-.254a.272.272,0,0,1,.009-.047l.909-2.961a.382.382,0,0,1,.391-.207c.179,0,.353.066.4.207l.909,2.961a.208.208,0,0,1,.009.047C11.871,12.186,11.649,12.294,11.485,12.294Zm0,0" fill="#8e8e8e" />
            <path id="Path_19046" data-name="Path 19046" d="M10.148,11.066h.819l-.41-1.445Zm0,0" fill="#8e8e8e" />
            <path id="Path_19047" data-name="Path 19047" d="M5.24,7.735a2.483,2.483,0,0,1,.55-1.56,2.358,2.358,0,0,1-1.35-.423,2.357,2.357,0,0,1-1.35.423.248.248,0,0,1,0-.5,1.866,1.866,0,0,0,.961-.266,2.364,2.364,0,0,1-.62-1.363H3.09a.248.248,0,1,1,0-.5h1.1v-.6a.248.248,0,1,1,.5,0v.6h1.1a.248.248,0,1,1,0,.5H5.449a2.364,2.364,0,0,1-.62,1.363,1.863,1.863,0,0,0,.961.266.248.248,0,0,1,.247.23,2.486,2.486,0,0,1,1.7-.669H8.881V4.2l1.463-1.045a.44.44,0,0,0,0-.715L8.863,1.378A1.619,1.619,0,0,0,7.265,0H1.616A1.618,1.618,0,0,0,0,1.616V7.265A1.618,1.618,0,0,0,1.616,8.881H5.24Zm0,0" fill="#8e8e8e" />
            <path id="Path_19048" data-name="Path 19048" d="M4.44,5.1A1.871,1.871,0,0,0,4.95,4.051H3.931A1.872,1.872,0,0,0,4.44,5.1Zm0,0" fill="#8e8e8e" />
          </g>
        </g>
      </svg>

    </>
  )
}

export default Translated