import { all, fork, put, takeEvery } from "@redux-saga/core/effects"
import { toast } from "react-toastify"
import store from "../store"
import { REACT_APP_ACCOUNTING_URL, REACT_APP_USER_SERVICE_URL } from "../../urls";
import axios from "axios";

function* getCreateDataTicket() {
  const base_user = REACT_APP_ACCOUNTING_URL;
  const token = store.getState()?.whatsapp?.userTokenWhatsapp;
  let AxiosInstance = axios.create({
    baseURL: `${base_user}`,
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
  try {
    const response = yield AxiosInstance.get(REACT_APP_ACCOUNTING_URL + "/api/v1/tickets/create");
    yield put({
      type: "GET_CREATE_DATA_TICKETS_REDUCER",
      payload: response.data.data
    })
  } catch (e) {
    toast.error("failed! BACKEND ERROR");
  }
}

function* watchGetCreateDataTicket() {
  yield takeEvery("GET_CREATE_DATA_TICKETS", getCreateDataTicket);
}

function* CreateTicket(action) {
  yield put({ type: "CREATE_LOAD_TICKET" })
  const base_user = REACT_APP_ACCOUNTING_URL;
  const token = store.getState()?.whatsapp?.userTokenWhatsapp;
  let AxiosInstance = axios.create({
    baseURL: `${base_user}`,
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
  try {
    yield AxiosInstance.post(
      REACT_APP_ACCOUNTING_URL + "/api/v1/tickets", JSON.stringify(action.payload)
    );
    toast.success("the ticket has been sent successfully!")
    yield put({ type: "CREATE_LOAD_TICKET_END" })
  } catch (e) {
    yield put({ type: "CREATE_LOAD_TICKET_FAILED" })
    toast.error("failed! BACKEND ERROR");
  }
}

function* watchCreateTicket() {
  yield takeEvery("ADD-TRF", CreateTicket);
}

function* getClients(action) {
  const base_user = REACT_APP_ACCOUNTING_URL;
  const token = store.getState()?.whatsapp?.userTokenWhatsapp;
  let AxiosInstance = axios.create({
    baseURL: `${base_user}`,
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
  try {
    let a = yield AxiosInstance.get(REACT_APP_ACCOUNTING_URL + "/api/v1/tickets/ticket_clients?search=" + action.payload);
    yield put({ type: "CLIENT_ASYNC", payload: a.data.data });
  } catch (e) {
    yield put({ type: "CLIENT_ASYNC", payload: [] });
  }
}
function* watchClients() {
  yield takeEvery("CLIENT_ASYNC_SAGA", getClients);
}
export function* TransferSaga() {
  yield all([
    fork(watchCreateTicket),
    fork(watchClients),
    fork(watchGetCreateDataTicket)
  ]);
}
