export default function ChangeUser() {
    return (<>
        <svg
            className="bord"
            xmlns="http://www.w3.org/2000/svg"
            width="350"
            height="50"
            viewBox="0 0 350 50"
        >
            <g
                id="Rectangle_4720"
                data-name="Rectangle 4720"
                fill="#f7f7f7"
                stroke="#ddd"
                strokeWidth="0.5"
            >
                <rect width="350" height="50" rx="15" stroke="none" />
                <rect
                    x="0.25"
                    y="0.25"
                    width="349.5"
                    height="49.5"
                    rx="14.75"
                    fill="none"
                />
            </g>
        </svg>
        <svg
            id="_15x15_photo_back"
            data-name="15x15 photo back"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="15"
            height="15"
            viewBox="0 0 15 15"
        >
            <g id="Mask_Group_212" data-name="Mask Group 212">
                <g id="exchange">
                    <g id="Group_7948" data-name="Group 7948">
                        <g id="Group_7947" data-name="Group 7947">
                            <path
                                id="Path_19229"
                                data-name="Path 19229"
                                d="M9.167,13.9A6.615,6.615,0,0,1,1.313,9.836a6.542,6.542,0,0,1-.1-4.385A6.66,6.66,0,0,1,3,2.654L2.849,3.9l.877.106.332-2.754L1.339.934l-.1.877,1.219.144A7.548,7.548,0,0,0,.373,5.176,7.5,7.5,0,0,0,9.39,14.757Z"
                                fill="#8e8e8e"
                            />
                        </g>
                    </g>
                    <g id="Group_7950" data-name="Group 7950">
                        <g id="Group_7949" data-name="Group 7949">
                            <path
                                id="Path_19230"
                                data-name="Path 19230"
                                d="M14.545,10.062A7.5,7.5,0,0,0,5.184.37l.274.84a6.611,6.611,0,0,1,6.107,11.5l.164-1.359-.877-.106L10.519,14l2.719.322.1-.877-1.115-.132A7.517,7.517,0,0,0,14.545,10.062Z"
                                fill="#8e8e8e"
                            />
                        </g>
                    </g>
                    <g
                        id="Group_7954"
                        data-name="Group 7954"
                        transform="translate(1.149 -0.508)"
                    >
                        <g id="Group_7953" data-name="Group 7953">
                            <path
                                id="Path_19232"
                                data-name="Path 19232"
                                d="M8.12,7.744a1.873,1.873,0,1,0-2.91,0A2.649,2.649,0,0,0,3.547,10.2v.442H9.788V10.2A2.649,2.649,0,0,0,8.12,7.744Z"
                                fill="#8e8e8e"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </>)
}