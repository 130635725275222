import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Application from '../../../../assets/svg/Application'
import Filter from '../../../../assets/svg/Filter'
import Search from '../../../../assets/svg/search'
import Sort from '../../../../assets/svg/Sort'
import Translated from '../../../../assets/svg/Translated'
import Curre from "../Languages/svgs/Countries.svg"
import FollowerIcon from "../Languages/svgs/FollowerIcon"
import CompaniesIcon from './Companies/CompaniesIcon'
import CompaniesList from './Companies/Countries'
import CountriesList from './Countries/Countries'
import CountriesIcon from './Countries/CountriesIcon'
import LicensesList from './Liscenses/Countries'
import LiscenseIcon from './Liscenses/LiscenseIcon'
import OcassionsIcon from './Ocassions/OcassionsIcon'
import OfficesList from './Offices/Countries'
import OfficesIcon from './Offices/OfficesIcon'
import { trans } from "../../../../Internationalization"
function Countries(props) {
  const getName=(child)=>{
    if(child.region_translations.filter((s)=>s.language_code===lang).length>0){
      return child.region_translations.filter((s)=>s.language_code===lang)[0].region_name
    }
    else return child.region_translations.filter((s)=>s.language_code==="en")[0].region_name
  }
  const [lang, setLang] = useState(null)
  const translated_lang = useSelector((state) => state.auth.lang)
  const getLang = () => {
    if (lang) {
      return (lang)
    }
    else {
      return translated_lang
    }
  }
  const [lang_id, setLang_id] = useState(null)
  const view = useSelector(state => state.countries.view)

  const [languageFollower, setLanguageFollower] = useState([{ name: "Countries", id: 0 }])

  const dispatch = useDispatch()
  useEffect(() => {

  }, [])
  const makeFollower = (level, value, id) => {
    if (id === 0) {
      setLang_id(null)

    }

    else setLang_id(id)
    if (languageFollower.length >= level) {
      let arr = languageFollower
      arr[level] = { name: value, id: id }
      arr = arr.filter((a, index) => index <= level)
      setLanguageFollower([...arr])
    } else {
      let arr = languageFollower
      setLanguageFollower([...languageFollower, { name: value, id: id }])

    }
  }
  const languages = useSelector(state => state.langs.languages)
  const [office, setOffice] = useState(false)
  const addItem = (e) => {
    switch (view) {
      case "Countries": {
        setLang_id(null)
        setLanguageFollower([{ name: "Countries", id: 0 }])
        Add()
      }
      case "Offices": {
        setLanguageFollower([{ name: "Offices", id: 0 }])
        setLang_id(0)
      }
        break;

      default:
        break;
    }
  }
  useEffect(() => {
  }, [lang_id])
  const showRoute = () => {
    switch (view) {
      case "Countries":
        return (<CountriesList lang={getLang()} active={props.active} view={view} setModalData={(e) => setModalData(e)} setLang_id={(e) => setLang_id(e)} lang_id={lang_id} open={open} setOpen={(e) => setOpen(e)} Add={(e) => Add(e)} Close={(e) => Close(e)} Update={(e) => Update(e)} Read={(e) => Read(e)} makeFollower={(e, n, id) => makeFollower(e, n, id)} setLanguageFollower={(level, val) => setLanguageFollower(level, val)} languageFollower={languageFollower} />)

      case "Communications Companies":
        return <CompaniesList lang={getLang()} active={props.active} view={view} setModalData={(e) => setModalDataCom(e)} setLang_id={(e) => setLang_id(e)} lang_id={lang_id} open={openCom} setOpen={(e) => setOpenCom(e)} Add={(e) => AddCom(e)} Close={(e) => CloseCom(e)} Update={(e) => UpdateCom(e)} Read={(e) => { if (languageFollower.length === 2) Read(e); else ReadCom(e) }} makeFollower={(e, n, id) => makeFollower(e, n, id)} setLanguageFollower={(level, val) => setLanguageFollower(level, val)} languageFollower={languageFollower} />
      case "Offices":
        return <OfficesList close={() => setOffice(false)} office={office} lang={getLang()} active={props.active} view={view} setModalData={(e) => setModalDataCom(e)} setLang_id={(e) => setLang_id(e)} lang_id={lang_id} makeFollower={(e, n, id) => makeFollower(e, n, id)} setLanguageFollower={(level, val) => setLanguageFollower(level, val)} languageFollower={languageFollower} />
      case "Electricty Companies":
        return <CompaniesList lang={getLang()} active={props.active} view={view} setModalData={(e) => setModalDataCom(e)} setLang_id={(e) => setLang_id(e)} lang_id={lang_id} open={openCom} setOpen={(e) => setOpenCom(e)} Add={(e) => AddCom(e)} Close={(e) => CloseCom(e)} Update={(e) => UpdateCom(e)} Read={(e) => { if (languageFollower.length === 2) Read(e); else ReadCom(e) }} makeFollower={(e, n, id) => makeFollower(e, n, id)} setLanguageFollower={(level, val) => setLanguageFollower(level, val)} languageFollower={languageFollower} />
      case "Water Companies":
        return <CompaniesList lang={getLang()} active={props.active} view={view} setModalData={(e) => setModalDataCom(e)} setLang_id={(e) => setLang_id(e)} lang_id={lang_id} open={openCom} setOpen={(e) => setOpenCom(e)} Add={(e) => AddCom(e)} Close={(e) => CloseCom(e)} Update={(e) => UpdateCom(e)} Read={(e) => { if (languageFollower.length === 2) Read(e); else ReadCom(e) }} makeFollower={(e, n, id) => makeFollower(e, n, id)} setLanguageFollower={(level, val) => setLanguageFollower(level, val)} languageFollower={languageFollower} />
      case "Gas Companies":
        return <CompaniesList lang={getLang()} active={props.active} view={view} setModalData={(e) => setModalDataCom(e)} setLang_id={(e) => setLang_id(e)} lang_id={lang_id} open={openCom} setOpen={(e) => setOpenCom(e)} Add={(e) => AddCom(e)} Close={(e) => CloseCom(e)} Update={(e) => UpdateCom(e)} Read={(e) => { if (languageFollower.length === 2) Read(e); else ReadCom(e) }} makeFollower={(e, n, id) => makeFollower(e, n, id)} setLanguageFollower={(level, val) => setLanguageFollower(level, val)} languageFollower={languageFollower} />
      case "Licenses":
        return <LicensesList close={() => setOffice()} lang={getLang()} active={props.active} view={view} setModalData={(e) => setModalDataCom(e)} setLang_id={(e) => setLang_id(e)} lang_id={lang_id} makeFollower={(e, n, id) => makeFollower(e, n, id)} setLanguageFollower={(level, val) => setLanguageFollower(level, val)} languageFollower={languageFollower} />
      default:

        break;
    }
  }
  const countries = useSelector(state => state.countries.countries)
  const companies = useSelector(state => state.companies.countries)

  useEffect(() => {
    if (props.active) {
      if (view === "Countries" && countries.length === 0) {
        dispatch({ type: "GET-REG" })
      } else if (view === "Communications Companies") {
        // dispatch({type:"GET-COM-REDUCER",payload:[{id:1,region_translations:[{region_name:"usa",language_code:"en"}], communication_companies:[{id:2,translations:[{name:"usa",language_code:"en"}], short_name:"a", company_phone_codes:"asas,9999,9999,999", services:[{id:3,translations:[{name:"usa",language_code:"en"}], short_name:"a"},{id:4,translations:[{name:"usa",language_code:"en"}], short_name:"a"}]}]}]})
        dispatch({ type: "GET-COM-REDUCER", payload: [] })

        dispatch({ type: "GET-COM", id: 1 })
      }
      if (view === "Water Companies") {
        dispatch({ type: "GET-COM-REDUCER", payload: [] })

        dispatch({ type: "GET-COM", id: 2 })

      }
      else if (view === "Gas Companies") {
        dispatch({ type: "GET-COM-REDUCER", payload: [] })

        dispatch({ type: "GET-COM", id: 3 })

      }
      else if (view === "Electricty Companies") {
        dispatch({ type: "GET-COM-REDUCER", payload: [] })

        dispatch({ type: "GET-COM", id: 4 })
      }
    }
  }, [view, props.active])
  //Countries Functions Start
  const open = useSelector(state => state.countries.open)
  const setOpen = (e) => {
    dispatch({ type: "OPEN-REG", payload: e })
  }
  const mode = useSelector(state => state.countries.mode)
  useEffect(() => {
    setOpen(false)

  }, [view])
  const setMode = (e) => {
    dispatch({ type: "MODE-REG", payload: e })
  }
  const setModalData = (e) => {
    dispatch({ type: "MOD-DATA-REG", payload: { name: e.name, icon: e.icon } })
  }
  const setData = (e) => {
    dispatch({ type: "NEW-REG", payload: { ...e } })
  }
  const Update = (e) => {
    dispatch({ type: "request-running-REG-red", paylod: null })
    setMode("update");
    let obj = { map_photo_path: null, region_translations: [], outline_photo_path_1: null, flag_photo_path: null, outline_photo_path_2: null, sub_regions: [], phone_code: "", post_code: "", gmt_difference: "", languages: [], currencies: [], is_veriffied_from_us: 1 }
    setData(obj);
    if (e.region_translations.length !== languages.length) {
      obj.icon = e.icon
      obj.language_code = e.language_code
      languages.map((d) => {
        if (e.region_translations.filter((asd) => asd.language_code?.toLowerCase() === d.language_code?.toLowerCase()).length > 0)
          obj.region_translations = [...obj.region_translations, e.region_translations.filter((asd) => asd.language_code?.toLowerCase() === d.language_code?.toLowerCase())[0]]
        else
          obj.region_translations = [...obj.region_translations, { region_name: "", language_code: d.language_code }]
      })
      setData({ ...e, region_translations: [...obj.region_translations] });
    }
    else {
      setData({ ...e });
    }
    setModalData({ name: getName(e) + " ", icon: null, code: "Country" });
    setMode("update");
    setOpen(true);
  }
  const Read = (e) => {
    let obj = { map_photo_path: null, region_translations: [], outline_photo_path_1: null, flag_photo_path: null, outline_photo_path_2: null, sub_regions: [], phone_code: "", post_code: "", gmt_difference: "", languages: [], currencies: [], is_veriffied_from_us: 1 }
    setData(obj);
    if (e.region_translations.length !== languages.length) {
      obj.icon = e.icon
      obj.language_code = e.language_code
      languages.map((d) => {
        if (e.region_translations.filter((asd) => asd.language_code?.toLowerCase() === d.language_code?.toLowerCase()).length > 0)
          obj.region_translations = [...obj.region_translations, e.region_translations.filter((asd) => asd.language_code?.toLowerCase() === d.language_code?.toLowerCase())[0]]
        else
          obj.region_translations = [...obj.region_translations, { region_name: "", language_code: d.language_code }]
      })
      setData({ ...e, region_translations: [...obj.region_translations] });
    }
    else {
      setData({ ...e });
    }

    setModalData({ name: getName(e), icon: e.icon, code: e.region_translations.filter((a) => a.language_code === "en")[0].region_description })
    setMode("read");
    setOpen(true);
  }
  const Close = () => {
    let obj = { map_photo_path: null, region_translations: [], outline_photo_path_1: null, flag_photo_path: null, outline_photo_path_2: null, sub_regions: [], phone_code: "", post_code: "", gmt_difference: "", languages: [], currencies: [], is_veriffied_from_us: 1 }
    languages.map((a) => {
      obj = { ...obj, region_translations: [...obj.region_translations, { region_name: "", region_description: "", language_code: a.language_code }] }
    })
    setOpen(false);
    setData(obj);
    setModalData({ name: "", icon: null, code: "" })
  }
  const Add = (e) => {
    setOpen(false);
    dispatch({ type: "request-running-REG-red", paylod: null })

    let obj = { map_photo_path: null, region_translations: [], outline_photo_path_1: null, flag_photo_path: null, outline_photo_path_2: null, sub_regions: [], phone_code: "", post_code: "", gmt_difference: "", languages: [], currencies: [], is_veriffied_from_us: 1 }
    setData(obj);
    setData(obj)
    languages.map((a) => {
      obj = { ...obj, region_translations: [...obj.region_translations, { region_name: "", region_description: "", language_code: a.language_code }] }
    })
    if (e) {
      obj = { ...obj, parent_region_id: e.id, office_post_code: null }
      setModalData({ name: " City", icon: null, code: "City" });
    }
    else {
      obj = { ...obj, parent_region_id: null }
      setModalData({ name: "Country", icon: null, code: "Country" })
    }
    setData(obj);
    setMode("add");
    if (languageFollower.length === 1) {
      setData({ ...obj, is_country: 1, region_type_id: 1 })
    }
    if (languageFollower.length === 2) {
      setData({ ...obj, region_type_id: 2, is_city: 1 })
    }
    if (languageFollower.length === 3) {
      setData({ ...obj, region_type_id: 8 })
    }
    if (languageFollower.length === 4) {
      setData({ ...obj, region_type_id: 3, is_district: 1 })
    }
    if (languageFollower.length >= 5) {
      setData({ ...obj, region_type_id:4, is_street: 1 })
    }

    setTimeout(() => {
      setOpen(true);
    }, 100);

  }
  //Countries Functions End
  //Compaines Functions Start
  const openCom = useSelector(state => state.companies.open)
  const setOpenCom = (e) => {
    dispatch({ type: "OPEN-COM", payload: e })
  }
  const modeCom = useSelector(state => state.companies.mode)
  useEffect(() => {
    setOpenCom(false)

  }, [view])
  const setModeCom = (e) => {
    dispatch({ type: "MODE-COM", payload: e })
  }
  const setModalDataCom = (e) => {
    dispatch({ type: "MOD-DATA-COM", payload: { name: e.name, icon: e.icon } })
  }
  const setDataCom = (e) => {
    dispatch({ type: "NEW-COM", payload: { ...e } })
  }
  const UpdateCom = (e) => {
    dispatch({ type: "request-running-COM-red", paylod: null })
    setModeCom("update");
    let obj = { subscriptions: [], regions: [], regs: "", registered_as: "", subscription_data: { files: [] }, short_name: "", translations: [], company_phone_codes: "", country_id: "", photo_path: "", flat_photo_path: "", subscription_no: "", subscription_date: "", company_id: "", company_regions: [] }
    setDataCom({ ...obj, regs: "", company_regions: [] });

    if (!e.registered_as) {
      if (e.translations.length !== languages.length) {
        obj.icon = e.icon
        obj.language_code = e.language_code
        languages.map((d) => {
          if (e.translations.filter((asd) => asd.language_code?.toLowerCase() === d.language_code?.toLowerCase()).length > 0)
            obj.translations = [...obj.translations, e.translations.filter((asd) => asd.language_code?.toLowerCase() === d.language_code?.toLowerCase())[0]]
          else
            obj.translations = [...obj.translations, { name: "", language_code: d.language_code }]
        })
        obj = { ...e, translations: [...obj.translations] }
        if (view === "Communications Companies")
          obj = { ...obj, company_type_id: 1 }
        if (view === "Water Companies")
          obj = { ...obj, company_type_id: 2 }
        if (view === "Gas Companies")
          obj = { ...obj, company_type_id: 3 }
        if (view === "Electricty Companies")
          obj = { ...obj, company_type_id: 4 }
        setDataCom({ ...e, regs: "", company_regions: [], translations: [...obj.translations], company_type_id: obj.company_type_id });

      }
      else {
        if (view === "Communications Companies")
          obj = { ...obj, company_type_id: 1 }
        if (view === "Water Companies")
          obj = { ...obj, company_type_id: 2 }
        if (view === "Gas Companies")
          obj = { ...obj, company_type_id: 3 }
        if (view === "Electricty Companies")
          obj = { ...obj, company_type_id: 4 }
        setDataCom({ ...e, company_type_id: obj.company_type_id, regs: "", company_regions: [] });
      }
      setModalDataCom({ name: e.translations.filter((a) => a.language_code === "en")[0].name + " ", icon: null, code: "Country" });

    }
    else {
      setDataCom({ ...e, regs: "", company_regions: [] })
      setModalDataCom({ name: e.registered_as + " ", icon: null, code: "Country" });

    }


    setModeCom("update");
    setOpenCom(true);
  }
  const ReadCom = (e) => {
    let obj = { subscriptions: [], regions: [], regs: "", registered_as: "", subscription_data: { files: [] }, short_name: "", translations: [], company_phone_codes: "", country_id: "", photo_path: null, flat_photo_path: null, subscription_no: "", subscription_date: "", company_id: "", company_regions: [] }

    setDataCom(obj);
    if (!e.registered_as) {
      if (e.translations.length !== languages.length) {
        obj.icon = e.icon
        obj.language_code = e.language_code
        languages.map((d) => {
          if (e.translations.filter((asd) => asd.language_code?.toLowerCase() === d.language_code?.toLowerCase()).length > 0)
            obj.translations = [...obj.translations, e.translations.filter((asd) => asd.language_code?.toLowerCase() === d.language_code?.toLowerCase())[0]]
          else
            obj.translations = [...obj.translations, { name: "", language_code: d.language_code }]
        })
        setDataCom({ ...e, company_regions: [], translations: [...obj.translations], subscriptions: [], registered_as: "", subscription_data: { files: [] }, subscription_no: "", subscription_date: "", company_id: "", company_regions: [], regs: "" });
      }
      else {
        setDataCom({ ...e, company_regions: [], subscriptions: [], registered_as: "", subscription_data: { files: [] }, subscription_no: "", subscription_date: "", company_id: "", company_regions: [], regs: "" });
      }
      setModalDataCom({ name: e.translations.filter((a) => a.language_code === "en")[0].name, icon: e.icon, code: e.translations.filter((a) => a.language_code === "en")[0].code })

    }
    else {
      setDataCom({ ...e, regs: "", company_regions: [] })
      setModalDataCom({ name: e.registered_as, icon: e.icon, code: "" })

    }

    setModeCom("read");
    setOpenCom(true);
  }
  const CloseCom = () => {
    let obj = { subscriptions: [], regions: [], company_regions: [], registered_as: "", subscription_data: { files: [] }, short_name: "", translations: [], company_phone_codes: "", country_id: "", photo_path: null, flat_photo_path: null, subscription_no: "", subscription_date: "", company_id: "", company_regions: [] }

    languages.map((a) => {
      obj = { ...obj, translations: [...obj.translations, { name: "", code: "", language_code: a.language_code }] }
    })
    setOpenCom(false);
    setDataCom(obj);
    setModalDataCom({ name: "", icon: null, code: "" })
  }
  const AddCom = (e) => {
    setOpenCom(false);
    dispatch({ type: "request-running-COM-red", paylod: null })

    let obj = { subscriptions: [], regions: [], regs: "", registered_as: "", subscription_data: { files: [] }, service_id: e.id, short_name: "", translations: [], company_phone_codes: "", country_id: "", photo_path: "", flat_photo_path: "", subscription_no: "", company_name: "", date: "", company_id: "", company_regions: [] }

    if (view === "Communications Companies")
      obj = { ...obj, company_type_id: 1 }
    if (view === "Water Companies")
      obj = { ...obj, company_type_id: 2 }
    if (view === "Gas Companies")
      obj = { ...obj, company_type_id: 3 }
    if (view === "Electricty Companies")
      obj = { ...obj, company_type_id: 4 }
    setDataCom(obj);
    setDataCom(obj)
    languages.map((a) => {
      obj = { ...obj, regions: [], translations: [...obj.translations, { name: "", code: "", language_code: a.language_code }] }
    })
    if (e) {
      if (e.services) {
        obj = { ...obj, company_id: e.id }

      }
      else if (e.subscriptions) {
        obj = { ...obj, service_id: e.id }

      }
      else {
        obj = { ...obj, country_id: e.id }
      }
      setModalDataCom({ name: " City", icon: null, code: "City" });
    }
    else {
      obj = { ...obj, country_id: null }
      setModalDataCom({ name: "Country", icon: null, code: "Country" })
    }
    setDataCom(obj);
    setModeCom("add");
    setTimeout(() => {
      setOpenCom(true);
    }, 100);

  }
  //Companies Functions End
  const AddOption = () => {
    if (view === "Countries") {
      setLang_id(null)
      Add()

    }
    if (view === "Offices") {
      setLanguageFollower([{ name: "Offices", id: 0 }])
      setLang_id(0)
    }
    if (view.includes("Companies")) {
      setLang_id(companies.filter((a) => a.id === languageFollower[languageFollower.length - 1].id)[0].id)
      AddCom(companies.filter((a) => a.id === languageFollower[languageFollower.length - 1].id)[0])
    }
    if (view === "Licenses") {
      setLanguageFollower([{ name: "Licenses", id: 0 }])
      setLang_id(0)
    }
  }
  const disab = () => {
    if (view === "Countries") {
      if (languageFollower.length === 1) {
        return false
      }
      else {
        return true
      }
    }
    if (view === "Offices") {
      return false
    }
    if (view.includes("Companies")) {
      if (languageFollower.length === 1 || languageFollower.length > 2)
        return true
      else
        return false
    }
    if (view === "Licenses") {
      return false
    }
  }
  return (
    <div className={`languages-page ${props.active && "Active-page"}`}>

      <div className='lang-top'>
        <div className='lang-info'>
          <div className='lang-icon header-icon'>
            <img alt="REG" src={Curre} />
          </div>
          <div className='lang-name' onClick={() => { makeFollower(0, view, 0); }}>{trans("countries")} </div>
          <div className='follower-list'>
            {languageFollower.map((fo, index) => {
              if (fo.name !== "Countries")
                return (
                  <div className='follower' onClick={() => makeFollower(index, fo.name, fo.id)}>
                    <span><FollowerIcon expand={false} /></span>
                    <span>{fo.name}</span>
                  </div>

                )
            })}
          </div>
        </div>
        {/* <div className='lang-items'>
            <div className='lang-items'>
                    {languages.map(lang => <div className='lang-item'>{lang.language_code.toUpperCase()}</div>)}
            </div>
            </div> */}
        <div className='top-options'>
          <div className='top-option'>
            <span><Search /></span>
          </div>
          <div className='top-option langs'>
            <span><Translated /></span>
            <div className='lang-items'>
              {languages.map(langs => <div onClick={() => dispatch({ type: "TRANSLATE", payload: langs.language_code })} onMouseLeave={() => setLang(null)} onMouseEnter={() => setLang(langs.language_code)} className={'lang-item' + ` ${translated_lang?.toLowerCase() === langs.language_code?.toLowerCase() && "selected-lang"}`}>{langs.language_code.toUpperCase()}</div>)}
            </div>
          </div>
          <div className='top-option'>
            <span><Sort /></span>
          </div>
          <div className='top-option'>
            <span><Filter /></span>
          </div>
          <div className='top-option'>
            <span><Application /></span>
          </div>
          <svg className={'lines'} xmlns="http://www.w3.org/2000/svg" width="0.5" height="25.5" viewBox="0 0 0.5 25.5">
            <line id="Line_992" data-name="Line 992" y1="25" transform="translate(0.25 0.25)" fill="none" stroke="#8e8e8e" strokeLinecap="round" strokeWidth="0.5" />
          </svg>
        </div>
        <div className={`lang-add currency-add`}>
          <CompaniesIcon view={view} active={view === "Communications Companies" || view === "Water Companies" || view === "Gas Companies" || view === "Electricty Companies"} change={(e) => { dispatch({ type: "REG-VIEW", payload: e }); setLanguageFollower([{ name: e, id: 0 }]); setLang_id(null) }} />
          <LiscenseIcon active={view === "Licenses"} change={() => { dispatch({ type: "REG-VIEW", payload: "Licenses" }); setLanguageFollower([{ name: "Licenses", id: 0 }]); setLang_id(null) }} />
          <OfficesIcon view={view} active={view === "Offices"} change={() => { dispatch({ type: "REG-VIEW", payload: "Offices" }); setLanguageFollower([{ name: "Offices", id: 0 }]); setLang_id(null); }} />

          <OcassionsIcon active={view === "Formal Occasions"} change={() => { dispatch({ type: "REG-VIEW", payload: "Formal Occasions" }); setLanguageFollower([{ name: "Formal Occasions", id: 0 }]); setLang_id(null) }} />

          <CountriesIcon active={view === "Countries"} change={() => { dispatch({ type: "REG-VIEW", payload: "Countries" }); setLanguageFollower([{ name: "Countries", id: 0 }]); setLang_id(null) }} />
          <svg className={'lines'} style={{ marginRight: "20px", width: "1px" }} xmlns="http://www.w3.org/2000/svg" width="0.5" height="25.5" viewBox="0 0 0.5 25.5">
            <line id="Line_992" data-name="Line 992" y1="25" transform="translate(0.25 0.25)" fill="none" stroke="#8e8e8e" strokeLinecap="round" strokeWidth="0.5" />
          </svg>
          <svg className={disab() && 'disab'} onClick={() => { if (!disab()) AddOption() }} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">

            <g id="Mask_Group_16" data-name="Mask Group 16" transform="translate(-324 -333)">
              <path id="add-3" d="M13.235,5.714H9.286V1.765a1.786,1.786,0,0,0-3.571,0v3.95H1.765a1.786,1.786,0,0,0,0,3.571h3.95v3.95a1.786,1.786,0,0,0,3.571,0V9.286h3.95a1.786,1.786,0,0,0,0-3.571Z" transform="translate(324 333)" fill="#404040" />
            </g>
          </svg>


        </div>
      </div>
      {showRoute()}
    </div>
  )
}

export default Countries