import React from 'react'

function Search() {
  return (
    <>
    <svg className='normal' id="_15x15_photo_back" data-name="15x15 photo back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
    <g id="Mask_Group_165" data-name="Mask Group 165">
        <g id="_x32_-Magnifying_Glass" transform="translate(-2.969 -2.971)">
        <path id="Path_19050" data-name="Path 19050" d="M17.842,17.225l-3.408-3.408a6.54,6.54,0,0,0-9.547-8.93,6.54,6.54,0,0,0,8.93,9.547l3.408,3.408a.436.436,0,0,0,.617-.617ZM5.5,13.523a5.671,5.671,0,1,1,4.01,1.661A5.635,5.635,0,0,1,5.5,13.523Z" fill="#8e8e8e"/>
        </g>
    </g>
    </svg>
    <svg className='active-svg' id="_15x15_photo_back" data-name="15x15 photo back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
  <g id="Mask_Group_164" data-name="Mask Group 164">
    <g id="_x32_-Magnifying_Glass" transform="translate(-3.168 -3.166)">
      <path id="Path_19049" data-name="Path 19049" d="M17.9,16.65l-2.883-2.883a6.621,6.621,0,1,0-1.257,1.257l2.883,2.883A.889.889,0,1,0,17.9,16.65ZM6.357,13.185A4.827,4.827,0,1,1,9.77,14.6,4.833,4.833,0,0,1,6.357,13.185Z" fill="#8e8e8e"/>
    </g>
  </g>
</svg>

    </>
  )
}

export default Search