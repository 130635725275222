export default function EditIcon(props) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="10.021" height="9.968" viewBox="0 0 10.021 9.968">
        <g id="Group_11908" data-name="Group 11908">
            <path id="Path_21336" data-name="Path 21336" d="M9.734,6.317a.263.263,0,0,0-.263.263V8.912a.789.789,0,0,1-.788.788H1.313a.789.789,0,0,1-.788-.788V2.067a.789.789,0,0,1,.788-.788H3.645a.263.263,0,0,0,0-.525H1.313A1.315,1.315,0,0,0,0,2.067V8.912a1.315,1.315,0,0,0,1.313,1.313h7.37A1.315,1.315,0,0,0,10,8.912V6.579A.263.263,0,0,0,9.734,6.317Zm0,0" transform="translate(0 -0.23)" fill={props.color} />
            <path id="Path_21339" data-name="Path 21339" d="M13.651.2a.657.657,0,0,0-.929,0l-.279.279,1.237,1.237.279-.279a.657.657,0,0,0,0-.929Zm0,0" transform="translate(-4.13 0.016)" fill={props.color} />
            <path id="Path_21337" data-name="Path 21337" d="M6.148,5.106,9.983,1.271,11.22,2.508,7.384,6.343Zm0,0" transform="translate(-2.04 -0.402)" fill={props.color} />
            <path id="Path_21338" data-name="Path 21338" d="M5.211,9.12l1.367-.379L5.59,7.754Zm0,0" transform="translate(-1.729 -2.554)" fill={props.color} />
        </g>
    </svg>
    )
}