export default function TicketsBlue() {
    return (<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="10" height="10" viewBox="0 0 10 10">
        <g id="Mask_Group_253" data-name="Mask Group 253">
            <g id="Line_color" transform="translate(-0.118 -0.121)">
                <g id="Group_10542" data-name="Group 10542">
                    <g id="Group_10539" data-name="Group 10539">
                        <g id="Group_10538" data-name="Group 10538">
                            <g id="Group_10537" data-name="Group 10537">
                                <path id="Path_21342" data-name="Path 21342" d="M8.364,3.079c-2.089-1.292-4.238.754-4.238.754L1.85,6.109S.621,7.18,2.447,9s2.886.6,2.886.6L7.61,7.317s2.046-2.148.754-4.237ZM7.656,4.485a.494.494,0,1,1,0-.7A.493.493,0,0,1,7.656,4.485Z" fill="#69a8ff" />
                                <g id="Group_10536" data-name="Group 10536">
                                    <path id="Path_21343" data-name="Path 21343" d="M8.424,2.976a2.961,2.961,0,0,0-1.809-.432,4.053,4.053,0,0,0-1.532.478,4.862,4.862,0,0,0-1.165.849l-1.6,1.6c-.178.178-.355.358-.535.535a1.512,1.512,0,0,0-.22.269,1.56,1.56,0,0,0-.233.91A2.622,2.622,0,0,0,1.97,8.647c.633.792,1.727,1.746,2.833,1.4a1.478,1.478,0,0,0,.581-.336c.085-.081.166-.167.249-.25L7.285,7.812A6.7,6.7,0,0,0,8.092,6.9a3.843,3.843,0,0,0,.747-2.983,3.008,3.008,0,0,0-.372-.9c-.08-.132-.288-.011-.207.121a2.75,2.75,0,0,1,.395,1.74A3.827,3.827,0,0,1,8.178,6.3,4.593,4.593,0,0,1,7.4,7.361L5.837,8.92l-.556.556-.05.051c-.019.019-.04.038-.06.056l-.043.035c.024-.019-.028.02-.039.027a1.3,1.3,0,0,1-.772.232,2.311,2.311,0,0,1-1.364-.585c-.727-.586-1.673-1.616-1.309-2.64a1.336,1.336,0,0,1,.16-.306c.01-.014.04-.054.025-.034l.037-.045q.026-.03.054-.058l.263-.262L3.8,4.333a6.57,6.57,0,0,1,.835-.751,3.651,3.651,0,0,1,2.8-.754,2.793,2.793,0,0,1,.868.354c.132.081.253-.127.121-.207Z" fill="#3c3c59" />
                                    <path id="Path_21344" data-name="Path 21344" d="M7.572,4.4l-.026.024-.012.01c.017-.015,0,0,0,0a.557.557,0,0,1-.064.039l-.017.008c.014-.006,0,0-.008,0l-.014,0a.492.492,0,0,1-.07.018l-.014,0c.027,0,.009,0,0,0l-.034,0H7.283c-.008,0-.046-.005-.016,0A.547.547,0,0,1,7.206,4.5l-.032-.01L7.16,4.48c-.014,0,.018.009,0,0a.6.6,0,0,1-.06-.034l-.025-.017c.02.015-.01-.009-.015-.014a.573.573,0,0,1-.049-.052c.015.018,0-.006-.008-.012s-.014-.022-.021-.034l-.016-.031c-.01-.021,0,.014,0-.007a.558.558,0,0,1-.02-.07c0-.011,0-.022-.006-.033,0,.025,0,0,0-.011a.634.634,0,0,1,0-.072s0-.019,0,0,0,0,0-.008,0-.022.007-.033a.536.536,0,0,1,.021-.064c-.009.022,0,0,.006-.011l.017-.03.019-.029L7.015,3.9c-.01.014,0,0,0,0a.5.5,0,0,1,.047-.047l.016-.013s-.015.011,0,0l.033-.022L7.14,3.8l.018-.009s.017-.008,0,0l.022-.008.037-.011.028-.006.019,0s-.022,0-.008,0,.048,0,.072,0h.019s.019,0,0,0,0,0,0,0l.019,0a.491.491,0,0,1,.07.02l.014.005s-.018-.009-.006,0l.031.015.03.018.012.008s.024.018.012.008,0,0,0,0l.015.014.026.025.024.027s-.011-.015,0,0l.014.021a.616.616,0,0,1,.038.069c-.01-.021,0,0,0,.012s.008.025.011.037.006.025.008.038,0,.025,0,.008,0,0,0,.008a.491.491,0,0,1,0,.077s0,.025,0,.008,0,0,0,.008,0,.025-.008.038-.007.025-.011.037l-.007.018c-.006.016.009-.017,0,0a.723.723,0,0,1-.039.069l-.009.012c-.018.026.01-.009,0,0L7.572,4.4a.12.12,0,0,0,.17.17.623.623,0,0,0,.118-.7.614.614,0,0,0-.6-.344.613.613,0,1,0,.487,1.045.12.12,0,0,0-.17-.17Z" fill="#3c3c59" />
                                </g>
                            </g>
                        </g>
                    </g>
                    <g id="Group_10541" data-name="Group 10541">
                        <g id="Group_10540" data-name="Group 10540">
                            <path id="Path_21345" data-name="Path 21345" d="M8.531,3.246A4.058,4.058,0,0,0,8.864,1.2c-.047-.4-.2-1-.673-1.075S7.368.553,7.178.909a4.078,4.078,0,0,0-.46,1.96c.008.458.105,1.252.661,1.375.15.033.215-.2.064-.231a.506.506,0,0,1-.323-.306,1.817,1.817,0,0,1-.155-.667,3.889,3.889,0,0,1,.3-1.767A2.348,2.348,0,0,1,7.646.641.743.743,0,0,1,8.062.362c.37-.052.5.479.549.744a3.7,3.7,0,0,1-.287,2.018c-.061.14.146.263.207.121Z" fill="#3c3c59" />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
    )
}