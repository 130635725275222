import React from 'react'
import { useSelector } from 'react-redux'
import LanguagetItem from './LanguagetItem'

function LangChildreen(props) {
    const {lang_id, setLang_id} = props
    const Language = useSelector((state)=>state.languages.SelectedLanguage)
    const data = useSelector((state)=>state.langs.newLang)
    const requested_data = useSelector((state)=>state.langs.request_data)
    const mode = useSelector((state)=>state.langs.mode)
    const Active_Ids = useSelector((state)=>state.langs.mode)
    return (<>
        <div className='lang-childreen department'>
          {props.child.son_languages.map((child)=>(
              <LanguagetItem  languageFollower={props.languageFollower} 
              level={props.level}
              lang={props.lang}
              ActiveAdd={(e)=>props.ActiveAdd(e)}
              makeFollower={(e,w,id)=>props.makeFollower(e,w,id)} 
              accent={"accent"}
              lang_id={lang_id}
              Active_Ids={Active_Ids}
              setModalData={(e)=>props.setModalData(e)}
              setLang_id={(e)=>setLang_id(e)}
              child={child}
              open={props.open}
              Close={()=>props.Close()}
              Add={(e)=>props.Add(e)}
              Update={(e)=>props.Update(e)}
              Read={(e)=>props.Read(e)}
              setOpen={(e)=>props.setOpen(e)}/>
          ))} 
             {(((props.open)&&props.lang_id===props.child.language_code&&mode==="add")||(requested_data&&requested_data.parent_language_code===props.child.language_code)&&mode!=="update"&&mode!=="read")&& <LanguagetItem  lang={props.lang} {...props}  languageFollower={props.languageFollower}  new={"new"}  level={props.level}  makeFollower={(e,w,id)=>props.makeFollower(e,w,id)} accent={"accent"} lang_id={lang_id} setLang_id={(e)=>setLang_id(e)} child={requested_data?requested_data: data}/>}
      </div>
        {/* ATTENTION!! ADDING PROPS TO THE NEXT LINE languageFollower */}
        
    </>
  
    )
}

export default LangChildreen