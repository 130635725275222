

// data?.client.length &&
//   data?.phone_number.length &&
//   data?.mobile.length &&
//   data?.amount.length &&
//   data?.ticket_type.length &&
//   data?.ticket_currency_name.length &&
//   data?.ticket_local_currency_name.lemgth &&
//   data?.ticket_country_name.length &&
//   data?.ticket_city_name.length &&
//   data?.local_currency_amount.length &&
//   data?.ticket_payment_type_name.length &&
//   data?.ticket_operating_unit_name.length &&
//   data?.ticket_commission_type_name.length
const initialState = {
  newLang: {
    client: "",
    phone_number: "",
    mobile: "",
    sugar_client_id: "",
    amount: "",
    ticket_country_id: null,
    ticket_city_id: null,
    ticket_type: "deposit",
    ticket_currency_id: null,
    ticket_local_currency_id: null,
    ticket_currency_name: "",
    ticket_local_currency_name: "",
    ticket_country_name: "",
    ticket_city_name: "",
    ticketable_type: "deposit_odoo_ticket",
    address: "",
    local_currency_amount: "",
    trading_number: "",
    comment: "",
    ticket_payment_type_name: "",
    ticket_operating_unit_name: "",
    ticket_commission_type_name: "",
    ticket_payment_type_id: null,
    ticket_operating_unit_id: null,
    ticket_commission_type_id: null,
    arabic_name: "",
    files: [],
    rate: 0
  },
  Active_Ids: [],
  loading: false,
  img_upload: { type: "", status: false },
  countries: [],
  clients: [],
  create_load: false,
  client_load: false,
  create_data: {
    ticket_payment_types: [],
    ticket_operating_units: [],
    ticket_countries: [],
    ticket_cities: [],
    ticket_clients: [],
    ticket_types: [],
    currencies: []
  },
  reset: 0,
  localReset: 0,
  done: 0
}

export const TransferReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "ticket_modal_reseted": {
      return {
        ...state,
        localReset: state.localReset + 1
      }
    }
    case "REGION_ASYNC": {
      return ({
        ...state,
        countries: payload
      })
    }
    case "CLIENT_ASYNC": {
      return ({
        ...state,
        clients: payload,
        client_load: false
      })
    }
    case "CLIENT_ASYNC_SAGA": {
      return ({
        ...state,
        client_load: true
      })
    }
    case "NEW-TRF": {
      return {
        ...state,
        newLang: { ...payload }
      }
    }
    case "GET_CREATE_DATA_TICKETS_REDUCER": {
      return {
        ...state,
        create_data: payload,
        newLang: {
          ...state.newLang,
          ticket_currency_id: payload.default_currency_id
        }
      }
    }
    case "CREATE_LOAD_TICKET": {
      return {
        ...state,
        create_load: true
      }
    }
    case "CREATE_LOAD_TICKET_END": {
      return {
        ...state,
        create_load: false,
        newLang: {
          client: "",
          phone_number: "",
          mobile: "",
          sugar_client_id: "",
          amount: "",
          ticket_country_id: null,
          ticket_city_id: null,
          ticket_type: "deposit",
          ticket_currency_id: null,
          ticket_local_currency_id: null,
          ticket_currency_name: "",
          ticket_local_currency_name: "",
          ticket_country_name: "",
          ticket_city_name: "",
          ticketable_type: "deposit_odoo_ticket",
          address: "",
          local_currency_amount: "",
          trading_number: "",
          comment: "",
          ticket_payment_type_name: "",
          ticket_operating_unit_name: "",
          ticket_commission_type_name: "",
          ticket_payment_type_id: null,
          ticket_operating_unit_id: null,
          ticket_commission_type_id: null,
          arabic_name: "",
          files: []
        },
        reset: state.reset + 1,
        done: state.done + 1
      }
    }
    case "modal_closed_ticket": {
      return {
        ...state,
        done: 0
      }
    }
    case "CREATE_LOAD_TICKET_FAILED": {
      return {
        ...state,
        create_load: false
      }
    }
    default:
      return state
  }
}
