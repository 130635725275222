import { REACT_APP_PERMISSIONS_SERVICE_URL } from "../../urls";

export const LOGIN_SAGA = "LOGIN_SAGA";
export const LOGIN_REDUCER = "LOGIN_REDUCER";
export const SIGNUP_SAGA = "SIGNUP_SAGA";
export const SIGNUP_REDUCER = "SIGNUP_REDUCER";
export const FORGET_SAGA = "FORGET_SAGA";
export const FORGET_REDUCER = "FORGET_REDUCER";
export const GET_COUNTRIES_SAGA = "GET_COUNTRIES_SAGA";
export const WAIT = "WAIT";
export const DONE = "DONE";
export const ERROR = "ERROR";
export const remote_role_server = REACT_APP_PERMISSIONS_SERVICE_URL;
export const user_server = "https://antiksef.online/resturant_staging/users_service/public";
export const VERIFY = "VERIFY";