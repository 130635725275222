
const initialState = {
  view: "Countries",
  test: [],
  offices: [],
  pageFollower: ["Employees"],
  newDepartment: { name: "", tax: "", No: "", approval_code: "", country_id: 0, domain: "", post_code: "", date: "", pdf_files: [{ file_path: "" }] },
  mode: "read",
  open: false,
  modalData: { name: "", icon: null, code: "" },
  loading: false,
  Active_Ids: [],
  request_running: false,
  request_data: null,
  message: "",
  error: false,
  img_upload: { type: "", status: false },
  floading: false,
  regs: null

}
export const LiscensesReducer = (state = initialState, { type, payload }) => {

  switch (type) {
    case "LES-REG-LOADING": {
      return ({ ...state, floading: true })
    }
    case "LES-REG-DONE": {
      return ({ ...state, floading: false, regs: payload })
    }
    case "GET-CUR-REDUCER": {
      let a = []
      payload.map((s) => {
        a.push({ ...s, code: s.flat_photo_path })
      })
      return ({
        ...state,
        test: [...a]
      })
    }
    case "IMG-UPLOAD-LES": {
      return ({
        ...state,
        img_upload: { ...payload }
      })
    }
    case "request-running-LES": {
      return ({
        ...state,
        request_running: true,
        request_data: payload
      })
    }
    case "GENERAL-ERR-LES": {
      return ({
        ...state,
        request_running: false,
        request_data: payload.data,
        error: true,
        message: payload.message
      })
    }
    case "request-running-LES-red": {

      return ({
        ...state,
        request_running: false,
        request_data: payload,
        error: false,
        message: ""
      })
    }
    case "GENERAL-LOADING-LES": {
      return ({
        ...state,
        loading: true
      })
    }
    case "VIEW-LES": {
      return ({
        ...state,
        view_language: payload
      })
    }
    case "GENERAL-DONE-LES": {
      return ({
        ...state,
        loading: false,
        open: false,


      })
    }
    case "LEVEL-DONE-LES": {
      return ({
        ...state,
        loading: false
      })
    }
    case "GET-LES-REDUCER": {
      let arr = []

      payload.map((a) => {
        let ars = []
        a.region_translations.map((asd) => {
          ars.push({ name: asd.region_name, language_code: asd.language_code })
        })
        arr.push({ ...a, translations: ars, name: ars.filter((axc) => axc.language_code === "en")[0].name })
      })
      return ({ ...state, offices: [...arr], request_data: null, request_running: false, error: false, message: "" })

    }
    case "Active-add": {
      return ({
        ...state,
        Active_Ids: payload
      })
    }
    case "REG-VIEW":
      return {
        ...state,
        view: payload,
        pageFollower: [payload]
      }
    case "NEW-LES": {


      return {
        ...state,
        newDepartment: { ...payload }
      }
    }
    case "OPEN-LES": {
      return ({
        ...state,
        open: payload
      })
    }
    case "MODE-LES": {
      return ({
        ...state,
        mode: payload
      })
    }
    case "MOD-DATA-LES": {
      return ({
        ...state,
        modalData: { ...payload }
      })
    }

    default:
      return state
  }
}
