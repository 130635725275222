
const initialState = {
    tickets: [],
    lockedTickets: [],
    loading: false,
    news: 0
}
const compares = (a, b) => {
    if (parseInt(new Date(a?.created_at).getTime()) < parseInt(new Date(b?.created_at).getTime())) {
        return -1
    }
    else {
        return 1
    }
}
const sortTickets = (tickets) => {
    if (tickets.length < 2) {
        return tickets
    } else {
        return tickets.sort((a, b) => compares(a, b))
    }
}
export const brokerticketsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "NEW_TICKET_RECIEVED": {
            if (action.payload.ticket?.ticket_status_id === 2) {
                return {
                    ...state,
                    news: state.news + 1
                }
            } else {
                return {
                    ...state
                }
            }

        }
        case "GET_BROKER_TICKETS_STATICS_ONLY_RED": {
            return ({
                ...state,
                tickets: state.tickets.map(one => {
                    return {
                        ...one,
                        tickets_count: action.payload.filter(newOne => newOne.id === one.id)[0]?.tickets_count
                    }
                })
            })
        }

        case "GET_BROKER_TICKETS_STATICS_RED": {
            return ({
                ...state,
                tickets: action.payload.map((one) => {
                    return {
                        ...one,
                        tickets: sortTickets(one.tickets)
                    }
                })
            })
        }
        case "edit_ticket_rate_emp_red": {
            let id = action.payload.id
            let newRate = action.payload.rate
            let tickets = state.tickets.map((type, index) => {
                if (type.id === 2) {
                    return {
                        ...type,
                        tickets: type.tickets.map(one => {
                            if (one.id === id) {
                                return {
                                    ...one,
                                    ticket_rates: [...one.ticket_rates,
                                        newRate
                                    ]
                                }
                            } else {
                                return one
                            }
                        })
                    }
                } else {
                    return type
                }
            })
            return {
                ...state,
                tickets
            }
        }
        case "approveTicketRed": {
            let ticket_id = action.payload.ticket_id
            let ticket = null
            let currentStatus = action.payload.currentStatus
            let tickets = state.tickets.map((type, index) => {
                if (type.id === currentStatus) {
                    ticket = type.tickets.filter(one => one.id === ticket_id)[0]
                    return {
                        ...type,
                        tickets: sortTickets(type.tickets.filter(one => one.id !== ticket_id))
                    }
                } else if (type.id === currentStatus + 1) {
                    let newTick = type.tickets
                    newTick.unshift(ticket)
                    return {
                        ...type,
                        tickets: sortTickets(newTick)
                    }
                } else {
                    return type
                }
            })
            return {
                ...state,
                tickets
            }
        }
        case "GET_UPDATED_EMP_STATICS_RED": {
            let ticket = action.payload?.data
            let ticketsWithout
            let newtickets
            if (state.tickets.filter(status => status.id === ticket.ticket_status_id).filter(tic => tic.id === ticket.id).length) {
                newtickets = state.tickets.map(one => {
                    if (one.id === ticket.ticket_status_id) {
                        return {
                            ...one,
                            tickets: one.tickets.map(tice => {
                                if (tice.id === ticket.id) {
                                    return ticket
                                } else {
                                    return tice
                                }
                            })
                        }
                    } else {
                        return {
                            ...one
                        }
                    }
                })
            } else {
                ticketsWithout = state.tickets.map(one => {
                    return {
                        ...one,
                        tickets: one.tickets.filter(tic => tic.id !== ticket.id)
                    }
                })
                newtickets = ticketsWithout.map(one => {
                    if (one.id === ticket.ticket_status_id) {
                        return {
                            ...one,
                            tickets: sortTickets([...one.tickets, ticket])
                        }
                    } else {
                        return {
                            ...one,
                            tickets: sortTickets(one.tickets.filter(tic => tic.id !== ticket.id))
                        }
                    }
                })
            }
            return ({
                ...state,
                tickets: newtickets
            })
        }
        case "GET_EMP_TICKETS_COUNTRIES_RED": {
            return ({
                ...state,
                countries: action.payload
            })
        }
        case "GET_EMP_TICKETS_CITIES_RED": {
            return ({
                ...state,
                cities: action.payload
            })
        }
        case "GET_EMP_TICKETS_STATICS_WAIT": {
            return ({
                ...state,
                loading: true
            })
        }
        case "GET_EMP_TICKETS_STATICS_DONE": {
            return ({
                ...state,
                loading: false
            })
        }
        case "TicketEvent": {
            let ticket = action.payload.ticket
            let chat_id = action.payload.ticket.whatsapp_chat_id
            let chats = state.ticket_types
            chats = chats.map(one => {
                if (one.whatsapp_chat_id === chat_id) {
                    if (one.tickets.filter(tic => tic.id === ticket.id).length > 0) {
                        let newTickets = one.tickets
                        newTickets = newTickets.map(tick => {
                            if (tick.id === ticket.id) {
                                return {
                                    ...ticket
                                }
                            } else {
                                return {
                                    ...tick
                                }
                            }
                        })
                        return ({
                            ...one,
                            tickets: newTickets
                        })
                    } else {
                        let newTickets = one.tickets
                        newTickets.unshift(ticket)
                        return ({
                            ...one,
                            tickets: newTickets
                        })
                    }
                } else {
                    return {
                        ...one
                    }
                }
            })
            return ({
                ...state,
                ticket_types: chats
            })
        }
        case "GET_EMP_PAGE_TICKETS_RED": {
            let ticket_types = state.ticket_types.map((type) => {
                if (type.whatsapp_chat_id === action.payload.chat_id) {
                    return {
                        ...type,
                        tickets: sortTickets(type.tickets.concat(action.payload.data))
                    }
                } else {
                    return {
                        ...type,
                        tickets: sortTickets(type.tickets)
                    }
                }
            })
            return {
                ...state,
                ticket_types: ticket_types
            }
        }
        case "ticketLockedByUser": {
            let lockedTickets = state.lockedTickets
            lockedTickets.push(action.payload)
            return {
                ...state,
                lockedTickets
            }
        }
        case "ticketUnLockedByUser": {
            let lockedTickets = state.lockedTickets.filter(one => one.id === action.payload.ticket_id)

            return {
                ...state,
                lockedTickets
            }
        }
        default:
            return state;
    }
}