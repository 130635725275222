import React from 'react'
import { trans } from '../../../../../Internationalization'
function CountriesIcon(props) {
  return (
    <div style={{ marginRight: "20px" }} className='icon-lang' onClick={() => props.change()}>

      <div className='icon-lang-desc'>
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
          <defs>
            <filter id="Ellipse_319" x="0" y="0" width="24" height="24" filterUnits="userSpaceOnUse">
              <feOffset dy="3" input="SourceAlpha" />
              <feGaussianBlur stdDeviation="3" result="blur" />
              <feFlood floodOpacity="0.2" />
              <feComposite operator="in" in2="blur" />
              <feComposite in="SourceGraphic" />
            </filter>
          </defs>
          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Ellipse_319)">
            <circle id="Ellipse_319-2" data-name="Ellipse 319" cx="3" cy="3" r="3" transform="translate(9 6)" fill="#404040" />
          </g>
        </svg>
        <div className='icon-lang-desc-bu hst contries'>{trans("countries")}</div>
      </div>

      <svg className={`${props.active && "activated-hrm"}`} id="_15x15_photo_back" data-name="15x15 photo back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
        <g id="Mask_Group_127" data-name="Mask Group 127" >
          <g id="coronavirus-2" transform="translate(-0.67 -0.67)">
            <path id="Path_18912" data-name="Path 18912" d="M5.872,2.214a.481.481,0,1,0-.48-.481.481.481,0,0,0,.48.481Z" fill="#8e8e8e" />
            <path id="Path_18913" data-name="Path 18913" d="M5.912,3.558l.837-1.12A1.11,1.11,0,0,0,6.977,1.7a1.066,1.066,0,0,0-2.13,0,1.138,1.138,0,0,0,.229.739ZM5.872.933a.8.8,0,1,1-.8.8.8.8,0,0,1,.8-.8Z" fill="#8e8e8e" />
            <path id="Path_18914" data-name="Path 18914" d="M11.585,4.867a.562.562,0,0,0,.355.188l.093.012a.821.821,0,0,1,.685.577.8.8,0,0,1-.236.834l-.924.817a.532.532,0,0,0-.179.4v.217a.416.416,0,0,0,.191.351.772.772,0,0,1,.351.574l.022.216a.357.357,0,0,0,.356.323h.541a.8.8,0,0,0,.734-.48l.032-.074a.775.775,0,0,1,.679-.466l.127-.006.042,0,.308-.031a10.447,10.447,0,0,0-.343-1.779l-.181.242a.16.16,0,0,1-.256,0l-.776-1.038a1.2,1.2,0,0,1-.24-.784,1.186,1.186,0,0,1,.168-.537,7.187,7.187,0,0,0-1.069-.867l-.523.9a.366.366,0,0,0,.043.421Z" fill="#8e8e8e" />
            <path id="Path_18915" data-name="Path 18915" d="M14.109,6.415l.648-.867a.861.861,0,0,0,.177-.572.825.825,0,0,0-1.65,0,.884.884,0,0,0,.178.574Zm-.032-2.089A.674.674,0,1,1,13.4,5a.674.674,0,0,1,.674-.674Z" fill="#8e8e8e" />
            <path id="Path_18916" data-name="Path 18916" d="M14.485,8.662l-.06,0-.127.006a.458.458,0,0,0-.4.275l-.032.074a1.121,1.121,0,0,1-1.027.671H12.3a.675.675,0,0,1-.674-.61L11.6,8.866a.454.454,0,0,0-.207-.338.734.734,0,0,1-.337-.619V7.693a.851.851,0,0,1,.287-.637l.924-.817a.485.485,0,0,0,.142-.5.5.5,0,0,0-.419-.353L11.9,5.372a.88.88,0,0,1-.556-.295.685.685,0,0,1-.079-.79l.53-.908a8.063,8.063,0,0,0-.882-.468A6.914,6.914,0,0,0,8.1,2.262c-.086,0-.18,0-.279.008l.709,1.022A1.116,1.116,0,0,1,8.5,4.612l-.227.3a.873.873,0,0,1-.8.332l-.258-.033a.583.583,0,0,0-.577.283.877.877,0,0,1-.867.426l-.595-.076a.616.616,0,0,0-.59.267.508.508,0,0,0-.009.556l.393.621a.448.448,0,0,0,.589.155l.4-.217a1.317,1.317,0,0,1,.972-.056,1.382,1.382,0,0,1,2.744.177,1.428,1.428,0,0,1-.291.95l-.25.334.147.2q.032.045.067.086l.491.579a.6.6,0,0,1-.092.866l-.745.573a.849.849,0,0,0-.3.425l-.248.806a.851.851,0,0,1-.16.295l-.242.291a1.065,1.065,0,0,0-.241.573.732.732,0,0,1-.445.6.541.541,0,0,1-.743-.41l-.228-1.367a.709.709,0,0,0-.135-.312l-.834-1.094a1.315,1.315,0,0,1-.222-.448L4.94,9.3a.7.7,0,0,0-.485-.483L4.4,8.8a1.121,1.121,0,0,1-.472-.265L3.41,8.05a1.511,1.511,0,0,1-.271-.342.791.791,0,0,0-.829-.381l-.748.243A6.693,6.693,0,1,0,14.8,8.966c0-.1,0-.216-.011-.334Z" fill="#8e8e8e" />
            <path id="Path_18917" data-name="Path 18917" d="M14.077,5.355A.355.355,0,1,0,13.722,5a.355.355,0,0,0,.355.355Z" fill="#8e8e8e" />
            <path id="Path_18918" data-name="Path 18918" d="M2.765,4.812a.481.481,0,1,0-.48-.481A.481.481,0,0,0,2.765,4.812Z" fill="#8e8e8e" />
            <path id="Path_18919" data-name="Path 18919" d="M2.24,7.016a1.111,1.111,0,0,1,1.176.532,1.191,1.191,0,0,0,.214.27l.513.483a.8.8,0,0,0,.337.189l.06.016a1.016,1.016,0,0,1,.708.706l.275.994a1,1,0,0,0,.169.34l.834,1.094a1.029,1.029,0,0,1,.2.453l.228,1.367a.222.222,0,0,0,.3.168A.413.413,0,0,0,7.5,13.29a1.383,1.383,0,0,1,.313-.744l.242-.291a.534.534,0,0,0,.1-.185l.248-.806a1.166,1.166,0,0,1,.406-.585l.745-.573A.282.282,0,0,0,9.6,9.7l-.491-.579c-.029-.034-.057-.069-.082-.105L8.938,8.9l-.515.689a.16.16,0,0,1-.256,0L7.2,8.294A1.49,1.49,0,0,1,6.911,7.5a1.006,1.006,0,0,0-.8,0l-.4.217a.759.759,0,0,1-.36.09.769.769,0,0,1-.65-.356l-.393-.621a.827.827,0,0,1,.014-.906.933.933,0,0,1,.9-.4L5.81,5.6a.558.558,0,0,0,.552-.271.9.9,0,0,1,.893-.438l.258.033a.553.553,0,0,0,.506-.211l.227-.3a.8.8,0,0,0,.024-.944L7.454,2.3c-.091.009-.183.02-.275.032a1.5,1.5,0,0,1-.175.3L6.04,3.92a.16.16,0,0,1-.256,0l-.711-.95a8.971,8.971,0,0,0-1.068.692A1.418,1.418,0,0,1,3.9,5.228L2.933,6.519a.16.16,0,0,1-.256,0l-.512-.685a7.519,7.519,0,0,0-.515,1.372l.57-.185.018,0Z" fill="#8e8e8e" />
            <path id="Path_18920" data-name="Path 18920" d="M9.132,8.1a1.11,1.11,0,0,0,.228-.737,1.066,1.066,0,0,0-2.13,0,1.138,1.138,0,0,0,.229.739l.836,1.118ZM7.455,7.4a.8.8,0,1,1,.8.8A.8.8,0,0,1,7.455,7.4Z" fill="#8e8e8e" />
            <ellipse id="Ellipse_326" data-name="Ellipse 326" cx="0.48" cy="0.481" rx="0.48" ry="0.481" transform="translate(7.774 6.918)" fill="#8e8e8e" />
            <path id="Path_18921" data-name="Path 18921" d="M2.805,6.157l.837-1.12A1.11,1.11,0,0,0,3.87,4.3a1.088,1.088,0,0,0-1.065-1.03A1.088,1.088,0,0,0,1.74,4.3a1.139,1.139,0,0,0,.229.74Zm-.04-2.625a.8.8,0,1,1-.8.8.8.8,0,0,1,.8-.8Z" fill="#8e8e8e" />
          </g>
        </g>
      </svg>



    </div>
  )
}

export default CountriesIcon