import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import EmployeeItem from './EmployeeItem'
import EmployeeModal from './EmployeeModal'
import EmployItem from './EmployItem'
function Employee(props) {

  const dispatch = useDispatch()
  const ActiveAdd = (e) => {
    dispatch({ type: "Active-add", payload: e })
  }
  const { lang_id, setLang_id } = props

  const employee = props.emp()
  const depts = useSelector(state => state.employee.depts)
  const { makeFollower } = props
  const data = useSelector(state => state.employee.newDepartment)
  const Active_Ids = useSelector(state => state.employee.Active_Ids)
  useEffect(() => {
    props.setLanguageFollowers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.viewDisp])
  const departments = useSelector(state => state.department.departments)
  const all_departments = useSelector(state => state.department.all_departments)
  const countries = useSelector(state => state.countries.countries)
  useEffect(() => {
    if (departments.length === 0) {
      dispatch({ type: "GET-DEPT" })
    }
    if (all_departments.length === 0) {
      dispatch({ type: "GET-PURE-DEPT" })
    }
    dispatch({ type: "GET-JOBS" });
    if (countries.length === 0) {
      dispatch({ type: "GET-REG" });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const open = useSelector((state) => state.employee.open)

  const getFilteredEmps = (d) => {
    if (props.workFilter.length === 0 && props.arr.length === 0) {
      return d
    } else if (props.arr.length === 0 && props.workFilter.length > 0) {
      return d.filter((de) => props.workFilter.includes(de.status))
    } else if (props.arr.length > 0 && props.workFilter.length === 0) {
      return d.filter((de) => props.arr.filter(country => country.id === de.work_office_region?.country_id)[0])
    }
    else return d
  }
  return (
    <>
      {((lang_id === 0 || lang_id === null) && open) && <EmployeeModal setFollower={(e) => props.setLanguageFollowers(e)} forCustomer={props.forCustomer} Active_Ids={Active_Ids} ActiveAdd={() => props.ActiveAdd(data.id)} languageFollower={props.languageFollower} setExpand={(e) => { }} data={data} {...props} />}
      {!props.viewDisp ? <div className='lang-body'>
        {
          depts.map((dept, index) => (
            <EmployeeItem
              key={index}
              lang={props.lang}
              ActiveAdd={(e) => ActiveAdd(e)}
              open={props.open}
              Close={() => props.Close()}
              Add={(e) => props.Add(e)}
              Update={(e) => props.Update(e)}
              Read={(e) => props.Read(e)}
              setModalData={(e) => props.setModalData(e)}
              setOpen={(e) => props.setOpen(e)}
              child={dept}
              Active_Ids={Active_Ids}
              languageFollower={props.languageFollower}
              level={1}
              makeFollower={(level, value, id) => makeFollower(level, value, id)}
              lang_id={lang_id}
              setLang_id={(e) => setLang_id(e)}
              setFollower={(e) => props.setLanguageFollowers(e)}
            />
          ))
        }
      </div> :
        <div className='view-body'>
          {employee.length > 0 && getFilteredEmps(employee).map((a, index) => (
            <EmployItem
              key={index}
              forCustomer={props.forCustomer}
              arr={props.arr.map((d) => { return d.id })}
              child={a}
              lang={props.lang}
              ActiveAdd={(e) => ActiveAdd(e)}
              open={props.open}
              Close={() => props.Close()}
              Add={(e) => props.Add(e)}
              Update={(e) => props.Update(e)}
              Read={(e) => props.Read(e)}
              setModalData={(e) => props.setModalData(e)}
              setOpen={(e) => props.setOpen(e)}
              Active_Ids={Active_Ids}
              languageFollower={props.languageFollower}
              level={1}
              makeFollower={(level, value, id) => makeFollower(level, value, id)}
              lang_id={lang_id}
              setLang_id={(e) => setLang_id(e)}
              setFollower={(e) => props.setLanguageFollowers(e)}
            />
          ))}
        </div>
      }
    </>
  )
}

export default Employee