import React from 'react'
import FillIcon from './svgs/FillIcon'
import OutlineIcon from './svgs/OutlineIcon'

function LanguageIcon(props) {
  return (
    <>
      <div className={`language-icon ${props.type}`}>
        {props.type !== "flat" ? <FillIcon icon={props.name} /> : <OutlineIcon icon={props.name} />}
      </div>

    </>

  )
}

export default LanguageIcon