export default function Location() {
    return (<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
        <g id="Mask_Group_243" data-name="Mask Group 243">
            <g id="business-and-trade" transform="translate(0 0.056)">
                <path id="Path_19352" data-name="Path 19352" d="M8.144,1.132,1.275.088A1.108,1.108,0,0,0,.387.332,1.118,1.118,0,0,0,0,1.169V14.338a.627.627,0,0,0,.625.625H2.656V11.682A1.091,1.091,0,0,1,3.75,10.588H5.313a1.091,1.091,0,0,1,1.094,1.094v3.281H9.063V2.213a1.094,1.094,0,0,0-.919-1.081ZM3.437,9.182H2.5a.469.469,0,0,1,0-.937h.938a.469.469,0,0,1,0,.938Zm0-1.875H2.5a.469.469,0,0,1,0-.937h.938a.469.469,0,0,1,0,.938Zm0-1.875H2.5a.469.469,0,0,1,0-.937h.938a.469.469,0,0,1,0,.938Zm0-1.875H2.5a.469.469,0,0,1,0-.937h.938a.469.469,0,0,1,0,.938ZM6.563,9.182H5.625a.469.469,0,0,1,0-.937h.938a.469.469,0,0,1,0,.938Zm0-1.875H5.625a.469.469,0,0,1,0-.937h.938a.469.469,0,0,1,0,.938Zm0-1.875H5.625a.469.469,0,0,1,0-.937h.938a.469.469,0,0,1,0,.938Zm0-1.875H5.625a.469.469,0,0,1,0-.937h.938a.469.469,0,0,1,0,.938Z" transform="translate(0 -0.074)" fill="#95bdff" />
                <path id="Path_19353" data-name="Path 19353" d="M10.908,4.828,6.458,3.9v9.156h4.219a1.1,1.1,0,0,0,1.094-1.094V5.9A1.088,1.088,0,0,0,10.908,4.828ZM9.427,11.177H8.49a.469.469,0,0,1,0-.937h.938a.469.469,0,0,1,0,.938Zm0-1.875H8.49a.469.469,0,0,1,0-.937h.938a.469.469,0,0,1,0,.938Zm0-1.875H8.49a.469.469,0,0,1,0-.937h.938a.469.469,0,0,1,0,.938Z" transform="translate(3.229 1.837)" fill="#95bdff" />
            </g>
        </g>
    </svg>

    )
}