import React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { FILE_SERVER } from '../../../../constants'
import "./index.css"
import mag from "../../../../assets/svg/Magnifier.svg"
function SearchBar(props) {
  const countries = useSelector(state => state.countries.countries)
  const depts = useSelector(state => state.department.departments)
  const [search, setSearch] = useState(false)

  return (
    <>
      {<div className='search-bar'>
        {countries.length > 0 && <div className='coutries-bar'>
          {countries.map((a) => (<>
            {a.flag_photo_path ?
              <svg className={`${props.filterCount.filter((ac) => ac.id === a.id).length > 0 && "active-count-svg"}`} onClick={() => { if (props.filterCount.filter((re) => re.id === a.id).length === 0) props.filterCountries(a, false); else props.filterCountries(a, true) }} width="23" height="15" data-src={FILE_SERVER + a.flag_photo_path}></svg>
              :
              <svg className={`no-trans sm ${props.filterCount.filter((ac) => ac.id === a.id).length > 0 && "active-count-svg "}`} onClick={() => { if (props.filterCount.filter((re) => re.id === a.id).length === 0) props.filterCountries(a, false); else props.filterCountries(a, true) }} xmlns="http://www.w3.org/2000/svg" width="23" height="15" viewBox="0 0 23 15">
                <g textAnchor='middle' id="Group_7626" data-name="Group 7626" transform="translate(-274 -138)">
                  <g id="_15x23_flag_photo" data-name="15x23 flag photo" transform="translate(274 138)">
                    <rect id="Rectangle_4601" data-name="Rectangle 4601" width="23" height="15" rx="2" fill="#404040" />
                  </g>
                  <text textAnchor='middle' id="CFO" transform="translate(274 139)" fill="#F7F7F7" font-size="9" font-family="SegoeUI, Segoe UI"><tspan textAnchor='middle' x="50%" y="10">{a.region_translations.filter((a) => a.language_code === (localStorage.getItem("APP_LANG") ?? "en"))[0].region_description}</tspan></text>
                </g>
              </svg>
            }
          </>

          ))}
        </div>}
        <div className='line-elem'>
          <svg xmlns="http://www.w3.org/2000/svg" width="1" height="26" viewBox="0 0 1 26">
            <line id="Line_1093" data-name="Line 1093" y2="25" transform="translate(0.5 0.5)" fill="none" stroke="#cecdcd" strokeLinecap="round" strokeWidth="1" />
          </svg>
        </div>
        <div className={'filter-elem ' + ` ${!search && "active-filt"}`}>
          {depts.map((a, index) => (
            <>
              <div style={{ marginLeft: "10px" }} className={` filter-opt ${props.filterDept.filter((ac) => ac.id === a.id).length > 0 && "active-filte"}`} onClick={() => { if (props.filterDept.filter((re) => re.id === a.id).length === 0) props.filterDepartments(a, false); else props.filterDepartments(a, true) }}>{a.translations.filter((af) => af.language_code === (localStorage.getItem("APP_LANG") ?? "en"))[0]?.name}<span>{a.num_of_employees}</span></div>
              {index !== depts.length - 1 && <svg style={{ marginLeft: "10px" }} xmlns="http://www.w3.org/2000/svg" width="0.5" height="14.5" viewBox="0 0 0.5 14.5">
                <line id="Line_1098" data-name="Line 1098" y2="14" transform="translate(0.25 0.25)" fill="none" stroke="#cecdcd" strokeLinecap="round" strokeWidth="0.5" />
              </svg>}
            </>
          ))}

        </div>
        <div className={'search-elem ' + ` ${search && " active-search"}`}>
          <div className='search-inp'>
            <svg onClick={() => setSearch(true)} className='magnif' data-src={mag}></svg>
            <input value={props.searchName} onChange={(e) => props.setsearchName(e)} placeholder='Search In Table' />
            <svg onClick={() => setSearch(false)} className='cancel-ic' xmlns="http://www.w3.org/2000/svg" width="11.411" height="11.41" viewBox="0 0 11.411 11.41">
              <g id="Group_7876" data-name="Group 7876" transform="translate(0.707 0.707)">
                <g id="Group_7875" data-name="Group 7875" transform="translate(0 0)">
                  <line id="Line_962" data-name="Line 962" x1="14.135" y1="0.001" transform="translate(0.002 0) rotate(45)" fill="none" stroke="#ff2e2e" strokeLinecap="round" strokeWidth="1" />
                  <line id="Line_963" data-name="Line 963" x1="14.135" y2="0.001" transform="translate(9.995 0.001) rotate(135)" fill="none" stroke="#ff2e2e" strokeLinecap="round" strokeWidth="1" />
                </g>
              </g>
            </svg>

          </div>

        </div>

      </div>}
    </>
  )
}

export default SearchBar