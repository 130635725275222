import React from 'react'

function Links() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
 
    <g id="_15x15_photo_back" data-name="15x15 photo back">
      <g id="_x38_2_attachment" transform="translate(0.498 0)">
        <path id="Path_18957" data-name="Path 18957" d="M15,4.5A3.974,3.974,0,0,1,13.83,7.329L6.624,14.621A3,3,0,1,1,2.379,10.38L7.585,5.2a1.967,1.967,0,0,1,2.77-.057A1.86,1.86,0,0,1,10.9,6.533a2.036,2.036,0,0,1-.6,1.382l-4.94,4.94a.5.5,0,0,1-.707-.707l4.94-4.94A1.037,1.037,0,0,0,9.9,6.5a.87.87,0,0,0-.249-.65.967.967,0,0,0-1.358.059L3.085,11.088a2,2,0,0,0,2.83,2.828l7.206-7.291A3,3,0,1,0,8.88,2.379L1.853,9.356a.5.5,0,0,1-.7-.71L8.174,1.671A4,4,0,0,1,15,4.5Z" transform="translate(-1 -0.5)" fill="#8e8e8e"/>
      </g>
    </g>
  </svg>
  
  )
}

export default Links