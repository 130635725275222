import Deposit from "./svg/deposit";

export default function StaticsSquare(props) {
    return (<div className="statics_square_area">
        <div className="statics_square_filters_area">
            <div className='status_icon_square_tic'>
                <Deposit color="#404040" />
                <div className='status_info_name_square_tic'>Deposit</div>
            </div>
        </div>
        <div className="statics_square_numbers_area">
            <div className="statics_square_numbers_area_tic">
                <div className="statics_square_numbers_area_tic_label">
                    Ticket
                </div>
                <div className="statics_square_numbers_area_tic_data">
                    {props.total_tickets_count}
                </div>
            </div>
            <div className="statics_square_numbers_area_amm">
                <div className="statics_square_numbers_area_tic_label">
                    Total Ammount
                </div>
                <div className="statics_square_numbers_area_tic_data" style={{ fontWeight: "bold" }}>
                    {props.total_tickets_amount}
                </div>
            </div>
        </div>
    </div>)
}