import { useState, useEffect } from "react";
import Dashboard from "./Dashboard";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { getDashboardOptions } from "./constants";
import avat from "../../../assets/svg/avat.png";
import wa from "../../../assets/svg/whatsapp.svg";
import "../chat/index.css";
import ProfileNo from "../WhatsApp/assets/images/profileNo.png";
import { FILE_SERVER, isNew } from "../../../constants";
import Whatsapp from "./Whatsapp";
import { logout } from "../../../redux/auth/actions";
import { _getAppLang, _changeAppLang } from '../../../Internationalization';
import CallComponent from "../../../Agora/CallComponent";
import { ToastContainer } from "react-toastify";

function MainPage(props) {
  const refresh = useSelector(state => state.whatsapp.refresh)
  const user = useSelector(state => state?.auth?.user);
  const getStyle = () => {
    let a = window.pageYOffset || document.documentElement?.scrollTop;
    // 
    if (a === 0) return { position: "absolute", zIndex: 99 };
    else return { position: "fixed", zIndex: "99999" };
  };
  const pass = useSelector((state) => state.auth.pass);
  const rolesVar = useSelector((state) => state.auth.rolesVar);
  const timeOnline = useSelector((state) => state.auth.user)?.employee?.employee_daily_log?.executed_working_time || 0;
  const chats = useSelector((state) => state.whatsapp.chats);

  const isCallIncoming = useSelector(state => state.chat.isCallIncoming)
  const employees = useSelector(state => state.employee.employee)

  const getNew = () => {
    return chatData.filter((s) => s.channel_type?.slug !== "team").filter((c) => isNew(c.messages) > 0);
  };
  const getNewWhats = () => {
    return chats.filter((d) => parseInt(d.unread) > 0)
  }
  const setPass = (a) => {
    dispatch({ type: "PASS", payload: a });
  };
  const viewedList = useSelector((state) => state.categories.viewedList);
  const chatData = useSelector((state) => state.chat.data);
  const [chat, openChat] = useState(false);
  const WAP = useSelector((state) => state.whatsapp.WAP);
  const channels = useSelector(state => state.chat.channels)
  const roles = useSelector(state => state.auth.roles)
  const [openExternal, setOpenExternal] = useState(false)
  const setWA = (a) => {
    dispatch({ type: "OPEN-WA", payload: a })
  }
  const [ActiveLink, setActiveLink] = useState(null);
  const [language, setLanguage] = useState(_getAppLang());
  const [showTip, setShowTip] = useState(null)

  useEffect(() => {
    if (
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user")).id &&
      pass
    ) {
      if (roles.length === 0) {
        dispatch({ type: "GET_AUTH_ROLES" })
      }
      if (channels.length === 0) {
        dispatch({ type: "GET_CHAT" });
      }
      if (employees?.length === 0) {
        dispatch({ type: "GET-EMP" });
      }
    } else {
      props.history.push("/auth/signup");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!props?.match.params.route) {
      setActiveLink(null);
    } else {
      getDashboardOptions({ roles })?.map((m) => {
        m.childreen.map((s) => {
          if (s.name?.toLowerCase() === props?.match?.params.route?.toLowerCase()) {
            setActiveLink(s);
          }
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.match.params]);

  const dispatch = useDispatch();
  const AddLayout = (laoyout, level, element) => {
    let res = [];
    if (
      element !== null &&
      !element.target.classList.contains("plus-con") &&
      !element.target.classList.contains("disable_click")
    ) {
      if (viewedList.filter((f) => f.name === laoyout.name).length > 0) {
        res = viewedList.filter((k, i) => i < level);
      } else {
        res = viewedList.filter((k, i) => i < level);

        if (res.length === 0) res.push(laoyout);
        else res[level] = laoyout;
      }
      dispatch({ type: "ADD_LAYOUT", layout: res });
    }
  };
  const [foc, setFocue] = useState(false);
  const getTime = (d) => {
    const a = new Date();
    if (!d)
      return `${(a.getHours() > 9
        ? a.getHours().toString()
        : "0" + a.getHours().toString()) +
        ":" +
        (a.getMinutes() > 9
          ? a.getMinutes().toString()
          : "0" + a.getMinutes().toString())
        }`;
    else return `${localStorage.getItem("session-start")}`;
  };
  var ti = 0

  useEffect(() => {
    const themeSwitcher = document.getElementById('theme-switcher');

    // Load saved theme preference
    const savedTheme = localStorage.getItem('theme')
    if (savedTheme) {
      if (savedTheme === 'dark-theme') {
        document.body.classList?.add('dark-theme');
        themeSwitcher.checked = true;
      }
    } else {
      // Detect OS preference if no saved preference
      if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
        document.body.classList?.add('dark-theme');
        themeSwitcher.checked = true;
      }
    }
    // Handle toggle switch
    themeSwitcher.addEventListener('change', function () {
      document.body.classList?.toggle('dark-theme');
      localStorage.setItem('theme', this.checked ? 'dark-theme' : 'light-theme');
    });
  }, [])
  if (localStorage.getItem("user")) {
    let u = JSON.parse(localStorage.getItem("user"))?.employee
    ti = parseInt(u?.employee_daily_log?.executed_working_time) || 0
  } else {
    ;
  }
  useEffect(() => {
    if (user?.access_token && (rolesVar === true)) {
      if (roles.includes("USE_WHATSAPP")) {
        dispatch({ type: "GET_WA_CHATS" });
        dispatch({ type: "GET_WA_ADMIN_CHATS" })
      }
    }
    return (() => {
      ;
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolesVar]);

  return (
    <div className="main">
      <ToastContainer position="bottom-left" style={{ zIndex: 999 }} />
      {isCallIncoming && <CallComponent reply={() => setWA(true)} />}
      {/* {!navigator.onLine && <div className="off_label">
        <img alt="" className="off_img" src={off} />
        <span>You are Offline</span></div>} */}
      {roles.includes("USE_WHATSAPP") && WAP ? <Whatsapp open={WAP} close={() => setWA(false)} openExternal={openExternal} setOpenExternal={setOpenExternal} /> : <></>}

      {/* {localStorage.getItem("user") && (
        <ChatWidget open={chat} openChat={(e) => openChat(e)} />
      )} */}
      <div
        className={`avatar-account  ${foc ? "active-h" : ""}`}
        onMouseLeave={() => setFocue(false)}
      >
        <span className="abs-span" onMouseEnter={() => setFocue(true)}></span>
        {localStorage.getItem("user") &&
          JSON.parse(localStorage.getItem("user")).avatar &&
          JSON.parse(localStorage.getItem("user")).avatar.length > 0 ? (
          <img alt=""
            onMouseEnter={() => setFocue(true)}
            src={FILE_SERVER + JSON.parse(localStorage.getItem("user")).avatar}
          />
        ) : (
          <img alt="" onMouseEnter={() => setFocue(true)} src={avat} />
        )}

        <div className="time-info">
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="15"
              height="15"
              viewBox="0 0 15 15"
            >
              <g id="Mask_Group_178" data-name="Mask Group 178">
                <g id="deadline" transform="translate(0.636 0)">
                  <path
                    id="Path_19125"
                    data-name="Path 19125"
                    d="M11.747,5.209a2,2,0,0,0,.311-1.324.778.778,0,0,0-.4-.5.7.7,0,0,0-.556-.052,1.415,1.415,0,0,0-.73.813,5.917,5.917,0,0,0-3.2-.77l-.083-.8c.1-.019.2-.04.319-.059a4.4,4.4,0,0,0,1.365-.4,1.115,1.115,0,0,0,.216-.162A.761.761,0,0,0,9.237,1.3,1.084,1.084,0,0,0,8.7.518,2.041,2.041,0,0,0,7.289.426L4.86.9c-.062.012-.131.024-.2.038-.742.133-1.98.357-1.852,1.472a.873.873,0,0,0,.376.654,1.159,1.159,0,0,0,.644.169,2.565,2.565,0,0,0,.7-.112c.271-.081.544-.143.825-.2l.155.768a5.936,5.936,0,0,0-2.465,1.6A.252.252,0,0,0,2.965,5.2a2.306,2.306,0,0,0-1.208-.333.766.766,0,0,0-.72.366.624.624,0,0,0,.1.661,2.052,2.052,0,0,0,.758.449c.1.045.2.093.288.136a5.986,5.986,0,0,0,5.231,8.87,6.157,6.157,0,0,0,1.194-.119A5.99,5.99,0,0,0,11.747,5.209Zm-.495-1.434a.222.222,0,0,1,.19.024.3.3,0,0,1,.164.2,1.452,1.452,0,0,1-.209.873,5.752,5.752,0,0,0-.63-.485.143.143,0,0,0,.021-.031,1.057,1.057,0,0,1,.464-.585ZM4.4,2.672a1.26,1.26,0,0,1-.958,0,.407.407,0,0,1-.169-.319C3.2,1.728,3.894,1.55,4.741,1.4l.207-.038L7.377.884A3.224,3.224,0,0,1,7.969.815a1,1,0,0,1,.492.107.631.631,0,0,1,.311.433.3.3,0,0,1-.1.264.772.772,0,0,1-.133.1,4.049,4.049,0,0,1-1.21.34c-.162.029-.311.057-.437.086-.326.076-.663.138-.991.2a14.094,14.094,0,0,0-1.5.333Zm1.415.157L5.982,2.8c.214-.04.43-.081.649-.126l.078.742c-.155.019-.309.043-.464.074-.1.019-.195.043-.29.067ZM2.09,5.915a2.886,2.886,0,0,1-.575-.3c-.055-.074-.076-.138-.057-.174s.131-.107.3-.107a1.861,1.861,0,0,1,.963.264.174.174,0,0,0,.045.017c-.119.15-.233.3-.338.464-.1-.05-.214-.1-.34-.159Zm6.429,8.854A5.52,5.52,0,1,1,6.338,3.947a5.575,5.575,0,0,1,1.1-.109A5.52,5.52,0,0,1,8.519,14.769Z"
                    transform="translate(-0.982 -0.347)"
                    fill="#404040"
                  />
                  <path
                    id="Path_19126"
                    data-name="Path 19126"
                    d="M9.591,5.7a.235.235,0,0,0-.319.083L7.663,8.55a.851.851,0,0,0-.43-.024.882.882,0,0,0,.174,1.747.859.859,0,0,0,.174-.017.882.882,0,0,0,.692-1.039A.86.86,0,0,0,8.057,8.8L9.676,6.022A.235.235,0,0,0,9.591,5.7Zm-2.1,4.1a.416.416,0,1,1-.164-.815.492.492,0,0,1,.083-.007.415.415,0,0,1,.228.069.415.415,0,0,1-.147.754Z"
                    transform="translate(-0.982 -0.347)"
                    fill="#404040"
                  />
                  <path
                    id="Path_19127"
                    data-name="Path 19127"
                    d="M5.825,12.86A3.888,3.888,0,0,1,3.488,8.6,4.288,4.288,0,0,1,7.251,5.135a.233.233,0,1,0-.05-.464A4.755,4.755,0,0,0,3.029,8.514a4.387,4.387,0,0,0,2.589,4.762.232.232,0,0,0,.1.024.231.231,0,0,0,.1-.44Z"
                    transform="translate(-0.982 -0.347)"
                    fill="#215bf8"
                  />
                  <path
                    id="Path_19128"
                    data-name="Path 19128"
                    d="M13.8,7.71a4.783,4.783,0,0,0,.04,1.1A4.956,4.956,0,0,0,13.977,9.9,3.064,3.064,0,0,0,14.008,8.8,3.066,3.066,0,0,0,13.8,7.71Z"
                    transform="translate(-0.982 -0.347)"
                    fill="#404040"
                  />
                  <path
                    id="Path_19129"
                    data-name="Path 19129"
                    d="M14.7,9.082a2.968,2.968,0,0,0-.221-1.213,9.915,9.915,0,0,0,.055,1.217,10.435,10.435,0,0,0,.033,1.217A2.953,2.953,0,0,0,14.7,9.082Z"
                    transform="translate(-0.982 -0.347)"
                    fill="#404040"
                  />
                  <path
                    id="Path_19130"
                    data-name="Path 19130"
                    d="M1.833,13.164c-.045,0-.043.262.136.511s.423.335.44.295-.152-.176-.3-.392-.223-.421-.271-.414Z"
                    transform="translate(-0.982 -0.347)"
                    fill="#404040"
                  />
                  <path
                    id="Path_19131"
                    data-name="Path 19131"
                    d="M1.327,13.124a1.349,1.349,0,0,0,.152.92,1.339,1.339,0,0,0,.6.713c.033-.036-.247-.333-.452-.782s-.252-.854-.3-.851Z"
                    transform="translate(-0.982 -0.347)"
                    fill="#404040"
                  />
                </g>
              </g>
            </svg>
          </span>
          <div className="times-in">
            <div className="time-hour">{getTime()}</div>
            <div className="times-work">
              <span className="sem">8</span> h {timeOnline < 3600 ?
                <>
                  <span className="sem"> / {parseInt(timeOnline / 60)} </span> m
                </>
                :
                <>
                  <span className="sem">
                    {" "}
                    / {parseFloat(timeOnline / 60 / 60).toFixed(2)}{" "}
                  </span>{" "}
                  h
                </>
              }
            </div>
          </div>
          <div className="times-in out-time">
            <div className="time-hour">{getTime(true)}</div>
            <div className="times-work">
              <span className="sem">8</span> h
              <>
                <span className="sem">
                  {" "}
                  / {parseFloat(timeOnline / 60 / 60 - 8).toFixed(2)}{" "}
                </span>{" "}
                h
              </>
            </div>
          </div>
        </div>
        {
          <div className="account-options">
            <svg
              onClick={() => {

                logout(() => { ; })
                dispatch({ type: "log_out" })
                localStorage.removeItem("user")
                setTimeout(() => {
                  window.location.reload()
                }, 500)
              }}
              id="_15x15_photo_back"
              data-name="15x15 photo back"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="15"
              height="15"
              viewBox="0 0 15 15"
            >
              <g id="Mask_Group_179" data-name="Mask Group 179">
                <g id="layer1" transform="translate(0 0)">
                  <path
                    id="path52"
                    d="M8.992,825.387a.752.752,0,0,0-.743.762v6.029a.751.751,0,1,0,1.5,0v-6.029a.752.752,0,0,0-.759-.762Zm4.376,1.511c-.024,0-.049,0-.073,0a.754.754,0,0,0-.416,1.33,6,6,0,1,1-7.779.022.756.756,0,0,0,.084-1.062.748.748,0,0,0-1.058-.084A7.556,7.556,0,0,0,9,840.387a7.556,7.556,0,0,0,4.845-13.3.749.749,0,0,0-.481-.185Z"
                    transform="translate(-1.5 -825.387)"
                    fill="#f80000"
                  />
                </g>
              </g>
            </svg>
            <svg
              onClick={() => {

                setPass(false);
                dispatch({ type: "log_out" })
                props.history.push("/auth/signup");
              }}
              id="_15x15_photo_back"
              data-name="15x15 photo back"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="15"
              height="15"
              viewBox="0 0 15 15"
            >
              <g id="Mask_Group_180" data-name="Mask Group 180">
                <g id="padlock" transform="translate(1.875)">
                  <g id="Group_7828" data-name="Group 7828">
                    <path
                      id="Path_19132"
                      data-name="Path 19132"
                      d="M12.188,6.563V4.688a4.219,4.219,0,0,0-8.437,0V6.563A1.381,1.381,0,0,0,2.344,7.969v6.094A1.41,1.41,0,0,0,3.75,15.469h8.438a1.41,1.41,0,0,0,1.406-1.406V7.969A1.381,1.381,0,0,0,12.188,6.563Zm-7.5-1.875a3.281,3.281,0,0,1,6.563,0V6.563H4.688Zm3.75,6.938v1.031a.469.469,0,0,1-.937,0V11.625a1.365,1.365,0,0,1-.937-1.312,1.406,1.406,0,0,1,2.813,0A1.365,1.365,0,0,1,8.438,11.625Z"
                      transform="translate(-2.344 -0.469)"
                      fill="#404040"
                    />
                  </g>
                </g>
              </g>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15" onClick={() => {
              window.location.replace("/auth/resetpassword")
            }}>
              <defs>
                <clipPath id="clip-path">
                  <rect id="Rectangle_4561" data-name="Rectangle 4561" width="15" height="15" fill="none" />
                </clipPath>
              </defs>
              <g id="Group_7957" data-name="Group 7957" transform="translate(-735 -701)">
                <g id="Group_10760" data-name="Group 10760">
                  <g id="_15x15_photo_back" data-name="15x15 photo back" transform="translate(735 701)">
                    <g id="Mask_Group_212" data-name="Mask Group 212" clip-path="url(#clip-path)">
                      <g id="exchange">
                        <g id="Group_7948" data-name="Group 7948">
                          <g id="Group_7947" data-name="Group 7947">
                            <path id="Path_19229" data-name="Path 19229" d="M9.167,13.9A6.615,6.615,0,0,1,1.313,9.836a6.542,6.542,0,0,1-.1-4.385A6.66,6.66,0,0,1,3,2.654L2.849,3.9l.877.106.332-2.754L1.339.934l-.1.877,1.219.144A7.548,7.548,0,0,0,.373,5.176,7.5,7.5,0,0,0,9.39,14.757Z" fill="#b743ea" />
                          </g>
                        </g>
                        <g id="Group_7950" data-name="Group 7950">
                          <g id="Group_7949" data-name="Group 7949">
                            <path id="Path_19230" data-name="Path 19230" d="M14.545,10.062A7.5,7.5,0,0,0,5.184.37l.274.84a6.611,6.611,0,0,1,6.107,11.5l.164-1.359-.877-.106L10.519,14l2.719.322.1-.877-1.115-.132A7.517,7.517,0,0,0,14.545,10.062Z" fill="#b743ea" />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <path id="Path_19237" data-name="Path 19237" d="M7.328,4.26a1.693,1.693,0,0,0-1.69,1.69v.4H5.118a.4.4,0,0,0-.4.4V9.81a.4.4,0,0,0,.4.4H9.539a.4.4,0,0,0,.4-.4V6.745a.4.4,0,0,0-.4-.4H9.018v-.4a1.693,1.693,0,0,0-1.69-1.69Zm.895,2.087H6.434v-.4a.895.895,0,1,1,1.789,0Z" transform="translate(735.172 701.24)" fill="#b743ea" />
                </g>
              </g>
            </svg>

            <svg
              className={`${language === "ar" && "active-language"}`}
              onClick={() => {
                setLanguage("ar")
                _changeAppLang("ar")
                dispatch({ type: "TRANSLATE", payload: "ar" })
              }}
              id="Group_4725"
              data-name="Group 4725"
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 15 15"
            >
              <path
                id="Exclusion_1"
                data-name="Exclusion 1"
                d="M13,15H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0H13a2,2,0,0,1,2,2V13A2,2,0,0,1,13,15ZM9.162,8.17h1.559l1.3,2.4a.4.4,0,0,0,.4.262.387.387,0,0,0,.415-.374.552.552,0,0,0-.085-.284L11.561,8.044a1.854,1.854,0,0,0,1.309-1.87,1.886,1.886,0,0,0-2.077-1.941H8.756a.387.387,0,0,0-.407.419v5.759a.407.407,0,1,0,.813,0V8.171Zm-5.654.714H6.087L6.665,10.5a.389.389,0,0,0,.4.311.371.371,0,0,0,.406-.356.665.665,0,0,0-.049-.226l-2.1-5.673a.511.511,0,0,0-.51-.389.525.525,0,0,0-.533.393L2.18,10.23a.665.665,0,0,0-.049.226.371.371,0,0,0,.407.356.387.387,0,0,0,.4-.311l.574-1.617Zm2.348-.69H3.742L4.786,5.222h.027l1.043,2.97Zm4.865-.741H9.162v-2.5h1.522A1.194,1.194,0,0,1,12.03,6.2,1.166,1.166,0,0,1,10.721,7.453Z"
                transform="translate(0 0)"
                fill="#8e8e8e"
              />
            </svg>
            <svg
              className={`${language === "en" && "active-language"}`}
              onClick={() => {
                setLanguage("en")
                dispatch({ type: "TRANSLATE", payload: "en" })
                _changeAppLang("en")
              }}
              id="Group_4727"
              data-name="Group 4727"
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 15 15"
            >
              <path
                id="Exclusion_4"
                data-name="Exclusion 4"
                d="M13,15H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0H13a2,2,0,0,1,2,2V13A2,2,0,0,1,13,15ZM8.286,5.582h.042l3.572,5.1a.506.506,0,0,0,.49.278.433.433,0,0,0,.48-.462V4.476a.419.419,0,1,0-.838,0V9.437H11.99L8.422,4.321a.514.514,0,0,0-.49-.283c-.309,0-.479.155-.479.437v5.992a.439.439,0,0,0,.413.494.4.4,0,0,0,.419-.438V5.583ZM2.554,4.047a.4.4,0,0,0-.424.438V10.4a.4.4,0,0,0,.424.438H6a.382.382,0,1,0,0-.762H2.977V7.743H5.835a.372.372,0,0,0,.4-.377.368.368,0,0,0-.4-.376H2.977V4.811H6a.382.382,0,1,0,0-.763Z"
                transform="translate(0 0)"
                fill="#8e8e8e"
              />
            </svg>
          </div>
        }
      </div>
      <div className="account-cont" style={getStyle()}>
        <div className="lang-top account-top">
          <div className={`top-account-icons`}>
            {(!WAP && roles.includes("USE_WHATSAPP")) ? <div className="open_ticket_modal_icon_external" onClick={() => {
              setOpenExternal(true)
              setWA(true)
            }}>
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
                <g id="Mask_Group_16" data-name="Mask Group 16" transform="translate(-324 -333)">
                  <path id="add-3" d="M13.235,5.714H9.286V1.765a1.786,1.786,0,0,0-3.571,0v3.95H1.765a1.786,1.786,0,0,0,0,3.571h3.95v3.95a1.786,1.786,0,0,0,3.571,0V9.286h3.95a1.786,1.786,0,0,0,0-3.571Z" transform="translate(324 333)" fill="#8e8e8e" />
                </g>
              </svg>
            </div> : <></>}
            <div className="chat-icon" style={{ marginRight: "15px" }}>
              {roles.includes("USE_WHATSAPP") ? getNewWhats().length > 0 && !WAP && !refresh && (
                <div
                  className="chat-top-icons wa-icons"
                  style={{
                    left: "10px",
                  }}
                >
                  {getNewWhats() &&
                    getNewWhats()
                      .slice(0, 3)
                      .map((a, index) => (
                        <div key={index}
                          className="chat-avatar"
                          onMouseEnter={() => setShowTip(index)}
                          onMouseLeave={() => setShowTip(null)}
                          style={{ left: `0px` }}
                          onClick={() => {
                            dispatch({ type: "CHAT_ID", payload: a });
                            dispatch({ type: "READ_CHAT", id: a.id })
                            setWA(true);
                          }}
                        >
                          <img alt=""
                            src={
                              a?.whatsapp_contact?.profile_photo ? a?.whatsapp_contact?.profile_photo : ProfileNo
                            }
                          />
                          {showTip === index && <div className="popup_emp_name_out"> {a?.whatsapp_contact?.name || a?.whatsapp_contact?.pushname || (("+") + a?.whatsapp_contact?.number)} </div>}
                        </div>
                      ))}
                </div>
              ) : <></>}
              {roles.includes("USE_WHATSAPP") ? ((!WAP && refresh) ? <div id="startup">
                <svg className="spinner-container" width="65px" height="65px" viewBox="0 0 52 52">
                  <circle className="path" cx="26px" cy="26px" r="20px" fill="none" strokeWidth="4px" />
                </svg>
              </div> : (!WAP && <svg onClick={() => setWA(true)} data-src={wa} ></svg>)) : <></>}
              {(roles.includes("USE_WHATSAPP") && !chat) && getNew().length > 0 && (
                <div
                  onClick={() => {
                    dispatch({ type: "MAIN", payload: "main" });
                    openChat(true);
                    setWA(true);
                  }}
                  className="points-cont"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7"
                    height="7"
                    viewBox="0 0 7 7"
                  >
                    <circle
                      id="Ellipse_355"
                      data-name="Ellipse 355"
                      cx="3.5"
                      cy="3.5"
                      r="3.5"
                      fill="#007cff"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7"
                    height="7"
                    viewBox="0 0 7 7"
                  >
                    <circle
                      id="Ellipse_355"
                      data-name="Ellipse 355"
                      cx="3.5"
                      cy="3.5"
                      r="3.5"
                      fill="#007cff"
                    />
                  </svg>
                </div>
              )}
            </div>
            <svg
              style={{ marginRight: "20px" }}
              id="_20X20_photo_Back"
              data-name="20X20 photo Back"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="20"
              height="20"
              viewBox="0 0 20 20"
            >
              <g id="Mask_Group_218" data-name="Mask Group 218">
                <g id="bell">
                  <path
                    id="Path_19249"
                    data-name="Path 19249"
                    d="M17.816,14.094a5.582,5.582,0,0,1-1.982-4.271V7.5a5.838,5.838,0,0,0-5-5.767v-.9a.833.833,0,0,0-1.667,0v.9a5.837,5.837,0,0,0-5,5.767V9.823A5.588,5.588,0,0,1,2.177,14.1a1.458,1.458,0,0,0,.948,2.566h13.75a1.458,1.458,0,0,0,.941-2.572Z"
                    fill="#8e8e8e"
                  />
                  <path
                    id="Path_19250"
                    data-name="Path 19250"
                    d="M10,20a3.13,3.13,0,0,0,3.062-2.5H6.938A3.13,3.13,0,0,0,10,20Z"
                    fill="#8e8e8e"
                  />
                </g>
              </g>
            </svg>
            <label className="switchTheme">
              <input type="checkbox" id="theme-switcher" />
              <span className="sliderTheme roundTheme"></span>
            </label>
          </div>
        </div>
      </div>
      <Dashboard
        foc={foc}
        ActiveLink={ActiveLink}
        setActiveLink={(e) => setActiveLink(e)}
        viewedList={viewedList}
        AddLayout={(laoyout, level, element) =>
          AddLayout(laoyout, level, element)
        }
      />
    </div>
  );
}

export default withRouter(MainPage);
