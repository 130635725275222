export default function Location() {
    return (<svg id="_10x10_flag_photo" data-name="10x10 flag photo" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="10" height="10" viewBox="0 0 10 10">
        <g id="Mask_Group_243" data-name="Mask Group 243" >
            <g id="business-and-trade" transform="translate(0 -0.037)">
                <path id="Path_19352" data-name="Path 19352" d="M5.429.779.85.083A.738.738,0,0,0,.258.246.745.745,0,0,0,0,.8V9.583A.418.418,0,0,0,.417,10H1.771V7.813A.727.727,0,0,1,2.5,7.083H3.542a.727.727,0,0,1,.729.729V10H6.042V1.5A.73.73,0,0,0,5.429.779ZM2.292,6.146H1.667a.313.313,0,1,1,0-.625h.625a.313.313,0,0,1,0,.625Zm0-1.25H1.667a.313.313,0,1,1,0-.625h.625a.313.313,0,0,1,0,.625Zm0-1.25H1.667a.313.313,0,0,1,0-.625h.625a.313.313,0,0,1,0,.625Zm0-1.25H1.667a.313.313,0,0,1,0-.625h.625a.313.313,0,0,1,0,.625Zm2.083,3.75H3.75a.313.313,0,1,1,0-.625h.625a.312.312,0,0,1,0,.625Zm0-1.25H3.75a.313.313,0,1,1,0-.625h.625a.312.312,0,0,1,0,.625Zm0-1.25H3.75a.313.313,0,0,1,0-.625h.625a.312.312,0,0,1,0,.625Zm0-1.25H3.75a.313.313,0,0,1,0-.625h.625a.312.312,0,0,1,0,.625Z" fill="#8e8e8e" />
                <path id="Path_19353" data-name="Path 19353" d="M9.425,4.517,6.458,3.9V10H9.271A.73.73,0,0,0,10,9.271V5.23A.726.726,0,0,0,9.425,4.517ZM8.438,8.75H7.813a.313.313,0,0,1,0-.625h.625a.313.313,0,0,1,0,.625Zm0-1.25H7.813a.313.313,0,0,1,0-.625h.625a.313.313,0,0,1,0,.625Zm0-1.25H7.813a.313.313,0,0,1,0-.625h.625a.313.313,0,0,1,0,.625Z" fill="#8e8e8e" />
            </g>
        </g>
    </svg>
    )
}