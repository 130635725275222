import React, { useCallback, useEffect, useMemo, useState } from "react"
import InputText from "./components/inputText"
import { useDispatch, useSelector } from "react-redux"
// import acc from "./assets/ticketModalIcons/acc.svg"
// import { textMarshal } from "text-marshal"
import Spinner from "../../Dashboard/Spinner"
import Wallet from "./pages/Chat/svg/wallet"
import { upload } from "../../../uploadService"
import { useDropzone } from 'react-dropzone'
// import { REACT_APP_BASE_FILE_URL } from "../../../urls"
import { toast } from "react-toastify"
import WaitingIcon from "./assets/icons/waiting"
import { REACT_APP_BASE_FILE_URL } from "../../../urls"

function TransferModal(props) {
    const [countryFocus, setCountryFocus] = useState(false)
    const [currencyFocus, setCurrencyFocus] = useState(false)
    const [localCurrencyFocus, setLocalCurrencyFocus] = useState(false)
    const countries = useSelector((state) => state.transfer.create_data.ticket_countries)
    const cities = useSelector((state) => state.transfer.create_data.ticket_cities)
    const currencies = useSelector((state) => state.transfer.create_data.currencies)
    const default_currency_id = useSelector((state) => state.transfer.create_data.default_currency_id)
    const ticket_payment_types = useSelector((state) => state.transfer.create_data.ticket_payment_types)
    const ticket_operating_units = useSelector((state) => state.transfer.create_data.ticket_operating_units)
    const clients = useSelector((state) => state.transfer.clients)
    const client_load = useSelector((state) => state.transfer.client_load)
    const create_load = useSelector((state) => state.transfer.create_load)
    const [nameFocuse, setNameFocuse] = useState(false)
    const reset = useSelector(state => state.transfer.reset)
    const [local_set_from_country, setLocalSetFromCountry] = useState(false)
    const localReset = useSelector(state => state.transfer.localReset)
    const [manyLocals, setManyLocals] = useState([])
    const [hasManyLocals, setHasManyLocals] = useState(false)
    const [loadingUpload, setLoadingUpload] = useState(false)
    const loading = useSelector((state) => state.transfer.loading);
    const data = useSelector((state) => state.transfer.newLang)
    const [valid, setValid] = useState(false)
    const roles = useSelector(state => state.auth.roles)
    const resetAll = () => {
        setNameFocuse(false)
        setCurrencyFocus(false)
        setCountryFocus(false)
        setLocalCurrencyFocus(false)
    }
    useEffect(() => {
        dispatch({ type: "GET_CREATE_DATA_TICKETS" })
    }, [])
    useEffect(() => {
        if (reset !== localReset) {
            resetAll()
            dispatch({
                type: "ticket_modal_reseted"
            })
            props.Close()
        }
    }, [reset])

    const dispatch = useDispatch()
    const setLocalCurrencyFromCountry = (country_id, city_id, full_name) => {
        if (currencies?.filter(one => one?.ticket_country_id === country_id)?.length > 0) {
            let currency = currencies?.filter(one => one?.ticket_country_id === country_id)[0]
            dispatch({
                type: "NEW-TRF",
                payload: {
                    ...data,
                    ticket_local_currency_id: currency.id,
                    ticket_local_currency_name: currency.name,
                    rate: data?.ticket_type === "deposit" ? (currency?.currency_rate?.deposit_rate ? currency?.currency_rate?.deposit_rate : 0) : (currency?.currency_rate?.withdrawal_rate ? currency?.currency_rate?.withdrawal_rate : 0),
                    ticket_country_id: country_id,
                    ticket_city_id: city_id,
                    ticket_country_name: full_name
                },
            })
            if (currencies?.filter(one => one?.ticket_country_id === country_id)?.length === 1) {
                setLocalSetFromCountry(true)
            } else {
                setHasManyLocals(true)
                setManyLocals(currencies?.filter(one => one?.ticket_country_id === country_id))
                setLocalSetFromCountry(false)
            }
        } else {
            setLocalSetFromCountry(false)
            setHasManyLocals(false)
            setManyLocals(currencies)
        }

    }
    const handleAbsulutePosition = (value) => {
        let element = document.getElementById("default_currency_local_amount")
        if (element) {
            element.style.left = `${(value.length * 7) + 40}px`
        }
    }
    const country_city_merger = (countries, cities) => {
        let fullArray = []
        countries.map((country, index) => {
            cities.map((city, index) => {
                if (city.ticket_country_id === country.id) {
                    fullArray.push({
                        city_id: city.id,
                        country_id: country.id,
                        country_name: country.name,
                        city_name: city.name,
                        flag: country.flag,
                        full_name: `${country.name} / ${city.name}`
                    })
                }
            })
        })
        return fullArray
    }
    const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#8e8e8e',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out',
        width: "50px",
        height: "70px",
        justifyContent: "center",
        marginRight: "10px",
        marginBottom: "10px"
    };

    const focusedStyle = {
        borderColor: '#8e8e8e'
    };

    const acceptStyle = {
        borderColor: '#00e676'
    };

    const rejectStyle = {
        borderColor: '#ff1744'
    };

    const thumbs = data.files?.map(file => (<div style={{ position: "relative" }}>
        <svg onClick={() => {
            dispatch({
                type: "NEW-TRF",
                payload: { ...data, files: data.files.filter(one => one !== file) }
            })
        }} xmlns="http://www.w3.org/2000/svg" width="9.9" height="9.899" viewBox="0 0 9.9 9.899" style={{ position: "absolute", top: "0px", right: "15px", backgroundColor: "#b5b5b5", borderRadius: "5px" }}>
            <g id="Group_7804" data-name="Group 7804" transform="translate(0.707 0.707)">
                <line id="Line_962" data-name="Line 962" x1="12" transform="translate(0 0) rotate(45)" fill="none" stroke="#ff2e2e" stroke-linecap="round" stroke-width="1" />
                <line id="Line_963" data-name="Line 963" x1="12" transform="translate(8.485 0) rotate(135)" fill="none" stroke="#ff2e2e" stroke-linecap="round" stroke-width="1" />
            </g>
        </svg>
        <img
            key={file}
            src={file}
            className="drop_zone_files_ticket_image"
            // Revoke data uri after image is loaded
            onLoad={() => { URL.revokeObjectURL(file) }}
        />
    </div>
    ));
    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => data?.files?.forEach(file => URL.revokeObjectURL(file));
    }, []);

    const uploadFile = async (file) => {
        setLoadingUpload(true)
        await upload(file).then((res) => {
            dispatch({
                type: "NEW-TRF",
                payload: { ...data, files: [...data?.files, `${REACT_APP_BASE_FILE_URL}${res}`] }
            });
            setLoadingUpload(false)
        }).catch(() => {
            toast.error("error while upload file")
            setLoadingUpload(false)
        })
    }

    const onDrop = (acceptedFiles) => {
        acceptedFiles.map((file) => uploadFile(file))
    }
    function StyledDropzone(props) {
        const {
            getRootProps,
            getInputProps,
            isFocused,
            isDragAccept,
            isDragReject,
        } = useDropzone({ onDrop });

        const style = useMemo(() => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {})
        }), [
            isFocused,
            isDragAccept,
            isDragReject
        ]);

        return (
            <div className="container">
                <div {...getRootProps({ style })}>
                    <input {...getInputProps()} disabled={loadingUpload} />
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
                        <path id="add-3" d="M8.824,3.81H6.19V1.176a1.191,1.191,0,0,0-2.381,0V3.81H1.176a1.191,1.191,0,0,0,0,2.381H3.81V8.824a1.191,1.191,0,0,0,2.381,0V6.19H8.824a1.191,1.191,0,0,0,0-2.381Z" fill="#8e8e8e" />
                    </svg>
                </div>
            </div>
        );
    }

    const ButtonValidate = () => {
        if (
            data?.client?.length &&
            data?.amount?.length &&
            data?.ticket_type?.length &&
            data?.ticket_currency_id &&
            data?.ticket_country_id &&
            data?.ticket_city_id &&
            data?.ticket_operating_unit_id &&
            data?.trading_number?.length &&
            !loadingUpload
        ) {
            setValid(true)
        } else {
            setValid(false)
        }
    };
    useEffect(() => {
        ButtonValidate()
    }, [data, loadingUpload])
    const sendRequest = () => {
        if (!create_load) {
            if (
                data?.client?.length &&
                data?.amount?.length &&
                data?.ticket_type?.length &&
                data?.ticket_currency_id &&
                data?.ticket_country_id &&
                data?.ticket_city_id &&
                data?.ticket_operating_unit_id &&
                data?.trading_number?.length &&
                !loadingUpload
            ) {
                dispatch({ type: "ADD-TRF", payload: data });
            } else {
                Array.from(document.querySelectorAll(".aside_ticket_modal_body .required_value input")).map((inp) => {
                    if (inp.type !== "date" && inp.value?.length < 2) {
                        inp.parentElement.classList.add("shake-modal");
                        inp.classList.add("red-bord");
                        setTimeout(() => {
                            inp.parentElement.classList.remove("shake-modal");
                        }, 400);
                        setTimeout(() => {
                            inp.classList.remove("red-bord");
                        }, 1000);
                    }
                });
                Array.from(document.querySelectorAll(".aside_ticket_modal_body .required_value_not_input")).map((inp) => {
                    if (!data?.ticket_operating_unit_id) {
                        inp.parentElement.classList.add("shake-modal");
                        inp.classList.add("red-bord");
                        setTimeout(() => {
                            inp.parentElement.classList.remove("shake-modal");
                        }, 400);
                        setTimeout(() => {
                            inp.classList.remove("red-bord");
                        }, 1000);
                    }
                });
            }
        }
    };

    return (
        <div className="aside_ticket_modal" style={{
            position: "absolute",
            right: props.wideView ? "0px" : "450px"
        }}>
            <div className="aside_ticket_modal_top">
                <div className="modal-top-title">
                    {
                        <svg
                            className="no-dis"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                        >
                            <g
                                id="Mask_Group_16"
                                data-name="Mask Group 16"
                                transform="translate(-324 -333)"
                            >
                                <path
                                    id="add-3"
                                    d="M13.235,5.714H9.286V1.765a1.786,1.786,0,0,0-3.571,0v3.95H1.765a1.786,1.786,0,0,0,0,3.571h3.95v3.95a1.786,1.786,0,0,0,3.571,0V9.286h3.95a1.786,1.786,0,0,0,0-3.571Z"
                                    transform="translate(324 333)"
                                    fill="#404040"
                                />
                            </g>
                        </svg>
                    }
                    {/* ATTENTION!! ADDING CONDITION NEXT LINE */}

                    <div className="modal-title">
                        Transfer Request
                    </div>

                </div>
                <div className="modal-top-buttons">
                    <div
                        className="close-button"
                        onClick={() => {
                            if (!loading) {
                                props.Close();
                            }
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="9.9"
                            height="9.899"
                            viewBox="0 0 9.9 9.899"
                        >
                            <g
                                id="Group_4637"
                                data-name="Group 4637"
                                transform="translate(-224.153 -513.36) rotate(45)"
                            >
                                <line
                                    id="Line_962"
                                    data-name="Line 962"
                                    x1="12"
                                    transform="translate(522.5 204.5)"
                                    fill="none"
                                    stroke="#ff2e2e"
                                    strokeLinecap="round"
                                    strokeWidth="1"
                                />
                                <line
                                    id="Line_963"
                                    data-name="Line 963"
                                    x1="12"
                                    transform="translate(528.5 198.5) rotate(90)"
                                    fill="none"
                                    stroke="#ff2e2e"
                                    strokeLinecap="round"
                                    strokeWidth="1"
                                />
                            </g>
                        </svg>
                    </div>
                </div>
            </div>
            <div className="aside_ticket_modal_body">
                <div className="aside_ticket_modal_body_request_type">
                    <div className="modal-icon-name">Ticket type</div>
                    <div className="modal-icons trf">
                        <div
                            onClick={() => {
                                // dispatch({
                                //     type: "NEW-TRF",
                                //     payload: { ...data, ticket_type: "deposit" },
                                // })
                            }
                            }
                            className="aside_ticket_modal_body_req"
                        >
                            <svg
                                id="Group_7851"
                                data-name="Group 7851"
                                xmlns="http://www.w3.org/2000/svg"
                                width="71"
                                height="51"
                                viewBox="0 0 71 51"
                            >
                                <g
                                    id="Rectangle_4641"
                                    data-name="Rectangle 4641"
                                    fill="#f2fff7"
                                    stroke="#5d5d5d"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={data?.ticket_type === "deposit" ? "0.6" : "0"}
                                >
                                    <rect width="70" height="50" rx="10" stroke="none" />
                                    <rect
                                        x="0.25"
                                        y="0.25"
                                        width="69.5"
                                        height="49.5"
                                        rx="9.75"
                                        fill="none"
                                    />
                                </g>
                                <path
                                    id="Path_19065"
                                    data-name="Path 19065"
                                    d="M1.67,1.568H4.8c3.4,0,5.4-2.115,5.4-5.72s-2-5.712-5.4-5.712H1.67a.676.676,0,0,0-.713.737V.831A.676.676,0,0,0,1.67,1.568ZM2.383.284V-8.581H4.7C7.3-8.581,8.737-7,8.737-4.136,8.737-1.3,7.295.284,4.7.284Z"
                                    transform="translate(29.043 28.864)"
                                    fill="#404040"
                                />
                                <path
                                    id="Path_19071"
                                    data-name="Path 19071"
                                    d="M126.136,64.906a12.99,12.99,0,0,1-6.717-1.869.213.213,0,0,1,.22-.364,12.78,12.78,0,0,0,15.545-20.035,13.717,13.717,0,0,0-1.931-1.583.213.213,0,0,1,.233-.356,14.14,14.14,0,0,1,2,1.635,13.231,13.231,0,0,1-9.345,22.572Zm0,0"
                                    transform="translate(-92.33 -28.227)"
                                    fill="#555"
                                />
                                <path
                                    id="Path_19073"
                                    data-name="Path 19073"
                                    d="M380.717,42.172a.213.213,0,0,1-.213-.213v-2.1a.211.211,0,0,1,.064-.152.226.226,0,0,1,.154-.061l2.348.054a.213.213,0,0,1,.208.218.217.217,0,0,1-.218.208l-2.13-.049v1.884A.213.213,0,0,1,380.717,42.172Zm0,0"
                                    transform="translate(-339.623 -27.262)"
                                    fill="#555"
                                />
                            </svg>
                            {data?.ticket_type === "deposit" && (
                                <div className="req_type">Deposit</div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="modal-icon-section" style={{ marginTop: "15px" }}>
                    <div className="modal-icon-name">
                        <svg
                            id="_15x15_photo_back"
                            data-name="15x15 photo back"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                        >
                            <g id="Mask_Group_208" data-name="Mask Group 208">
                                <path
                                    id="location-pin"
                                    d="M7.5,0A5.652,5.652,0,0,0,1.875,5.666c0,4.439,5.1,9.023,5.313,9.216a.47.47,0,0,0,.624,0c.217-.193,5.313-4.777,5.313-9.216A5.652,5.652,0,0,0,7.5,0Zm0,8.75a3.125,3.125,0,1,1,3.125-3.125A3.129,3.129,0,0,1,7.5,8.75Z"
                                    fill="#5d5d5d"
                                />
                            </g>
                        </svg>

                        <div className="modal-name-icon">Ticket Country / City</div>
                    </div>
                </div>
                <div className="input-section">
                    <div className="input-holder required_value">
                        <div className="absolute_flag_country_ticket">
                            {data?.ticket_country_id && (
                                countries.filter((a) => a.id === data?.ticket_country_id)[0]?.flag
                            )}
                        </div>
                        <InputText
                            setFocuse={(a) => setCountryFocus(a)}
                            placeholder="Ticket Country / City"
                            pad35
                            value={data?.ticket_country_name ? data?.ticket_country_name : ""}
                            setValue={(e) => {
                                dispatch({
                                    type: "NEW-TRF",
                                    payload: {
                                        ...data,
                                        ticket_country_name: e.target.value,
                                        ticket_country_id: null,
                                        ticket_city_id: null,
                                        ticket_local_currency_id: null,
                                        ticket_local_currency_name: ""
                                    },
                                });
                            }}
                            isSelect
                            selectedValue={data?.ticket_country_id}
                        />
                        {countryFocus ? (
                            <div className="input-sync">
                                {country_city_merger(countries, cities).filter(one => one.full_name?.toLowerCase()?.includes(data.ticket_country_name?.toLowerCase()))?.map((a) => (
                                    <div
                                        className="input-sync-item"
                                        onClick={() => {
                                            dispatch({
                                                type: "NEW-TRF",
                                                payload: {
                                                    ...data,
                                                    ticket_country_id: a.country_id,
                                                    ticket_city_id: a.city_id,
                                                    ticket_country_name: a.full_name
                                                },
                                            })
                                            setCountryFocus(false)
                                            setLocalCurrencyFromCountry(a.country_id, a.city_id, a.full_name)
                                        }}
                                    >
                                        <div className="relative_flag_country_ticket">
                                            {a.flag}
                                        </div>
                                        <div className="input-sync-text">
                                            {a.full_name}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : ""}
                    </div>
                </div>
                {data?.ticket_country_id ? <><div className="modal-icon-section" style={{ marginTop: "15px" }}>
                    <div className="modal-icon-name">
                        <svg
                            id="_15x15_photo_back"
                            data-name="15x15 photo back"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                        >
                            <g id="Mask_Group_208" data-name="Mask Group 208">
                                <path
                                    id="location-pin"
                                    d="M7.5,0A5.652,5.652,0,0,0,1.875,5.666c0,4.439,5.1,9.023,5.313,9.216a.47.47,0,0,0,.624,0c.217-.193,5.313-4.777,5.313-9.216A5.652,5.652,0,0,0,7.5,0Zm0,8.75a3.125,3.125,0,1,1,3.125-3.125A3.129,3.129,0,0,1,7.5,8.75Z"
                                    fill="#5d5d5d"
                                />
                            </g>
                        </svg>

                        <div className="modal-name-icon">Address</div>
                    </div>
                </div>
                    <div className="input-section">
                        <div className="input-holder">
                            <InputText
                                setFocuse={(a) => {

                                }}
                                placeholder="Address"
                                value={data?.address ? data?.address : ""}
                                setValue={(e) => {
                                    dispatch({
                                        type: "NEW-TRF",
                                        payload: {
                                            ...data,
                                            address: e.target.value
                                        },
                                    });
                                }}
                            />
                        </div>
                    </div> </> : <></>}
                <div
                    className="modal-icon-section trf-modal"
                    style={{ marginTop: "15px" }}
                >
                    <div className="modal-icon-name">
                        <svg
                            id="_15x15_photo_back"
                            data-name="15x15 photo back"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                        >
                            <g id="Mask_Group_173" data-name="Mask Group 173">
                                <g
                                    id="Group_13"
                                    data-name="Group 13"
                                    transform="translate(1.608 0)"
                                >
                                    <path
                                        id="Path_20"
                                        data-name="Path 20"
                                        d="M653,149.289c.4-.085.763-.413.823-1.141.049-.6-.1-.9-.329-1.058.625-2.552-1.1-3.052-1.1-3.052a2.59,2.59,0,0,0-3.809-.653,4.5,4.5,0,0,0-1.114.956,3.978,3.978,0,0,0-.852,2.665c-.308.115-.55.414-.489,1.148.063.761.459,1.085.878,1.148a3.044,3.044,0,0,0,5.993-.01Zm-3,1.874c-1.523,0-2.754-2.066-2.754-3.781,0-.23.007-.452.022-.654a5.225,5.225,0,0,0,4.263-1.571,5,5,0,0,1,1.214,2.17v.055c.01,1.713-1.216,3.783-2.739,3.783Z"
                                        transform="translate(-644.076 -142.822)"
                                        fill="#5d5d5d"
                                    />
                                    <path
                                        id="Path_21"
                                        data-name="Path 21"
                                        d="M644.191,174.579l.177-.73a.426.426,0,0,1,.123-.211l-.053-.04-1.576-1.3-.959.23a3.481,3.481,0,0,0-2.767,3.328v2.066a.767.767,0,0,0,.794.731h4.059l.619-3.528a.43.43,0,0,1-.417-.54Z"
                                        transform="translate(-639.136 -163.648)"
                                        fill="#5d5d5d"
                                    />
                                    <path
                                        id="Path_22"
                                        data-name="Path 22"
                                        d="M663.477,172.517l-.946-.23-1.573,1.3-.053.04a.427.427,0,0,1,.124.211l.177.73a.43.43,0,0,1-.417.531l.619,3.528h4.058a.737.737,0,0,0,.762-.731v-2.066a3.465,3.465,0,0,0-2.751-3.319Z"
                                        transform="translate(-654.445 -163.648)"
                                        fill="#5d5d5d"
                                    />
                                </g>
                            </g>
                        </svg>
                        <div className="modal-name-icon">Client</div>
                    </div>
                </div>
                <div className="input-section" onMouseLeave={() => setNameFocuse(false)}>
                    <div className="input-holder required_value">
                        <svg id="_10x10_flag_photo" className="absolute_input_icon" data-name="10x10 flag photo" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="10" height="10" viewBox="0 0 10 10">
                            <g id="Mask_Group_209" data-name="Mask Group 209">
                                <g id="user" transform="translate(0.828)">
                                    <path id="Path_19219" data-name="Path 19219" d="M4.108,4.817a2.33,2.33,0,0,0,1.7-.706,2.33,2.33,0,0,0,.706-1.7,2.331,2.331,0,0,0-.706-1.7A2.331,2.331,0,0,0,4.108,0a2.33,2.33,0,0,0-1.7.706,2.331,2.331,0,0,0-.706,1.7,2.33,2.33,0,0,0,.706,1.7A2.331,2.331,0,0,0,4.108,4.817Zm0,0" fill="#cecdcd" />
                                    <path id="Path_19220" data-name="Path 19220" d="M8.323,7.689a5.949,5.949,0,0,0-.081-.632,4.978,4.978,0,0,0-.155-.635,3.138,3.138,0,0,0-.261-.592,2.236,2.236,0,0,0-.394-.513,1.736,1.736,0,0,0-.566-.355,1.955,1.955,0,0,0-.722-.131A.733.733,0,0,0,5.752,5l-.408.263a2.337,2.337,0,0,1-.528.232,2.049,2.049,0,0,1-1.291,0A2.331,2.331,0,0,1,3,5.259L2.59,5A.732.732,0,0,0,2.2,4.83a1.953,1.953,0,0,0-.722.131,1.735,1.735,0,0,0-.566.355,2.236,2.236,0,0,0-.394.513,3.144,3.144,0,0,0-.261.593A4.99,4.99,0,0,0,.1,7.058,5.928,5.928,0,0,0,.02,7.69C.007,7.881,0,8.08,0,8.28A1.661,1.661,0,0,0,.493,9.536,1.775,1.775,0,0,0,1.764,10H6.579A1.775,1.775,0,0,0,7.85,9.536,1.66,1.66,0,0,0,8.343,8.28C8.343,8.079,8.336,7.88,8.323,7.689Zm0,0" fill="#cecdcd" />
                                </g>
                            </g>
                        </svg>

                        <InputText
                            setFocuse={(s) => {
                                setNameFocuse(s)
                            }}
                            placeholder="Client Name / id"
                            pad35
                            value={data?.client ? data?.client : ""}
                            setValue={(e) => {
                                setNameFocuse(true)
                                dispatch({
                                    type: "NEW-TRF",
                                    payload: {
                                        ...data,
                                        client: e.target.value
                                    },
                                });
                                dispatch({
                                    type: "CLIENT_ASYNC_SAGA",
                                    payload: e.target.value,
                                });
                            }}
                        />
                        {(clients.length && nameFocuse) ? (
                            <div className="input-sync required_value">
                                {clients.map((a) => (
                                    <div
                                        className="input-sync-item"
                                        onClick={() => {
                                            dispatch({
                                                type: "NEW-TRF",
                                                payload: {
                                                    ...data,
                                                    client: a.name ? a.name : "",
                                                    mobile: a.mobile ? a.mobile : "",
                                                    phone_number: a.phone_number ? a.phone_number : "",
                                                    sugar_client_id: a.sugar_client_id ? a.sugar_client_id : "",
                                                    arabic_name: a.arabic_name ? a.arabic_name : "",
                                                    trading_number: a.trading_number ? a.trading_number : ""
                                                },
                                            })
                                            setNameFocuse(false)
                                        }
                                        }
                                    >
                                        <div className="input-sync-text">{a.name}</div>
                                    </div>
                                ))}
                                {client_load && (
                                    <div className="input-sync-item spin-cont">
                                        <Spinner />
                                    </div>
                                )}
                            </div>
                        ) : ""}
                    </div>
                </div>
                <div className="multi_inputs_one_raw">
                    <div className="input-section">
                        <div className="input-holder">
                            <svg id="_10x10_flag_photo" className="absolute_input_icon" data-name="10x10 flag photo" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="10" height="10" viewBox="0 0 10 10">
                                <g id="Mask_Group_209" data-name="Mask Group 209">
                                    <g id="user" transform="translate(0.828)">
                                        <path id="Path_19219" data-name="Path 19219" d="M4.108,4.817a2.33,2.33,0,0,0,1.7-.706,2.33,2.33,0,0,0,.706-1.7,2.331,2.331,0,0,0-.706-1.7A2.331,2.331,0,0,0,4.108,0a2.33,2.33,0,0,0-1.7.706,2.331,2.331,0,0,0-.706,1.7,2.33,2.33,0,0,0,.706,1.7A2.331,2.331,0,0,0,4.108,4.817Zm0,0" fill="#cecdcd" />
                                        <path id="Path_19220" data-name="Path 19220" d="M8.323,7.689a5.949,5.949,0,0,0-.081-.632,4.978,4.978,0,0,0-.155-.635,3.138,3.138,0,0,0-.261-.592,2.236,2.236,0,0,0-.394-.513,1.736,1.736,0,0,0-.566-.355,1.955,1.955,0,0,0-.722-.131A.733.733,0,0,0,5.752,5l-.408.263a2.337,2.337,0,0,1-.528.232,2.049,2.049,0,0,1-1.291,0A2.331,2.331,0,0,1,3,5.259L2.59,5A.732.732,0,0,0,2.2,4.83a1.953,1.953,0,0,0-.722.131,1.735,1.735,0,0,0-.566.355,2.236,2.236,0,0,0-.394.513,3.144,3.144,0,0,0-.261.593A4.99,4.99,0,0,0,.1,7.058,5.928,5.928,0,0,0,.02,7.69C.007,7.881,0,8.08,0,8.28A1.661,1.661,0,0,0,.493,9.536,1.775,1.775,0,0,0,1.764,10H6.579A1.775,1.775,0,0,0,7.85,9.536,1.66,1.66,0,0,0,8.343,8.28C8.343,8.079,8.336,7.88,8.323,7.689Zm0,0" fill="#cecdcd" />
                                    </g>
                                </g>
                            </svg>

                            <InputText
                                setFocuse={(s) => {

                                }}
                                placeholder="Arabic Name"
                                pad35
                                value={data?.arabic_name ? data?.arabic_name : ""}
                                setValue={(e) => {
                                    dispatch({
                                        type: "NEW-TRF",
                                        payload: {
                                            ...data,
                                            arabic_name: e.target.value
                                        },
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div className="input-section">
                        <div className="input-holder">
                            <svg id="_10x10_flag_photo" className="absolute_input_icon" data-name="10x10 flag photo" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="10" height="10" viewBox="0 0 10 10">
                                <g id="Mask_Group_209" data-name="Mask Group 209">
                                    <g id="user" transform="translate(0.828)">
                                        <path id="Path_19219" data-name="Path 19219" d="M4.108,4.817a2.33,2.33,0,0,0,1.7-.706,2.33,2.33,0,0,0,.706-1.7,2.331,2.331,0,0,0-.706-1.7A2.331,2.331,0,0,0,4.108,0a2.33,2.33,0,0,0-1.7.706,2.331,2.331,0,0,0-.706,1.7,2.33,2.33,0,0,0,.706,1.7A2.331,2.331,0,0,0,4.108,4.817Zm0,0" fill="#cecdcd" />
                                        <path id="Path_19220" data-name="Path 19220" d="M8.323,7.689a5.949,5.949,0,0,0-.081-.632,4.978,4.978,0,0,0-.155-.635,3.138,3.138,0,0,0-.261-.592,2.236,2.236,0,0,0-.394-.513,1.736,1.736,0,0,0-.566-.355,1.955,1.955,0,0,0-.722-.131A.733.733,0,0,0,5.752,5l-.408.263a2.337,2.337,0,0,1-.528.232,2.049,2.049,0,0,1-1.291,0A2.331,2.331,0,0,1,3,5.259L2.59,5A.732.732,0,0,0,2.2,4.83a1.953,1.953,0,0,0-.722.131,1.735,1.735,0,0,0-.566.355,2.236,2.236,0,0,0-.394.513,3.144,3.144,0,0,0-.261.593A4.99,4.99,0,0,0,.1,7.058,5.928,5.928,0,0,0,.02,7.69C.007,7.881,0,8.08,0,8.28A1.661,1.661,0,0,0,.493,9.536,1.775,1.775,0,0,0,1.764,10H6.579A1.775,1.775,0,0,0,7.85,9.536,1.66,1.66,0,0,0,8.343,8.28C8.343,8.079,8.336,7.88,8.323,7.689Zm0,0" fill="#cecdcd" />
                                    </g>
                                </g>
                            </svg>
                            <InputText
                                setFocuse={(s) => {

                                }}
                                placeholder="Sugar client id"
                                pad35
                                value={data?.sugar_client_id ? data?.sugar_client_id : ""}
                                setValue={(e) => {
                                    dispatch({
                                        type: "NEW-TRF",
                                        payload: {
                                            ...data,
                                            sugar_client_id: e.target.value
                                        },
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="multi_inputs_one_raw">
                    <div className="input-section">
                        <div className="input-holder">
                            <svg xmlns="http://www.w3.org/2000/svg" className="absolute_input_icon" width="9.738" height="10" viewBox="0 0 9.738 10">
                                <g id="Group_10753" data-name="Group 10753" transform="translate(0)">
                                    <path id="XMLID_8_" d="M8.554,4.2A3.024,3.024,0,0,0,5.877,1.315l-.1-.015a1.391,1.391,0,0,0-.227-.025.381.381,0,0,0-.408.342.4.4,0,0,0,.059.31.586.586,0,0,0,.421.181c.042.006.081.011.114.019A2.133,2.133,0,0,1,7.749,4.222c.005.032.008.072.01.115.01.158.03.488.384.488h0a.629.629,0,0,0,.094-.008c.33-.05.32-.351.315-.5a.735.735,0,0,1,0-.1A.141.141,0,0,0,8.554,4.2Z" transform="translate(-0.131)" fill="#cecdcd" />
                                    <path id="XMLID_9_" d="M5.468.8c.04,0,.077.006.108.01A3.616,3.616,0,0,1,9.061,4.4a1.293,1.293,0,0,1,.006.132c0,.173.009.532.395.54h.012a.379.379,0,0,0,.286-.109A.6.6,0,0,0,9.866,4.5c0-.037,0-.072,0-.1A4.474,4.474,0,0,0,5.671.006H5.617c-.023,0-.05,0-.08,0S5.462,0,5.421,0a.4.4,0,0,0-.437.408C4.963.761,5.3.786,5.468.8Z" transform="translate(-0.131)" fill="#cecdcd" />
                                    <path id="XMLID_10_" d="M8.955,7.258c-.047-.036-.095-.073-.14-.109-.24-.193-.495-.371-.741-.543L7.92,6.5a1.53,1.53,0,0,0-.868-.33,1.125,1.125,0,0,0-.937.593.5.5,0,0,1-.427.259.848.848,0,0,1-.344-.086A4.141,4.141,0,0,1,3.18,4.829c-.2-.452-.136-.748.218-.989a1,1,0,0,0,.549-.877A5.01,5.01,0,0,0,2.184.558a1,1,0,0,0-.681,0A1.97,1.97,0,0,0,.278,1.565,1.874,1.874,0,0,0,.3,3.078,12.206,12.206,0,0,0,2.983,7.155a12.983,12.983,0,0,0,4.063,2.7,2.25,2.25,0,0,0,.4.119l.093.022A.156.156,0,0,0,7.58,10h.013A2.305,2.305,0,0,0,9.507,8.543C9.748,7.864,9.308,7.528,8.955,7.258Z" transform="translate(-0.131)" fill="#cecdcd" />
                                </g>
                            </svg>

                            <InputText
                                setFocuse={(s) => {

                                }}
                                placeholder="phone number"
                                pad35
                                value={data?.phone_number ? data?.phone_number : ""}
                                type="tel"
                                setValue={(e) => {
                                    dispatch({
                                        type: "NEW-TRF",
                                        payload: {
                                            ...data,
                                            phone_number: e.target.value
                                        },
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div className="input-section">
                        <div className="input-holder">
                            <svg xmlns="http://www.w3.org/2000/svg" className="absolute_input_icon" width="9.738" height="10" viewBox="0 0 9.738 10">
                                <g id="Group_10753" data-name="Group 10753" transform="translate(0)">
                                    <path id="XMLID_8_" d="M8.554,4.2A3.024,3.024,0,0,0,5.877,1.315l-.1-.015a1.391,1.391,0,0,0-.227-.025.381.381,0,0,0-.408.342.4.4,0,0,0,.059.31.586.586,0,0,0,.421.181c.042.006.081.011.114.019A2.133,2.133,0,0,1,7.749,4.222c.005.032.008.072.01.115.01.158.03.488.384.488h0a.629.629,0,0,0,.094-.008c.33-.05.32-.351.315-.5a.735.735,0,0,1,0-.1A.141.141,0,0,0,8.554,4.2Z" transform="translate(-0.131)" fill="#cecdcd" />
                                    <path id="XMLID_9_" d="M5.468.8c.04,0,.077.006.108.01A3.616,3.616,0,0,1,9.061,4.4a1.293,1.293,0,0,1,.006.132c0,.173.009.532.395.54h.012a.379.379,0,0,0,.286-.109A.6.6,0,0,0,9.866,4.5c0-.037,0-.072,0-.1A4.474,4.474,0,0,0,5.671.006H5.617c-.023,0-.05,0-.08,0S5.462,0,5.421,0a.4.4,0,0,0-.437.408C4.963.761,5.3.786,5.468.8Z" transform="translate(-0.131)" fill="#cecdcd" />
                                    <path id="XMLID_10_" d="M8.955,7.258c-.047-.036-.095-.073-.14-.109-.24-.193-.495-.371-.741-.543L7.92,6.5a1.53,1.53,0,0,0-.868-.33,1.125,1.125,0,0,0-.937.593.5.5,0,0,1-.427.259.848.848,0,0,1-.344-.086A4.141,4.141,0,0,1,3.18,4.829c-.2-.452-.136-.748.218-.989a1,1,0,0,0,.549-.877A5.01,5.01,0,0,0,2.184.558a1,1,0,0,0-.681,0A1.97,1.97,0,0,0,.278,1.565,1.874,1.874,0,0,0,.3,3.078,12.206,12.206,0,0,0,2.983,7.155a12.983,12.983,0,0,0,4.063,2.7,2.25,2.25,0,0,0,.4.119l.093.022A.156.156,0,0,0,7.58,10h.013A2.305,2.305,0,0,0,9.507,8.543C9.748,7.864,9.308,7.528,8.955,7.258Z" transform="translate(-0.131)" fill="#cecdcd" />
                                </g>
                            </svg>
                            <InputText
                                setFocuse={(s) => { }}
                                placeholder="mobile"
                                pad35
                                value={data?.mobile ? data?.mobile : ""}
                                type="tel"
                                setValue={(e) => {
                                    dispatch({
                                        type: "NEW-TRF",
                                        payload: {
                                            ...data,
                                            mobile: e.target.value
                                        },
                                    })
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="input-section">
                    <div className="input-holder amoun required_value">
                        <InputText
                            setFocuse={() => {

                            }}
                            placeholder="Trading Number"
                            value={data?.trading_number}
                            setValue={(e) => {
                                dispatch({
                                    type: "NEW-TRF",
                                    payload: { ...data, trading_number: e.target.value },
                                });
                            }}
                        />
                    </div>
                </div>
                <div className="modal-icon-section" style={{ marginTop: "15px" }}>
                    <div className="modal-icon-name">
                        <svg
                            id="_15x15_photo_back"
                            data-name="15x15 photo back"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                        >
                            <g id="Mask_Group_177" data-name="Mask Group 177">
                                <g id="money-8" transform="translate(0 0)">
                                    <g
                                        id="Group_7795"
                                        data-name="Group 7795"
                                        transform="translate(0 6.965)"
                                    >
                                        <g id="Group_7794" data-name="Group 7794">
                                            <path
                                                id="Path_19112"
                                                data-name="Path 19112"
                                                d="M.2,7.519a.313.313,0,0,0-.186.4l.2.54L1.709,6.965Z"
                                                transform="translate(0 -6.965)"
                                                fill="#5d5d5d"
                                            />
                                        </g>
                                    </g>
                                    <g
                                        id="Group_7797"
                                        data-name="Group 7797"
                                        transform="translate(1.619 12.319)"
                                    >
                                        <g id="Group_7796" data-name="Group 7796">
                                            <path
                                                id="Path_19113"
                                                data-name="Path 19113"
                                                d="M1.619,12.319l.9,2.475a.31.31,0,0,0,.162.176A.314.314,0,0,0,2.812,15a.306.306,0,0,0,.108-.019l.993-.366Z"
                                                transform="translate(-1.619 -12.319)"
                                                fill="#5d5d5d"
                                            />
                                        </g>
                                    </g>
                                    <g
                                        id="Group_7799"
                                        data-name="Group 7799"
                                        transform="translate(8.343 7.374)"
                                    >
                                        <g id="Group_7798" data-name="Group 7798">
                                            <path
                                                id="Path_19114"
                                                data-name="Path 19114"
                                                d="M14.981,10.206l-1.03-2.832L11.479,9.846,12.7,9.4a.312.312,0,1,1,.216.586l-1.556.574a.312.312,0,0,1-.4-.185s0,0,0-.006L8.343,12.984,14.8,10.606A.312.312,0,0,0,14.981,10.206Z"
                                                transform="translate(-8.343 -7.374)"
                                                fill="#5d5d5d"
                                            />
                                        </g>
                                    </g>
                                    <g
                                        id="Group_7801"
                                        data-name="Group 7801"
                                        transform="translate(0.001)"
                                    >
                                        <g id="Group_7800" data-name="Group 7800">
                                            <path
                                                id="Path_19115"
                                                data-name="Path 19115"
                                                d="M14.908,5.091l-5-5a.313.313,0,0,0-.442,0L.092,9.466a.313.313,0,0,0,0,.442l5,5A.309.309,0,0,0,5.312,15a.314.314,0,0,0,.221-.091l9.375-9.375A.313.313,0,0,0,14.908,5.091ZM3.659,8.659l-1.25,1.25a.313.313,0,1,1-.442-.442l1.25-1.25a.313.313,0,0,1,.442.442Zm5.4.4a1.18,1.18,0,0,1-.856.327,2.582,2.582,0,0,1-1.738-.83,2.863,2.863,0,0,1-.781-1.33,1.306,1.306,0,0,1,.278-1.264,1.3,1.3,0,0,1,1.264-.278,2.858,2.858,0,0,1,1.33.781C9.421,7.329,9.642,8.469,9.055,9.056Zm3.978-3.522-1.25,1.25a.313.313,0,0,1-.442-.442l1.25-1.25a.313.313,0,0,1,.442.442Z"
                                                transform="translate(-0.001 0)"
                                                fill="#5d5d5d"
                                            />
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>

                        <div className="modal-name-icon"> Amount & Currency</div>
                    </div>
                </div>
                <div className="multi_inputs_one_raw">
                    <div className="input-section">
                        <div className="input-holder amoun required_value">
                            <svg className="absolute_input_icon" id="_10x10_flag_photo" data-name="10x10 flag photo" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="10" height="10" viewBox="0 0 10 10">
                                <g id="Mask_Group_193" data-name="Mask Group 193">
                                    <g id="money" transform="translate(0.853 0)">
                                        <g id="Group_7902" data-name="Group 7902">
                                            <g id="Group_7895" data-name="Group 7895" transform="translate(4.356 6.426)">
                                                <path id="Path_19165" data-name="Path 19165" d="M5.68,6.643a.475.475,0,0,0-.133-.1,1.61,1.61,0,0,0-.338-.119v.895a.635.635,0,0,0,.5-.264h0a.409.409,0,0,0,.037-.279A.281.281,0,0,0,5.68,6.643Z" transform="translate(-5.209 -6.426)" fill="#cecdcd" />
                                            </g>
                                            <g id="Group_7896" data-name="Group 7896" transform="translate(4.856 7.057)">
                                                <path id="Path_19166" data-name="Path 19166" d="M5.709,7.059v0h0Z" transform="translate(-5.709 -7.057)" fill="#cecdcd" />
                                            </g>
                                            <g id="Group_7897" data-name="Group 7897" transform="translate(3.417 4.991)">
                                                <path id="Path_19167" data-name="Path 19167" d="M4.33,5.213a.335.335,0,0,0-.058.157.377.377,0,0,0,.019.174.279.279,0,0,0,.108.119.845.845,0,0,0,.161.083c.047.019.1.037.157.054V4.991A.618.618,0,0,0,4.33,5.213Z" transform="translate(-4.269 -4.991)" fill="#cecdcd" />
                                            </g>
                                            <g id="Group_7898" data-name="Group 7898" transform="translate(4.857 7.05)">
                                                <path id="Path_19168" data-name="Path 19168" d="M5.713,7.05l0,.007h0Z" transform="translate(-5.71 -7.05)" fill="#cecdcd" />
                                            </g>
                                            <g id="Group_7899" data-name="Group 7899" transform="translate(4.865 7.041)">
                                                <path id="Path_19169" data-name="Path 19169" d="M5.718,7.042Z" transform="translate(-5.718 -7.041)" fill="#cecdcd" />
                                            </g>
                                            <g id="Group_7900" data-name="Group 7900">
                                                <path id="Path_19170" data-name="Path 19170" d="M5.524,2.176C6.285,1.537,6.793.1,6.48.034A7.559,7.559,0,0,0,4.726.382C4.107.457,3.433-.289,3.055.127c-.308.338.221,1.567,1.047,2.1-2.465,1.211-5.929,7.293.115,7.734C12.579,10.574,8.391,3.356,5.524,2.176Zm.718,4.8a.879.879,0,0,1-.339.6,1.3,1.3,0,0,1-.694.245v.262a.246.246,0,1,1-.492,0V7.79c-.042-.008-.084-.018-.126-.029a1.208,1.208,0,0,1-.6-.37A1.085,1.085,0,0,1,3.8,7.084c-.011-.029-.022-.059-.031-.089a.457.457,0,0,1-.019-.083.246.246,0,0,1,.478-.1c.009.028.016.057.026.085a.548.548,0,0,0,.038.081.627.627,0,0,0,.108.138.732.732,0,0,0,.313.171V6.312a1.751,1.751,0,0,1-.645-.279.75.75,0,0,1-.218-.262.819.819,0,0,1-.076-.352.839.839,0,0,1,.074-.357.869.869,0,0,1,.2-.277,1.232,1.232,0,0,1,.663-.294V4.22a.246.246,0,1,1,.492,0v.27l.1.015a1.215,1.215,0,0,1,.642.312.939.939,0,0,1,.2.283.9.9,0,0,1,.035.09.547.547,0,0,1,.023.088.246.246,0,0,1-.473.124c-.01-.027-.015-.055-.026-.082a.433.433,0,0,0-.04-.077.469.469,0,0,0-.116-.122.8.8,0,0,0-.338-.132v.931a3.123,3.123,0,0,1,.42.125.982.982,0,0,1,.513.4c-.017-.031-.034-.06,0,0s.018.032,0,0A.877.877,0,0,1,6.242,6.972Z" transform="translate(-0.853 0)" fill="#cecdcd" />
                                            </g>
                                            <g id="Group_7901" data-name="Group 7901" transform="translate(4.85 7.063)">
                                                <path id="Path_19171" data-name="Path 19171" d="M5.706,7.063l0,.007Z" transform="translate(-5.702 -7.063)" fill="#cecdcd" />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>

                            <InputText
                                setFocuse={() => {

                                }}
                                placeholder="Amount"
                                pad35
                                type="number"
                                value={data?.amount}
                                setValue={(e) => {
                                    dispatch({
                                        type: "NEW-TRF",
                                        payload: { ...data, amount: e.target.value },
                                    });
                                    handleAbsulutePosition(e.target.value)
                                }}
                            />
                            <div id="default_currency_local_amount" className="absulute_currency">
                                {(data?.amount && default_currency_id) ? currencies?.filter(one => one.id === default_currency_id)[0]?.name + " / " + (data.amount * data.rate) + " " + (data?.ticket_local_currency_name ? data?.ticket_local_currency_name : "no local") :
                                    (data?.amount ? "no default" : "")}
                            </div>
                            {hasManyLocals && <div>
                                <svg
                                    onClick={() => {
                                        setLocalCurrencyFocus(true)
                                    }}
                                    style={{
                                        position: "absolute",
                                        bottom: "14px",
                                        right: "0px",
                                        zIndex: 999,
                                        cursor: "pointer"
                                    }} id="_15x15_photo_back" data-name="15x15 photo back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
                                    <g id="Mask_Group_194" data-name="Mask Group 194">
                                        <g id="dollar" transform="translate(0 0.001)">
                                            <g id="Group_7904" data-name="Group 7904">
                                                <g id="Group_7903" data-name="Group 7903">
                                                    <path id="Path_19172" data-name="Path 19172" d="M7.5,11.739a.326.326,0,0,0,.326-.326v-.326h.2a1.6,1.6,0,0,0,.7-.161,1.768,1.768,0,0,0,.558-.428,1.962,1.962,0,0,0,.368-.619,2.1,2.1,0,0,0,.135-.748,2.124,2.124,0,0,0-.134-.747,1.976,1.976,0,0,0-.369-.62,1.768,1.768,0,0,0-.558-.428,1.6,1.6,0,0,0-.7-.161h-.2V4.565h.2a.955.955,0,0,1,.415.1,1.126,1.126,0,0,1,.353.272,1.342,1.342,0,0,1,.247.417.326.326,0,1,0,.612-.228A1.976,1.976,0,0,0,9.28,4.5a1.768,1.768,0,0,0-.558-.428,1.605,1.605,0,0,0-.7-.161h-.2V3.586a.326.326,0,1,0-.652,0v.326h-.2a1.6,1.6,0,0,0-.7.161,1.768,1.768,0,0,0-.558.428,1.967,1.967,0,0,0-.369.62,2.151,2.151,0,0,0,0,1.495,1.976,1.976,0,0,0,.369.62,1.768,1.768,0,0,0,.558.428,1.6,1.6,0,0,0,.7.161h.2v2.609h-.2a.955.955,0,0,1-.415-.1,1.126,1.126,0,0,1-.353-.272,1.342,1.342,0,0,1-.247-.417.326.326,0,1,0-.612.228,1.976,1.976,0,0,0,.369.62,1.768,1.768,0,0,0,.558.428,1.605,1.605,0,0,0,.7.161h.2v.326A.326.326,0,0,0,7.5,11.739Zm.326-3.913h.2a.955.955,0,0,1,.415.1,1.126,1.126,0,0,1,.353.272,1.352,1.352,0,0,1,.248.418,1.486,1.486,0,0,1,.092.518,1.464,1.464,0,0,1-.093.519,1.35,1.35,0,0,1-.247.417,1.126,1.126,0,0,1-.353.272.952.952,0,0,1-.415.1h-.2Zm-.848-.652a.955.955,0,0,1-.415-.1,1.126,1.126,0,0,1-.353-.272,1.352,1.352,0,0,1-.248-.418,1.486,1.486,0,0,1-.092-.518,1.464,1.464,0,0,1,.093-.519,1.35,1.35,0,0,1,.247-.417,1.126,1.126,0,0,1,.353-.272.952.952,0,0,1,.415-.1h.2V7.173Z" transform="translate(0 -0.001)" fill="#5d5d5d" />
                                                    <path id="Path_19173" data-name="Path 19173" d="M14.674,7.173a.326.326,0,0,0-.326.326c0,.237-.012.471-.035.7s-.059.458-.1.68a6.528,6.528,0,0,1-.168.656c-.067.214-.144.425-.23.629s-.183.4-.289.6-.219.382-.343.565-.255.358-.393.527-.287.331-.443.487-.318.3-.487.442-.344.271-.528.394-.371.237-.564.343-.394.2-.6.288-.415.164-.628.231-.434.123-.657.168-.45.08-.679.1a7.183,7.183,0,0,1-1.4,0c-.23-.024-.458-.059-.68-.1a6.529,6.529,0,0,1-.656-.168c-.214-.067-.425-.144-.629-.23s-.405-.183-.6-.288-.383-.22-.565-.344-.358-.255-.527-.393-.331-.287-.487-.443-.3-.318-.442-.487-.271-.344-.393-.527c-.053-.078-.1-.161-.147-.241H3.587a.326.326,0,1,0,0-.652H.978a.326.326,0,0,0-.326.326v2.609a.326.326,0,1,0,.652,0V11.724c.128.188.263.371.409.546s.314.363.483.532.347.331.532.484.378.3.577.431.406.26.619.376.431.22.656.316.453.179.689.252.475.134.719.185a7.52,7.52,0,0,0,.745.113A7.432,7.432,0,0,0,7.5,15c.258,0,.514-.014.768-.039s.5-.064.744-.113.483-.112.72-.185.464-.158.689-.253.443-.2.656-.316.419-.241.618-.375.393-.279.578-.432.363-.314.532-.483.331-.347.484-.532.3-.378.431-.577.26-.406.375-.618a7.254,7.254,0,0,0,.317-.657q.142-.336.252-.689t.185-.719a7.52,7.52,0,0,0,.113-.745c.025-.252.038-.507.038-.766A.326.326,0,0,0,14.674,7.173Z" transform="translate(0 -0.001)" fill="#5d5d5d" />
                                                    <path id="Path_19174" data-name="Path 19174" d="M.687,6.8c.024-.23.059-.458.1-.68A6.529,6.529,0,0,1,.96,5.463c.067-.214.143-.425.23-.629s.183-.405.288-.6.22-.383.344-.565.255-.358.393-.527.287-.331.443-.487.318-.3.487-.442.345-.271.528-.394.371-.237.564-.343.395-.2.6-.288.415-.164.628-.23S5.9.837,6.12.791s.45-.08.679-.1a7.183,7.183,0,0,1,1.4,0c.23.024.458.059.68.1A6.529,6.529,0,0,1,9.536.96c.214.067.425.144.629.23s.405.183.6.288.383.22.565.344.358.255.527.393.331.287.487.443.3.318.442.487.271.344.394.528c.053.078.1.161.147.241H11.413a.326.326,0,1,0,0,.652h2.609a.326.326,0,0,0,.326-.326V1.63a.326.326,0,1,0-.652,0V3.275c-.128-.188-.263-.371-.409-.546s-.314-.363-.483-.532-.347-.331-.533-.485-.378-.3-.577-.431-.406-.26-.619-.376-.431-.22-.656-.316S9.965.41,9.729.337,9.254.2,9.01.152A7.52,7.52,0,0,0,8.265.039a7.728,7.728,0,0,0-1.534,0c-.252.025-.5.064-.744.113s-.482.112-.718.185S4.8.495,4.58.589s-.443.2-.656.316-.418.241-.618.376-.392.279-.577.432-.363.314-.533.483-.331.347-.484.533-.3.378-.431.577-.261.406-.376.62-.22.431-.316.655S.41,5.034.337,5.27.2,5.745.153,5.988a7.52,7.52,0,0,0-.113.745A7.609,7.609,0,0,0,0,7.5a.326.326,0,1,0,.652,0C.652,7.263.665,7.029.687,6.8Z" transform="translate(0 -0.001)" fill="#5d5d5d" />
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                                {localCurrencyFocus && !local_set_from_country ? (
                                    <div className="input-sync">
                                        {manyLocals?.map((a) => (
                                            <div
                                                className="input-sync-item"
                                                onClick={() => {
                                                    dispatch({
                                                        type: "NEW-TRF",
                                                        payload: {
                                                            ...data,
                                                            ticket_local_currency_id: a.id,
                                                            ticket_local_currency_name: a.name,
                                                            rate: data?.ticket_type === "deposit" ? (a?.currency_rate?.deposit_rate ? a?.currency_rate?.deposit_rate : 0) : (a?.currency_rate?.withdrawal_rate ? a?.currency_rate?.withdrawal_rate : 0),
                                                            ticket_payment_type_id: a.ticket_payment_type_id
                                                        },
                                                    })
                                                    setLocalCurrencyFocus(false)
                                                }}
                                            >
                                                <div className="input-sync-text">
                                                    {a.name}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : ""}
                            </div>}
                        </div>
                    </div>
                </div>
                <div className="multi_inputs_one_raw">
                    <div className="input-section">
                        <div className="input-holder amoun">
                            <svg className="absolute_input_icon" xmlns="http://www.w3.org/2000/svg" width="9.727" height="10" viewBox="0 0 9.727 10">
                                <g id="Group_10758" data-name="Group 10758" transform="translate(-0.137)">
                                    <path id="Path_19145" data-name="Path 19145" d="M4.406,3.366a3.012,3.012,0,0,1,.536-1.457,2.431,2.431,0,1,0-2.881,2.9A3.645,3.645,0,0,1,4.406,3.366ZM1.6,3.01a.154.154,0,0,1,.157-.157h.5V2.737L2.183,2.62H1.752a.157.157,0,0,1,0-.314H2l-.531-.872A.352.352,0,0,1,1.41,1.27.312.312,0,0,1,1.756.976a.307.307,0,0,1,.274.189L2.568,2.19l.538-1.025A.307.307,0,0,1,3.38.976a.312.312,0,0,1,.346.293.352.352,0,0,1-.056.165l-.531.873h.245a.157.157,0,0,1,0,.314h-.43l-.068.117v.117h.5a.157.157,0,0,1,0,.314h-.5v.4a.3.3,0,0,1-.317.322.3.3,0,0,1-.317-.322v-.4h-.5A.154.154,0,0,1,1.6,3.01Z" fill="#cecdcd" />
                                    <path id="Path_19146" data-name="Path 19146" d="M7.432,1.216a2.434,2.434,0,0,0-2.406,2.1,3.625,3.625,0,0,1,1.314.257.17.17,0,0,1,.17-.1h.084a.749.749,0,0,1-.12-.43.914.914,0,0,1,1.009-.848c.7,0,1.046.378,1.046.691a.272.272,0,0,1-.3.281c-.354,0-.137-.49-.679-.49a.421.421,0,0,0-.462.4.882.882,0,0,0,.133.394h.438c.165,0,.249.06.249.193s-.084.193-.249.193H7.337a.255.255,0,0,1,.021.1.519.519,0,0,1-.041.192,3.665,3.665,0,0,1,.368.355,1.588,1.588,0,0,0,.329.049c.068,0,.234-.044.3-.044a.233.233,0,0,1,.233.257c0,.227-.2.325-.412.339a3.678,3.678,0,0,1,.34.743A2.429,2.429,0,0,0,7.432,1.216Z" fill="#cecdcd" />
                                    <path id="Path_19149" data-name="Path 19149" d="M5,3.921a3.039,3.039,0,1,0,3.04,3.04A3.043,3.043,0,0,0,5,3.921Zm.161,4.837V9.07a.152.152,0,1,1-.3,0V8.758c-.849-.02-1.271-.528-1.271-.925A.289.289,0,0,1,3.9,7.517c.563,0,.125.694.96.729V7.195c-.744-.136-1.2-.462-1.2-1.02a1.1,1.1,0,0,1,1.2-1.055V4.852a.152.152,0,1,1,.3,0V5.12c.391.01,1.2.256,1.2.749a.3.3,0,0,1-.317.311c-.327,0-.322-.537-.879-.547v.955c.663.141,1.251.337,1.251,1.111C6.412,8.371,5.909,8.713,5.161,8.758Z" fill="#cecdcd" />
                                </g>
                            </svg>
                            <InputText
                                setFocuse={() => {

                                }}
                                placeholder="Rate"
                                pad35
                                type="number"
                                disabled={!roles.includes("CAN_EDIT_LOCAL_CURRENCY_AMOUNT")}
                                value={data?.rate}
                                setValue={(e) => {
                                    dispatch({
                                        type: "NEW-TRF",
                                        payload: { ...data, rate: e.target.value },
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
                {data?.ticket_country_id && data?.ticket_country_name?.toLowerCase()?.includes("india") ? <><div
                    className="modal-icon-section"
                    style={{ marginTop: "15px" }}
                >
                    <div className="modal-icon-name">
                        <svg id="_10x10_flag_photo" data-name="10x10 flag photo" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
                            <g id="Mask_Group_195" data-name="Mask Group 195">
                                <g id="options" transform="translate(0 0.011)">
                                    <path id="Path_19175" data-name="Path 19175" d="M2.346,5.313V3.125a1.867,1.867,0,0,1-.585,0V5.314A2.652,2.652,0,0,1,2.054,5.3a2.683,2.683,0,0,1,.292.016Z" transform="translate(0.935 1.989)" fill="#5d5d5d" />
                                    <path id="Path_19176" data-name="Path 19176" d="M4.115,6.288,6.282,4.121a1.877,1.877,0,0,1-.413-.413L3.7,5.875a2.665,2.665,0,0,1,.413.413Z" transform="translate(2.494 2.488)" fill="#5d5d5d" />
                                    <path id="Path_19177" data-name="Path 19177" d="M6.885,7.643H4.676a2.684,2.684,0,0,1,.016.292,2.656,2.656,0,0,1-.016.292H6.885a1.868,1.868,0,0,1,0-.585Z" transform="translate(3.001 4.052)" fill="#5d5d5d" />
                                    <circle id="Ellipse_335" data-name="Ellipse 335" cx="1.277" cy="1.277" r="1.277" transform="translate(0.777)" fill="#5d5d5d" />
                                    <circle id="Ellipse_336" data-name="Ellipse 336" cx="1.277" cy="1.277" r="1.277" transform="translate(11.098 1.328)" fill="#5d5d5d" />
                                    <circle id="Ellipse_337" data-name="Ellipse 337" cx="1.277" cy="1.277" r="1.277" transform="translate(12.447 11.649)" fill="#5d5d5d" />
                                    <path id="Path_19178" data-name="Path 19178" d="M4.108,7.936A2.054,2.054,0,1,0,2.054,9.989,2.041,2.041,0,0,0,4.108,7.936Z" transform="translate(0 4.989)" fill="#5d5d5d" />
                                </g>
                            </g>
                        </svg>


                        <div className="modal-name-icon">Payment Type</div>
                    </div>
                </div>
                    <div className="input-section payment-type">
                        <div className="input-holder">
                            <div className="select_area_commision_type">
                                {ticket_payment_types.map((a) => (
                                    <div
                                        className={a.id === data?.ticket_payment_type_id ? "select_item_commision_type_selected" : "select_item_commision_type"}
                                        onClick={() => {
                                            dispatch({
                                                type: "NEW-TRF",
                                                payload: {
                                                    ...data,
                                                    ticket_payment_type_id: a.id,
                                                    ticket_local_currency_id: currencies.filter(currency => currency.ticket_payment_type_id === a.id)[0]?.id,
                                                    ticket_local_currency_name: currencies.filter(currency => currency.ticket_payment_type_id === a.id)[0]?.name
                                                },
                                            })
                                        }}
                                    >
                                        <div className={a.id === data?.ticket_payment_type_id ? "select_item_commision_type_text_selected" : "select_item_commision_type_text"}>
                                            {a.name}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div></> : <></>}
                {/* start */}
                <div className="modal-icon-section" style={{ marginTop: "15px" }}>
                    <div className="modal-icon-name">
                        <svg id="Group_10757" data-name="Group 10757" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                            <path id="Path_19179" data-name="Path 19179" d="M5.753,0a.439.439,0,0,0-.441.438V6.6a.439.439,0,0,0,.441.438h6.169a.439.439,0,0,0,.441-.438A6.625,6.625,0,0,0,5.753,0Z" transform="translate(2.637)" fill="#5d5d5d" />
                            <g id="Group_7908" data-name="Group 7908" transform="translate(0 1.191)">
                                <g id="Group_7907" data-name="Group 7907">
                                    <path id="Path_19180" data-name="Path 19180" d="M9.4,13.97,7.223,8V1.651a.453.453,0,0,0-.448-.459A6.906,6.906,0,0,0,6.775,15a6.533,6.533,0,0,0,2.358-.438A.463.463,0,0,0,9.4,13.97Z" transform="translate(0 -1.191)" fill="#5d5d5d" />
                                </g>
                            </g>
                            <path id="Path_19181" data-name="Path 19181" d="M11.923,5.293H5.753a.436.436,0,0,0-.362.2.484.484,0,0,0-.05.427l2.166,6.106a.455.455,0,0,0,.231.259.419.419,0,0,0,.337.012,7.031,7.031,0,0,0,4.288-6.54A.452.452,0,0,0,11.923,5.293Z" transform="translate(2.637 2.413)" fill="#5d5d5d" />
                        </svg>
                        <div className="modal-name-icon">Operating Unit</div>
                    </div>
                </div>
                <div className="input-section">
                    <div className="input-holder required_value_not_input">
                        <div className="select_area_commision_type">
                            {ticket_operating_units.map((a) => (
                                <div
                                    className={a.id === data?.ticket_operating_unit_id ? "select_item_commision_type_selected" : "select_item_commision_type"}
                                    onClick={() => {
                                        dispatch({
                                            type: "NEW-TRF",
                                            payload: {
                                                ...data,
                                                ticket_operating_unit_id: a.id,
                                                ticket_commission_type_id: null
                                            },
                                        })
                                    }}
                                >
                                    <div className={a.id === data?.ticket_operating_unit_id ? "select_item_commision_type_text_selected" : "select_item_commision_type_text"}>
                                        {a.name}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {ticket_operating_units.filter(one => one.id === data?.ticket_operating_unit_id)[0]?.ticket_commission_types?.length ? <><div className="modal-icon-section" style={{ marginTop: "15px" }}>
                    <div className="modal-icon-name">
                        <svg id="Group_10757" data-name="Group 10757" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                            <path id="Path_19179" data-name="Path 19179" d="M5.753,0a.439.439,0,0,0-.441.438V6.6a.439.439,0,0,0,.441.438h6.169a.439.439,0,0,0,.441-.438A6.625,6.625,0,0,0,5.753,0Z" transform="translate(2.637)" fill="#5d5d5d" />
                            <g id="Group_7908" data-name="Group 7908" transform="translate(0 1.191)">
                                <g id="Group_7907" data-name="Group 7907">
                                    <path id="Path_19180" data-name="Path 19180" d="M9.4,13.97,7.223,8V1.651a.453.453,0,0,0-.448-.459A6.906,6.906,0,0,0,6.775,15a6.533,6.533,0,0,0,2.358-.438A.463.463,0,0,0,9.4,13.97Z" transform="translate(0 -1.191)" fill="#5d5d5d" />
                                </g>
                            </g>
                            <path id="Path_19181" data-name="Path 19181" d="M11.923,5.293H5.753a.436.436,0,0,0-.362.2.484.484,0,0,0-.05.427l2.166,6.106a.455.455,0,0,0,.231.259.419.419,0,0,0,.337.012,7.031,7.031,0,0,0,4.288-6.54A.452.452,0,0,0,11.923,5.293Z" transform="translate(2.637 2.413)" fill="#5d5d5d" />
                        </svg>
                        <div className="modal-name-icon">Commission Type</div>
                    </div>
                </div>
                    <div className="input-section">
                        <div className="input-holder comm-type">
                            <div className="select_area_commision_type">
                                {ticket_operating_units.filter(one => one.id === data?.ticket_operating_unit_id)[0]?.ticket_commission_types?.map((a) => (
                                    <div
                                        className={a.id === data?.ticket_commission_type_id ? "select_item_commision_type_selected" : "select_item_commision_type"}
                                        onClick={() => {
                                            dispatch({
                                                type: "NEW-TRF",
                                                payload: {
                                                    ...data,
                                                    ticket_commission_type_id: a.id
                                                },
                                            })
                                        }}
                                    >
                                        <div className={a.id === data?.ticket_commission_type_id ? "select_item_commision_type_text_selected" : "select_item_commision_type_text"}>
                                            {a.name}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div> </> : <></>}
                {/* end */}
                <div className="modal-icon-section" style={{ marginTop: "15px" }}>
                    <div className="modal-icon-name">
                        <Wallet />
                        <div className="modal-name-icon">Comment</div>
                    </div>
                </div>
                <div className="input-section">
                    <div className="input-holder">
                        <InputText
                            setFocuse={(a) => {

                            }}
                            placeholder="Comment"
                            value={data?.comment ? data?.comment : ""}
                            setValue={(e) => {
                                dispatch({
                                    type: "NEW-TRF",
                                    payload: {
                                        ...data,
                                        comment: e.target.value
                                    },
                                });
                            }}
                        />
                    </div>
                </div>
                <div className="modal-icon-section" style={{ marginTop: "15px" }}>
                    <div className="modal-icon-name">
                        <svg id="_10x10_flag_photo" data-name="10x10 flag photo" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
                            <g id="Mask_Group_274" data-name="Mask Group 274">
                                <g id="attach" transform="translate(0 0.268)">
                                    <g id="Group_9988" data-name="Group 9988">
                                        <path id="Path_21273" data-name="Path 21273" d="M13.6,1.7a4.738,4.738,0,0,0-6.781,0L1,7.619a3.541,3.541,0,0,0,0,4.935,3.384,3.384,0,0,0,4.844,0l5.328-5.429a2.124,2.124,0,0,0,0-2.961,2.031,2.031,0,0,0-2.906,0L4.783,7.714a.707.707,0,0,0,0,.987.676.676,0,0,0,.969,0l3.484-3.55a.677.677,0,0,1,.969,0,.708.708,0,0,1,0,.987L4.877,11.567a2.031,2.031,0,0,1-2.906,0,2.124,2.124,0,0,1,0-2.961L7.783,2.683a3.384,3.384,0,0,1,4.844,0,3.536,3.536,0,0,1,0,4.935L6.814,13.541a.707.707,0,0,0,0,.987.676.676,0,0,0,.969,0L13.6,8.606a4.951,4.951,0,0,0,0-6.909Z" transform="translate(0 -0.268)" fill="#8e8e8e" />
                                    </g>
                                </g>
                            </g>
                        </svg>

                        <div className="modal-name-icon">Attached Files</div>
                    </div>
                </div>
                <div className="input-section">
                    <div className="drop_zone_files_ticket">
                        <StyledDropzone />
                        {thumbs}
                        {loadingUpload && <WaitingIcon size={10} />}
                    </div>
                </div>
            </div>
            <div className="modal-footer" style={{ marginTop: "5px" }}>
                <div
                    onClick={() => sendRequest()}
                    className={"modal-button-transfer" + ` ${!valid && "disable"} ${create_load && "disable"}`}
                >
                    {create_load ? "sending..." : "Send Request"}
                </div>
            </div>
        </div >
    );
}

export default TransferModal