import React from 'react'

function Communication(props) {
  return (
    <>
    <svg className={props.active&&"acv"} id="_15x15_photo_back" data-name="15x15 photo back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
  <g id="Mask_Group_153" data-name="Mask Group 153">
    <g id="radar-2" transform="translate(-1.061 -1.066)">
      <path id="Path_19024" data-name="Path 19024" d="M9.23,16.06a8.11,8.11,0,0,0,3.49-.788.269.269,0,0,0-.085-.522,7.718,7.718,0,0,1-8.8-1.456,7.624,7.624,0,0,1-1.472-8.7,7.441,7.441,0,0,1,.488-.852c-.041,1.822,1.322,4.456,3.7,6.836,2.345,2.345,4.937,3.705,6.756,3.7,3.431-.177,2.617-4,.143-7.256a.268.268,0,1,0-.427.324c5.2,7.253-.434,8.483-6.094,2.849C1.334,4.585,2.495-1.115,9.783,4.1a.268.268,0,1,0,.324-.426C7.324,1.564,4.651.947,3.458,2.14A8.385,8.385,0,0,0,1.881,4.363,8.181,8.181,0,0,0,9.23,16.06Z" fill="#8e8e8e"/>
      <path id="Path_19025" data-name="Path 19025" d="M8.209,8.544a.268.268,0,1,0,.379.379L10.9,6.614a1.436,1.436,0,1,0-.378-.378ZM11.7,4.527a.9.9,0,1,1-.9.9.9.9,0,0,1,.9-.9Z" fill="#8e8e8e"/>
      <path id="Path_19026" data-name="Path 19026" d="M11.7,1.071a.268.268,0,1,0,0,.535,3.841,3.841,0,0,1,3.823,3.822.268.268,0,1,0,.535,0A4.378,4.378,0,0,0,11.7,1.071Z" fill="#8e8e8e"/>
      <path id="Path_19027" data-name="Path 19027" d="M13.373,3.758a2.346,2.346,0,0,1,.692,1.67.268.268,0,1,0,.535,0,2.911,2.911,0,0,0-2.9-2.9.268.268,0,1,0,0,.535,2.348,2.348,0,0,1,1.67.692Z" fill="#8e8e8e"/>
    </g>
  </g>
</svg>

    </>
  )
}

export default Communication