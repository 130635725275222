import React, { useEffect, useState } from 'react'
import Time from "../svg/time"
import Person from '../svg/person'
import Tether from '../svg/tether'
import WaitingIcon from '../svg/usdt_right'
import Location from '../svg/location'
import HashTrack from '../hash_track'
// import WhatsTen from './svg/whats_ten.'
// import ID2 from './svg/id2'
import Rf from '../svg/rf'
import Docum from '../svg/document'
// import SmallCheck from './svg/smallCheck'
import HashLastTrack from '../hash_last'
// import BigCheck from './svg/bigCheck'
// import BigFail from './svg/bigFail'
// import SmallFail from './svg/smallFail'
import Withdrawal from '../svg/withdrowal'
import Deposit from '../svg/deposit'
import PackageCart from '../svg/package_cart'
import Insructions from '../insructions'
import ID2 from '../svg/id2'
import { toast } from 'react-toastify'
import CopyHash from '../svg/copyHash'

export default function HashTicket(props) {
    const ticket = props.ticket
    const [open, setOpen] = useState(false)
    const [openDetail, setOpenDetail] = useState(false)
    const [openOrders, setOpenOrders] = useState(false)
    const getTime = (t) => {
        let diff = new Date().getTime() - new Date(t).getTime()
        if (diff / 1000 < 60) {
            return (`${(diff / 1000).toFixed(0)} s`)
        } else if (diff / 1000 < 3600) {
            return (`${(diff / 60000).toFixed(0)} m`)
        } else if (diff / 1000 < (3600 * 24)) {
            return (`${(diff / 3600000).toFixed(0)} h`)
        } else if (diff / 1000 > (3600 * 24)) {
            return (`${(diff / (3600000 * 24)).toFixed(0)} d`)
        }
    }
    const [update, setUpdate] = useState(1)
    const [openInstructions, setOpenInstructions] = useState(false)

    useEffect(() => {
        const interval = setInterval(() => setUpdate(update + 1), 1000);
        return () => clearInterval(interval);
    }, [update])

    const getLastTime = ({
        approved_at,
        received_at,
        applied_at
    }) => {
        if (applied_at) {
            return getTime(applied_at)
        } else if (approved_at) {
            return getTime(approved_at)
        } else {
            return getTime(received_at)
        }
    }
    const [height, setHeight] = useState(82)
    useEffect(() => {
        let heightVar = 82
        if ((open === true) && (openOrders === true)) {
            let height1 = document?.getElementById(`${ticket.id}-ticket-details`)?.clientHeight || 0
            let height2 = document?.getElementById(`${ticket.id}-ticket-comment`)?.clientHeight || 10
            let height3 = document?.getElementById(`${ticket.id}-ticket-status`)?.clientHeight || 0
            let height4 = document?.getElementById(`${ticket.id}-ticket-chat`)?.clientHeight || 0
            setHeight(heightVar + height3 + height1 + height2 + height4 - 32)
        } else if (open === true) {
            let height1 = document?.getElementById(`${ticket.id}-ticket-status`)?.clientHeight || 0
            setHeight(heightVar + height1 - 14)
        } else {
            setHeight(heightVar)
        }
    }, [open, openOrders, openDetail, ticket])
    return (<div className={`${open ? "broker_bulk_element_with_status_selected" : "broker_bulk_element_with_status"}`}
        onMouseEnter={() => {
            setOpen(true)
        }}
        onMouseLeave={() => {
            if (open && !openOrders) {
                setOpenDetail(false)
                setOpen(false)
                setOpenInstructions(false)
            }
        }}
        onClick={() => {
            setOpen(true)
        }}
    // style={{ height: `${height}px` }}
    >
        {(openInstructions && ticket?.ticket_processing_flow) ? <><Insructions ticket_processing_flow={ticket.ticket_processing_flow} setOpenInstructions={(value) => setOpenInstructions(value)} /></> : <><div
            className={`broker_bulk_element_usdt`}
            style={{ backgroundColor: props.ticketColor }}>
            <div className={`broker_bulk_element_name ${openOrders ? "withmargin" : ""}`}
                onClick={() => {
                    setOpenOrders(!openOrders)
                }}>
                <div className="broker_bulk_element_name_icon">
                    <div className="broker_bulk_icon" onMouseEnter={() => setOpenInstructions(true)}>
                        {ticket?.type === "deposit" ? <Deposit color={props.icons_color} /> : <Withdrawal color={props.icons_color} />}
                    </div>
                    <div className="broker_bulk_name_tether">
                        {(ticket?.request_number || ticket?.reference) ? (ticket?.request_number || ticket?.reference) : "Waiting"}
                    </div>
                </div>
                <div className="broker_bulk_element_right_icon">
                    <div className="broker_bulk_element_time">
                        <div className="broker_bulk_element_time">
                            <div className="broker_bulk_element_time_unit" style={{ textTransform: "lowercase" }}> {getTime(ticket?.rejected_at ? ticket?.rejected_at : ticket?.received_at)} </div>
                        </div>
                    </div>
                    <Time color="#000000" />
                    <PackageCart color1={props.icons_color} color2={props.icons_color} />
                </div>
            </div>
            <div className="broker_bulk_element_data"
                onClick={() => {
                    setOpenOrders(!openOrders)
                }}>
                <div className="broker_bulk_element_country_data">
                    <div className="broker_bulk_element_country_icon_odoo">
                        <Tether />
                    </div>
                    <div className="broker_bulk_element_country_name_odoo">
                        {ticket?.name?.slice(0, 14)}{ticket?.name?.length > 15 ? "..." : ""}, {ticket?.company}
                    </div>
                </div>
            </div>
            {open && openOrders ? <><div id={`${ticket.id}-ticket-details`} className='broker_bulk_orders_area'>
                {<div className='broker_bulk_orders_area_element'>
                    <div className='broker_bulk_orders_area_element_icon'><Person /></div>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginRight: "10px",
                        width: "-webkit-fill-available"
                    }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginRight: "10px"
                        }}>
                            <div className='broker_bulk_orders_area_element_text' style={{
                                textDecoration: "auto",
                                color: "#404040"
                            }}>
                                {(ticket?.name) ? (ticket?.name) : "-"}  {ticket.ticket_logs?.filter(one => parseInt(one.event) === 2).length > 0 && "*"}
                            </div>
                        </div>
                    </div>
                </div>}
                {<div className='broker_bulk_orders_area_element'>
                    <div className='broker_bulk_orders_area_element_icon'><ID2 /></div>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginRight: "10px",
                        width: "-webkit-fill-available"
                    }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginRight: "10px"
                        }}>
                            <div className='broker_bulk_orders_area_element_text' style={{
                                textDecoration: "auto",
                                color: "#404040"
                            }}>
                                {ticket?.account_number} {ticket.ticket_logs?.filter(one => parseInt(one.event) === 4).length > 0 && "*"}
                            </div>
                        </div>
                    </div>
                </div>}
                <div className='broker_bulk_orders_area_element'>
                    <div className='broker_bulk_orders_area_element_icon'><Location /></div>
                    <div className='broker_bulk_orders_area_element_text'>{ticket?.company}</div>
                </div>
                {<div className='broker_bulk_orders_area_element'>
                    <div className='broker_bulk_orders_area_element_icon'><Rf /></div>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginRight: "10px",
                        width: "-webkit-fill-available"
                    }}>
                        <div className='broker_bulk_orders_area_element_text' style={{ color: "#404040" }}>
                            {ticket?.amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} {ticket?.currency}
                        </div>
                    </div>
                </div>}
            </div></> : <></>}
        </div>
            {open && openOrders ? <>
                {ticket?.hash && <div id={`${ticket.id}-ticket-comment`} className='broker_bulk_orders_area_comment' style={{ backgroundColor: "#acacac" }}>
                    <div className='broker_bulk_orders_area_comment_inner'>
                        <div style={{ width: "100%", display: "flex", overflow: "hidden" }}>
                            <div style={{ width: "100%" }}>  Hash: {ticket?.hash?.slice(0, 19)} ... </div>
                            <div
                                onClick={() => {
                                    if (ticket?.hash && ticket?.hash?.length > 0) {
                                        let elem = document.querySelector("#hash-copy");
                                        elem.value = ticket?.hash
                                        elem.select()
                                        document.execCommand('Copy');
                                        toast.success("Copied!")
                                    }
                                }}
                                style={{ width: "15px", height: "15px" }}><CopyHash /></div>
                        </div>
                    </div>
                </div>}
            </> : <></>}</>}
        {
            open ? (!openDetail ? <div id={`${ticket.id}-ticket-status`} className={`${openOrders ? "broker_status_area_element_with_order" : "broker_status_area_element"}`}
                style={{
                    backgroundColor: "#EFEFEF"
                }}
                onClick={() => setOpenDetail(true)}>
                <div className='broker_status_area_element_child'>
                    <div className="broker_status_area_element_name">
                        <div className="broker_status_area_element_name_icon">

                        </div>
                        <div className="broker_status_area_element_name_name">
                            <WaitingIcon size={10} />
                            <HashLastTrack
                                status={ticket?.ticket_status_id}
                            />
                        </div>
                    </div>
                    <div className="broker_status_area_element_time">
                        <div className="broker_status_area_element_time_time">
                            {getLastTime({
                                approved_at: ticket?.approved_at,
                                received_at: ticket?.received_at,
                                applied_at: ticket?.applied_at
                            })}
                        </div>
                        <div className="broker_status_area_element_time_icon">
                            <Time color="#5D5D5D" />
                        </div>
                    </div>
                </div>
            </div> : <div id={`${ticket.id}-ticket-status`} className={`${openOrders ? "broker_status_area_element_with_order" : "broker_status_area_element_full_options"}`}
                style={{
                    backgroundColor: "#EFEFEF"
                }}
                onClick={() => setOpenDetail(false)}>
                <HashTrack
                    created_at={ticket?.created_at}
                    rejected_at={ticket?.rejected_at}
                    rejected_reason={ticket?.rejected_reason}
                    approved_at={ticket?.approved_at}
                    approved_by_user_id={ticket?.approved_by_user_id}
                    approved_by_user_full_name={ticket?.approved_by_user_full_name}
                    approved_by_contact_id={ticket?.approved_by_contact_id}
                    approved_by_contact_name={ticket?.approved_by_contact_name}
                    approved_by_contact_profile_photo={ticket?.approved_by_contact_profile_photo}
                    received_at={ticket?.received_at}
                    received_by_user_id={ticket?.received_by_user_id}
                    received_by_user_full_name={ticket?.received_by_user_full_name}
                    applied_at={ticket?.applied_at}
                    applied_by_contact_id={ticket?.applied_by_contact_id}
                    applied_by_contact_name={ticket?.applied_by_contact_name}
                    applied_by_contact_profile_photo={ticket?.applied_by_contact_profile_photo}
                    openOrders={openOrders}
                    setOpenDetail={setOpenDetail}
                    ticket_status_id={props.ticket_status_id}
                    ticket_logs={ticket.ticket_logs}
                    comment={ticket?.comment}
                />
            </div>) : <></>
        }
    </div >
    )
}