export default function Edit() {
  return (<svg id="_15x15_photo_back" data-name="15x15 photo back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
    <g id="Mask_Group_308" data-name="Mask Group 308">
      <g id="edit-5" transform="translate(0 0.029)">
        <path id="Path_21336" data-name="Path 21336" d="M14.57,9.08a.393.393,0,0,0-.393.393v3.491A1.181,1.181,0,0,1,13,14.144H1.966A1.181,1.181,0,0,1,.786,12.964V2.719A1.181,1.181,0,0,1,1.966,1.54H5.456a.393.393,0,0,0,0-.786H1.966A1.968,1.968,0,0,0,0,2.719V12.964A1.968,1.968,0,0,0,1.966,14.93H13a1.968,1.968,0,0,0,1.966-1.966V9.474A.393.393,0,0,0,14.57,9.08Zm0,0" fill="#5d5d5d" />
        <path id="Path_21337" data-name="Path 21337" d="M6.148,7.012l5.74-5.741,1.851,1.851L8,8.863Zm0,0" fill="#5d5d5d" />
        <path id="Path_21338" data-name="Path 21338" d="M5.211,9.8l2.046-.567L5.778,7.754Zm0,0" fill="#5d5d5d" />
        <path id="Path_21339" data-name="Path 21339" d="M14.251.3a.984.984,0,0,0-1.39,0l-.417.417L14.3,2.566l.417-.417a.984.984,0,0,0,0-1.39Zm0,0" fill="#5d5d5d" />
      </g>
    </g>
  </svg>)
}

