import React from 'react'

function FillIcon({name,size}) {
  return (
  <>
    {size?
    <>
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="19" viewBox="0 0 15 19">
  <g id="Group_7627" data-name="Group 7627" transform="translate(-209 -111)">
    <g id="Group_7626" data-name="Group 7626" transform="translate(0 -0.242)">
      <g id="_15x15_photo_back" data-name="15x15 photo back" transform="translate(209 113.242)">
        <g id="Rectangle_4561" data-name="Rectangle 4561" fill="none" stroke="#707070" strokeWidth="0.5">
          <rect id="K" width="15" height="15" rx="2" stroke="none"/>
          <rect id="K" x="0.25" y="0.25" width="14.5" height="14.5" rx="1.75" fill="none"/>
        </g>
      </g>
      <text id="K" transform="translate(209 111.242)" fill="#5d5d5d" font-size="12" font-family="SegoeUI, Segoe UI" letter-spacing="0.009em"><tspan x="3.189" y="14">{name}</tspan></text>
    </g>
  </g>
</svg>

    </>
    :
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
    <rect id="Rectangle_4606" data-name="Rectangle 4606" width="50" height="50" rx="10" fill="#404040"/>
    <text textAnchor='middle' id="A" transform="translate(0 4)" fill="#FFFFFF" font-size="30" font-family="SegoeUI, Segoe UI" letter-spacing="0.025em"><tspan x="50%" y="32">{name}</tspan></text>
  </svg>}
  </>
  
  )
}

export default FillIcon