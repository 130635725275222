export default function Deposit() {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
        <g id="Group_11731" data-name="Group 11731" transform="translate(-201.646 -161.91)">
            <g id="Group_8029" data-name="Group 8029" transform="translate(22 -506)">
                <g id="Group_8027" data-name="Group 8027" transform="translate(188.645 669.91)">
                    <path id="Path_19268" data-name="Path 19268" d="M126.031,63.612c-2.292,0-4.129-1.066-2.713-.723a12.428,12.428,0,0,0,12.5-4.387,11.9,11.9,0,0,0-1.025-15.969,13.223,13.223,0,0,0-1.87-1.5.2.2,0,0,1-.051-.273.209.209,0,0,1,.277-.064,13.634,13.634,0,0,1,1.936,1.548,12.321,12.321,0,0,1,2.764,13.633,12.816,12.816,0,0,1-11.811,7.734Zm0,0" transform="translate(-122.822 -40.611)" fill="#f2fff7" />
                    <path id="Path_19277" data-name="Path 19277" d="M380.717,42.172a.213.213,0,0,1-.213-.213v-2.1a.211.211,0,0,1,.064-.152.226.226,0,0,1,.154-.061l2.348.054a.213.213,0,0,1,.208.218.217.217,0,0,1-.218.208l-2.13-.049v1.884A.213.213,0,0,1,380.717,42.172Zm0,0" transform="translate(-370.545 -39.646)" fill="#f2fff7" />
                </g>
            </g>
            <g id="Group_11734" data-name="Group 11734">
                <path id="Path_19273" data-name="Path 19273" d="M1.67,1.568H4.8c3.4,0,5.4-2.115,5.4-5.72s-2-5.712-5.4-5.712H1.67a.676.676,0,0,0-.713.737V.831A.676.676,0,0,0,1.67,1.568ZM2.383.284V-8.581H4.7C7.3-8.581,8.737-7,8.737-4.136,8.737-1.3,7.295.284,4.7.284Z" transform="translate(208.543 178.559)" fill="#404040" />
                <g id="Group_8028" data-name="Group 8028" transform="translate(201.646 161.91)">
                    <path id="Path_19268-2" data-name="Path 19268" d="M135.612,40.67c2.292,0,4.129,1.066,2.713.723a12.428,12.428,0,0,0-12.5,4.387,11.9,11.9,0,0,0,1.025,15.969,13.222,13.222,0,0,0,1.87,1.5.2.2,0,0,1,.051.273.209.209,0,0,1-.277.064,13.634,13.634,0,0,1-1.936-1.548A12.321,12.321,0,0,1,123.8,48.4a12.816,12.816,0,0,1,11.811-7.734Zm0,0" transform="translate(-122.822 -40.67)" fill="#5d5d5d" />
                    <path id="Path_19277-2" data-name="Path 19277" d="M383.065,39.646a.213.213,0,0,1,.213.213v2.1a.211.211,0,0,1-.064.152.226.226,0,0,1-.154.061l-2.348-.054a.213.213,0,0,1-.208-.218.217.217,0,0,1,.218-.208l2.13.049V39.857A.213.213,0,0,1,383.065,39.646Zm0,0" transform="translate(-377.237 -19.171)" fill="#5d5d5d" />
                </g>
            </g>
        </g>
    </svg>

    )
}