import React from 'react'

function Elec(props) {
  return (
    <>
    <svg className={props.active&&"acv"} id="_15x15_photo_back" data-name="15x15 photo back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
  <g id="Mask_Group_156" data-name="Mask Group 156">
    <g id="flash-2">
      <g id="Group_7726" data-name="Group 7726">
        <path id="Path_19036" data-name="Path 19036" d="M11.73,5.15A.24.24,0,0,0,11.505,5H8.555L11.48.375a.227.227,0,0,0,0-.25A.263.263,0,0,0,11.255,0h-4A.263.263,0,0,0,7.03.125l-3.75,7.5a.227.227,0,0,0,0,.25A.3.3,0,0,0,3.505,8H6.08l-2.8,6.675a.25.25,0,0,0,.1.3A.187.187,0,0,0,3.505,15a.284.284,0,0,0,.2-.075l8-9.5A.268.268,0,0,0,11.73,5.15ZM4.38,13.325l2.3-5.5A.243.243,0,0,0,6.655,7.6a.309.309,0,0,0-.2-.1H3.905l3.5-7h3.4L7.855,5.125a.227.227,0,0,0,0,.25A.263.263,0,0,0,8.08,5.5h2.9Z" fill="#404040"/>
      </g>
    </g>
  </g>
</svg>

    </>
  )
}

export default Elec