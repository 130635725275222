export default function Resend() {
    return (<svg id="_15x15_photo_back" data-name="15x15 photo back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
        <g id="Mask_Group_222" data-name="Mask Group 222">
            <g id="transfer-2">
                <path id="Path_19262" data-name="Path 19262" d="M14.531,9.375a.469.469,0,0,1-.469-.469,3.285,3.285,0,0,0-3.281-3.281H6.25V7.656a.469.469,0,0,1-.792.339L2.02,4.714a.468.468,0,0,1,0-.677L5.458.755a.469.469,0,0,1,.792.339V3.125h3.906A4.85,4.85,0,0,1,15,7.969v.938A.469.469,0,0,1,14.531,9.375Z" fill="#1f9e2c" />
                <path id="Path_19263" data-name="Path 19263" d="M8.75,13.906V11.875H4.844A4.85,4.85,0,0,1,0,7.031V6.094a.469.469,0,0,1,.938,0A3.285,3.285,0,0,0,4.219,9.375H8.75V7.344a.469.469,0,0,1,.792-.339l3.438,3.281a.468.468,0,0,1,0,.677L9.542,14.245A.469.469,0,0,1,8.75,13.906Z" fill="#1f9e2c" />
            </g>
        </g>
    </svg>
    )
}