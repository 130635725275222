import React, { useEffect, useState } from "react";
import PinInput from "react-pin-input";
// import Logo from "./Logo";
import lock from "../../assets/svg/lock.svg";
import "./index.css";
import Footer from "./Footer";
import Avatar from "./Avatar";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { _LoginWhatsapp } from "../../redux/whatsapp/actions";
import { logout } from "../../redux/auth/actions";
import { trans } from '../../Internationalization'
import BigLock2 from "./icons/biglock2";
import OptionsList from "./icons/optionsList";
import ClearLogin from "./icons/clearLogin";
import ChangeUser from "./icons/changeuser";
import ForgetPassWord from "./icons/forgetpassword";
function Signedup(props) {
    const dispatch = useDispatch();
    // const pass = useSelector((state) => state.auth.pass);
    const setPass = (a) => {
        dispatch({ type: "PASS", payload: a });
    };
    const [pin, setPin] = useState("");
    const [name, setName] = useState("");
    const [locks, setLock] = useState(true);
    const [attempt, setAttempt] = useState(3);
    const [counter, setCounter] = useState(0);
    const [initial, setInitial] = useState(true);
    useEffect(() => {
        if (attempt === 0) {
            setCounter(10);
        }
        if (attempt !== 3) {
            setTimeout(() => {
                setInitial(false);
                setPin("");
                setInitial(true);
                document.querySelector(".pincode-input-text").focus();
            }, 400);
        }
    }, [attempt]);
    useEffect(() => {
        if (counter > 0) setTimeout(() => setCounter(counter - 1), 1000);
        else {
            document.querySelector(".pincode-input-text").focus();
            document.querySelectorAll(".absolute-child").forEach((elem) => {
                elem.classList.remove("red-background");
            });
            setAttempt(3);
        }
    }, [counter]);
    useEffect(() => {
        let arr = document.querySelectorAll(".absolute-child");
        arr.forEach((elem) => {
            elem.addEventListener(
                "select",
                function (e) {
                    e.selecionStart = e.selecionEnd;
                },
                false
            );
        });
    }, []);
    const [signedIn, setSignedIn] = useState(false);
    useEffect(() => {
        if (
            localStorage.getItem("user") &&
            JSON.parse(localStorage.getItem("user")).id
        ) {
            setSignedIn(true);
        } else {
            setSignedIn(false);
        }
    }, []);
    const shake = (e) => {
        if (localStorage.getItem("user")) {
            dispatch({
                type: "LOGIN_SAGA",
                payload: {
                    username:
                        localStorage.getItem("user") &&
                        JSON.parse(localStorage.getItem("user"))?.username,
                    password: e,
                },
            });
            // let mn = JSON.parse(localStorage.getItem("user"))?.username
        }
        else {
            dispatch({
                type: "LOGIN_SAGA",
                payload: { username: name, password: e },
            });
            ;
        }
    };
    const user = useSelector((state) => state.auth.user);
    const error = useSelector((state) => state.auth.error);
    const loading = useSelector((state) => state.auth.loading);

    useEffect(() => {
        if (user) {
        }

        if (user && user?.id && !loading) {
            setTimeout(() => {
                setPass(true);
                localStorage.setItem("user", JSON.stringify(user));
                props.history.push("/");
            }, 1000);
            setLock(false);

            document.querySelectorAll(".absolute-child").forEach((elem) => {
                elem.classList.remove("red-background");
                elem.classList.add("green-background");
            });
        }
        if (error && !loading) {
            setLock(true);
            setAttempt(attempt - 1);
            document.querySelector(".pin-input").classList.add("shake-modals");
            document.querySelectorAll(".absolute-child").forEach((elem) => {
                elem.classList.add("red-background");
                elem.classList.remove("green-background");
            });
            setTimeout(() => {
                document.querySelector(".pin-input").classList.remove("shake-modals");
                document.querySelectorAll(".absolute-child").forEach((elem) => {
                    elem.classList.remove("red-background");
                });
            }, 400);
        }
    }, [user, loading]);
    const onChange = () => {
        setLock(true);
        document.querySelectorAll(".absolute-child").forEach((elem) => {
            elem.classList.remove("red-background");
            elem.classList.remove("green-background");
        });
    };
    const [options, setOptions] = useState(false);
    return (
        <div className="main full-page sign">
            <Footer />
            <div className="signup-main">
                {signedIn && <Avatar />}
                {/* <Logo/> */}
                <div className="signup-container">
                    {!localStorage.getItem("user") && (
                        <div className="relative-input">
                            <BigLock2 />
                            <input
                                onKeyDown={(e) => {
                                    if (e.code === "Enter")
                                        document.querySelector(".pincode-input-text").focus();
                                }}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="username"
                                placeholder="User Name"
                            />
                        </div>
                    )}
                    <div className="pin-input">
                        <div className="absolute-container">
                            <div className="absolute-child">
                                {pin.length > 0 && <img src={lock} />}
                            </div>
                            <div className="absolute-child">
                                {pin.length > 1 && <img src={lock} />}
                            </div>
                            <div className="absolute-child">
                                {pin.length > 2 && <img src={lock} />}
                            </div>
                            <div className="absolute-child">
                                {pin.length > 3 && <img src={lock} />}
                            </div>
                            <div className="absolute-child">
                                {pin.length > 4 && <img src={lock} />}
                            </div>
                            <div className="absolute-child">
                                {pin.length > 5 && <img src={lock} />}
                            </div>
                        </div>
                        {initial && (
                            <PinInput
                                length={6}
                                initialValue=""
                                onChange={(value, index) => {
                                    setPin(value);
                                    onChange();
                                }}
                                type="numeric"
                                disabled={counter > 0}
                                inputMode="number"
                                style={{ marginTop: 0 }}
                                onComplete={(value, index) => shake(value)}
                                inputStyle={{
                                    borderRadius: 15,
                                    backgroundColor: "#F7F7F7",
                                    margin: "initial",
                                    color: "transparent",
                                    border: "#ddddddc5 0.5px solid",
                                    width: 50,
                                    height: 50,
                                }}
                                // onComplete={(value, index) => setPin(value)}
                                autoSelect={true}
                                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}

                            // disabled={disablePin}
                            />
                        )}
                    </div>
                    <div className="text-container">
                        {attempt < 3 && (
                            <>
                                {attempt !== 0 ? (
                                    <div className="attempt">{trans("you_have")} {attempt} {trans("attempts")} </div>
                                ) : (
                                    <div className="counter">
                                        {trans("try_after")} {counter}
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
                {signedIn && (
                    <div className="signup-options">
                        <div
                            className={"mobile-options " + ` ${options && "active-button"}`}
                            onClick={() => setOptions(!options)}
                        >
                            <OptionsList />
                        </div>
                        <div
                            className="signup-option del-option"
                            onClick={() => {
                                setPass(false)
                                logout(() => { ; })
                                dispatch({ type: "log_out" })
                                localStorage.removeItem("user");
                                setTimeout(() => {
                                    window.location.reload()
                                }, 500)
                            }}
                        >
                            <ClearLogin />
                            <div className="option-text">
                                {trans("clear_login")}
                            </div>
                        </div>
                        <div className="signup-option change-option"
                            onClick={() => {
                                setPass(false)
                                logout(() => { ; })
                                dispatch({ type: "log_out" })
                                localStorage.removeItem("user");
                                setTimeout(() => {
                                    window.location.reload()
                                }, 500)
                            }}>
                            <ChangeUser />
                            <div className="option-text">{trans("change_user")}</div>
                        </div>
                        <div className="signup-option forget-option" onClick={() => {
                            window.location.replace("/auth/resetpassword")
                        }}>
                            <ForgetPassWord />
                            <div className="option-text">
                                {trans("forget_pass")}</div>
                        </div>
                        {/* <div className="signup-option forget-user-option">
              <svg
                className="bord"
                xmlns="http://www.w3.org/2000/svg"
                width="350"
                height="50"
                viewBox="0 0 350 50"
              >
                <g
                  id="Rectangle_4720"
                  data-name="Rectangle 4720"
                  fill="#f7f7f7"
                  stroke="#ddd"
                  strokeWidth="0.5"
                >
                  <rect width="350" height="50" rx="15" stroke="none" />
                  <rect
                    x="0.25"
                    y="0.25"
                    width="349.5"
                    height="49.5"
                    rx="14.75"
                    fill="none"
                  />
                </g>
              </svg>
              <svg
                id="_15x15_photo_back"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="15"
                height="15"
                viewBox="0 0 15 15"
              >
                <g
                  id="Group_7955"
                  data-name="Group 7955"
                  transform="translate(-735 -701)"
                >
                  <g
                    id="_15x15_photo_back"
                    data-name="15x15 photo back"
                    transform="translate(735 701)"
                  >
                    <g id="Mask_Group_212" data-name="Mask Group 212">
                      <g id="exchange">
                        <g id="Group_7948" data-name="Group 7948">
                          <g id="Group_7947" data-name="Group 7947">
                            <path
                              id="Path_19229"
                              data-name="Path 19229"
                              d="M9.167,13.9A6.615,6.615,0,0,1,1.313,9.836a6.542,6.542,0,0,1-.1-4.385A6.66,6.66,0,0,1,3,2.654L2.849,3.9l.877.106.332-2.754L1.339.934l-.1.877,1.219.144A7.548,7.548,0,0,0,.373,5.176,7.5,7.5,0,0,0,9.39,14.757Z"
                              fill="#8e8e8e"
                            />
                          </g>
                        </g>
                        <g id="Group_7950" data-name="Group 7950">
                          <g id="Group_7949" data-name="Group 7949">
                            <path
                              id="Path_19230"
                              data-name="Path 19230"
                              d="M14.545,10.062A7.5,7.5,0,0,0,5.184.37l.274.84a6.611,6.611,0,0,1,6.107,11.5l.164-1.359-.877-.106L10.519,14l2.719.322.1-.877-1.115-.132A7.517,7.517,0,0,0,14.545,10.062Z"
                              fill="#8e8e8e"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <path
                    id="Path_19237"
                    data-name="Path 19237"
                    d="M7.328,4.26a1.693,1.693,0,0,0-1.69,1.69v.4H5.118a.4.4,0,0,0-.4.4V9.81a.4.4,0,0,0,.4.4H9.539a.4.4,0,0,0,.4-.4V6.745a.4.4,0,0,0-.4-.4H9.018v-.4a1.693,1.693,0,0,0-1.69-1.69Zm.895,2.087H6.434v-.4a.895.895,0,1,1,1.789,0Z"
                    transform="translate(735.172 701.24)"
                    fill="#8e8e8e"
                  />
                </g>
              </svg>

              <div className="option-text">{trans("change_pass")}</div>
            </div> */}
                    </div>
                )}
            </div>
        </div>
    );
}

export default withRouter(Signedup);
