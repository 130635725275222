export default function WaitingIcon({ size }) {
    if (size === 10) {
        return (<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="10" height="10" viewBox="0 0 10 10">
            <g id="Mask_Group_386" data-name="Mask Group 386" transform="translate(0.203)">
                <g id="status" transform="translate(-0.068 0.022)">
                    <g id="ngicon">
                        <g id="Group_11657" data-name="Group 11657">
                            <g id="Group_11654" data-name="Group 11654" transform="translate(0 4.316)">
                                <path id="Path_21633" data-name="Path 21633" d="M222.246,94.343a3.789,3.789,0,0,1-4.572-3.7.574.574,0,0,0-1.147,0,4.936,4.936,0,0,0,5.957,4.825.574.574,0,0,0-.237-1.122Z" transform="translate(-216.526 -90.066)" fill="#007cff" fill-rule="evenodd" />
                            </g>
                            <g id="Group_11655" data-name="Group 11655" transform="translate(4.972)">
                                <path id="Path_21634" data-name="Path 21634" d="M222.06,86.833a3.785,3.785,0,0,1,1.479,6.954.573.573,0,0,0,.611.971,4.932,4.932,0,0,0-1.93-9.06.574.574,0,1,0-.16,1.136Z" transform="translate(-221.567 -85.691)" fill="#00c408" fill-rule="evenodd" />
                            </g>
                            <g id="Group_11656" data-name="Group 11656" transform="translate(0.314 0.202)">
                                <path id="Path_21635" data-name="Path 21635" d="M217.95,89.171a3.8,3.8,0,0,1,2.26-2.161.574.574,0,0,0-.38-1.082,4.952,4.952,0,0,0-2.945,2.815.574.574,0,1,0,1.064.429Z" transform="translate(-216.845 -85.896)" fill="#404040" fill-rule="evenodd" />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>)
    } else {
        return (<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
            <g id="Mask_Group_393" data-name="Mask Group 393">
                <g id="status" transform="translate(-0.007 0.031)">
                    <g id="ngicon">
                        <g id="Group_11740" data-name="Group 11740">
                            <g id="Group_11737" data-name="Group 11737" transform="translate(0 6.265)">
                                <path id="Path_21831" data-name="Path 21831" d="M224.828,96.275a5.5,5.5,0,0,1-6.637-5.376.832.832,0,1,0-1.665,0,7.165,7.165,0,0,0,8.646,7,.833.833,0,0,0-.345-1.629Z" transform="translate(-216.526 -90.066)" fill="#007cff" fill-rule="evenodd" />
                            </g>
                            <g id="Group_11738" data-name="Group 11738" transform="translate(7.217)">
                                <path id="Path_21832" data-name="Path 21832" d="M222.283,87.348a5.5,5.5,0,0,1,2.147,10.094.832.832,0,0,0,.887,1.409,7.16,7.16,0,0,0-2.8-13.152.833.833,0,0,0-.233,1.649Z" transform="translate(-221.567 -85.691)" fill="#00c408" fill-rule="evenodd" />
                            </g>
                            <g id="Group_11739" data-name="Group 11739" transform="translate(0.455 0.293)">
                                <path id="Path_21833" data-name="Path 21833" d="M218.449,90.651a5.518,5.518,0,0,1,3.281-3.137.833.833,0,0,0-.551-1.571,7.188,7.188,0,0,0-4.274,4.086.832.832,0,1,0,1.544.622Z" transform="translate(-216.845 -85.896)" fill="#404040" fill-rule="evenodd" />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
        )
    }

}