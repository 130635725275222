import React, { useEffect, memo } from "react";
// import Spinner from "../../../../Spinner";
import { useDispatch, useSelector } from "react-redux";
import Message from "./Message";
import { toast } from "react-toastify";
// import SpinnerLoading from "./spinnerLoading";

var vCard = require('vcard-parser');

const Convo = ({ img, setImg, setVid, vid, getAudioMessages, lastMsgRef, setReplyMessage, submitNewMessage }) => {
  const dispatch = useDispatch()
  const qoutedMessage = useSelector((state) => state.whatsapp.qoutedMessage)
  const activeChat = useSelector(state => state.whatsapp?.activeChat);
  const active = useSelector((state) => state.whatsapp.activeChat)
  const is_fetch = useSelector((state) => state.whatsapp.is_fetch)
  const allMessages = useSelector(state => state?.whatsapp?.activeChat?.messages) || [];
  // const datetMessages = DatesMessages(allMessages)
  // const [loading, setLoading] = useState(false)
  const GetMessage = (msgId, quoteId) => {
    dispatch({ type: "SET-REPLY-MESSAGE", payload: msgId })
    if (allMessages.filter((f) => f.id === quoteId).length > 0) {
      var numb = quoteId
      let el = document.querySelector(`#s${numb}`)
      if (el) {
        el.scrollIntoView({ block: "center" })
        setTimeout(() => {
          el.classList.add("backdrop_msg")
        }, 500)
        setTimeout(() => {
          el.classList.remove("backdrop_msg")
        }, 1400)
      }
    }
    else {
      dispatch({ type: "GET_MES", cid: activeChat.id, qid: allMessages[0].id, sid: quoteId })
    }
  }
  // useEffect(() => {
  //   console.log(`messages added
  //   \n ${new Date().toLocaleDateString()} - ${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`)
  // }, [allMessages.length])
  useEffect(() => {
    setTimeout(() => {
      if (qoutedMessage) {
        var numb = qoutedMessage
        let el = document.querySelector(`#s${numb}`)
        if (el) {
          el.scrollIntoView({ block: "center" })
          setTimeout(() => {
            el.classList.add("backdrop_msg")
          }, 300)
          setTimeout(() => {
            el.classList.remove("backdrop_msg")
          }, 800)
          dispatch({ type: "clearQuotedMessage" })
        }
      }
    }, 300)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qoutedMessage])

  const getVcardName = (card) => {
    var myVCard = vCard.parse(card);
    return (myVCard?.fn[0].value)
  }
  const getVcardWaId = (card) => {
    var myVCard = vCard.parse(card);
    if (myVCard?.tel[0]?.meta?.waid[0]) {
      return (myVCard?.tel[0].meta.waid[0])
    }
    else {
      return null
    }
  }
  const CopyText = (a, text) => {
    if (a.type === "vcard") {

      let elem = document.querySelector("#text-copy");

      elem.value = `name: ${getVcardName(text)}\n phone: ${getVcardWaId(text)}`
      elem.select()
      document.execCommand('Copy');
      toast.success("Copied!")
    }
    else if (a.type === "ticket") {
      let elem = document.querySelector("#text-copy");
      elem.value = `Ticket Message ${a?.ticket?.request_number}`
      elem.select()
      document.execCommand('Copy');
      toast.success("Copied!")
    }
    else
      if (text && text.length > 0) {
        let elem = document.querySelector("#text-copy");
        elem.value = text
        elem.select()
        document.execCommand('Copy');
        toast.success("Copied!")
      }
  }
  let now = new Date();
  let nowString = `${now.getFullYear()}-${(now.getMonth() + 1) > 9 ? (now.getMonth() + 1).toString() : ("0" + (now.getMonth() + 1).toString())}-${(now.getDate()) > 9 ? now.getDate() : "0" + parseInt(now.getDate()).toString()}`
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  const showDate = (d) => {
    let messagedate = new Date(d)
    let messageString = `${messagedate.getFullYear()}-${(messagedate.getMonth() + 1) > 9 ? (messagedate.getMonth() + 1).toString() : ("0" + (messagedate.getMonth() + 1).toString())}-${(messagedate.getDate()) > 9 ? messagedate.getDate() : "0" + parseInt(messagedate.getDate()).toString()}`

    let day = days[messagedate.getDay()]
    if (messageString === nowString)
      return ("Today")
    else if ((new Date(nowString) - new Date(messageString)) === 86400000) {
      return ("Yesterday")
    }
    else if ((new Date(nowString) - new Date(messageString)) < (86400000 * 6))
      return (day)
    else
      return messageString
  }
  const isLastOfDay = (first, second) => {
    let messagedate1 = new Date(first)
    let messageString1 = `${messagedate1.getFullYear()}-${(messagedate1.getMonth() + 1) > 9 ? (messagedate1.getMonth() + 1).toString() : ("0" + (messagedate1.getMonth() + 1).toString())}-${(messagedate1.getDate()) > 9 ? messagedate1.getDate() : "0" + parseInt(messagedate1.getDate()).toString()}`
    let messagedate2 = new Date(second)
    let messageString2 = `${messagedate2.getFullYear()}-${(messagedate2.getMonth() + 1) > 9 ? (messagedate2.getMonth() + 1).toString() : ("0" + (messagedate2.getMonth() + 1).toString())}-${(messagedate2.getDate()) > 9 ? messagedate2.getDate() : "0" + parseInt(messagedate2.getDate()).toString()}`
    if (messageString1 === messageString2) {
      return false
    } else {
      return true
    }
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      let element = document.querySelector("#XD-1");
      if (element) {
        element.scrollTop = -0;
      }
    }, 10);
    const timeout2 = setTimeout(() => {
      let element = document.querySelector("#XD-1");
      if (element) {
        element.scrollTop = -0;
      }
    }, 30);
    const timeout3 = setTimeout(() => {
      let element = document.querySelector("#XD-1");
      if (element) {
        element.scrollTop = -0;
      }
    }, 150);
    const timeout4 = setTimeout(() => {
      let element = document.querySelector("#XD-1");
      if (element) {
        element.scrollTop = -0;
      }
    }, 250);
    return () => {
      clearTimeout(timeout)
      clearTimeout(timeout2)
      clearTimeout(timeout3)
      clearTimeout(timeout4)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="dates-element" style={{ position: "relative" }}>
      <div className={`${"group_widg"} chat__msg-group`}>
        {allMessages?.map((a, i) => {
          if (a.whatsapp_chat_id === activeChat?.id || a.cid === activeChat?.id || a.chat_id === activeChat?.id) {
            return (<>
              {i === 0 ? <><div className={`chat__date-wrapper normal-date-wrapper`}>
                <span className="chat__date normal-date"> {showDate(a.sent_at)}</span>
              </div></> : (isLastOfDay(a?.sent_at, allMessages[i - 1]?.sent_at) ? <><div className={`chat__date-wrapper normal-date-wrapper`}>
                <span className="chat__date normal-date"> {showDate(a.sent_at)}</span>
              </div></> : <></>)}
              <Message
                copy={() => CopyText(a, a.body)}
                getAudioMessages={() => getAudioMessages()}
                key={i}
                GetMessage={(m, n) => GetMessage(m, n)}
                setReplyMessage={setReplyMessage}
                active={active}
                img={img}
                vid={vid}
                setVid={(e) => setVid(e)}
                setImg={(e) => setImg(e)}
                activeChat={activeChat}
                a={a}
                i={i}
                submitNewMessage={submitNewMessage}
              />
            </>)
          }
        }
        )}
      </div>
    </div>
  );
};

export default memo(Convo);