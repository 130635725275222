import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import avat from "../../../assets/svg/avat.png";
import point from "../../../assets/svg/point.svg";
import { useStopwatch } from "react-timer-hook";
import { upload } from "../../../uploadServiceChat";
import VideoCall from "../../../Agora/VideoCall";
import VoiceCall from "../../../Agora/VoiceCall";
import Cam from "../WhatsApp/assets/images/cam";
import fil from "../WhatsApp/assets/images/output.png";
import Voc from "../WhatsApp/assets/images/voc";
import { FILE_SERVER, isNew } from "../../../constants";
import ChatHeader from "./ChatHeader";
import ChatConvo from "./ChatConvo";
import ChatSidebar from "../WhatsApp/pages/Chat/components/ChatSidebar";
import ForwardListChat from "./ForwardListChat";
import Icon from "../WhatsApp/components/Icon";
import ChatInput from "../WhatsApp/pages/Chat/components/ChatInput";
import EmojiTray from "../WhatsApp/pages/Chat/components/EmojiTray";
import Recorder from "../WhatsApp/components/recorder";

function ChattingScreen(props) {
  const activeChat = useSelector((state) => state.chat.activeChat);
  const lastMsgRef = useRef()
  const [showEmojis, setShowEmojis] = useState(false)
  const [showAttach, setShowAttach] = useState(false)
  const reply = (s) => {
    document.querySelector(".chat__input")?.focus()
    replyMessage.current = s
  }
  var dateOfConversation = useRef(null);
  const replyMessage = useRef(null)
  const call = useSelector(state => state.chat.call)
  const fetch = useSelector((state) => state.chat.fetch);
  const forwarderMessage = useSelector((state) => state.whatsapp.forwarderMessage);
  const isReachTheFinalMes = useSelector(state => state.chat.isReachTheFinalMes)

  useEffect(() => {
    if (fetch && activeChat?.id && activeChat?.messages?.length > 10)

      dispatch({ type: "GET_CHAT_PAGE", channel: activeChat?.id, mid: activeChat?.messages[0]?.id })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChat?.id])

  const onScroll = (e) => {
    if (fetch && activeChat?.messages[0]?.id && !isReachTheFinalMes) {
      setTimeout(() => {
        dispatch({ type: "GET_CHAT_PAGE", channel: activeChat?.id, mid: activeChat?.messages[0]?.id })
      }, 100);
    }
  };
  const getNew = () => {
    let a = []
    chatData.forEach((c) => {
      if (isNew(c.messages) > 0)
        if (activeChat && c.id !== activeChat?.id)
          a.push(c)
    })
    return a
  }
  const refs = useSelector((state) => state.chat.refs)
  const channels = useSelector((state) => state.chat.pusher_channels)
  const sendStatues = (desc) => {
    let obj = { id: activeChat?.id, uid: JSON.parse(localStorage.getItem("user")).id, desc: desc }
    let a = channels.filter((cv) => cv.id === activeChat?.id)[0]
    if (a && a.channel) {

      a.channel.trigger("client-TypingEvent", obj)
    }


  }
  const { reset } =
    useStopwatch({ autoStart: true });
  const blobs = useRef();
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const sendAudio = async (e, i) => {
    if (MediaStreamRef.current) {
      MediaStreamRef.current?.getTracks()?.forEach((track) => {
        track.stop();
      })
    }
    dispatch({ type: "REFS" })
    if (e) {
      setRec(false)
      reset();
      var reader = new FileReader();
      reader.readAsDataURL(e);
      var file = new File([e], "wave-" + i);
      reader.onloadend = function () {
        var base64data = reader.result;
        dispatch({
          type: "SEND-MESSAGE",
          payload: {
            act: activeChat,
            message: {
              parent_message: replyMessage.current,
              parent_message_id: replyMessage.current?.id,
              receiver_user_id: activeChat?.channel_members.filter(
                (a) =>
                  parseInt(a.user_id) !==
                  parseInt(JSON.parse(localStorage.getItem("user")).id)
              )[0].user_id,
              receiver_role_id: activeChat?.channel_members.filter(
                (a) =>
                  parseInt(a.user_id) !==
                  parseInt(JSON.parse(localStorage.getItem("user")).id)
              )[0].role_id,
              sender_role_id: JSON.parse(localStorage.getItem("user")).role_id,
              sender_user_id: JSON.parse(localStorage.getItem("user")).id,
              message_type: { name: "VoiceMessage" },
              message_content: [{ file_path: base64data, caption: "" }],
              created_at: new Date(),
              type: "pending",
              mid: i,
              message_status: [{ is_watched: false, is_received: 0, user_id: (localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).id) }
                , {
                is_received: 0, is_watched: false, user_id: activeChat?.channel_members.filter(
                  (a) =>
                    parseInt(a.user_id) !==
                    parseInt(JSON.parse(localStorage.getItem("user")).id)
                )[0].user_id
              }],
              cid: activeChat?.id,
            },
          },
        });

      };

      let pat = await upload(file);
      if (activeChat?.channel_type?.slug === "team")
        dispatch({
          type: "SEND_MES",
          payload: {
            channel_id: activeChat?.id,
            whatsapp_data: { updated_at: new Date().toISOString(), type: "VoiceMessage" },
            sender_role_id: JSON.parse(localStorage.getItem("user")).role_id,
            content: [{ file_path: pat, caption: "" }],
            parent_message_id: replyMessage.current?.id,
            message_type: "VoiceMessage",
            mid: i,
            cid: activeChat?.id,
          },
        });
      else
        dispatch({
          type: "SEND_MES",
          payload: {
            receiver_user_id: activeChat?.channel_members.filter(
              (a) =>
                parseInt(a.user_id) !==
                parseInt(JSON.parse(localStorage.getItem("user")).id)
            )[0].user_id,
            receiver_role_id: activeChat?.channel_members.filter(
              (a) =>
                parseInt(a.user_id) !==
                parseInt(JSON.parse(localStorage.getItem("user")).id)
            )[0].role_id,
            sender_role_id: JSON.parse(localStorage.getItem("user")).role_id,
            content: [{ file_path: pat, caption: "" }],
            parent_message_id: replyMessage.current?.id,
            message_type: "VoiceMessage",
            mid: i,
            cid: activeChat?.id,
          },
        });
    }
    replyMessage.current = null;
  };
  const sendPhoto = (m, i) => {
    dispatch({ type: "REFS" })

    toBase64(m, i);
  };
  function send_mes(data, type) {
    dispatch({ type: "REFS" })
    if (type === "TextMessage") {
      let i = Math.random();
      if (activeChat?.channel_type?.slug === "team")
        dispatch({
          type: "SEND_MES",
          payload: {
            channel_id: activeChat?.id,
            whatsapp_data: { updated_at: new Date().toISOString(), type: "TextMessage" },

            sender_role_id: JSON.parse(localStorage.getItem("user")).role_id,
            content: data,
            parent_message_id: replyMessage.current?.id,
            message_type: "TextMessage",
            mid: i,
            cid: activeChat?.id,
          },
        });
      else
        dispatch({
          type: "SEND_MES",
          payload: {
            receiver_user_id: activeChat?.channel_members.filter(
              (a) =>
                parseInt(a.user_id) !==
                parseInt(JSON.parse(localStorage.getItem("user")).id)
            )[0].user_id,
            receiver_role_id: activeChat?.channel_members.filter(
              (a) =>
                parseInt(a.user_id) !==
                parseInt(JSON.parse(localStorage.getItem("user")).id)
            )[0].role_id,
            sender_role_id: JSON.parse(localStorage.getItem("user")).role_id,
            content: data,
            parent_message_id: replyMessage.current?.id,
            message_type: "TextMessage",
            mid: i,
            cid: activeChat?.id,
          },
        });
      dispatch({
        type: "SEND-MESSAGE",
        payload: {
          act: activeChat,
          message: {
            parent_message: replyMessage.current,
            parent_message_id: replyMessage.current?.id,
            receiver_user_id: activeChat?.channel_members.filter(
              (a) =>
                parseInt(a.user_id) !==
                parseInt(JSON.parse(localStorage.getItem("user")).id)
            )[0].user_id,
            receiver_role_id: activeChat?.channel_members.filter(
              (a) =>
                parseInt(a.user_id) !==
                parseInt(JSON.parse(localStorage.getItem("user")).id)
            )[0].role_id,
            sender_role_id: JSON.parse(localStorage.getItem("user")).role_id,
            sender_user_id: JSON.parse(localStorage.getItem("user")).id,
            message_type: { name: "TextMessage" },
            message_content: { content: data },
            created_at: new Date(),
            mid: i,
            message_status: [{ is_watched: false, is_received: 0, user_id: (localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).id) }
              , {
              is_received: 0, is_watched: false, user_id: activeChat?.channel_members.filter(
                (a) =>
                  parseInt(a.user_id) !==
                  parseInt(JSON.parse(localStorage.getItem("user")).id)
              )[0].user_id
            }],
            type: "pending",
            cid: activeChat?.id,
          },
        },
      });
      sendStatues(null)
      setMessage("")
    }
    if (type === "ImageMessage") {
      if (activeChat?.channel_type?.slug === "team")
        dispatch({
          type: "SEND_MES",
          payload: {
            channel_id: activeChat?.id,
            whatsapp_data: { updated_at: new Date().toISOString(), type: "ImageMessage" },

            sender_role_id: JSON.parse(localStorage.getItem("user")).role_id,
            content: [{ file_path: data, caption: "" }],
            parent_message_id: replyMessage.current?.id,
            message_type: "ImageMessage",
          },
        });
      else
        dispatch({
          type: "SEND_MES",
          payload: {
            receiver_user_id: activeChat?.channel_members.filter(
              (a) =>
                parseInt(a.user_id) !==
                parseInt(JSON.parse(localStorage.getItem("user")).id)
            )[0].user_id,
            receiver_role_id: activeChat?.channel_members.filter(
              (a) =>
                parseInt(a.user_id) !==
                parseInt(JSON.parse(localStorage.getItem("user")).id)
            )[0].role_id,
            sender_role_id: JSON.parse(localStorage.getItem("user")).role_id,
            content: [{ file_path: data, caption: "" }],
            parent_message_id: replyMessage.current?.id,
            message_type: "ImageMessage",
          },
        });
      dispatch({
        type: "SEND-MESSAGE",
        payload: {
          act: activeChat,
          message: {
            parent_message: replyMessage.current,
            parent_message_id: replyMessage.current?.id,
            receiver_user_id: activeChat?.channel_members.filter(
              (a) =>
                parseInt(a.user_id) !==
                parseInt(JSON.parse(localStorage.getItem("user")).id)
            )[0].user_id,
            receiver_role_id: activeChat?.channel_members.filter(
              (a) =>
                parseInt(a.user_id) !==
                parseInt(JSON.parse(localStorage.getItem("user")).id)
            )[0].role_id,
            sender_role_id: JSON.parse(localStorage.getItem("user")).role_id,
            sender_user_id: JSON.parse(localStorage.getItem("user")).id,

            message_type: { name: "ImageMessage" },
            type: "pending",
            created_at: new Date(),
            message_status: [{ is_watched: false, is_received: 0, user_id: (localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).id) }
              , {
              is_received: 0, is_watched: false, user_id: activeChat?.channel_members.filter(
                (a) =>
                  parseInt(a.user_id) !==
                  parseInt(JSON.parse(localStorage.getItem("user")).id)
              )[0].user_id
            }],
            message_content: [{ file_path: data, caption: "" }],
            cid: activeChat?.id,
          },
        },
      });
    }
    if (type === "VoiceMessage") {
      if (activeChat?.channel_type?.slug === "team")
        dispatch({
          type: "SEND_MES",
          payload: {
            channel_id: activeChat?.id,
            whatsapp_data: { updated_at: new Date().toISOString(), type: "VoiceMessage" },

            sender_role_id: JSON.parse(localStorage.getItem("user")).role_id,
            content: [{ file_path: data, caption: "" }],
            parent_message_id: replyMessage.current?.id,
            message_type: "VoiceMessage",
          },
        });
      else
        dispatch({
          type: "SEND_MES",
          payload: {
            receiver_user_id: activeChat?.channel_members.filter(
              (a) =>
                parseInt(a.user_id) !==
                parseInt(JSON.parse(localStorage.getItem("user").id))
            )[0].user_id,
            receiver_role_id: activeChat?.channel_members.filter(
              (a) =>
                parseInt(a.user_id) !==
                parseInt(JSON.parse(localStorage.getItem("user").id))
            )[0].role_id,
            sender_role_id: JSON.parse(localStorage.getItem("user")).role_id,
            content: [{ file_path: data, caption: "" }],
            parent_message_id: replyMessage.current?.id,
            message_type: "VoiceMessage",
          },
        });
      dispatch({
        type: "SEND-MESSAGE",
        payload: {
          act: activeChat,
          message: {
            parent_message: replyMessage.current,
            parent_message_id: replyMessage.current?.id,
            receiver_user_id: activeChat?.channel_members.filter(
              (a) =>
                parseInt(a.user_id) !==
                parseInt(JSON.parse(localStorage.getItem("user")).id)
            )[0].user_id,
            receiver_role_id: activeChat?.channel_members.filter(
              (a) =>
                parseInt(a.user_id) !==
                parseInt(JSON.parse(localStorage.getItem("user")).id)
            )[0].role_id,
            sender_role_id: JSON.parse(localStorage.getItem("user")).role_id,
            sender_user_id: JSON.parse(localStorage.getItem("user")).id,

            message_type: { name: "VoiceMessage" },
            type: "pending",
            created_at: new Date(),
            message_status: [{ is_watched: false, is_received: 0, user_id: (localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).id) }
              , {
              is_received: 0, is_watched: false, user_id: activeChat?.channel_members.filter(
                (a) =>
                  parseInt(a.user_id) !==
                  parseInt(JSON.parse(localStorage.getItem("user")).id)
              )[0].user_id
            }],
            message_content: [{ file_path: data, caption: "" }],
            cid: activeChat?.id,
          },
        },
      });
    }
    replyMessage.current = null;
  };
  const toBase64 = (file, i) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);

        // dispatch({type:"SEND-MESSAGE",payload:{act:activeChat,message:{sender:"me",sent:"19:12",recived:"",read:"",content:base64data,type:"audio"}}})
        dispatch({
          type: "SEND-MESSAGE",
          payload: {
            act: activeChat,
            message: {
              parent_message: replyMessage.current,
              parent_message_id: replyMessage.current?.id,
              receiver_user_id: activeChat?.channel_members.filter(
                (a) =>
                  parseInt(a.user_id) !==
                  parseInt(JSON.parse(localStorage.getItem("user")).id)
              )[0].user_id,
              receiver_role_id: activeChat?.channel_members.filter(
                (a) =>
                  parseInt(a.user_id) !==
                  parseInt(JSON.parse(localStorage.getItem("user")).id)
              )[0].role_id,
              sender_role_id: JSON.parse(localStorage.getItem("user")).role_id,
              sender_user_id: JSON.parse(localStorage.getItem("user")).id,
              message_type: { name: "ImageMessage" },
              message_content: [{ file_path: reader.result, caption: "" }],
              type: "pending",
              created_at: new Date(),
              message_status: [{ is_watched: false, is_received: 0, user_id: (localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).id) }
                , {
                is_received: 0, is_watched: false, user_id: activeChat?.channel_members.filter(
                  (a) =>
                    parseInt(a.user_id) !==
                    parseInt(JSON.parse(localStorage.getItem("user")).id)
                )[0].user_id
              }],
              mid: i,
              cid: activeChat?.id,
            },
          },
        });

        //send_mes(message,"TextMessage");
        //dispatch({type:"SEND-MESSAGE",payload:{act:activeChat,message:{sender:"me",sent:"19:12",recived:"19:12",read:"19:12",content:reader.result,type:"img"}}})
      };
      reader.onerror = (error) => reject(error);
    });
  const uploadPhoto = async (e) => {
    sendStatues("Sending Photo...")
    let i = Math.random();
    sendPhoto(e, i);
    let pat = await upload(e);
    sendStatues(null)

    if (activeChat?.channel_type?.slug === "team")
      dispatch({
        type: "SEND_MES",
        payload: {
          channel_id: activeChat?.id,

          whatsapp_data: { updated_at: new Date().toISOString(), type: "ImageMessage" },
          sender_role_id: JSON.parse(localStorage.getItem("user")).role_id,
          content: [{ file_path: pat, caption: "" }],
          parent_message_id: replyMessage.current?.id,
          message_type: "ImageMessage",
          mid: i,
          cid: activeChat?.id,
        },
      });
    else
      dispatch({
        type: "SEND_MES",
        payload: {
          receiver_user_id: activeChat?.channel_members.filter(
            (a) =>
              parseInt(a.user_id) !==
              parseInt(JSON.parse(localStorage.getItem("user")).id)
          )[0].user_id,
          receiver_role_id: activeChat?.channel_members.filter(
            (a) =>
              parseInt(a.user_id) !==
              parseInt(JSON.parse(localStorage.getItem("user")).id)
          )[0].role_id,
          sender_role_id: JSON.parse(localStorage.getItem("user")).role_id,
          content: [{ file_path: pat, caption: "" }],
          parent_message_id: replyMessage.current?.id,
          message_type: "ImageMessage",
          mid: i,
          cid: activeChat?.id,
        },
      });
    replyMessage.current = null;

    setTimeout(() => {
      sendStatues(null)

    }, 5000);


  };
  const sendDocs = (e, i) => {
    dispatch({ type: "REFS" })
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(e);
      reader.onload = () => {
        resolve(reader.result);

        // dispatch({type:"SEND-MESSAGE",payload:{act:activeChat,message:{sender:"me",sent:"19:12",recived:"",read:"",content:base64data,type:"audio"}}})
        dispatch({
          type: "SEND-MESSAGE",
          payload: {
            act: activeChat,
            message: {
              parent_message: replyMessage.current,
              parent_message_id: replyMessage.current?.id,
              receiver_user_id: activeChat?.channel_members.filter(
                (a) =>
                  parseInt(a.user_id) !==
                  parseInt(JSON.parse(localStorage.getItem("user")).id)
              )[0].user_id,
              receiver_role_id: activeChat?.channel_members.filter(
                (a) =>
                  parseInt(a.user_id) !==
                  parseInt(JSON.parse(localStorage.getItem("user")).id)
              )[0].role_id,
              sender_role_id: JSON.parse(localStorage.getItem("user")).role_id,
              sender_user_id: JSON.parse(localStorage.getItem("user")).id,
              message_type: { name: "FileMessage" },
              message_content: [{ file_path: reader.result, caption: "" }],
              type: "pending",
              created_at: new Date(),
              message_status: [{ is_watched: false, is_received: 0, user_id: (localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).id) }
                , {
                is_received: 0, is_watched: false, user_id: activeChat?.channel_members.filter(
                  (a) =>
                    parseInt(a.user_id) !==
                    parseInt(JSON.parse(localStorage.getItem("user")).id)
                )[0].user_id
              }],
              mid: i,
              cid: activeChat?.id,
            },
          },
        });

        //send_mes(message,"TextMessage");
        //dispatch({type:"SEND-MESSAGE",payload:{act:activeChat,message:{sender:"me",sent:"19:12",recived:"19:12",read:"19:12",content:reader.result,type:"img"}}})
      };
      reader.onerror = (error) => reject(error);
    });
  }
  const sendDocument = async (e) => {
    sendStatues("Sending Document...")
    let i = Math.random();
    sendDocs(e, i);
    let pat = await upload(e);
    sendStatues(null)

    if (activeChat?.channel_type?.slug === "team")
      dispatch({
        type: "SEND_MES",
        payload: {
          channel_id: activeChat?.id,

          whatsapp_data: { updated_at: new Date().toISOString(), type: "FileMessage" },
          sender_role_id: JSON.parse(localStorage.getItem("user")).role_id,
          content: [{ file_path: pat, caption: "" }],
          parent_message_id: replyMessage.current?.id,
          message_type: "FileMessage",
          mid: i,
          cid: activeChat?.id,
        },
      });
    else
      dispatch({
        type: "SEND_MES",
        payload: {
          receiver_user_id: activeChat?.channel_members.filter(
            (a) =>
              parseInt(a.user_id) !==
              parseInt(JSON.parse(localStorage.getItem("user")).id)
          )[0].user_id,
          receiver_role_id: activeChat?.channel_members.filter(
            (a) =>
              parseInt(a.user_id) !==
              parseInt(JSON.parse(localStorage.getItem("user")).id)
          )[0].role_id,
          sender_role_id: JSON.parse(localStorage.getItem("user")).role_id,
          content: [{ file_path: pat, caption: "" }],
          parent_message_id: replyMessage.current?.id,
          message_type: "FileMessage",
          mid: i,
          cid: activeChat?.id,
        },
      });
    replyMessage.current = null;

    setTimeout(() => {
      sendStatues(null)

    }, 5000);


  };
  const sendVid = async (e) => {
    sendStatues("Sending Vdieo...")
    let i = Math.random();
    dispatch({ type: "REFS" })
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(e);
      reader.onload = () => {
        resolve(reader.result);
        dispatch({
          type: "SEND-MESSAGE",
          payload: {
            act: activeChat,
            message: {
              parent_message: replyMessage.current,
              parent_message_id: replyMessage.current?.id,
              receiver_user_id: activeChat?.channel_members.filter(
                (a) =>
                  parseInt(a.user_id) !==
                  parseInt(JSON.parse(localStorage.getItem("user")).id)
              )[0].user_id,
              receiver_role_id: activeChat?.channel_members.filter(
                (a) =>
                  parseInt(a.user_id) !==
                  parseInt(JSON.parse(localStorage.getItem("user")).id)
              )[0].role_id,
              sender_role_id: JSON.parse(localStorage.getItem("user")).role_id,
              sender_user_id: JSON.parse(localStorage.getItem("user")).id,
              message_type: { name: "VideoMessage" },
              message_content: [{ file_path: reader.result, caption: "" }],
              type: "pending",
              created_at: new Date(),
              message_status: [{ is_watched: false, is_received: 0, user_id: (localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).id) }
                , {
                is_received: 0, is_watched: false, user_id: activeChat?.channel_members.filter(
                  (a) =>
                    parseInt(a.user_id) !==
                    parseInt(JSON.parse(localStorage.getItem("user")).id)
                )[0].user_id
              }],
              mid: i,
              cid: activeChat?.id,
            },
          },
        });

        //send_mes(message,"TextMessage");
        //dispatch({type:"SEND-MESSAGE",payload:{act:activeChat,message:{sender:"me",sent:"19:12",recived:"19:12",read:"19:12",content:reader.result,type:"img"}}})
      };
      reader.onerror = (error) => reject(error);
    });
    let pat = await upload(e);
    sendStatues(null)

    if (activeChat?.channel_type?.slug === "team")
      dispatch({
        type: "SEND_MES",
        payload: {
          channel_id: activeChat?.id,

          whatsapp_data: { updated_at: new Date().toISOString(), type: "VideoMessage" },
          sender_role_id: JSON.parse(localStorage.getItem("user")).role_id,
          content: [{ file_path: pat, caption: "" }],
          parent_message_id: replyMessage.current?.id,
          message_type: "VideoMessage",
          mid: i,
          cid: activeChat?.id,
        },
      });
    else
      dispatch({
        type: "SEND_MES",
        payload: {
          receiver_user_id: activeChat?.channel_members.filter(
            (a) =>
              parseInt(a.user_id) !==
              parseInt(JSON.parse(localStorage.getItem("user")).id)
          )[0].user_id,
          receiver_role_id: activeChat?.channel_members.filter(
            (a) =>
              parseInt(a.user_id) !==
              parseInt(JSON.parse(localStorage.getItem("user")).id)
          )[0].role_id,
          sender_role_id: JSON.parse(localStorage.getItem("user")).role_id,
          content: [{ file_path: pat, caption: "" }],
          parent_message_id: replyMessage.current?.id,
          message_type: "VideoMessage",
          mid: i,
          cid: activeChat?.id,
        },
      });
    replyMessage.current = null;

    setTimeout(() => {
      sendStatues(null)

    }, 5000);


  };
  useEffect(() => {

  }, [blobs]);
  useEffect(() => {
    setTimeout(lastMsgRef.current?.scrollIntoView(), 800)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMsgRef.current]);
  useEffect(() => {
    setTimeout(lastMsgRef.current?.scrollIntoView(), 800)
    activeChat && activeChat?.id && dispatch({ type: "WATCH_CHANNEL", payload: activeChat?.id })
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [refs])
  const chatData = useSelector((state) => state.chat.data);
  const viewDisplay = useSelector((state) => state.whatsapp.viewDisplay);
  const MediaStreamRef = useRef()
  const [imgs, setImgs] = useState(null)
  const [vid, setVid] = useState(null)
  const [rec, setRec] = useState(false);
  return (
    <>
      {props.active && !viewDisplay && <>
        <div className="new-chats">
          {activeChat && activeChat?.id && getNew().filter((cv) => cv.id !== activeChat && activeChat?.id && activeChat?.id && cv.channel_type?.slug !== "team").map((a) => {
            return (
              <div className='new-chat' onClick={() => { dispatch({ type: "OPEN-CHAT", payload: a }); dispatch({ type: "WATCH_CHANNEL", payload: a.id }); }}>
                <svg data-src={point}></svg>

                {activeChat?.channel_type?.slug === "team" ? <div className='img-cont'>
                  <img alt="avatar" src={a.photo_path ? FILE_SERVER + a.photo_path : avat} />
                </div>
                  : <div className='img-cont'>
                    <img alt="avatar" src={a.channel_members.filter((ada) => ada.user_id !== JSON.parse(localStorage.getItem("user")).id)[0].user.avatar ? FILE_SERVER + (a.channel_members.filter((ada) => ada.user_id !== JSON.parse(localStorage.getItem("user")).id)[0].user.avatar) : avat} />
                  </div>}


              </div>
            )
          })}
        </div>

      </>}
      {activeChat?.id && call === "vid" && <VideoCall active={activeChat?.channel_members.filter((ada) => ada.user_id !== JSON.parse(localStorage.getItem("user")).id)[0].user.avatar ? FILE_SERVER + (activeChat?.channel_members.filter((ada) => ada.user_id !== JSON.parse(localStorage.getItem("user")).id)[0].user.avatar) : avat} channel={activeChat?.pusher_channel_name} user_id={activeChat.channel_members.filter((u) => u.user_id !== JSON.parse(localStorage.getItem("user")).id)[0].user_id} />}
      {activeChat?.id && call === "aud" && <VoiceCall active={activeChat?.channel_members.filter((ada) => ada.user_id !== JSON.parse(localStorage.getItem("user")).id)[0].user.avatar ? FILE_SERVER + (activeChat?.channel_members.filter((ada) => ada.user_id !== JSON.parse(localStorage.getItem("user")).id)[0].user.avatar) : avat} channel={activeChat?.pusher_channel_name} user_id={activeChat.channel_members.filter((u) => u.user_id !== JSON.parse(localStorage.getItem("user")).id)[0].user_id} />}

      <div className={`chat ${activeChat?.id && "activeC"}`}>
        {(imgs || vid) &&

          <div className="fixed-img-prev">
            <div className="bac-drop"></div>
            <div className="svv" onClick={() => { setImgs(null); setVid(); }}> <Icon id="cancel" /></div>
            {vid ? <video src={vid} controls><source src={vid} /></video> : <img alt="imgs" src={imgs} />}
          </div>}
        <div className="chat__body" style={{ height: "100%" }}>
          <div className="chat__bg"></div>
          <ChatHeader activeChat={activeChat} makeCall={(w) => {
            if (w === "video") {
              dispatch({ type: "VIDEO_CALL" })

            } else {
              dispatch({ type: "AUDIO_CALL" })
            }
          }}
            user={activeChat?.channel_type?.slug === "team" ? activeChat :
              activeChat?.channel_members?.filter((a) =>
                parseInt(a?.user_id) !==
                parseInt(JSON.parse(localStorage.getItem("user")).id)
              )[0]?.user} typing={activeChat?.status} close={() => {
                dispatch({ type: "MAIN", payload: "main" });
                dispatch({ type: "OPEN-CHAT", payload: null });
              }} getNewMessage={() => getNew()} call={call} />
          <div tabIndex={"-1"}
            id="XD-2"
            style={{
              overflowAnchor: "none",
              flexDirection: "column-reverse"
            }}
            className="chat__content"
            onWheel={(e) => {
              let els = document.querySelector("#XD-2")
              if (fetch && els && (-els?.scrollTop * 100) / (els?.scrollHeight - els?.clientHeight) && !isReachTheFinalMes) {
                onScroll()
              }
            }}
          >
            <div>
              <ChatConvo setVid={(s) => setVid(s)} setImg={(ds) => setImgs(ds)} reply={(d) => reply(d)} messages={activeChat?.messages || []} activeChat={activeChat} dateOfConversation={dateOfConversation} />
              <div
                ref={lastMsgRef}
                className="ref-bot"
                style={{
                  position: "static",
                  bottom: "0px",
                  opacity: "0",
                  height: "2px",
                }}
              ></div>
            </div>
          </div>

          <>
            {<footer className="chat__footer">
              {rec && (
                <Recorder
                  send={(e) => {
                    let i = Math.random()
                    if (e) sendAudio(e, i);
                    setRec(false);
                  }}
                  style={{ display: "block" }}
                  rec={rec}
                  setRec={setRec}
                />
              )}
              {!rec && <>
                {replyMessage.current && <div className="reply-sc">
                  <div
                    className="reply-cancel"
                    onClick={() => (replyMessage.current = null)}
                  >
                    <Icon id="cancel" />
                  </div>
                  <div className="replay-mes">
                    <div className="bar-c"></div>
                    <div className="reply-container">
                      <div className="group_name reply-name">
                        {replyMessage.current?.sender_user_id === JSON.parse(localStorage.getItem("user")).id
                          ? "You"
                          : ((activeChat?.channel_members.filter((s) => s.user_id === replyMessage.current?.sender_user_id)[0]?.user?.full_name
                            ? activeChat?.channel_members.filter((s) => s.user_id === replyMessage.current?.sender_user_id)[0]?.user?.full_name
                            : activeChat?.channel_members.filter((s) => s.user_id === replyMessage.current?.sender_user_id)[0]?.user?.username) || ((replyMessage.current?.sender_whatsapp_contact?.name) || (replyMessage.current?.sender_whatsapp_contact?.pushname) || ("+" + replyMessage.current?.sender_whatsapp_contact?.number)))}
                      </div>
                      <div className="reply-desc">
                        {
                          <>
                            {replyMessage.current.message_type.name === "VoiceMessage" && <Voc />}
                            {replyMessage.current.message_type.name === "ImageMessage" && <Cam />}
                            {replyMessage.current.message_type.name === "FileMessage" && <img alt="file" width={15} height={19} src={fil} />}
                          </>
                        }{" "}
                        {replyMessage.current?.body}
                      </div>
                    </div>
                    {replyMessage.current.message_type.name === "ImageMessage" && (
                      <div className="replay-img">
                        {replyMessage.current.message_type.name === "ImageMessage" && <img alt="" src={FILE_SERVER + replyMessage.current.message_content[0].file_path} />}
                      </div>
                    )}
                  </div>
                </div>}
                {showEmojis && <EmojiTray
                  showEmojis={showEmojis}
                  newMessage={message}
                  setNewMessage={setMessage}
                />}
                <ChatInput
                  replyMessage={null}
                  onFocus={() => { sendStatues("Typing...") }}
                  onBlur={() => { sendStatues(null) }}
                  sendVid={(e) => sendVid(e)}
                  showEmojis={showEmojis}
                  sendDocument={(e) => sendDocument(e)}
                  setRec={(e) => { setRec(e) }}
                  sendAud={(e) => {
                    setRec(false)
                    let i = Math.random();
                    setTimeout(() => {
                      sendAudio(e, i);
                      if (MediaStreamRef.current) {
                        MediaStreamRef.current?.getTracks()?.forEach((track) => {
                          track.stop();
                        })
                      }
                    }, 1000);
                  }}
                  handlePhoto={(e) => uploadPhoto(e)}
                  rec={rec}
                  setShowEmojis={setShowEmojis}
                  showAttach={showAttach}
                  setShowAttach={setShowAttach}
                  newMessage={message}
                  setNewMessage={(mes) => setMessage(mes)}
                  submitNewMessage={(e) => {
                    // console.log(e)
                    send_mes(e, "TextMessage")
                  }}
                  chat={true}
                />
              </>}
            </footer>}
          </>


        </div>
        {activeChat?.id && forwarderMessage && <ChatSidebar
          searchedMessages={forwarderMessage}
          heading="Forward Message"
          active={forwarderMessage}
          closeSidebar={() => dispatch({ type: "FORWARD-MESSAGE", payload: null })}>
          <ForwardListChat closeSidebar={() => dispatch({ type: "FORWARD-MESSAGE", payload: null })} chatss={chatData} chats={chatData.filter((cha) => cha.id !== activeChat?.id)} />
        </ChatSidebar>}
      </div>
    </>
  );
}

export default ChattingScreen;