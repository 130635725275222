export default function InsructionsIcon({
    onMouseLeave,
    onMouseOver
}) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" onMouseLeave={() => onMouseLeave()} onMouseOver={() => onMouseOver()}>
        <g id="Group_11938" data-name="Group 11938" transform="translate(-369 -234)">
            <circle id="Ellipse_422" data-name="Ellipse 422" cx="10" cy="10" r="10" transform="translate(369 234)" fill="#0c7d26" />
            <text id="_" data-name="!" transform="translate(377 250)" fill="#fff" font-size="15" font-family="SegoeUI, Segoe UI" letter-spacing="0.025em"><tspan x="0" y="0">!</tspan></text>
        </g>
    </svg>
    )
}