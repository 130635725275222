export default function Search() {
    return (<svg id="_15x15_photo_back" data-name="15x15 photo back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
        <defs>
            <clipPath id="clip-path">
                <rect id="Rectangle_4561" data-name="Rectangle 4561" width="15" height="15" fill="none" />
            </clipPath>
        </defs>
        <g id="Mask_Group_165" data-name="Mask Group 165" clip-path="url(#clip-path)">
            <g id="_x32_-Magnifying_Glass" transform="translate(0.001 -0.001)">
                <path id="Path_19050" data-name="Path 19050" d="M17.842,17.225l-3.408-3.408a6.54,6.54,0,0,0-9.547-8.93,6.54,6.54,0,0,0,8.93,9.547l3.408,3.408a.436.436,0,0,0,.617-.617ZM5.5,13.523a5.671,5.671,0,1,1,4.01,1.661A5.635,5.635,0,0,1,5.5,13.523Z" transform="translate(-2.97 -2.97)" fill="#404040" />
            </g>
        </g>
    </svg>

    )
}