import React, { useState } from 'react'
import BulkList from './bulks_list'
import PackageCart from './svg/package_cart'

export default function MainStatuses(props) {
    const ticket_types = props.ticket_types
    const [currentBulk, setCurrentBulk] = useState(null)
    const getColor = (index) => {
        if (index === 0) {
            return "#FFF7F4"
        } else if (index === 1) {
            return "#F7F7F7"
        } else if (index === 2) {
            return "#F4F8FF"
        } else if (index === 3) {
            return "#F9FFF4"
        } else {
            return "#FDEAEA"
        }
    }
    const getIconColor = (index) => {
        if (index === 0) {
            return "#FF9067"
        } else if (index === 1) {
            return "#404040"
        } else if (index === 2) {
            return "#007CFF"
        } else if (index === 3) {
            return "#00C408"
        } else {
            return "#FF7171"
        }
    }
    const getTextColor = (index) => {
        if (index === 0) {
            return "#FF9067"
        } else if (index === 1) {
            return "#404040"
        } else if (index === 2) {
            return "#007CFF"
        } else if (index === 3) {
            return "#00C408"
        } else {
            return "#FF7171"
        }
    }
    return (<div className="shipment_main_statuses">
        {ticket_types.map((type, index) => <div key={index}
            className='status_normal_employee_parent'>
            <div className='status_normal_employee' style={{ backgroundColor: getColor(index) }} >
                <PackageCart color1={getIconColor(index)} color2={getIconColor(index)} />
                <div className='status_normal_employee_type' style={{ color: getTextColor(index) }} >
                    {type.name} {" "}
                    {type.tickets_count}
                </div>
            </div>
            <BulkList
                currentBulk={currentBulk}
                setCurrentBulk={(e) => setCurrentBulk(e)}
                data={type.tickets}
                chat={type}
                ticket_status_id={type.id}
                expanded={false}
                getPage={(e) => props.getPage(e)}
                total_count={type.tickets_count}
                icons_color={getIconColor(index)}
                ticketColor={getColor(index)}
                status={type.name}
                editTicket={props.editTicket}
                finishEditTicket={props.finishEditTicket}
                index={index}
            // mainStatuses={mainStatuses}
            // selectedFilter={fakeFilterSelected[index]}
            />
        </div>)}
    </div>)

}