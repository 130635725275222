import React from 'react'

function OutlineIcon({name}) {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
  <g id="Rectangle_4606" data-name="Rectangle 4606" transform="translate(0)" fill="none" stroke="#404040" strokeWidth="1">
    <rect width="50" height="50" rx="10" stroke="none"/>
    <rect x="0.5" y="0.5" width="49" height="49" rx="9.5" fill="none"/>
  </g>
  <text textAnchor='middle' id="A" transform="translate(0 10)" fill="#404040" font-size="30" font-family="SegoeUI, Segoe UI" letter-spacing="0.025em"><tspan textAnchor='middle' x="50%" y="50%">{name}</tspan></text>
</svg>

  )
}

export default OutlineIcon