import React from 'react'

function Docs() {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="12.278" height="15" viewBox="0 0 12.278 15">
  <path id="Path_18954" data-name="Path 18954" d="M12.812,1.7h-.439V12.473a1.3,1.3,0,0,1-1.3,1.3H3.1v.4A.829.829,0,0,0,3.925,15H12.81a.829.829,0,0,0,.829-.829V2.527A.828.828,0,0,0,12.812,1.7Z" transform="translate(-1.361)" fill="#8e8e8e"/>
  <path id="Path_18955" data-name="Path 18955" d="M6.631,13.3h4.442a.829.829,0,0,0,.829-.829V.829A.829.829,0,0,0,11.073,0H5.154V.274a.805.805,0,0,1,0,.088v2.27A1.168,1.168,0,0,1,3.991,3.8H1.721c-.023,0-.049,0-.072,0H1.361v8.678a.829.829,0,0,0,.829.829H6.631ZM6.8,10.486H3.9a.392.392,0,0,1,0-.783H6.8a.392.392,0,1,1,0,.783Zm2.9-2H3.9a.392.392,0,1,1,0-.783H9.7a.392.392,0,1,1,0,.783ZM3.9,5.581H9.7a.392.392,0,1,1,0,.783H3.9a.392.392,0,0,1,0-.783Z" transform="translate(-1.361)" fill="#8e8e8e"/>
  <path id="Path_18956" data-name="Path 18956" d="M1.721,3.329H4a.7.7,0,0,0,.689-.689V.362A.349.349,0,0,0,4.336.013a.34.34,0,0,0-.244.1L1.474,2.733A.349.349,0,0,0,1.721,3.329Z" transform="translate(-1.361)" fill="#8e8e8e"/>
</svg>



  )
}

export default Docs