import React, { useEffect } from 'react'
import LinkedLanguages from "./LinkedLanguages/LinkedLanguages.js"
import HRM from './HRM';
import { useDispatch, useSelector } from 'react-redux';
import Currencies from './LinkedCurrencies/Currencies';
import Countries from './Countries';
import TranslateComponent from './Translate'
import TicketsPage from './test/index.js'
import BrokerTicketsPage from './brokerTransfer/index.js'
import EmpTicketsPage from './employee_trans/index.js'

function Routes(props) {
  const dispatch = useDispatch()
  const roles = useSelector((state) => state.auth.roles)

  // call important endpoints like languages
  const languages = useSelector(state => state.langs.languages)
  // call important endpoints like languages
  useEffect(() => {
    if (languages.length === 0) {
      dispatch({ type: "GET-LANG" })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // console.log(props.option)
  return (<>
    <>
      {props.option.routes === "Deposit Tickets" ? <BrokerTicketsPage active={true} {...props} /> : <></>}
      {props.option.routes === "Transfer" ? <TicketsPage active={true} {...props} /> : <></>}
      {props.option.routes === "Employee Transfer" ? <EmpTicketsPage active={true} {...props} /> : <></>}
      {props.option.routes === "Languages" ? <LinkedLanguages active={true} {...props} /> : <></>}
      {props.option.routes === "Currencies" ? <Currencies active={true} {...props} /> : <></>}
      {props.option.routes === "Countries" ? <Countries active={true} {...props} /> : <></>}
      {props.option.routes === "Translate" ? <TranslateComponent active={true} {...props} /> : <></>}
      {props.option.routes === "HRM" ? <HRM forCustomer={(roles?.includes("ACCOUNT_MODULE") && !roles?.includes("USER_EDIT_EMPLOYEE_SALARY"))} active={true} {...props} /> : <></>}
    </>
  </>)
}

export default Routes