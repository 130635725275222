
const initialState = {
    whatsapp_chats: [],
    countries: [],
    cities: [],
    total_tickets_count: 0,
    total_tickets_count_diff: 0,
    total_tickets_usdt_amount: 0,
    total_tickets_usd_amount: 0,
    total_tickets_usdt_amount_diff: 0,
    total_tickets_usd_amount_diff: 0,
    total_deposit_tickets_count: 0,
    total_deposit_tickets_usd_amount: 0,
    total_deposit_tickets_usdt_amount: 0,
    total_withdrawal_tickets_count: 0,
    total_withdrawal_tickets_usd_amount: 0,
    total_withdrawal_tickets_usdt_amount: 0,
    loading: false,
    lockedTickets: []
}
export const mergedTickets = ({ first, second }) => {
    let tickets = first
    second.map(ele => {
        if (tickets.filter(one => one.id === ele.id).length === 0) {
            tickets.push(ele)
        }
    })
    return tickets
}
export const ticketsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_TICKETS_STATICS_RED": {
            return ({
                ...state,
                whatsapp_chats: action.payload?.whatsapp_chats,
                total_tickets_count: action.payload?.total_tickets_count,
                total_tickets_count_diff: action.payload?.total_tickets_count_diff,
                total_tickets_usdt_amount: action.payload?.total_tickets_usdt_amount,
                total_tickets_usd_amount: action.payload?.total_tickets_usd_amount,
                total_tickets_usdt_amount_diff: action.payload?.total_tickets_usdt_amount_diff,
                total_tickets_usd_amount_diff: action.payload?.total_tickets_usd_amount_diff,
                total_deposit_tickets_count: action.payload?.total_deposit_tickets_count,
                total_deposit_tickets_usd_amount: action.payload?.total_deposit_tickets_usd_amount,
                total_deposit_tickets_usdt_amount: action.payload?.total_deposit_tickets_usdt_amount,
                total_withdrawal_tickets_count: action.payload?.total_withdrawal_tickets_count,
                total_withdrawal_tickets_usd_amount: action.payload?.total_withdrawal_tickets_usd_amount,
                total_withdrawal_tickets_usdt_amount: action.payload?.total_withdrawal_tickets_usdt_amount,
            })
        }
        case "GET_UPDATED_STATICS_RED": {
            let updatedChat = action.payload?.whatsapp_chats[0]
            let newChats = state.whatsapp_chats.map(one => {
                if (one.whatsapp_chat_id === updatedChat.whatsapp_chat_id) {
                    return {
                        ...updatedChat,
                        tickets: mergedTickets({
                            first: updatedChat.tickets,
                            second: one.tickets
                        })
                    }
                } else {
                    return {
                        ...one
                    }
                }
            })
            return ({
                ...state,
                whatsapp_chats: newChats,
                total_tickets_count: action.payload?.total_tickets_count,
                total_tickets_count_diff: action.payload?.total_tickets_count_diff,
                total_tickets_usdt_amount: action.payload?.total_tickets_usdt_amount,
                total_tickets_usd_amount: action.payload?.total_tickets_usd_amount,
                total_tickets_usdt_amount_diff: action.payload?.total_tickets_usdt_amount_diff,
                total_tickets_usd_amount_diff: action.payload?.total_tickets_usd_amount_diff,
                total_deposit_tickets_count: action.payload?.total_deposit_tickets_count,
                total_deposit_tickets_usd_amount: action.payload?.total_deposit_tickets_usd_amount,
                total_deposit_tickets_usdt_amount: action.payload?.total_deposit_tickets_usdt_amount,
                total_withdrawal_tickets_count: action.payload?.total_withdrawal_tickets_count,
                total_withdrawal_tickets_usd_amount: action.payload?.total_withdrawal_tickets_usd_amount,
                total_withdrawal_tickets_usdt_amount: action.payload?.total_withdrawal_tickets_usdt_amount,
            })
        }
        case "GET_TICKETS_COUNTRIES_RED": {
            return ({
                ...state,
                countries: action.payload
            })
        }
        case "GET_TICKETS_CITIES_RED": {
            return ({
                ...state,
                cities: action.payload
            })
        }
        case "GET_TICKETS_STATICS_WAIT": {
            return ({
                ...state,
                loading: true
            })
        }
        case "GET_TICKETS_STATICS_DONE": {
            return ({
                ...state,
                loading: false
            })
        }
        case "TicketEvent": {
            let ticket = action.payload.ticket
            let chat_id = action.payload.ticket.whatsapp_chat_id
            let chats = state.whatsapp_chats
            chats = chats.map(one => {
                if (one.whatsapp_chat_id === chat_id) {
                    if (one.tickets.filter(tic => tic.id === ticket.id).length > 0) {
                        let newTickets = one.tickets
                        newTickets = newTickets.map(tick => {
                            if (tick.id === ticket.id) {
                                return {
                                    ...ticket
                                }
                            } else {
                                return {
                                    ...tick
                                }
                            }
                        })
                        return ({
                            ...one,
                            tickets: newTickets
                        })
                    } else {
                        let newTickets = one.tickets
                        newTickets.unshift(ticket)
                        return ({
                            ...one,
                            tickets: newTickets
                        })
                    }
                } else {
                    return {
                        ...one
                    }
                }
            })
            return ({
                ...state,
                whatsapp_chats: chats
            })
        }
        case "GET_PAGE_TICKETS_RED": {
            let chats = state.whatsapp_chats.map((chat) => {
                if (chat.whatsapp_chat_id === action.payload.chat_id) {
                    return {
                        ...chat,
                        tickets: chat.tickets.concat(action.payload.data)
                    }
                } else {
                    return {
                        ...chat
                    }
                }
            })
            return {
                ...state,
                whatsapp_chats: chats
            }
        }
        case "ticketLockedByUser": {
            let lockedTickets = state.lockedTickets
            lockedTickets.push(action.payload)
            return {
                ...state,
                lockedTickets
            }
        }
        case "ticketUnLockedByUser": {
            let lockedTickets = state.lockedTickets.filter(one => one.id === action.payload.ticket_id)

            return {
                ...state,
                lockedTickets
            }
        }
        default:
            return state;
    }
}