export default function ClearLogin() {
    return (<>
        <svg
            className="bord"
            xmlns="http://www.w3.org/2000/svg"
            width="350"
            height="50"
            viewBox="0 0 350 50"
        >
            <g
                id="Rectangle_4720"
                data-name="Rectangle 4720"
                fill="#f7f7f7"
                stroke="#ddd"
                strokeWidth="0.5"
            >
                <rect width="350" height="50" rx="15" stroke="none" />
                <rect
                    x="0.25"
                    y="0.25"
                    width="349.5"
                    height="49.5"
                    rx="14.75"
                    fill="none"
                />
            </g>
        </svg>
        <svg
            id="_15x15_photo_back"
            data-name="15x15 photo back"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="15"
            height="15"
            viewBox="0 0 15 15"
        >
            <g id="Mask_Group_211" data-name="Mask Group 211">
                <g id="bin">
                    <g id="Group_7944" data-name="Group 7944">
                        <g id="Group_7943" data-name="Group 7943">
                            <path
                                id="Path_19227"
                                data-name="Path 19227"
                                d="M1.822,4.395l.778,9.4A1.325,1.325,0,0,0,3.914,15h7.172A1.325,1.325,0,0,0,12.4,13.791l.778-9.4ZM5.3,13.242a.439.439,0,0,1-.438-.412L4.425,5.74a.439.439,0,0,1,.411-.466.433.433,0,0,1,.466.411l.439,7.09A.44.44,0,0,1,5.3,13.242ZM7.939,12.8a.439.439,0,0,1-.879,0V5.713a.439.439,0,0,1,.879,0ZM10.575,5.74l-.439,7.09a.439.439,0,0,1-.877-.055L9.7,5.685a.447.447,0,0,1,.466-.411A.439.439,0,0,1,10.575,5.74Z"
                                fill="#8e8e8e"
                            />
                        </g>
                    </g>
                    <g id="Group_7946" data-name="Group 7946">
                        <g id="Group_7945" data-name="Group 7945">
                            <path
                                id="Path_19228"
                                data-name="Path 19228"
                                d="M13.213,1.758H10.576V1.318A1.32,1.32,0,0,0,9.258,0H5.742A1.32,1.32,0,0,0,4.424,1.318v.439H1.787a.879.879,0,0,0,0,1.758H13.213a.879.879,0,0,0,0-1.758Zm-3.516,0H5.3V1.318A.44.44,0,0,1,5.742.879H9.258a.44.44,0,0,1,.439.439Z"
                                fill="#8e8e8e"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg></>)
}