export default function Operation(props) {
  return (<svg id="pie-chart" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
    <g id="Group_7863" data-name="Group 7863" transform="translate(5.313)">
      <g id="Group_7862" data-name="Group 7862">
        <path id="Path_19151" data-name="Path 19151" d="M5.605,0a.293.293,0,0,0-.293.293V4.414a.293.293,0,0,0,.293.293h4.1A.293.293,0,0,0,10,4.414,4.416,4.416,0,0,0,5.605,0Z" transform="translate(-5.313)" fill={props.client ? "#f29595" : "#cecdcd"} />
      </g>
    </g>
    <g id="Group_7865" data-name="Group 7865" transform="translate(0 1.191)">
      <g id="Group_7864" data-name="Group 7864">
        <path id="Path_19152" data-name="Path 19152" d="M6.148,9.343,4.727,5.533V1.484a.293.293,0,0,0-.293-.293A4.433,4.433,0,0,0,0,5.586,4.45,4.45,0,0,0,4.434,10a4.373,4.373,0,0,0,1.543-.28A.293.293,0,0,0,6.148,9.343Z" transform="translate(0 -1.191)" fill={props.client ? "#f29595" : "#cecdcd"} />
      </g>
    </g>
    <g id="Group_7867" data-name="Group 7867" transform="translate(5.312 5.293)">
      <g id="Group_7866" data-name="Group 7866">
        <path id="Path_19153" data-name="Path 19153" d="M9.707,5.293h-4.1a.293.293,0,0,0-.274.4l1.44,3.86a.293.293,0,0,0,.378.171A4.445,4.445,0,0,0,10,5.586.293.293,0,0,0,9.707,5.293Z" transform="translate(-5.312 -5.293)" fill={props.client ? "#f29595" : "#cecdcd"} />
      </g>
    </g>
  </svg>)
}