export default function Cash() {
  return (<svg id="money-8" xmlns="http://www.w3.org/2000/svg" width="10.004" height="10" viewBox="0 0 10.004 10">
    <g id="Group_7795" data-name="Group 7795" transform="translate(0 4.644)">
      <g id="Group_7794" data-name="Group 7794">
        <path id="Path_19112" data-name="Path 19112" d="M.133,5.013a.209.209,0,0,0-.124.267l.133.36,1-1Z" transform="translate(0.003 -4.643)" fill="#cecdcd" />
      </g>
    </g>
    <g id="Group_7797" data-name="Group 7797" transform="translate(1.082 8.213)">
      <g id="Group_7796" data-name="Group 7796">
        <path id="Path_19113" data-name="Path 19113" d="M1.079,8.213l.6,1.65a.207.207,0,0,0,.108.117.209.209,0,0,0,.087.02.2.2,0,0,0,.072-.013l.662-.244Z" transform="translate(-1.079 -8.213)" fill="#cecdcd" />
      </g>
    </g>
    <g id="Group_7799" data-name="Group 7799" transform="translate(5.565 4.916)">
      <g id="Group_7798" data-name="Group 7798">
        <path id="Path_19114" data-name="Path 19114" d="M9.987,6.8,9.3,4.916,7.653,6.564l.814-.3a.208.208,0,1,1,.144.391L7.573,7.04a.208.208,0,0,1-.267-.123v0L5.562,8.656l4.3-1.585A.208.208,0,0,0,9.987,6.8Z" transform="translate(-5.562 -4.916)" fill="#cecdcd" />
      </g>
    </g>
    <g id="Group_7801" data-name="Group 7801" transform="translate(0.004)">
      <g id="Group_7800" data-name="Group 7800">
        <path id="Path_19115" data-name="Path 19115" d="M9.939,3.394,6.605.061a.209.209,0,0,0-.295,0L.061,6.311a.209.209,0,0,0,0,.295L3.395,9.939A.206.206,0,0,0,3.541,10a.209.209,0,0,0,.147-.061l6.25-6.25a.209.209,0,0,0,0-.3Zm-7.5,2.379-.833.833a.209.209,0,1,1-.295-.295l.833-.833a.209.209,0,0,1,.295.295Zm3.6.267a.787.787,0,0,1-.571.218A1.721,1.721,0,0,1,4.31,5.7a1.909,1.909,0,0,1-.521-.887.871.871,0,0,1,.185-.843.867.867,0,0,1,.843-.185A1.905,1.905,0,0,1,5.7,4.31c.577.576.724,1.336.333,1.727ZM8.691,3.691l-.833.833a.209.209,0,0,1-.295-.295L8.4,3.4a.209.209,0,0,1,.295.295Z" transform="translate(0 0)" fill="#cecdcd" />
      </g>
    </g>
  </svg>)
}

