export default function Phone() {
  return (<svg xmlns="http://www.w3.org/2000/svg" width="9.738" height="10" viewBox="0 0 9.738 10">
    <g id="Group_10748" data-name="Group 10748" transform="translate(-210.134 -299)">
      <path id="XMLID_8_" d="M8.554,4.2A3.024,3.024,0,0,0,5.877,1.315l-.1-.015a1.391,1.391,0,0,0-.227-.025.381.381,0,0,0-.408.342.4.4,0,0,0,.059.31.586.586,0,0,0,.421.181c.042.006.081.011.114.019A2.133,2.133,0,0,1,7.749,4.222c.005.032.008.072.01.115.01.158.03.488.384.488h0a.629.629,0,0,0,.094-.008c.33-.05.32-.351.315-.5a.735.735,0,0,1,0-.1A.141.141,0,0,0,8.554,4.2Z" transform="translate(210.003 299)" fill="#cecdcd" />
      <path id="XMLID_9_" d="M5.468.8c.04,0,.077.006.108.01A3.616,3.616,0,0,1,9.061,4.4a1.293,1.293,0,0,1,.006.132c0,.173.009.532.395.54h.012a.379.379,0,0,0,.286-.109A.6.6,0,0,0,9.866,4.5c0-.037,0-.072,0-.1A4.474,4.474,0,0,0,5.671.006H5.617c-.023,0-.05,0-.08,0S5.462,0,5.421,0a.4.4,0,0,0-.437.408C4.963.761,5.3.786,5.468.8Z" transform="translate(210.003 299)" fill="#cecdcd" />
      <path id="XMLID_10_" d="M8.955,7.258c-.047-.036-.095-.073-.14-.109-.24-.193-.495-.371-.741-.543L7.92,6.5a1.53,1.53,0,0,0-.868-.33,1.125,1.125,0,0,0-.937.593.5.5,0,0,1-.427.259.848.848,0,0,1-.344-.086A4.141,4.141,0,0,1,3.18,4.829c-.2-.452-.136-.748.218-.989a1,1,0,0,0,.549-.877A5.01,5.01,0,0,0,2.184.558a1,1,0,0,0-.681,0A1.97,1.97,0,0,0,.278,1.565,1.874,1.874,0,0,0,.3,3.078,12.206,12.206,0,0,0,2.983,7.155a12.983,12.983,0,0,0,4.063,2.7,2.25,2.25,0,0,0,.4.119l.093.022A.156.156,0,0,0,7.58,10h.013A2.305,2.305,0,0,0,9.507,8.543C9.748,7.864,9.308,7.528,8.955,7.258Z" transform="translate(210.003 299)" fill="#cecdcd" />
      <path id="XMLID_11_" d="M5.8,2.6a.462.462,0,0,0-.486.28.375.375,0,0,0,.016.326A.488.488,0,0,0,5.7,3.4a.87.87,0,0,1,.808.87.411.411,0,0,0,.392.406h0l.046,0c.249-.03.369-.212.358-.543a1.551,1.551,0,0,0-.484-1.049A1.444,1.444,0,0,0,5.8,2.6Z" transform="translate(210.003 299)" fill="#cecdcd" />
    </g>
  </svg>)
}

