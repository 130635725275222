import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import LoadingStatuse from '../../HRM/JobTitle/LoadingStatuse'
import CurrencyModal from './CurrencyModal'
import Empty from "../../Languages/svgs/emptyIcon.svg"
import { FILE_SERVER, showTrans } from '../../../../../constants'
import { trans } from '../../../../../Internationalization'
function CurrencyItem(props) {
  const mode = useSelector((state) => state.currency.mode)
  const { child, lang_id, makeFollower, setLang_id } = props
  const [expand, setExpand] = useState(false)
  const data = useSelector(state => state.currency.data)
  const request_running = useSelector((state) => state.currency.request_running)
  const request_data = useSelector((state) => state.currency.request_data)
  const error = useSelector((state) => state.currency.error)
  const message = useSelector((state) => state.currency.message)

  return (
    <>

      <div level={props.level} className={`${props.languageFollower && props.languageFollower.filter((s) => s.name === child.translations.filter((t) => t.language_code === "en")[0].name).length > 0 && "activated"} parent-info job ${expand ? "expand-line" : ""} ${(props.open) ? "disable-hover" : ""} ${props.accent}`} >
        <LoadingStatuse child={child} request_data={request_data} request_running={request_running} error={error} message={message} />
        {<div onClick={() => { setExpand(!expand); props.makeFollower(props.level, child.translations.filter((t) => t.language_code === "en")[0].name, child.id); setLang_id(child.id); }} className={`${((request_running || error) && (request_data.id === child.id) && "pending")} parent-icon pure outline ${lang_id === child.id && "active-lang"}`}>
          {child.flat_photo_path && child.flat_photo_path.length > 0 ?
            <svg style={{ fill: "transparent" }} data-src={FILE_SERVER + child.flat_photo_path} width="15" height="15"></svg>
            :
            // empty
            <>
              {/* <svg data-src={Empty} width="15" height="15"></svg> */}
              <svg data-src={Empty} width="15" height="15"></svg>

            </>
          }
        </div>


        }
        < div className='parent-row'>
          <div style={{ width: "auto" }} onClick={() => { props.makeFollower(props.level, child.translations.filter((t) => t.language_code === "en")[0].name); setLang_id(child.id); props.Read(child) }} className={`${((request_running || error) && (request_data.id === child.id) && "pending")} parent-name ${lang_id === child.id && "active-lang"}`}>{props.new ? showTrans(child.translations, "en") : showTrans(child.translations, props.lang)}</div>
          <div className={'icon-langs' + " " + `${props.new}`}>
            <div className='icon-lang' onClick={() => { props.makeFollower(props.level, child.translations.filter((t) => t.language_code === "en")[0].name, child.id); setLang_id(child.id); props.Update(child); }}>
              <div className='icon-lang-desc'>
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
                  <defs>
                    <filter id="Ellipse_319" x="0" y="0" width="24" height="24" filterUnits="userSpaceOnUse">
                      <feOffset dy="3" input="SourceAlpha" />
                      <feGaussianBlur stdDeviation="3" result="blur" />
                      <feFlood floodOpacity="0.2" />
                      <feComposite operator="in" in2="blur" />
                      <feComposite in="SourceGraphic" />
                    </filter>
                  </defs>
                  <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Ellipse_319)">
                    <circle id="Ellipse_319-2" data-name="Ellipse 319" cx="3" cy="3" r="3" transform="translate(9 6)" fill="#404040" />
                  </g>
                </svg>
                <div className='icon-lang-desc-bu'>{trans("edit_currencies")}</div>
              </div>
              <svg id="_15x15_photo_back" data-name="15x15 photo back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
                <g data-name="Mask Group 65" >
                  <g id="Group_4666" data-name="Group 4666" transform="translate(0.063 0)">
                    <g id="user" transform="translate(0.258)">
                      <path id="Path_16021" data-name="Path 16021" d="M13.436,24.162l1.583-.366L13.8,22.579Z" transform="translate(-6.428 -9.162)" fill="#8e8e8e" />
                      <path id="Path_16022" data-name="Path 16022" d="M9.213,18.943l.262-1.136v-.047l.006-.014v-.009l.008-.014v-.008L9.5,17.7l.006-.008.011-.012h0l3.531-3.531a5.421,5.421,0,0,0-4.867-3.239A5.239,5.239,0,0,0,4.19,12.762a7.346,7.346,0,0,0-1.668,4.726A13.977,13.977,0,0,0,8.178,19a10.04,10.04,0,0,0,1.035-.055Z" transform="translate(-2.521 -4.985)" fill="#8e8e8e" />
                      <path id="Path_16023" data-name="Path 16023" d="M0,0H5.927V2.156H0Z" transform="translate(7.595 12.882) rotate(-45)" fill="#8e8e8e" />
                      <circle id="Ellipse_318" data-name="Ellipse 318" cx="2.694" cy="2.694" r="2.694" transform="translate(2.963)" fill="#8e8e8e" />
                      <path id="Path_16024" data-name="Path 16024" d="M23.32,15.209a1.078,1.078,0,0,0-1.84-.762L23,15.966A1.07,1.07,0,0,0,23.32,15.209Z" transform="translate(-9.309 -6.137)" fill="#8e8e8e" />
                    </g>
                    <rect id="Rectangle_4536" data-name="Rectangle 4536" width="14.875" height="14.875" transform="translate(0 0.125)" fill="none" />
                  </g>
                </g>
              </svg>
            </div>
          </div>
        </div>

      </div>
      {/* ATTENTION!! ADDING PROPS TO THE NEXT LINE languageFollower */}
      {props.open && ((lang_id === child.id)) && <CurrencyModal Active_Ids={props.Active_Ids} ActiveAdd={() => props.ActiveAdd(data.id)} languageFollower={props.languageFollower} setExpand={(e) => { setExpand(false) }} data={data} {...props} />}

      {/* {SelectedLanguage.name.length>0&&    <DetailsModal languageFollower={props.languageFollower}  edit={edit} setEdit={(e)=>setEdit(e)}  lang={SelectedLanguage} setLang={(e)=>{setAccent(e); setLang(e)}}  update={()=>{selectLanguage(child)}} setExpand={(e)=>setExpand(e)} setopenAccent={(e)=>setopenAccent(e)} child={SelectedLanguage} open={detailsModal} setOpen={(e)=>setDetailModal(e)}/>} */}
    </>
  )
}

export default CurrencyItem