import React from 'react'
import InputText from './inputs/InputText'
import outline from "../../Languages/svgs/outline.svg"
import X from "../../Languages/svgs/xicon.svg"
import fill from "../../Languages/svgs/fill.svg"
import flat from "../../Languages/svgs/flat.svg"
import png from "../../Languages/svgs/png.svg"
import { useDispatch, useSelector } from 'react-redux'
import { FILE_SERVER } from '../../../../../constants'
import { upload } from '../../../../../uploadService'
import { toast } from 'react-toastify'
import Empty from "../../../../../assets/svg/empty.svg"
import LoadingImage from '../../../LoadingImage'
import { trans } from "../../../../../Internationalization"


function DepartmentModal(props) {
  const mode = useSelector(state => state.department.mode)
  const img_upload = useSelector(state => state.department.img_upload)
  const modalData = useSelector(state => state.department.modalData)
  const data = props.data
  const shake = (e) => {
    document.querySelector(".lang-modal.open").classList.add("shake-modal")
    setTimeout(() => {
      document.querySelector(".lang-modal.open").classList.remove("shake-modal")
    }, 400);
  }
  const { open, setOpen, setExpand } = props
  const dispatch = useDispatch()
  const ChangeTrans = (lang_code, value) => {

    let translations = data.translations
    let arr = translations.map((d) => {
      if (d.language_code === lang_code)
        return ({ ...d, name: value })
      else return ({ ...d })
    })
    dispatch({ type: "NEW-DEPARTMENT", payload: { ...data, translations: [...arr] } })
  }

  const ButtonValidate = () => {
    let bool = false;
    if (data.translations.filter(e => e.language_code === "en")[0].short_name && data.translations.filter(e => e.language_code === "en")[0].short_name.length > 0)
      bool = true
    else
      bool = false
    data.translations.forEach((s) => {
      if (s.name.length === 0)
        bool = false
    })
    return bool
  }
  const updateImage = async (file, type) => {
    dispatch({ type: "IMG-UPLOAD-DEPT", payload: { status: true, type: type } })

    let pat = await upload(file)
    let tmp = { ...data }
    tmp[`${type}`] = pat

    dispatch({ type: "NEW-DEPARTMENT", payload: { ...tmp } })
    dispatch({ type: "IMG-UPLOAD-DEPT", payload: { status: false, type: null } })
    dispatch({ type: "LEVEL-DONE-DEPT" })
  }
  const rem = (type) => {
    let tmp = { ...data }
    tmp[`${type}`] = ""

    dispatch({ type: "NEW-DEPARTMENT", payload: { ...tmp } })
  }
  const updatPhoto = (name) => {
    if (mode !== "read") {
      let Image = document.createElement("input")
      Image.onchange = (e) => {
        ;
        updateImage(e.target.files[0], name)
      }
      Image.type = "file"
      Image.hidden = true
      Image.accept = "image/svg+xml"
      Image.style = { position: "absolute", opacity: "0" }
      let i = document.body.appendChild(Image)
      i.click()
    }
  }
  const onDropImage = (file, type) => {
    if (mode !== "read") {
      file.preventDefault();

      if (file.dataTransfer.files.length === 1 && file.dataTransfer.files[0].type === "image/svg+xml") {
        updateImage(file.dataTransfer.files[0], type)
      }
      else {
        toast.error(trans("invalid_svg"))
      }
    }
  }
  const onDragOver = (e) => {
    if (mode !== "read") e.preventDefault()
  }
  const sendRequest = () => {
    if (ButtonValidate()) {
      if (mode === "add") {

        dispatch({ type: "ADD-DEPT", payload: data })
      }
      else {
        dispatch({ type: "UPD-DEPT", payload: data })

      }
    }
    else {
      document.querySelectorAll(".modal-input-section input").forEach((inp) => {
        if (inp.value.length < 2) {
          inp.parentElement.classList.add("shake-modal")
          inp.classList.add("red-bord")
          setTimeout(() => {
            inp.parentElement.classList.remove("shake-modal")
          }, 400);
          setTimeout(() => {
            inp.classList.remove("red-bord")
          }, 1000);
        }
      })
    }
  }
  return (<>
    {mode !== "read" && <div onClick={(e) => shake(e)} className={`lang-modalDisable ${open && "open"}`}></div>}
    <div className={`lang-modal ${open && "open"} ${props.fixModal}`}>
      <div className='modal-top'>
        <div className='modal-top-title'>
          {mode === "add" ?
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
              <defs>
                <clipPath id="clipPath">
                  <rect id="Rectangle_4527" data-name="Rectangle 4527" width="15" height="15" transform="translate(324 333)" fill="none" />
                </clipPath>
              </defs>
              <g id="Mask_Group_16" data-name="Mask Group 16" transform="translate(-324 -333)" clipPath="url(#clipPath)">
                <path id="add-3" d="M13.235,5.714H9.286V1.765a1.786,1.786,0,0,0-3.571,0v3.95H1.765a1.786,1.786,0,0,0,0,3.571h3.95v3.95a1.786,1.786,0,0,0,3.571,0V9.286h3.95a1.786,1.786,0,0,0,0-3.571Z" transform="translate(324 333)" fill="#404040" />
              </g>
            </svg> :
            props.languageFollower.length === 1 ?
              <>
                {data.flat_photo_path ?
                  <svg className='emp-dept' data-src={FILE_SERVER + data.flat_photo_path} width="15" height="15"></svg> :
                  <svg className='emp-dept' data-src={Empty} width="15" height="15"></svg>}
              </>
              :
              <>
                {data.flat_photo_path ?
                  <svg className='emp-dept' data-src={FILE_SERVER + data.flat_photo_path} width="15" height="15"></svg> :
                  <svg className='emp-dept' data-src={Empty} width="23" height="15"></svg>}
              </>

          }
          {/* ATTENTION!! ADDING CONDITION NEXT LINE */}

          {mode === "add" ? <div className='modal-title'>{trans("add_new")} {trans("department")}</div> : <div className='modal-title'>{mode === "update" ? trans("update") : ""} {`${modalData.name}`}{trans("department")}</div>}
        </div>
        {mode !== "read" ? <div className='modal-top-buttons'>
          <div className={`add-button ${!ButtonValidate() && "disabled-button"}`} onClick={() => { sendRequest(); if (ButtonValidate()) { props.Close() } }}>{mode === "add" ? trans("add") : trans("update")}</div>
          <div className='close-button' onClick={() => { setOpen(false); props.Close() }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="9.9" height="9.899" viewBox="0 0 9.9 9.899">
              <g id="Group_4637" data-name="Group 4637" transform="translate(-224.153 -513.36) rotate(45)">
                <line id="Line_962" data-name="Line 962" x1="12" transform="translate(522.5 204.5)" fill="none" stroke="#ff2e2e" strokeLinecap="round" strokeWidth="1" />
                <line id="Line_963" data-name="Line 963" x1="12" transform="translate(528.5 198.5) rotate(90)" fill="none" stroke="#ff2e2e" strokeLinecap="round" strokeWidth="1" />
              </g>
            </svg>
          </div>
        </div> :
          <div className='modal-top-buttons'>
            <div className='icon-lang marg' onClick={() => { props.Add(data); props.ActiveAdd(); setExpand(true); }}>
              <div className='icon-lang-desc'>
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
                  <defs>
                    <filter id="Ellipse_319" x="0" y="0" width="24" height="24" filterUnits="userSpaceOnUse">
                      <feOffset dy="3" input="SourceAlpha" />
                      <feGaussianBlur stdDeviation="3" result="blur" />
                      <feFlood floodOpacity="0.2" />
                      <feComposite operator="in" in2="blur" />
                      <feComposite in="SourceGraphic" />
                    </filter>
                  </defs>
                  <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Ellipse_319)">
                    <circle id="Ellipse_319-2" data-name="Ellipse 319" cx="3" cy="3" r="3" transform="translate(9 6)" fill="#404040" />
                  </g>
                </svg>
                <div className='icon-lang-desc-bu' style={{ whiteSpace: "nowrap", width: "auto", padding: "7px", left: "-37px" }}>{trans("add")} {data.translations.filter((e) => e.language_code === "en")[0].short_name.toUpperCase()} {trans("department")}</div>
              </div>
              <svg className='gray-color' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
                <g id="Mask_Group_16" data-name="Mask Group 16" transform="translate(-324 -333)" clipPath="url(#clipPath)">
                  <path id="add-3" d="M13.235,5.714H9.286V1.765a1.786,1.786,0,0,0-3.571,0v3.95H1.765a1.786,1.786,0,0,0,0,3.571h3.95v3.95a1.786,1.786,0,0,0,3.571,0V9.286h3.95a1.786,1.786,0,0,0,0-3.571Z" transform="translate(324 333)" fill="#8e8e8e" />
                </g>
              </svg>
            </div>
            <div className='icon-lang marg' onClick={() => { props.Update(props.data) }}>
              <div className='icon-lang-desc'>
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
                  <defs>
                    <filter id="Ellipse_319" x="0" y="0" width="24" height="24" filterUnits="userSpaceOnUse">
                      <feOffset dy="3" input="SourceAlpha" />
                      <feGaussianBlur stdDeviation="3" result="blur" />
                      <feFlood floodOpacity="0.2" />
                      <feComposite operator="in" in2="blur" />
                      <feComposite in="SourceGraphic" />
                    </filter>
                  </defs>
                  <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Ellipse_319)">
                    <circle id="Ellipse_319-2" data-name="Ellipse 319" cx="3" cy="3" r="3" transform="translate(9 6)" fill="#404040" />
                  </g>
                </svg>
                {/* ATTENTION!! ADDING CONDITION NEXT LINE */}
                <div className='icon-lang-desc-bu' style={{ whiteSpace: "nowrap", left: "-24px" }}>{trans("edit")}</div>
              </div>
              <svg className='gray-color' id="_15x15_photo_back" data-name="15x15 photo back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
                <g data-name="Mask Group 65" >
                  <g id="Group_4666" data-name="Group 4666" transform="translate(0.063 0)">
                    <g id="user" transform="translate(0.258)">
                      <path id="Path_16021" data-name="Path 16021" d="M13.436,24.162l1.583-.366L13.8,22.579Z" transform="translate(-6.428 -9.162)" fill="#8e8e8e" />
                      <path id="Path_16022" data-name="Path 16022" d="M9.213,18.943l.262-1.136v-.047l.006-.014v-.009l.008-.014v-.008L9.5,17.7l.006-.008.011-.012h0l3.531-3.531a5.421,5.421,0,0,0-4.867-3.239A5.239,5.239,0,0,0,4.19,12.762a7.346,7.346,0,0,0-1.668,4.726A13.977,13.977,0,0,0,8.178,19a10.04,10.04,0,0,0,1.035-.055Z" transform="translate(-2.521 -4.985)" fill="#8e8e8e" />
                      <path id="Path_16023" data-name="Path 16023" d="M0,0H5.927V2.156H0Z" transform="translate(7.595 12.882) rotate(-45)" fill="#8e8e8e" />
                      <circle id="Ellipse_318" data-name="Ellipse 318" cx="2.694" cy="2.694" r="2.694" transform="translate(2.963)" fill="#8e8e8e" />
                      <path id="Path_16024" data-name="Path 16024" d="M23.32,15.209a1.078,1.078,0,0,0-1.84-.762L23,15.966A1.07,1.07,0,0,0,23.32,15.209Z" transform="translate(-9.309 -6.137)" fill="#8e8e8e" />
                    </g>
                    <rect id="Rectangle_4536" data-name="Rectangle 4536" width="14.875" height="14.875" transform="translate(0 0.125)" fill="none" />
                  </g>
                </g>
              </svg>
            </div>
            <div className='close-button' onClick={() => { props.Close() }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="9.9" height="9.899" viewBox="0 0 9.9 9.899">
                <g id="Group_4637" data-name="Group 4637" transform="translate(-224.153 -513.36) rotate(45)">
                  <line id="Line_962" data-name="Line 962" x1="12" transform="translate(522.5 204.5)" fill="none" stroke="#ff2e2e" strokeLinecap="round" strokeWidth="1" />
                  <line id="Line_963" data-name="Line 963" x1="12" transform="translate(528.5 198.5) rotate(90)" fill="none" stroke="#ff2e2e" strokeLinecap="round" strokeWidth="1" />
                </g>
              </svg>
            </div>
          </div>
        }
      </div>
      <div className='modal-body'>
        <div className='modal-icon-section'>
          {/* ATTENTION!! ADDING CONDITION NEXT LINE */}

          {<div className='modal-icon-name'>{trans("dep_icon")}</div>}
          {(props.languageFollower.length === 1 || (props.languageFollower.length === 2 && mode !== "add")) ?
            <div className='modal-icons'>
              {!(img_upload.status && img_upload.type === "flat_photo_path") ? <div className='modal-icon' onDragLeave={(e) => e.target.style.transform = "scale(1)"} onDragEnter={(e) => e.target.style.transform = "scale(1.1)"} onDropCapture={(e) => { ; }} dropzone onDrop={(e) => { onDropImage(e, "flat_photo_path") }} onDragOver={(e) => onDragOver(e)} onContextMenu={(e) => { e.preventDefault(); if (mode !== "read") rem("flat_photo_path") }} onClick={() => updatPhoto("flat_photo_path")}>{data.flat_photo_path && data.flat_photo_path.length > 0 ? <svg data-src={FILE_SERVER + data.flat_photo_path} width="50" height="50"></svg> : <img alt="language-flat" style={{ marginLeft: "5px" }} src={flat} />}</div> : <div className='modal-icon'><LoadingImage /></div>}
              {!(img_upload.status && img_upload.type === "outline_photo_path") ? <div className='modal-icon mar' onDragLeave={(e) => e.target.style.transform = "scale(1)"} onDragEnter={(e) => e.target.style.transform = "scale(1.1)"} onDropCapture={(e) => { ; }} dropzone onDrop={(e) => { onDropImage(e, "outline_photo_path") }} onDragOver={(e) => onDragOver(e)} onContextMenu={(e) => { e.preventDefault(); if (mode !== "read") rem("outline_photo_path") }} onClick={() => updatPhoto("outline_photo_path")}>{data.outline_photo_path && data.outline_photo_path.length > 0 ? <svg data-src={FILE_SERVER + data.outline_photo_path} width="50" height="50"></svg> : <img alt="language-flat" style={{ marginLeft: "5px" }} src={outline} />}</div> : <div className='modal-icon mar'><LoadingImage /></div>}
              {!(img_upload.status && img_upload.type === "fill_photo_path") ? <div className='modal-icon mar' onDragLeave={(e) => e.target.style.transform = "scale(1)"} onDragEnter={(e) => e.target.style.transform = "scale(1.1)"} onDropCapture={(e) => { ; }} dropzone onDrop={(e) => { onDropImage(e, "fill_photo_path") }} onDragOver={(e) => onDragOver(e)} onContextMenu={(e) => { e.preventDefault(); if (mode !== "read") rem("fill_photo_path") }} onClick={() => updatPhoto("fill_photo_path")}>{data.fill_photo_path && data.fill_photo_path.length > 0 ? <svg data-src={FILE_SERVER + data.fill_photo_path} width="50" height="50"></svg> : <img alt="language-flat" src={fill} />}</div> : <div className='modal-icon mar'><LoadingImage /></div>}
              {!(img_upload.status && img_upload.type === "png_photo_path") ? <div className='modal-icon mar' onDragLeave={(e) => e.target.style.transform = "scale(1)"} onDragEnter={(e) => e.target.style.transform = "scale(1.1)"} onDropCapture={(e) => { ; }} dropzone onDrop={(e) => { onDropImage(e, "png_photo_path") }} onDragOver={(e) => onDragOver(e)} onContextMenu={(e) => { e.preventDefault(); if (mode !== "read") rem("png_photo_path") }} onClick={() => updatPhoto("png_photo_path")}>{data.png_photo_path && data.png_photo_path.length > 0 ? <svg data-src={FILE_SERVER + data.png_photo_path} width="50" height="50"></svg> : <img alt="language-flat" src={png} />}</div> : <div className='modal-icon mar'><LoadingImage /></div>}
            </div> :
            <div className='modal-icons'>
              {!(img_upload.status && img_upload.type === "flat_photo_path") ? <div className='modal-icon thir' onDragLeave={(e) => e.target.style.transform = "scale(1)"} onDragEnter={(e) => e.target.style.transform = "scale(1.1)"} onDropCapture={(e) => { ; }} dropzone onDrop={(e) => { onDropImage(e, "flat_photo_path") }} onDragOver={(e) => onDragOver(e)} onContextMenu={(e) => { e.preventDefault(); if (mode !== "read") rem("flat_photo_path") }} onClick={() => updatPhoto("flat_photo_path")}>
                {data.flat_photo_path && data.flat_photo_path.length > 0 ? <svg data-src={FILE_SERVER + data.flat_photo_path} width="70" height="50"></svg> :
                  <img alt="language-flat" style={{ marginLeft: "5px" }} src={flat} />
                }
              </div> :
                <div className='modal-icon thir'><LoadingImage /></div>

              }

            </div>
          }
        </div>
        <div className='modal-input-section'>
          {/* ATTENTION!! ADDING CONDITION NEXT LINE */}

          <div className='modal-icon-name'>{trans("sh_nam_nam")}</div>
          <div className='input-section'>
            <div className='input-holder'>
              <div className='short-input-element' style={{ width: "94px" }}>
                <svg className='lang-input-icon' xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14">
                  <text id="AR" transform="translate(0 11)" fill="#CECDCD" font-size="10" font-family="SegoeUI, Segoe UI" letter-spacing="0.025em"><tspan x="0" y="0">{data.translations.filter((s) => s.language_code === "en")[0].language_code.toUpperCase()}</tspan></text>
                </svg>
                <input readOnly={mode === "read"} className='pad20' onChange={(e) => {
                  if (e.target.value.length <= 5) {
                    dispatch({ type: "NEW-DEPARTMENT", payload: { ...data, translations: [...data.translations.filter((w) => w.language_code !== "en"), { name: data.translations.filter((ed) => ed.language_code === "en")[0].name, short_name: e.target.value, language_code: "en" }] } })
                  }
                }} value={data.translations.filter((sd) => sd.language_code === "en")[0].short_name} type="text" /><img onClick={() => dispatch({ type: "NEW-DEPARTMENT", payload: { ...data, translations: [...data.translations.filter((w) => w.language_code !== "en"), { name: data.translations.filter((ed) => ed.language_code === "en")[0].name, short_name: "", language_code: "en" }] } })} alt="close" src={X} />
              </div>
              <div className='full-input-element pa' style={{ paddingLeft: "10px" }}>
                <InputText disabled={mode === "read"} code={data.translations.filter((t) => t.language_code === "en")[0].language_code} pad={true} clear={() => { ChangeTrans(data.translations.filter((t) => t.language_code === "en")[0].language_code, "") }} lang={data.translations.filter((t) => t.language_code === "en")[0].name} setLang={(e) => { ChangeTrans(data.translations.filter((t) => t.language_code === "en")[0].language_code, e.target.value) }} /></div>
            </div>
            {data.translations.map((t, index) => {
              return (<>
                {t.language_code !== "en" && <div className='input-holder'>
                  <div className='full-input-element' style={{ width: "-webkit-fill-available" }}><InputText disabled={mode === "read"} code={t.language_code} pad={true} clear={() => { ChangeTrans(t.language_code, "") }} lang={t.name} setLang={(e) => { ChangeTrans(t.language_code, e.target.value) }} /></div>
                </div>}
              </>
              )
            })}

          </div>
        </div>
      </div>
    </div>

  </>)
}

export default DepartmentModal