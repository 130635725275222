import React, { memo, useEffect, useState } from "react";
import "./App.css";
import "./assets/css/index.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Loader from "./components/Loader";
import Home from "./pages/Home";
import Sidebar from "./components/Sidebar";
import Chat from "./pages/Chat";
import { useDispatch, useSelector } from "react-redux";
import ChattingScreen from "../chat/ChattingScreen";
import ChatSidebar from "./pages/Chat/components/ChatSidebar";
import Statics from "./pages/Chat/components/statics";
import Statics2 from "./pages/Chat/components/statics2";
import Refresh from "./assets/icons/refresh"
import TransferModal from "./transferModal";
// const userPrefersDark =
//   window.matchMedia &&
//   window.matchMedia("(prefers-color-scheme: dark)").matches;

function App(props) {
  const dispatch = useDispatch()
  let { WAwidth, setWAwidth } = props
  let id = useSelector(state => state.whatsapp?.activeChat) || null;
  let idchat = useSelector(state => state.chat?.activeChat) || null;
  const refresh = useSelector(state => state.whatsapp.refresh)
  const activeChat = useSelector(state => state?.whatsapp?.activeChat);
  const [user, setUser] = useState(null);
  const setId = (ml) => {
    id = ml;
    dispatch({ type: "CHAT_ID", payload: ml });
  };
  let contacts = useSelector(state => state?.whatsapp?.contacts) || null;
  const chats = useSelector(state => state.whatsapp?.chats) || [];
  const roles = useSelector((state) => state.auth.roles)
  var mode = useSelector((state) => state.whatsapp.mode)
  const [showStaticsSidebar, setShowStaticsSidebar] = useState(false)
  const [count, setCount] = useState(null)
  const done = useSelector(state => state.transfer.done)
  // useEffect(() => {
  //   if (userPrefersDark) document.body.classList.add("dark-theme");
  // }, []);
  const [openContacts, setOpen] = useState(false)
  const [openTicketModal, setOpenTicketModal] = useState(false)
  var founds = useSelector((state) => state.whatsapp.founds)
  const [render, setRender] = useState(false)
  useEffect(() => {
    window.addEventListener('resize', function (e) {
      if (window.innerWidth < 1100 && props.viewDisplay) {
        props.make(false)
        setWAwidth(490)
      }
    })
  }, [])
  useEffect(() => {
    if (done > 0) {
      setOpenTicketModal(false)
      dispatch({ type: "modal_closed_ticket" })
    }
  }, [done])
  useEffect(() => {
    setRender(!render)
  }, [founds])
  const change = () => {
    if (props.viewDisplay) {
      props.make(false)
      setWAwidth(490)
    }
    else {
      props.make(true)
      let shades = document.querySelector(".lang-modalDisable")
      setWAwidth(shades?.clientWidth - 400)
    }
  }
  useEffect(() => {
    if (props.openExternal) {
      setOpenTicketModal(true)
      props.setOpenExternal(false)
    }
  }, [])
  const getChatsByMode = (chats) => {
    return chats.filter(chat => chat.instance_name === mode)
  }
  const getLastChatIdByMode = (chats, mode) => {
    let filteredChats = chats.filter(chat => chat.instance_name === mode)
    return filteredChats.filter(one => !one.is_admin)[filteredChats?.filter(one => !one.is_admin)?.length - 1]?.id
  }
  const getLastChatId = (chats) => {
    return chats?.filter(one => !one.is_admin)[chats?.filter(one => !one.is_admin)?.length - 1]?.id
  }
  return (
    <div className="app">
      <textarea style={{ opacity: 0, height: '0px', position: "absolute" }} id="text-copy" />
      <div className="app__mobile-message"> Only available on desktop 😊. </div>

      {/*{chats?.length > 0 && (*/}
      <div id="app-cont" className="app-content">
        <div id="change" className="change-view" onClick={() => change()} />
        <div id="refresh_whatsapp" className="refresh-chats-icon" onClick={() => {
          if (roles.includes("USE_WHATSAPP")) {
            dispatch({ type: "GET_WA_CHATS" })
            dispatch({ type: "GET_WA_ADMIN_CHATS" })
          }
        }} >
          {refresh ? <div id="startup">
            <svg className="spinner-container" width="65px" height="65px" viewBox="0 0 52 52">
              <circle className="path" cx="26px" cy="26px" r="20px" fill="none" strokeWidth="4px" />
            </svg>
          </div> : <Refresh />}
        </div>
        <div className="open_ticket_modal_icon" onClick={() => {
          setOpenTicketModal(!openTicketModal)
        }}>
          {!openTicketModal ? <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
            <g id="Mask_Group_16" data-name="Mask Group 16" transform="translate(-324 -333)">
              <path id="add-3" d="M13.235,5.714H9.286V1.765a1.786,1.786,0,0,0-3.571,0v3.95H1.765a1.786,1.786,0,0,0,0,3.571h3.95v3.95a1.786,1.786,0,0,0,3.571,0V9.286h3.95a1.786,1.786,0,0,0,0-3.571Z" transform="translate(324 333)" fill="#8e8e8e" />
            </g>
          </svg>
            : <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
              <g id="Group_10755" data-name="Group 10755" transform="translate(-1265.412 -106.555)">
                <g id="Group_10756" data-name="Group 10756" transform="translate(1267.885 109.027)">
                  <line id="Line_1113" data-name="Line 1113" y1="9.206" x2="8.483" fill="none" stroke="#8e8e8e" stroke-linecap="round" stroke-width="3.5" />
                  <line id="Line_1114" data-name="Line 1114" x2="7.983" y2="9.206" transform="translate(0.5)" fill="none" stroke="#8e8e8e" stroke-linecap="round" stroke-width="3.5" />
                </g>
              </g>
            </svg>
          }
        </div>
        {(!(props.viewDisplay && !id?.id && !idchat?.id && !openContacts) || (!props.viewDisplay)) ?
          (idchat?.id ? <ChattingScreen WAwidth={WAwidth} dis={props.viewDisplay} active={idchat} /> : <Chat
            WAwidth={WAwidth}
            openContacts={openContacts}
            setOpen={(val) => {
              if (roles?.includes("ACCESS_CONTACT_LIST") || roles?.includes("USER_FULL_ACCESS")) {
                setOpen(val)
              }
            }}
            viewDisplay={props.viewDisplay}
            change={() => props.change()}
            user={user}
            id={id}
            setShowStaticsSidebar={(e) => setShowStaticsSidebar(e)}
          />)
          :
          <Home />
        }
        {openTicketModal ? <TransferModal wideView={props.viewDisplay} Close={() => { setOpenTicketModal(false) }} /> : <></>}
        {showStaticsSidebar ? <ChatSidebar
          heading={contacts.filter((s) => s.id === activeChat?.whatsapp_contact?.id)[0]?.id ?
            (contacts.filter((s) => s.id === activeChat?.whatsapp_contact?.id)[0]?.name?.length > 0 ?
              contacts.filter((s) => s.id === activeChat?.whatsapp_contact?.id)[0]?.name : ((contacts.filter((s) => s.id === activeChat?.whatsapp_contact?.id)[0]?.pushname?.length > 0
                && contacts.filter((s) => s.id === activeChat?.whatsapp_contact?.id)[0]?.pushname)
                || "+" + contacts.filter((s) => s.id === activeChat?.whatsapp_contact?.id)[0]?.number) || ("+" + id))
            : (activeChat?.whatsapp_contact?.name || activeChat?.whatsapp_contact?.number || ("+" + id))
          }
          active={showStaticsSidebar}
          closeSidebar={() => setShowStaticsSidebar(false)}
          isStatics={true}
          count={count}
          setCount={setCount}
        >
          {activeChat.is_hash_automation_system_enabled ? <Statics2 id={activeChat?.id} setCount={(e) => setCount(e)} /> : <Statics id={activeChat?.id} setCount={(e) => setCount(e)} />}
        </ChatSidebar> : (<Sidebar setOpen={(val) => {
          if (roles?.includes("ACCESS_CONTACT_LIST") || roles?.includes("USER_FULL_ACCESS")) {
            setOpen(val)
          }
        }} openContacts={openContacts}
          showContactsButton={roles?.includes("ACCESS_CONTACT_LIST") || roles?.includes("USER_FULL_ACCESS")}
          chats={
            roles?.includes("USER_FULL_ACCESS") ? getChatsByMode(chats) : chats
          }
          mode={mode}
          lastCustomerId={getLastChatIdByMode(chats, "customer_service")}
          lastOperationId={getLastChatIdByMode(chats, "operations")}
          last_id={getLastChatId(chats)}
          setId={(e) => setId(e)}
          setUser={(e) => setUser(e)} />)}
      </div>
      {/*)}*/}
    </div>
  );
}

export default memo(App);
