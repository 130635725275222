import PackageCart from "./svg/package_cart"

export default function PackageFilterExpand(props) {
    const filters = [
        {
            id: 0,
            name: "Recieved",
            color1: "#404040",
            color2: "#F7F8F3",
            tickets_count: props.chat.received_tickets_count,
            tickets_usdt_amount: props.chat.received_tickets_usdt_amount,
            tickets_usd_amount: props.chat.received_tickets_usd_amount
        }, {
            id: 1,
            name: "Waiting Approve",
            color1: "#404040",
            color2: "#404040",
            tickets_count: props.chat.waiting_approve_tickets_count,
            tickets_usdt_amount: props.chat.waiting_approve_tickets_usdt_amount,
            tickets_usd_amount: props.chat.waiting_approve_tickets_usd_amount
        }, {
            id: 2,
            name: "Approved",
            color1: "#007CFF",
            color2: "#007CFF",
            tickets_count: props.chat.approved_tickets_count,
            tickets_usdt_amount: props.chat.approved_tickets_usdt_amount,
            tickets_usd_amount: props.chat.approved_tickets_usd_amount
        }, {
            id: 3,
            name: "Applied",
            color1: "#15A80F",
            color2: "#15A80F",
            tickets_count: props.chat.applied_tickets_count,
            tickets_usdt_amount: props.chat.applied_tickets_usdt_amount,
            tickets_usd_amount: props.chat.applied_tickets_usd_amount
        }
    ]

    // received_tickets_count: 0
    // received_tickets_usdt_amount: 0
    // received_tickets_usd_amount: 0
    // waiting_approve_tickets_count: 0
    // waiting_approve_tickets_usdt_amount: 0
    // waiting_approve_tickets_usd_amount: 0
    // approved_tickets_count: 1
    // approved_tickets_usdt_amount: 59800
    // approved_tickets_usd_amount: 0
    // applied_tickets_count: 0
    // applied_tickets_usdt_amount: 0
    // applied_tickets_usd_amount: 0

    return (<div className="ship_package_filter_expand">
        {filters.map((filter, index) => <div key={index} className="node_parent_expand">
            <div className="ship_package_filter_child_expand">
                <div className="ship_package_filter_child_icon_expand">
                    <PackageCart color1={filter.color1} color2={filter.color2} />
                </div>
                <div className="ship_package_filter_child_name_expand">{filter.name}</div>
                <div className='status_info_info'>
                    <div className='status_info_info_packages'>
                        <div className='status_info_info_packages_num'>
                            {filter.tickets_count}
                        </div>
                    </div>
                    <div className='status_info_info_products'>
                        <div className='status_info_info_products_num'>
                            {filter.tickets_usdt_amount}
                        </div>
                    </div>
                    <div className='status_info_info_products'>
                        <div className='status_info_info_products_num'>
                            USDT
                        </div>
                    </div>
                    <div className='status_info_info_products'>
                        <div className='status_info_info_products_num'>
                            {filter.tickets_usd_amount}
                        </div>
                    </div>
                    <div className='status_info_info_products'>
                        <div className='status_info_info_products_num'>
                            USD
                        </div>
                    </div>
                </div>
            </div>
        </div>)}
    </div>)
}