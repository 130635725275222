export default function ForgetPassWord() {
    return (<>
        <svg
            className="bord"
            xmlns="http://www.w3.org/2000/svg"
            width="350"
            height="50"
            viewBox="0 0 350 50"
        >
            <g
                id="Rectangle_4720"
                data-name="Rectangle 4720"
                fill="#f7f7f7"
                stroke="#ddd"
                strokeWidth="0.5"
            >
                <rect width="350" height="50" rx="15" stroke="none" />
                <rect
                    x="0.25"
                    y="0.25"
                    width="349.5"
                    height="49.5"
                    rx="14.75"
                    fill="none"
                />
            </g>
        </svg>
        <svg
            id="_15x15_photo_back"
            data-name="15x15 photo back"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="15"
            height="15"
            viewBox="0 0 15 15"
        >
            <g id="Mask_Group_213" data-name="Mask Group 213">
                <g id="forgot-password">
                    <path
                        id="Path_19233"
                        data-name="Path 19233"
                        d="M5.01,4.248a2.49,2.49,0,0,1,4.98,0v1.23h1.758V4.248a4.248,4.248,0,0,0-8.5,0v1.23H5.01Z"
                        fill="#8e8e8e"
                    />
                    <path
                        id="Path_19234"
                        data-name="Path 19234"
                        d="M12.48,6.357H2.52A.439.439,0,0,0,2.08,6.8v7.764A.439.439,0,0,0,2.52,15H12.48a.439.439,0,0,0,.439-.439V6.8A.439.439,0,0,0,12.48,6.357ZM7.5,13.2a.439.439,0,1,1,.439-.439A.439.439,0,0,1,7.5,13.2Zm1.122-2.69a1.553,1.553,0,0,1-.683.418v.339a.439.439,0,0,1-.879,0v-.714a.439.439,0,0,1,.439-.439.669.669,0,0,0,.668-.7.67.67,0,0,0-.654-.632H7.5a.668.668,0,0,0-.662.575.681.681,0,0,0-.007.094.439.439,0,0,1-.879,0,1.547,1.547,0,0,1,3.093-.082A1.535,1.535,0,0,1,8.622,10.508Z"
                        fill="#8e8e8e"
                    />
                </g>
            </g>
        </svg>
    </>)
}