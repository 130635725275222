import { memo } from "react";
import store from "../../../../../../redux/store";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
// import Spinner from "../../../../Spinner";
import { toast } from "react-toastify";
import Document from "./document";
import TicketIcon from "./TicketIcon";
import User from "../svg/user";
import Id from "../svg/ID";
import Cash from "../svg/cash";
import date from "date-and-time";
import TronScan from "./tronScanFloating";
import TopSpinner from "./topSpinner";
import Rs from "./rs";
import { pusher } from "../../../../../../redux/chat/constants";
import { useSelector } from "react-redux";
import ProfileNo from "../../../assets/images/profileNo.png";
import { REACT_APP_ACCOUNTING_URL, REACT_APP_BASE_FILE_URL } from "../../../../../../urls";

const Statics2 = ({ id, setCount }) => {
    const employees = useSelector(state => state.employee.employee)
    const contacts = useSelector((state) => state.whatsapp.contacts)
    const roles = useSelector((state) => state.auth.roles)
    const getDiff = (dat) => {
        let no = new Date();
        let d = new Date(dat);
        let days = parseInt((no.getTime() - d.getTime()) / (1000 * 60 * 60 * 24)).toFixed(0)
        let hrs = parseInt((no.getTime() - d.getTime()) / (1000 * 60 * 60) - days * 24).toFixed(0)
        let min = parseInt(((no.getTime() - d.getTime()) / (1000 * 60) - hrs * 60 - days * 24 * 60)).toFixed(0)

        if (days > 0) {
            let now = new Date();
            let nowString = `${now.getFullYear()}-${(now.getMonth() + 1) > 9 ? (now.getMonth() + 1).toString() : ("0" + (now.getMonth() + 1).toString())}-${(now.getDate()) > 9 ? now.getDate() : "0" + parseInt(now.getDate()).toString()}`
            var Days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

            let day = new Date(d)
            day = Days[day.getDay()]
            if (d === nowString)
                return ("Today")
            else if (((new Date(nowString) - new Date(d)) > 86400000) && ((new Date(nowString) - new Date(d)) < 172800000)) {
                return ("Yesterday")
            }
            else if ((new Date(nowString) - new Date(d)) < (86400000 * 6))
                return (day)
            else
                return (date.format(d, "DD-MM-YYYY"))
        } else {
            return date.format(d, "HH:mm")
        }
    }
    const getDiffBetween = ({ date1, date2, date2Date }) => {
        if (date2Date) {
            let first = new Date(date1)
            let second = new Date(date2)
            let diff = (second.getTime() / 1000) - (first.getTime() / 1000)
            let days = parseInt(diff / (60 * 60 * 24)).toFixed(0)
            let hrs = parseInt(diff / (60 * 60) - days * 24).toFixed(0)
            let min = parseInt((diff / (60) - hrs * 60 - days * 24 * 60)).toFixed(0)
            let seconds = parseInt((diff - min * 60 - hrs * 60 - days * 24 * 60)).toFixed(0)

            if (days > 0) {
                return `${days} day`
            } else if (hrs > 0) {
                return `${hrs} hrs`
            }
            else if (min > 0) {
                return `${min} min`
            } else {
                return `${seconds} sec`
            }
        } else {
            let first = new Date(date1)
            let second = new Date(date2)
            let diff = (second.getTime() / 1000) - (first.getTime() / 1000)
            let days = parseInt(diff / (60 * 60 * 24)).toFixed(0)
            let hrs = parseInt(diff / (60 * 60) - days * 24).toFixed(0)
            let min = parseInt((diff / (60) - hrs * 60 - days * 24 * 60)).toFixed(0)
            let seconds = parseInt((diff - min * 60 - hrs * 60 - days * 24 * 60)).toFixed(0)

            if (days > 0) {
                return `${days} day`
            } else if (hrs > 0) {
                return `${hrs} hrs`
            }
            else if (min > 0) {
                return `${min} min`
            } else {
                return `${seconds} sec`
            }
        }
    }
    const getMessageTime = (created_at) => {
        let no = new Date();
        let d = new Date(created_at);
        return date.format(new Date(d), "HH:mm");
    }
    // PENDING: 1
    // APPROVED: 2
    // REJECT: 3

    const [approved_hash_count, setapproved_hash_count] = useState(null)
    const [rejected_hash_count, setrejected_hash_count] = useState(null)
    const [pending_hash_count, setpending_hash_count] = useState(null)
    const [pending_hash_ammount, setpending_hash_ammount] = useState(null)
    const [approved_hash_ammount, setapproved_hash_ammount] = useState(null)
    const [rejected_hash_ammount, setrejected_hash_ammount] = useState(null)
    const [openPreview, setOpenPreview] = useState(false)
    const [openPreview2, setOpenPreview2] = useState(false)
    const [total_amount, settotal_amount] = useState(null)
    const [tickets, setTickets] = useState([])
    // const [active, setActive] = useState(null)
    const [activeFilter, setActiveFilter] = useState(1)
    const [activeClick, setActiveClick] = useState(null)
    const [total, setTotal] = useState(null)
    const [paging, setPaging] = useState(1)
    const [waiting, setWaiting] = useState(false)
    const [toShow, setShow] = useState([])
    const [newElement, setNewElement] = useState(null)
    const [filterReject, setFilterReject] = useState(false)
    const [filterApprove, setFilterApprove] = useState(false)
    const [filterWait, setFilterWait] = useState(false)
    const [changedTicket, setChangedTicket] = useState(null)
    const [showEmpTip, setShowEmpTip] = useState(null)
    const [update, setUpdate] = useState(1)
    const [news, setNews] = useState(0)

    useEffect(() => {
        const interval = setInterval(() => setUpdate(update + 1), 1000);
        return () => clearInterval(interval);
    }, [update])

    let user = useSelector(state => state.auth.user)
    let chs = pusher.subscribe(`presence-whatsapp-user-${user.id}-channel`)
    useEffect(() => {
        if (newElement) {
            setTimeout(() => {
                setNewElement(null)
            }, 1200)
        }
    }, [newElement])

    useEffect(() => {
        if (changedTicket !== null) {
            setTimeout(() => {
                setChangedTicket(null)
            }, 1200)
        }
    }, [changedTicket])

    const updateStats = (hashTicketnew, hashTicketold) => {
        if (hashTicketold.ticket_status_id === 1 && hashTicketnew.ticket_status_id === 5) {
            setrejected_hash_ammount(rejected_hash_ammount + parseInt(hashTicketnew.amount))
            setrejected_hash_count(rejected_hash_count + 1)
            settotal_amount(total_amount + parseInt(hashTicketnew.amount))
            setCount(total + 1)
            setTotal(total + 1)
        }
        if (hashTicketold.ticket_status_id === 1 && hashTicketnew.ticket_status_id === 2) {
            setpending_hash_count(pending_hash_count + 1)
            setpending_hash_ammount(pending_hash_ammount + parseInt(hashTicketnew.amount))
            setapproved_hash_count(approved_hash_count + 1)
            setapproved_hash_ammount(approved_hash_ammount + parseInt(hashTicketnew.amount))
            settotal_amount(total_amount + parseInt(hashTicketnew.amount))
            setCount(total + 1)
            setTotal(total + 1)
        }
        if (hashTicketold.ticket_status_id === 2 && (hashTicketnew.ticket_status_id === 3)) {
            setpending_hash_count(pending_hash_count - 1)
            setpending_hash_ammount(pending_hash_ammount - parseInt(hashTicketnew.amount))
        }
        setChangedTicket(hashTicketnew.id)
    }
    const addStats = (hashTicket) => {
        if (hashTicket.ticket_status_id === 5) {
            setrejected_hash_ammount(rejected_hash_ammount + parseInt(hashTicket.amount))
            setrejected_hash_count(rejected_hash_count + 1)
            settotal_amount(total_amount + parseInt(hashTicket.amount))
            setCount(total + 1)
            setTotal(total + 1)
        } else if (hashTicket.ticket_status_id === 3) {
            setapproved_hash_count(approved_hash_count + 1)
            setapproved_hash_ammount(approved_hash_ammount + parseInt(hashTicket.amount))
            settotal_amount(total_amount + parseInt(hashTicket.amount))
            setCount(total + 1)
            setTotal(total + 1)
        } else if (hashTicket.ticket_status_id === 2) {
            setpending_hash_count(pending_hash_count + 1)
            setpending_hash_ammount(pending_hash_ammount + parseInt(hashTicket.amount))
            setapproved_hash_count(approved_hash_count + 1)
            setapproved_hash_ammount(approved_hash_ammount + parseInt(hashTicket.amount))
            settotal_amount(total_amount + parseInt(hashTicket.amount))
            setCount(total + 1)
            setTotal(total + 1)
        }
    }
    useEffect(() => {
        chs.bind("HashTicketEvent", (data) => {
            let whatsappChatId = data.whatsappChatId
            let hashTicket = data.ticket
            if (id === whatsappChatId) {
                if (activeFilter === 2) {
                    setNews(news + 1)
                } else {
                    if (tickets.filter(tik => tik.id === hashTicket.id)?.length > 0) {
                        updateStats(hashTicket, tickets.filter(tik => tik.id === hashTicket.id)[0])
                        setTickets(tickets.map(ele => ele.id === hashTicket.id ? hashTicket : ele))
                        getStaticsByFilter(filterWait, filterApprove, filterReject, tickets.map(ele => ele.id === hashTicket.id ? hashTicket : ele))
                    } else {
                        addStats(hashTicket)
                        setTickets([hashTicket, ...tickets])
                        getStaticsByFilter(filterWait, filterApprove, filterReject, [hashTicket, ...tickets])
                        setNewElement(hashTicket.id)
                    }
                }
            }
        })
        return (() => {
            chs.unbind("HashTicketEvent")
        }
        )
    }, [chs,
        tickets,
        approved_hash_ammount,
        pending_hash_ammount,
        rejected_hash_ammount,
        approved_hash_count,
        pending_hash_count,
        filterApprove,
        filterReject,
        filterWait,
        total,
        total_amount,
        activeFilter,
        news
    ])

    const getStatics = async ({ id, from, to, page, weLoadingMore }) => {
        setWaiting(true)
        const token = store.getState()?.whatsapp?.userTokenWhatsapp;
        const base_user = REACT_APP_ACCOUNTING_URL;
        let AxiosInstace = axios.create({
            baseURL: `${base_user}`,
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
        })
        // PENDING: 1
        // APPROVED: 2
        // REJECT: 3

        try {
            let response = await AxiosInstace.get(`/api/v1/tickets/hash_tickets/${id}?perPage=200&page=${page}&filter[created_to]=${from}&filter[created_from]=${to}`)
            setapproved_hash_count(response.data?.data?.total_success_count)
            setapproved_hash_ammount(response.data?.data?.total_success_amount)
            setrejected_hash_ammount(response.data?.data?.total_failed_amount)
            setrejected_hash_count(response.data?.data?.total_failed_count)
            setpending_hash_ammount(response.data?.data?.total_pending_amount)
            setpending_hash_count(response.data?.data?.total_pending_count)
            settotal_amount(response.data?.data?.total_failed_amount + response.data?.data?.total_success_amount)
            setCount(response.data?.data?.total_success_count + response.data?.data?.total_failed_count)
            setTotal(response.data?.data?.total_success_count + response.data?.data?.total_failed_count)
            if (weLoadingMore) {
                setTickets(response.data?.data?.data)
                getStaticsByFilter(filterWait, filterApprove, filterReject, response.data?.data?.data)
            } else {
                setTickets(response.data?.data?.data)
                getStaticsByFilter(filterWait, filterApprove, filterReject, response.data?.data?.data)
            }
            setPaging(page)
            setWaiting(false)
        }
        catch (e) {
            toast.error("request timed out!!")
            setapproved_hash_count(0)
            setrejected_hash_count(0)
            settotal_amount(0)
            setTotal(0)
            setCount(0)
            setpending_hash_ammount(0)
            setpending_hash_count(0)
            setWaiting(false)
            setTickets([])
        }
    }
    useEffect(() => {
        if (activeFilter === 1) {
            let to = new Date()
            to.setHours(0)
            to.setMinutes(0)
            to.setSeconds(0)
            getStatics({ id, from: new Date().toISOString(), to: to.toISOString(), page: 1 }).then(() => {
                setNews(0)
            })
        } else {
            getStatics({ id, from: new Date().toISOString(), to: new Date(99, 5, 24).toISOString(), page: 1 })
        }
    }, [id])
    const getStaticsByFilter = (wait, approve, reject, Tickets) => {
        if (wait && !approve && !reject) {
            setShow(Tickets.filter(one => one.ticket_status_id === 2))
        }
        if (!wait && !approve && reject) {
            setShow(Tickets.filter(one => one.ticket_status_id === 5))
        }
        if (!wait && !approve && !reject) {
            setShow(Tickets)
        }
        if (wait && approve && !reject) {
            setShow(Tickets.filter(one => (one.ticket_status_id === 2 || one.ticket_status_id === 3)))
        }
        if (!wait && approve && !reject) {
            setShow(Tickets.filter(one => one.ticket_status_id === 3))
        }
    }
    return (
        <div className="statics" onWheel={() => {

        }}>
            {(waiting) && <div className="ghabasheh">
                <TopSpinner />
            </div>
            }
            {((approved_hash_count !== null) && (rejected_hash_count !== null) && (total_amount !== null)) ? <>
                <div className="statics_element_filter">
                    <div className={activeFilter === 1 ? "statics_element_filter_button_active" : "statics_element_filter_button"} onClick={() => {
                        if (!waiting) {
                            if (activeFilter === 1) {
                                setActiveFilter(null)
                                getStatics({ id, from: new Date().toISOString(), to: new Date(99, 5, 24).toISOString(), page: 1 })
                                setNews(0)

                            } else {
                                setActiveFilter(1)
                                let to = new Date()
                                to.setHours(0)
                                to.setMinutes(0)
                                to.setSeconds(0)
                                getStatics({ id, from: new Date().toISOString(), to: to.toISOString(), page: 1 })
                                setNews(0)

                            }
                        }

                    }}>Today {news > 0 && news}</div>
                    <div className={activeFilter === 2 ? "statics_element_filter_button_active" : "statics_element_filter_button"} onClick={() => {
                        if (!waiting) {
                            if (activeFilter === 2) {
                                setActiveFilter(null)
                                getStatics({ id, from: new Date().toISOString(), to: new Date(99, 5, 24).toISOString(), page: 1 })
                                setNews(0)
                            } else {
                                setActiveFilter(2)
                                let from = new Date()
                                let to = date.addDays(new Date(), -1)
                                from.setHours(0)
                                from.setMinutes(0)
                                from.setSeconds(0)
                                to.setHours(0)
                                to.setMinutes(0)
                                to.setSeconds(0)
                                getStatics({ id, from: from.toISOString(), to: to.toISOString(), page: 1 })
                            }
                        }

                    }}>Yesterday</div>
                    <div className={activeFilter === 3 ? "statics_element_filter_button_active" : "statics_element_filter_button"} onClick={() => {
                        if (!waiting) {
                            if (activeFilter === 3) {
                                setActiveFilter(null)
                                getStatics({ id, from: new Date().toISOString(), to: new Date(99, 5, 24).toISOString(), page: 1 })
                                setNews(0)
                            } else {
                                setActiveFilter(3)
                                let to = date.addDays(new Date(), -7)
                                to.setHours(0)
                                to.setMinutes(0)
                                to.setSeconds(0)
                                getStatics({ id, from: new Date().toISOString(), to: to.toISOString(), page: 1 })
                                setNews(0)
                            }
                        }

                    }}>Week</div>
                    <div className={activeFilter === 4 ? "statics_element_filter_button_active" : "statics_element_filter_button"} onClick={() => {
                        if (!waiting) {
                            if (activeFilter === 4) {
                                setActiveFilter(null)
                                getStatics({ id, from: new Date().toISOString(), to: new Date(99, 5, 24).toISOString(), page: 1 })
                                setNews(0)
                            } else {
                                setActiveFilter(4)
                                let to = date.addDays(new Date(), -30)
                                to.setHours(0)
                                to.setMinutes(0)
                                to.setSeconds(0)
                                getStatics({ id, from: new Date().toISOString(), to: to.toISOString(), page: 1 })
                                setNews(0)
                            }
                        }

                    }}>Month</div>
                    {/* <div className="statics_element_filter_button_active">Custom</div> */}
                </div>
                <div className="statics_element">
                    <div className="ticket_and_total">
                        <div className="ticket_and_total1">Ticket </div>
                        {(((activeFilter === 1 || roles?.includes("VIEW_TOTAL_TICKETS_AMMOUNT")) && !waiting) || roles?.includes("USER_FULL_ACCESS")) ? <div className="ticket_and_total2"> Total Amount</div> : <div></div>}
                    </div>
                    <div className="ticket_and_total_data">
                        <div className="ticket_and_total_data1">
                            {!waiting ? total : <div className="dot-floating-white"></div>}
                        </div>
                        {(((activeFilter === 1 || roles?.includes("VIEW_TOTAL_TICKETS_AMMOUNT")) && !waiting) || roles?.includes("USER_FULL_ACCESS")) ? <div className="ticket_and_total_data2"> {!waiting ? total_amount : <div className="dot-floating-white"></div>}</div> : <div></div>}
                    </div>
                </div>
                <div className="statics_element_2">
                    <div className={filterApprove ? "recieved_tickets" : "recieved_tickets_closed"} onClick={() => {
                        getStaticsByFilter(false, !filterApprove, false, tickets)
                        setFilterReject(false)
                        setFilterWait(false)
                        setFilterApprove(!filterApprove)
                    }}>
                        <div className="recieved_tickets_title"> {"Recieved Ticket"}</div>
                        <div className="recieved_tickets_data">
                            <div className="recieved_tickets_data_1"> {!waiting ? approved_hash_count : <div className="dot-floating"></div>} </div>
                            {((activeFilter === 1 || roles?.includes("VIEW_TOTAL_APPROVED_TICKETS_AMMOUNT")) && !waiting) || roles?.includes("USER_FULL_ACCESS") ? <div className="recieved_tickets_data_2"> {!waiting ? approved_hash_ammount : <div className="dot-floating"></div>}</div> : <div></div>}
                        </div>
                    </div>
                    <div className={!filterReject ? (!filterWait ? "waiting_tickets_active" : "waiting_tickets") : "waiting_tickets_closed"} onClick={() => {
                        getStaticsByFilter(!filterWait, filterApprove, false, tickets)
                        setFilterReject(false)
                        setFilterWait(!filterWait)
                    }}>
                        <div className="recieved_tickets_title">{!filterReject ? "Waiting Approve" : "Waiting"} </div>
                        <div className="recieved_tickets_data">
                            <div className="recieved_tickets_data_1"> {!waiting ? pending_hash_count : <div className="dot-floating"></div>} </div>

                            {((!filterReject && (activeFilter === 1 || roles?.includes("VIEW_TOTAL_WAITING_TICKETS_AMMOUNT"))) && !waiting) || (roles?.includes("USER_FULL_ACCESS") && !filterReject) ? <div className="recieved_tickets_data_2"> {!waiting ? pending_hash_ammount : <div className="dot-floating"></div>}</div> : <div></div>}
                        </div>
                    </div>
                    <div className={filterReject ? "rejected_tickets" : "rejected_tickets_closed"} onClick={() => {
                        getStaticsByFilter(false, false, !filterReject, tickets)
                        setFilterWait(false)
                        setFilterApprove(false)
                        setFilterReject(!filterReject)
                    }}>
                        <div className="recieved_tickets_title">{filterReject ? "Rejected Tickets" : "Reject"}</div>
                        <div className="recieved_tickets_data">
                            <div className="recieved_tickets_data_1"> {!waiting ? rejected_hash_count : <div className="dot-floating"></div>} </div>

                            {((filterReject && (activeFilter === 1 || roles?.includes("VIEW_TOTAL_REJECTED_TICKETS_AMMOUNT"))) && !waiting) || (roles?.includes("USER_FULL_ACCESS") && filterReject) ? <div className="recieved_tickets_data_2"> {!waiting ? rejected_hash_ammount : <div className="dot-floating"></div>}</div> : <div></div>}
                        </div>
                    </div>
                </div>
                {!waiting ? toShow.map((ticket, index) => <>
                    <div className={`ticket_open_close ${(index === 0 && newElement === ticket.id) ? "ticket_animate_new_elem" : ""}
                    ${(ticket.ticket_status_id === 1) ? " ticket_open_close_tron" : ""}
                    ${(ticket.ticket_status_id === 3) ? " ticket_open_close_approved" : ""}
                    ${(ticket.ticket_status_id === 2) ? " ticket_open_close_waiting" : ""}
                    ${(ticket.ticket_status_id === 5) ? " ticket_open_close_rejected" : ""}

                    ${(ticket.ticket_status_id === 3 && activeClick === index) ?
                            "status10001" : (
                                (ticket.ticket_status_id === 3 && activeClick === index) ? "status10002" :
                                    (ticket.ticket_status_id === 5 && activeClick === index) ? "status10003" :
                                        (ticket.ticket_status_id === 1 && activeClick === index) ? "status10004" :
                                            "status10005"
                            )}
                    `}
                    >
                        <div className="ticket_open_close_title"
                            onClick={() => {
                                if (activeClick === index) {
                                    setActiveClick(null)
                                    setOpenPreview(false)
                                } else {
                                    setActiveClick(index)
                                }
                            }}>
                            <div className="ticket_open_close_title_element" style={{ marginRight: "19px", maxWidth: "87px", overflow: "hidden" }}>{ticket.name}</div>
                            <div className="ticket_open_close_title_element" style={{ fontWeight: "600", fontSize: "14px" }}>{ticket.amount}</div>
                            {ticket.error_message && <div className="ticket_open_close_title_element" style={{ fontSize: "14px", color: "#FF7171" }}> {ticket.error_message}</div>}
                            {(!ticket.error_message && ticket.request_number) && <div className={`ticket_open_close_title_element ${changedTicket === ticket.id ? "ticket_open_close_title_element_animate" : ""}`}>
                                {ticket.approved_by_user_id ? <div
                                    onMouseEnter={() => setShowEmpTip(index)}
                                    onMouseLeave={() => setShowEmpTip(null)}
                                    style={{
                                        width: "15px", height: "15px", borderRadius: "20px", marginRight: "5px", position: "relative"
                                    }}>
                                    {showEmpTip === index && <div className="popup_emp_name">{employees.filter(one => one.id === ticket.approved_by_user_id)[0]?.full_name}</div>}
                                    <img
                                        style={{
                                            width: "15px", height: "15px", borderRadius: "20px"
                                        }}
                                        alt=""
                                        src={REACT_APP_BASE_FILE_URL + employees.filter(one => one.id === ticket.approved_by_user_id)[0]?.avatar} />
                                </div> : (ticket.approved_by_contact_id ? <div
                                    onMouseEnter={() => setShowEmpTip(index)}
                                    onMouseLeave={() => setShowEmpTip(null)}
                                    style={{
                                        width: "15px", height: "15px", borderRadius: "20px", marginRight: "5px", position: "relative"
                                    }}>
                                    {showEmpTip === index && <div className="popup_emp_name">{
                                        contacts.filter(one => one.id === ticket.approved_by_contact_id)[0]?.name ||
                                        contacts.filter(one => one.id === ticket.approved_by_contact_id)[0]?.pushname ||
                                        contacts.filter(one => one.id === ticket.approved_by_contact_id)[0]?.number
                                    }</div>}
                                    <img
                                        style={{
                                            width: "15px", height: "15px", borderRadius: "20px"
                                        }}
                                        alt=""
                                        src={contacts.filter(one => one.id === ticket.approved_by_contact_id)[0]?.profile_photo || ProfileNo} />
                                </div> : <></>)
                                }
                                {ticket.request_number}
                            </div>}
                            {((ticket.ticket_status_id === 2) && update) &&
                                <div className={`ticket_open_close_title_element dsjfsdkjlgndflkjgn${update}`} style={{ fontSize: "10px", color: "#4A71F2" }}><Rs /> {getDiffBetween({ date1: ticket.created_at, date2: new Date(), date2Date: true })}</div>}
                            {((ticket.ticket_status_id === 1)) &&
                                <div className="ticket_open_close_title_element" style={{ fontSize: "14px", color: "#de7600" }}> Waiting Scan</div>}
                            <div className={`ticket_open_close_title_element ${(changedTicket === ticket.id) ? "ticket_animate_new_elem" : ""}`}>
                                {(ticket.ticket_status_id === 3) ?
                                    <>{getDiff(ticket.created_at)} / {getDiffBetween({ date1: ticket.created_at, date2: ticket.approved_at, date2Date: false })}</>
                                    : getDiff(ticket.created_at)}
                            </div>
                        </div>
                        {((activeClick === index)) && <div id={`sd${ticket.id}`} className="ticket_open_close_data">
                            {/* <div className="hash_sender_name">
                                <Waicon />
                                +44779227733882
                            </div> */}
                            <div className="name_account_ammount">
                                <div className="inner_item">
                                    <User />
                                    Name: {ticket.name}
                                </div>
                                <div className="inner_item">
                                    <Id />
                                    Account Number: {ticket.account_number}
                                </div>
                                <div className="inner_item">
                                    <Cash />
                                    Amount: <div style={{ fontSize: "14px", fontFamily: "SF-Pro-Rounded", fontWeight: 600, marginLeft: "3px" }}>{ticket.amount}</div>
                                </div>
                            </div>
                            <div className="hash_area_prime_plus">
                                <div className="hash_area_prime_plus_icons">
                                    <TicketIcon />
                                    {ticket.hash_transaction_id && <div onClick={() => setOpenPreview(true)} onMouseEnter={() => setOpenPreview2(true)} onMouseLeave={() => setOpenPreview2(false)}>
                                        <Document />
                                    </div>}
                                </div>
                                <div className="hash_area_prime_plus_inner">
                                    <div className="hash_area_prime_plus_inner_title">Hash:</div>
                                    <div className="hash_area_prime_plus_inner_data">
                                        {ticket.hash}
                                    </div>
                                </div>
                            </div>
                            {(openPreview || openPreview2) ? <TronScan ids={`d${ticket.id}`} hash_transaction_id={ticket?.hash_transaction_id} setOpenPreview={setOpenPreview} setOpenPreview2={setOpenPreview2}
                                getMessageTime={() => getMessageTime(ticket.created_at)} /> : <></>}
                        </div>}
                    </div>
                </>) : <>

                </>}
                {/* {(tickets.length < total && !waiting) ? <div style={{
                    backgroundColor: "#787171",
                    color: "#2e2828",
                    width: "50%",
                    height: "30px",
                    margin: "10px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "10px",
                    cursor: "pointer"
                }} onClick={() => {
                    if (activeFilter === 1) {
                        let to = date.addDays(new Date(), -1)
                        getStatics({ id, from: date.format(new Date(), "YYYY-MM-DD"), to: date.format(to, "YYYY-MM-DD"), page: paging + 1, weLoadingMore: true })
                    } else if (activeFilter === 2) {
                        let to = date.addDays(new Date(), -7)
                        getStatics({ id, from: date.format(new Date(), "YYYY-MM-DD"), to: date.format(to, "YYYY-MM-DD"), page: paging + 1, weLoadingMore: true })
                    } else if (activeFilter === 3) {
                        let to = date.addDays(new Date(), -30)
                        getStatics({ id, from: date.format(new Date(), "YYYY-MM-DD"), to: date.format(to, "YYYY-MM-DD"), page: paging + 1, weLoadingMore: true })
                    } else {
                        getStatics({ id, from: date.format(new Date(), "YYYY-MM-DD"), to: new Date(99, 5, 24), page: paging + 1, weLoadingMore: true })
                    }
                }} >Load More</div> : <>{tickets.length < total ? <></> : <></>}</>} */}
            </> : <div>
                <></>
            </div>}

        </div>
    );
};

export default memo(Statics2);
