export default function TicketIcon() {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g id="Group_11714" data-name="Group 11714" transform="translate(-1296 -105)">
            <g id="Group_11713" data-name="Group 11713">
                <g id="Group_11706" data-name="Group 11706">
                    <g id="Group_11705" data-name="Group 11705">
                        <g id="Group_11704" data-name="Group 11704">
                            <g id="Group_11707" data-name="Group 11707" transform="translate(1296 105)">
                                <g id="post" transform="translate(0.484)">
                                    <path id="Path_21428" data-name="Path 21428" d="M13.454,1.643V16.261H9.695l-.019-.005L13.243,3.971,5.225,1.643Zm0,0" transform="translate(5.222 1.643)" fill="#ff5d5d" />
                                    <g id="Group_11712" data-name="Group 11712">
                                        <g id="Group_11711" data-name="Group 11711">
                                            <path id="Path_21429" data-name="Path 21429" d="M9.453,2.279l.82.238,8.018,2.328L14.724,17.13l-.509,1.753L.176,14.808,4.252.769l3.583,1.04Zm0,0" transform="translate(0.174 0.77)" fill="#ffd6d6" />
                                        </g>
                                    </g>
                                    <path id="Path_21430" data-name="Path 21430" d="M8.894,0H8.747a1.1,1.1,0,0,0-1.1,1.077V2.115L4.514,1.2a.342.342,0,0,0-.426.237L.015,15.479a.348.348,0,0,0,.238.431l14.04,4.076a.35.35,0,0,0,.265-.029.344.344,0,0,0,.166-.206l.435-1.5h3.518a.359.359,0,0,0,.356-.352V3.285a.34.34,0,0,0-.356-.329H10.5l-.505-.169V1.077A1.093,1.093,0,0,0,8.894,0ZM8.341,1.077A.4.4,0,0,1,8.747.7h.147a.4.4,0,0,1,.4.382V2.584l-.956-.267Zm7.02,16.484L18.338,7.332V17.561ZM18.338,5.212l-5.445-1.56h5.445ZM10.359,3.619l7.68,2.231L14.567,17.8l-.413,1.419L.783,15.34,4.673,1.968,9.3,3.308V5.681a.394.394,0,0,1-.395.387.388.388,0,0,1-.387-.387V4.236a.348.348,0,1,0-.7,0V5.681a1.072,1.072,0,0,0,.313.765,1.091,1.091,0,0,0,1.86-.765V3.51Zm0,0" transform="translate(-0.001)" fill="#083863" />
                                </g>
                                <g id="Group_11709" data-name="Group 11709">
                                    <g id="Group_11708" data-name="Group 11708">
                                        <rect id="Rectangle_5170" data-name="Rectangle 5170" width="20" height="20" fill="none" />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
    )
}