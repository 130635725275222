import React from 'react'

function Images() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">

    <g id="_15x15_photo_back" data-name="15x15 photo back">
      <g id="image-gallery" transform="translate(0 0.625)">
        <g id="Group_7687" data-name="Group 7687" transform="translate(0 1.875)">
          <g id="Group_7686" data-name="Group 7686" transform="translate(0 2.458)">
            <path id="Path_18951" data-name="Path 18951" d="M3.906,12.188a2.34,2.34,0,0,1-2.214-1.594l-.022-.072a2.28,2.28,0,0,1-.108-.678V5.583L.046,10.644a1.419,1.419,0,0,0,1,1.722l9.665,2.588a1.433,1.433,0,0,0,.36.046,1.393,1.393,0,0,0,1.351-1.022l.563-1.791Z" transform="translate(0 -5.583)" fill="#8e8e8e"/>
          </g>
          <path id="Path_18952" data-name="Path 18952" d="M5.625,5.625a1.25,1.25,0,1,0-1.25-1.25A1.251,1.251,0,0,0,5.625,5.625Z" transform="translate(0 -3.125)" fill="#8e8e8e"/>
        </g>
        <path id="Path_18953" data-name="Path 18953" d="M13.438,1.25H4.063A1.565,1.565,0,0,0,2.5,2.813V9.688A1.565,1.565,0,0,0,4.063,11.25h9.375A1.565,1.565,0,0,0,15,9.688V2.813A1.565,1.565,0,0,0,13.438,1.25ZM4.063,2.5h9.375a.313.313,0,0,1,.313.313V7.25l-1.974-2.3a1.119,1.119,0,0,0-.838-.384,1.093,1.093,0,0,0-.835.394L7.781,7.742l-.756-.754a1.1,1.1,0,0,0-1.55,0L3.75,8.712v-5.9A.313.313,0,0,1,4.063,2.5Z" transform="translate(0 -1.25)" fill="#8e8e8e"/>
      </g>
    </g>
  </svg>
  
  )
}

export default Images