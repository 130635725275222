import React, { useEffect, useRef, useState } from 'react'
import Time from "../svg/time"
import Person from '../svg/person'
import Tether from '../svg/tether'
import WaitingIcon from '../svg/usdt_right'
import Location from '../svg/location'
import HashTrack from '../hash_track'
// import WhatsTen from './svg/whats_ten.'
// import ID2 from './svg/id2'
import Rf from '../svg/rf'
import Docum from '../svg/document'
// import SmallCheck from './svg/smallCheck'
import HashLastTrack from '../hash_last'
// import BigCheck from './svg/bigCheck'
// import BigFail from './svg/bigFail'
// import SmallFail from './svg/smallFail'
import Withdrawal from '../svg/withdrowal'
import Deposit from '../svg/deposit'
import PackageCart from '../svg/package_cart'
import EditIcon from '../svg/edit'
import { useDispatch, useSelector } from 'react-redux'
import ApproveTicket from '../svg/approveTicket'
import ProfileNo from "../../../WhatsApp/assets/images/profileNo.png"
import AdminChat from '../svg/adminChat'
import SendIcon from '../svg/sendIcon'
import { approveTicket, ticketChange, doneTicket, getAdminChat, applyTicket, deleteTicket } from '../../../../../redux/statics_emp/actions'
import CheckBig from '../svg/check15'
import MaskedPhone from '../svg/maskedPhone'
import Insructions from '../insructions'
import ID from '../svg/id'
import ID2 from '../svg/id2'
import { toast } from 'react-toastify'
import CopyHash from '../svg/copyHash'

export default function HashTicket(props) {
    const roles = useSelector(state => state.auth.roles)
    let lockedTickets = useSelector(state => state.empticketsReducer.lockedTickets)
    const ticket = props.ticket
    const [open, setOpen] = useState(false)
    const [openDetail, setOpenDetail] = useState(false)
    const [openOrders, setOpenOrders] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingName, setLoadingName] = useState(false)
    const [loadingAccount, setLoadingAccount] = useState(false)
    const [editName, setEditName] = useState(false)
    const [editAccount, setEditAccount] = useState(false)
    var lookup = require('country-data').lookup;
    const editTicket = (id) => {
        props.editTicket(id)
    }

    const dispatch = useDispatch()
    const getCountry = (val) => {
        let data = lookup.countries({ name: val })[0];
        if (data) {
            return data
        }
    }
    const getTime = (t) => {
        let diff = new Date().getTime() - new Date(t).getTime()
        if (diff / 1000 < 60) {
            return (`${(diff / 1000).toFixed(0)} s`)
        } else if (diff / 1000 < 3600) {
            return (`${(diff / 60000).toFixed(0)} m`)
        } else if (diff / 1000 < (3600 * 24)) {
            return (`${(diff / 3600000).toFixed(0)} h`)
        } else if (diff / 1000 > (3600 * 24)) {
            return (`${(diff / (3600000 * 24)).toFixed(0)} d`)
        }
    }
    const [update, setUpdate] = useState(1)
    const [confirmName, setConfirmName] = useState(false)
    const [confirmAccount, setConfirmAccount] = useState(false)
    const [mouseOnButton, setMouseOnButton] = useState(false)
    const [loadingApprove, setLoadingApprove] = useState(false)
    const [loadingAdminChat, setLoadingAdminChat] = useState(false)
    const [openInstructions, setOpenInstructions] = useState(false)
    const [top, setTop] = useState(false)
    const [left, setLeft] = useState(false)
    const [openContextMenu, setOpenContextMenu] = useState(false)

    useEffect(() => {
        const interval = setInterval(() => setUpdate(update + 1), 1000);
        return () => clearInterval(interval);
    }, [update])
    useEffect(() => {
        if (editName === true) {
            document.getElementById(`${ticket.id}-ticket-name-input`)?.focus()
        }
    }, [editName])
    useEffect(() => {
        if (editAccount === true) {
            document.getElementById(`${ticket.id}-ticket-account-input`)?.focus()
        }
    }, [editAccount])
    const getLastTime = ({
        approved_at,
        received_at,
        applied_at
    }) => {
        if (applied_at) {
            return getTime(applied_at)
        } else if (approved_at) {
            return getTime(approved_at)
        } else {
            return getTime(received_at)
        }
    }
    const getIconColor = (status) => {
        if (status === 1) {
            return "#FFFFFF"
        } else if (status === 2) {
            return "#84C0FF"
        } else {
            return "#00C408"
        }
    }
    const [height, setHeight] = useState(82)
    useEffect(() => {
        let heightVar = 82
        if ((open === true) && (openOrders === true)) {
            let height1 = document?.getElementById(`${ticket.id}-ticket-details`)?.clientHeight || 0
            let height2 = document?.getElementById(`${ticket.id}-ticket-comment`)?.clientHeight || 10
            let height3 = document?.getElementById(`${ticket.id}-ticket-status`)?.clientHeight || 0
            let height4 = document?.getElementById(`${ticket.id}-ticket-chat`)?.clientHeight || 0
            setHeight(heightVar + height3 + height1 + height2 + height4 - 32)
        } else if (open === true) {
            let height1 = document?.getElementById(`${ticket.id}-ticket-status`)?.clientHeight || 0
            setHeight(heightVar + height1 - 14)
        } else {
            setHeight(heightVar)
        }
    }, [open, openOrders, openDetail, ticket, confirmAccount, confirmName])

    const newRef = useRef(null);

    const handleOutsideClick = (e) => {
        if (newRef.current && !newRef.current.contains(e.target)) {
            setOpenContextMenu(false)
        }
    };
    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    });

    return (<div className={`${open ? "bulk_element_with_status_selected" : "bulk_element_with_status"}`}
        onMouseEnter={() => {
            setOpen(true)
        }}
        onMouseLeave={() => {
            if (open && !openOrders) {
                setOpenDetail(false)
                setOpen(false)
                setOpenInstructions(false)
            }
        }}
        onClick={() => {
            setOpen(true)
        }}
        onContextMenu={(e) => {
            setTop(e.clientY - 50)
            setLeft(e.clientX - 150)
            e.preventDefault(); // prevent the default behaviour when right clicked
            setOpenContextMenu(true)
        }}
    // style={{ height: `${height}px` }}
    >
        {openContextMenu && <div className='context_menu_ticket' ref={newRef} style={{
            top: top,
            left: left
        }}>
            <div className='context_menu_ticket_item' onClick={() => {
                setOpenContextMenu(false)
                doneTicket({
                    ticket_id: ticket?.id
                }).then(() => {

                })
            }}>Make It Done</div>
            <div className='context_menu_ticket_item' style={{ color: "#ff6e6e" }} onClick={() => {
                setOpenContextMenu(false)
                deleteTicket({
                    ticket_id: ticket?.id
                }).then(() => {

                })
            }}>Delete This Ticket</div>
        </div>}
        {(openInstructions && ticket?.ticket_processing_flow) ? <><Insructions ticket_processing_flow={ticket.ticket_processing_flow} setOpenInstructions={(value) => setOpenInstructions(value)} /></> : <><div className={`bulk_element_usdt`}
            style={{ backgroundColor: props.ticketColor }}>
            <div className={`bulk_element_name ${openOrders ? "withmargin" : ""}`}
                onClick={() => {
                    setOpenOrders(!openOrders)
                }}>
                <div className="bulk_element_name_icon">
                    <div className="bulk_icon" onMouseEnter={() => setOpenInstructions(true)}>
                        {ticket?.type === "deposit" ? <Deposit /> : <Withdrawal />}
                    </div>
                    <div className="bulk_name_tether">
                        {(ticket?.request_number || ticket?.reference) ? (ticket?.request_number || ticket?.reference) : "Waiting"}
                    </div>
                </div>
                <div className="bulk_element_right_icon">
                    <div className="bulk_element_time">
                        <div className="bulk_element_time">
                            <div className="bulk_element_time_unit" style={{ textTransform: "lowercase" }}> {getTime(ticket?.rejected_at ? ticket?.rejected_at : ticket?.received_at)} </div>
                        </div>
                    </div>
                    <Time color="#5d5d5d" />
                    <PackageCart color1={props.icon_color_1} color2={props.icon_color_2} />
                </div>
            </div>
            <div className="bulk_element_data"
                onClick={() => {
                    setOpenOrders(!openOrders)
                }}>
                <div className="bulk_element_country_data">
                    <div className="bulk_element_country_icon_odoo">
                        <Tether />
                    </div>
                    <div className="bulk_element_country_name_odoo">
                        {ticket?.name}, {ticket?.company}
                    </div>
                </div>
            </div>
            {open && openOrders ? <><div id={`${ticket.id}-ticket-details`} className='bulk_orders_area'>
                {<div className='bulk_orders_area_element'>
                    <div className='bulk_orders_area_element_icon'><Person /></div>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginRight: "10px",
                        width: "-webkit-fill-available"
                    }}>
                        {editName ?
                            <div className='bulk_orders_area_element_text'>
                                <input id={`${ticket.id}-ticket-name-input`} type='text' className='edit_rate_ticket_input'
                                    defaultValue={ticket?.name}
                                    onKeyUp={(e) => {
                                        if ((e.key === 'Enter' || e.keyCode === 13) && e.target?.value?.length > 0) {
                                            if (e.target?.value !== ticket.name) {
                                                props.finishEditTicket(ticket.id)
                                                setEditName(false)
                                                setLoadingName(true)
                                                ticketChange({
                                                    ticket_id: ticket.id,
                                                    field_name: "name",
                                                    new_value: e.target?.value
                                                }).then(() => {
                                                    setLoadingName(false)
                                                })
                                            } else {
                                                setEditName(false)
                                            }
                                        }
                                    }} onBlur={(e) => {
                                        if (e.target?.value?.length > 0 && (e.target?.value !== ticket.name)) {
                                            props.finishEditTicket(ticket.id)
                                            setEditName(false)
                                            setLoadingName(true)
                                            ticketChange({
                                                ticket_id: ticket.id,
                                                field_name: "name",
                                                new_value: e.target?.value
                                            }).then(() => {
                                                setLoadingName(false)
                                            })
                                        } else {
                                            setEditName(false)
                                        }
                                    }} />
                            </div> : <div style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginRight: "10px"
                            }}>
                                <div className='bulk_orders_area_element_text' style={{
                                    textDecoration: "auto",
                                    color: (confirmName || (ticket.ticket_status_id !== 2)) ? "#5D5D5D" : "#95BDFF"
                                }}>
                                    {(ticket?.name) ? (ticket?.name) : "-"}  {ticket.ticket_logs?.filter(one => parseInt(one.event) === 2).length > 0 && "*"}
                                    {((props.ticket_status_id === 2) && !confirmName) && <div className='edit_rate_ticket'
                                        style={{ cursor: lockedTickets.filter(one => one.ticket_id === ticket.id).length === 0 ? "pointer" : "initial" }}
                                        onClick={() => {
                                            if (lockedTickets.filter(one => one.ticket_id === ticket.id).length === 0) {
                                                editTicket(ticket.id)
                                                setEditName(true)
                                            }
                                            if (editName) {
                                                setEditName(false)
                                                props.finishEditTicket(ticket.id)
                                            }
                                        }}>
                                        <div className='rep-descs-emp'>{lockedTickets.filter(one => one.ticket_id === ticket.id).length > 0 ? `${lockedTickets.filter(one => one.ticket_id === ticket.id)[0].username} editing` :
                                            (editName ? "Exit" : "Edit Name")}</div>
                                        {loadingName ? <WaitingIcon size={10} /> : <EditIcon color={lockedTickets.filter(one => one.ticket_id === ticket.id).length === 0 ? "#95bdff" : "#FF4141"} />}
                                    </div>}
                                </div>
                            </div>
                        }
                        {props.ticket_status_id === 2 ? <div className='approveIcon' onClick={() => {
                            if (!confirmName) {
                                setConfirmName(true)
                            } else {
                                setConfirmName(false)
                            }
                        }}><ApproveTicket color={confirmName ? "#93D16B" : "#95BDFF"} /></div> : <div />}
                    </div>
                </div>}
                {<div className='bulk_orders_area_element'>
                    <div className='bulk_orders_area_element_icon'><ID2 /></div>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginRight: "10px",
                        width: "-webkit-fill-available"
                    }}>
                        {editAccount ?
                            <div className='bulk_orders_area_element_text'>
                                <input id={`${ticket.id}-ticket-account-input`} type='text' className='edit_rate_ticket_input'
                                    defaultValue={ticket?.account_number}
                                    onKeyUp={(e) => {
                                        if ((e.key === 'Enter' || e.keyCode === 13) && e.target?.value?.length > 0) {
                                            if (e.target?.value !== ticket.account_number) {
                                                props.finishEditTicket(ticket.id)
                                                setEditAccount(false)
                                                setLoadingAccount(true)
                                                ticketChange({
                                                    ticket_id: ticket.id,
                                                    field_name: "account_number",
                                                    new_value: e.target?.value
                                                }).then(() => {
                                                    setLoadingAccount(false)
                                                })
                                            } else {
                                                setEditAccount(false)
                                            }
                                        }
                                    }} onBlur={(e) => {
                                        if (e.target?.value?.length > 0 && (e.target?.value !== ticket.account_number)) {
                                            props.finishEditTicket(ticket.id)
                                            setEditAccount(false)
                                            setLoadingAccount(true)
                                            ticketChange({
                                                ticket_id: ticket.id,
                                                field_name: "account_number",
                                                new_value: e.target?.value
                                            }).then(() => {
                                                setLoadingAccount(false)
                                            })
                                        } else {
                                            setEditAccount(false)
                                        }
                                    }} />
                            </div> : <div style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginRight: "10px"
                            }}>
                                <div className='bulk_orders_area_element_text' style={{
                                    textDecoration: "auto",
                                    color: (confirmAccount || (ticket.ticket_status_id !== 2)) ? "#5D5D5D" : "#95BDFF"
                                }}>
                                    {ticket?.account_number} {ticket.ticket_logs?.filter(one => parseInt(one.event) === 4).length > 0 && "*"}
                                    {((props.ticket_status_id === 2) && !confirmAccount) && <div className='edit_rate_ticket'
                                        style={{ cursor: lockedTickets.filter(one => one.ticket_id === ticket.id).length === 0 ? "pointer" : "initial" }}
                                        onClick={() => {
                                            if (lockedTickets.filter(one => one.ticket_id === ticket.id).length === 0) {
                                                editTicket(ticket.id)
                                                setEditAccount(true)
                                            }
                                            if (editAccount) {
                                                setEditAccount(false)
                                                props.finishEditTicket(ticket.id)
                                            }
                                        }}>
                                        <div className='rep-descs-emp'>{lockedTickets.filter(one => one.ticket_id === ticket.id).length > 0 ? `${lockedTickets.filter(one => one.ticket_id === ticket.id)[0].username} editing` :
                                            (editAccount ? "Exit" : "Edit Account Number")}</div>
                                        {loadingAccount ? <WaitingIcon size={10} /> : <EditIcon color={lockedTickets.filter(one => one.ticket_id === ticket.id).length === 0 ? "#95bdff" : "#FF4141"} />}
                                    </div>}
                                </div>
                            </div>
                        }
                        {props.ticket_status_id === 2 ? <div className='approveIcon' onClick={() => {
                            if (!confirmAccount) {
                                setConfirmAccount(true)
                            } else {
                                setConfirmAccount(false)
                            }
                        }}><ApproveTicket color={confirmAccount ? "#93D16B" : "#95BDFF"} /></div> : <div />}
                    </div>
                </div>}
                <div className='bulk_orders_area_element'>
                    <div className='bulk_orders_area_element_icon'><Location /></div>
                    <div className='bulk_orders_area_element_text'>{ticket?.company}</div>
                </div>
                {<div className='bulk_orders_area_element'>
                    <div className='bulk_orders_area_element_icon'><Rf /></div>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginRight: "10px",
                        width: "-webkit-fill-available"
                    }}>
                        <div className='bulk_orders_area_element_text' style={{ color: "#5D5D5D" }}>
                            {ticket?.amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} {ticket?.currency}
                        </div>
                    </div>
                </div>}
            </div></> : <></>}
        </div>
            {open && openOrders ? <>
                {ticket?.hash && <div id={`${ticket.id}-ticket-comment`} className='bulk_orders_area_comment' style={{ backgroundColor: "#b9b9b9" }}>
                    <div className='bulk_orders_area_comment_inner'>
                        <div style={{ width: "100%", display: "flex", overflow: "hidden" }}>
                            <div style={{ width: "100%" }}>  Hash: {ticket?.hash?.slice(0, 19)} ... </div>
                            <div
                                onClick={() => {
                                    if (ticket?.hash && ticket?.hash?.length > 0) {
                                        let elem = document.querySelector("#hash-copy");
                                        elem.value = ticket?.hash
                                        elem.select()
                                        document.execCommand('Copy');
                                        toast.success("Copied!")
                                    }
                                }}
                                style={{ width: "15px", height: "15px" }}><CopyHash /></div>
                        </div>
                    </div>
                </div>}
            </> : <></>}</>}
        {
            open ? (!openDetail ? <div id={`${ticket.id}-ticket-status`} className={`${openOrders ? "status_area_element_with_order" : "status_area_element"}`}
                style={{
                    backgroundColor: "#EFEFEF"
                }}
                onClick={() => setOpenDetail(true)}>
                <div className='status_area_element_child'>
                    <div className="status_area_element_name">
                        <div className="status_area_element_name_icon">

                        </div>
                        <div className="status_area_element_name_name">
                            <WaitingIcon size={10} />
                            <HashLastTrack
                                status={ticket?.ticket_status_id}
                            />
                        </div>
                    </div>
                    <div className="status_area_element_time">
                        <div className="status_area_element_time_time">
                            {getLastTime({
                                approved_at: ticket?.approved_at,
                                received_at: ticket?.received_at,
                                applied_at: ticket?.applied_at
                            })}
                        </div>
                        <div className="status_area_element_time_icon">
                            <Time color="#5D5D5D" />
                        </div>
                    </div>
                </div>
            </div> : <div id={`${ticket.id}-ticket-status`} className={`${openOrders ? "status_area_element_with_order" : "status_area_element_full_options"}`}
                style={{
                    backgroundColor: "#EFEFEF"
                }}
                onClick={() => setOpenDetail(false)}>
                <HashTrack
                    created_at={ticket?.created_at}
                    rejected_at={ticket?.rejected_at}
                    rejected_reason={ticket?.rejected_reason}
                    approved_at={ticket?.approved_at}
                    approved_by_user_id={ticket?.approved_by_user_id}
                    approved_by_user_full_name={ticket?.approved_by_user_full_name}
                    approved_by_contact_id={ticket?.approved_by_contact_id}
                    approved_by_contact_name={ticket?.approved_by_contact_name}
                    approved_by_contact_profile_photo={ticket?.approved_by_contact_profile_photo}
                    received_at={ticket?.received_at}
                    received_by_user_id={ticket?.received_by_user_id}
                    received_by_user_full_name={ticket?.received_by_user_full_name}
                    applied_at={ticket?.applied_at}
                    applied_by_contact_id={ticket?.applied_by_contact_id}
                    applied_by_contact_name={ticket?.applied_by_contact_name}
                    applied_by_contact_profile_photo={ticket?.applied_by_contact_profile_photo}
                    openOrders={openOrders}
                    setOpenDetail={setOpenDetail}
                    ticket_status_id={props.ticket_status_id}
                    ticket_logs={ticket.ticket_logs}
                    comment={ticket?.comment}
                />
            </div>) : <></>
        }
        {
            open && openOrders ? <>
                <div id={`${ticket.id}-ticket-chat`} className='status_area_element_with_order_chat' style={{ backgroundColor: "#F4F5F5" }}>
                    <div className='status_area_element_with_order_chat_container'>
                        <div className='status_area_element_with_order_chat_container_inner'>
                            <img className='status_area_element_with_order_chat_img' src={ticket?.admin_whatsapp_contact?.profile_photo || ProfileNo} alt='ticket admin' />
                            {(!(confirmAccount && confirmName) && ticket.ticket_status_id === 2) ? <div className='admin_chat_with_admin_tickets_button' onClick={() => {
                                setLoadingAdminChat(true)
                                getAdminChat({ contact_id: ticket.admin_whatsapp_contact?.id }).then((chat) => {
                                    dispatch({ type: "OPEN-WA", payload: true })
                                    dispatch({ type: "CHAT_ID", payload: chat })
                                    setLoadingAdminChat(false)
                                }).catch((e) => {
                                    toast.info("cannot get the admin for this ticket please check configurations!")
                                    setLoadingAdminChat(false)
                                })
                            }}> {loadingAdminChat ? <WaitingIcon /> : <AdminChat />} Chat With Admin “ <div className='admin_chat_with_admin_tickets_button_admin_name'>{ticket?.admin_whatsapp_contact?.name || ticket?.admin_whatsapp_contact?.pushname}</div>”
                            </div> : <>{loadingAdminChat ? <WaitingIcon /> : <AdminChat onClick={
                                () => {
                                    setLoadingAdminChat(true)
                                    getAdminChat({ contact_id: ticket.admin_whatsapp_contact?.id }).then((chat) => {
                                        dispatch({ type: "OPEN-WA", payload: true })
                                        dispatch({ type: "CHAT_ID", payload: chat })
                                        setLoadingAdminChat(false)
                                    }).catch((e) => {
                                        toast.info("cannot get the admin for this ticket please check configurations!")
                                        setLoadingAdminChat(false)
                                    })
                                }} />}</>}
                        </div>
                        {ticket.ticket_status_id === 2 && <>{(confirmAccount && confirmName) ? <div className={mouseOnButton ? 'admin_send_ticket_button_active' : 'admin_send_ticket_button'}
                            onClick={() => {
                                setLoadingApprove(true)
                                approveTicket({
                                    ticket_id: ticket?.id
                                }).then(() => {
                                    setLoadingApprove(false)

                                })
                            }}
                            onMouseEnter={() => setMouseOnButton(true)} onMouseLeave={() => setMouseOnButton(false)}>
                            {mouseOnButton && <>{loadingApprove ? <WaitingIcon /> : <SendIcon color="#404040" />}</>}
                            {mouseOnButton ? "Approve & Send" : "Send"}
                            {!mouseOnButton && <>{loadingApprove ? <WaitingIcon /> : <SendIcon color="#404040" />}</>} </div> : (<SendIcon color="#8E8E8E" disabled={true} />)}
                        </>}
                        {(ticket.ticket_status_id === 3 && (roles.includes("APPLY_TICKETS_PERMISSION") || roles.includes("USER_FULL_ACCESS"))) && <div className={mouseOnButton ? 'admin_send_ticket_button_active' : 'admin_send_ticket_button'}
                            onClick={() => {
                                setLoadingApprove(true)
                                applyTicket({
                                    ticket_id: ticket?.id
                                }).then(() => {
                                    setLoadingApprove(false)

                                })
                            }}
                            onMouseEnter={() => setMouseOnButton(true)} onMouseLeave={() => setMouseOnButton(false)}>
                            {mouseOnButton && <>{loadingApprove ? <WaitingIcon /> : <SendIcon color="#404040" />}</>}
                            {mouseOnButton ? "Apply Ticket" : "Apply"}
                            {!mouseOnButton && <>{loadingApprove ? <WaitingIcon /> : <SendIcon color="#404040" />}</>} </div>
                        }
                        {ticket.ticket_status_id === 4 && <><div className={mouseOnButton ? 'admin_send_ticket_button_active' : 'admin_send_ticket_button'}
                            onClick={() => {
                                setLoadingApprove(true)
                                doneTicket({
                                    ticket_id: ticket?.id
                                }).then(() => {
                                    setLoadingApprove(false)

                                })
                            }}
                            onMouseEnter={() => setMouseOnButton(true)} onMouseLeave={() => setMouseOnButton(false)}>
                            {mouseOnButton && <>{loadingApprove ? <WaitingIcon /> : <CheckBig color="#404040" />}</>}
                            {mouseOnButton ? "Done" : "Done"}
                            {!mouseOnButton && <>{loadingApprove ? <WaitingIcon /> : <CheckBig color="#404040" />}</>} </div>
                        </>}
                    </div>
                </div>
            </> : <></>
        }
    </div >
    )
}