import { useEffect, useState } from "react"
import PackageCart from "./svg/package_cart"

export default function PackageFilter(props) {
    const filters = [
        {
            id: 1,
            name: "Recieved",
            color1: "#404040",
            color2: "#F7F8F3",
            tickets_count: props.chat.received_tickets_count
        }, {
            id: 2,
            name: "Waiting Approve",
            color1: "#404040",
            color2: "#404040",
            tickets_count: props.chat.waiting_approve_tickets_count
        }, {
            id: 3,
            name: "Approved",
            color1: "#007CFF",
            color2: "#007CFF",
            tickets_count: props.chat.approved_tickets_count
        }, {
            id: 4,
            name: "Applied",
            color1: "#15A80F",
            color2: "#15A80F",
            tickets_count: props.chat.applied_tickets_count
        }
    ]
    let [selected, setSelected] = useState(null)

    useEffect(() => {
        setSelected(props.filter)
    }, [props.filter])

    return (<div className="ship_package_filter">
        {filters.map((filter, index) => <div key={index} className={`ship_package_filter_child`} onClick={() => {
            if (selected === filter.id) {
                setSelected(null)
                props.setFilter(null)
            } else {
                setSelected(filter.id)
                props.setFilter(filter.id)
            }
        }}>
            <div className="ship_package_filter_child_icon">
                <PackageCart color1={filter.color1} color2={filter.color2} />
            </div>
            <div className={`${selected === filter.id ? "ship_package_filter_child_count_selected" : "ship_package_filter_child_count"}`}>{filter.tickets_count}</div>
        </div>)}
    </div>)
}