export default function BigLock2() {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g id="Group_12042" data-name="Group 12042" transform="translate(0 0.211)">
            <path id="Path_20" data-name="Path 20" d="M439.822,103.746c.524-.112,1.007-.545,1.086-1.5.065-.786-.136-1.19-.435-1.4.825-3.367-1.452-4.026-1.452-4.026A3.417,3.417,0,0,0,434,95.958a5.933,5.933,0,0,0-1.47,1.261,5.247,5.247,0,0,0-1.123,3.516c-.406.151-.725.547-.645,1.514.083,1,.606,1.431,1.158,1.514a4.015,4.015,0,0,0,7.906-.014Zm-3.956,2.472c-2.009,0-3.634-2.725-3.634-4.989,0-.3.009-.6.029-.863a6.893,6.893,0,0,0,5.625-2.073,6.6,6.6,0,0,1,1.6,2.863v.073c.014,2.26-1.6,4.99-3.614,4.99Z" transform="translate(-425.93 -95.215)" fill="#a2a0a0" />
            <path id="Path_21" data-name="Path 21" d="M432.76,117.878l.233-.963a.562.562,0,0,1,.162-.279l-.07-.053-2.079-1.721-1.266.3a4.592,4.592,0,0,0-3.65,4.391v2.725a1.011,1.011,0,0,0,1.048.964h5.355l.816-4.654a.568.568,0,0,1-.549-.713Z" transform="translate(-423.969 -103.457)" fill="#a2a0a0" />
            <path id="Path_22" data-name="Path 22" d="M444.072,115.161l-1.247-.3-2.076,1.721-.07.053a.563.563,0,0,1,.163.279l.233.963a.568.568,0,0,1-.549.7l.816,4.654H446.7a.972.972,0,0,0,1.005-.964v-2.725a4.571,4.571,0,0,0-3.629-4.379Z" transform="translate(-430.035 -103.459)" fill="#a2a0a0" />
            <rect id="Rectangle_4644" data-name="Rectangle 4644" width="20" height="20" transform="translate(0 -0.211)" fill="none" />
        </g>
    </svg>
    )
}