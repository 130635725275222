import axios from "axios";
import {
    all,
    call,
    fork,
    put,
    takeEvery
} from "redux-saga/effects";
import store from "../store"
import { toast } from 'react-toastify';
import { REACT_APP_ACCOUNTING_URL } from "../../urls";

function* getTicketsStatics(action) {
    try {
        yield put({ type: "GET_TICKETS_STATICS_WAIT" })
        const token = store.getState()?.whatsapp?.userTokenWhatsapp;
        const base_user = REACT_APP_ACCOUNTING_URL;
        let AxiosInstace = axios.create({
            baseURL: `${base_user}`,
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
        });
        let res = yield AxiosInstace.get(`/api/v1/tickets/employees_view?limit=2000`)
        yield put({
            type: "GET_EMP_TICKETS_STATICS_RED", payload: res?.data?.data
        })
        yield put({ type: "GET_TICKETS_STATICS_DONE" })
    }
    catch (e) {
        toast.error("network error !!")
        yield put({ type: "GET_TICKETS_STATICS_DONE" })
    }
}
function* watchgetTicketsStatics() {
    yield takeEvery("GET_EMP_TICKETS_STATICS_SAGA", getTicketsStatics)
}

function* getTicketsCountries(action) {
    try {
        const token = store.getState()?.whatsapp?.userTokenWhatsapp;
        const base_user = REACT_APP_ACCOUNTING_URL;
        let AxiosInstace = axios.create({
            baseURL: `${base_user}`,
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
        });
        let res = yield AxiosInstace.get(`/api/v1/ticket_countries`)
        yield put({
            type: "GET_EMP_TICKETS_COUNTRIES_RED", payload: res?.data?.data
        })
    }
    catch (e) {
        toast.error("network error !!")
    }
}
function* watchgetTicketsCountries() {
    yield takeEvery("GET_EMP_TICKETS_COUNTRIES_SAGA", getTicketsCountries)
}
function* getTicketsCities(action) {
    try {
        const token = store.getState()?.whatsapp?.userTokenWhatsapp;
        const base_user = REACT_APP_ACCOUNTING_URL;
        let AxiosInstace = axios.create({
            baseURL: `${base_user}`,
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
        });
        let res = yield AxiosInstace.get(`/api/v1/ticket_cities`)
        yield put({
            type: "GET_EMP_TICKETS_CITIES_RED", payload: res?.data?.data
        })
    }
    catch (e) {
        toast.error("network error !!")
    }
}
function* watchgetTicketsCities() {
    yield takeEvery("GET_EMP_TICKETS_CITIES_SAGA", getTicketsCities)
}

function* getTicketsStaticsOnly(action) {
    try {
        const token = store.getState()?.whatsapp?.userTokenWhatsapp;
        const base_user = REACT_APP_ACCOUNTING_URL;
        let AxiosInstace = axios.create({
            baseURL: `${base_user}`,
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
        });
        let res = yield AxiosInstace.get(`/api/v1/tickets/employees_view`)
        yield put({
            type: "GET_EMP_TICKETS_STATICS_ONLY_RED", payload: res?.data?.data
        })
    }
    catch (e) {
        toast.error("network error !!")
    }
}
function* watchgetTicketsStaticsOnly() {
    yield takeEvery("GET_EMP_TICKETS_STATICS_ONLY_SAGA", getTicketsStaticsOnly)
}

export default function* EmpTicketsSaga() {
    yield all([
        fork(watchgetTicketsStatics),
        fork(watchgetTicketsCountries),
        fork(watchgetTicketsCities),
        fork(watchgetTicketsStaticsOnly)
    ]);
}
