import React, { useEffect, useState } from "react"
import "./index.css"
import Footer from "./Footer"
import Avatar from "./Avatar"
import { withRouter } from "react-router-dom"
import BigLock2 from "./icons/biglock2"
import Signedup from "./oldSignUp"
import EnterUser from "./icons/enterUser"
import axios from "axios"
import { REACT_APP_USER_SERVICE_URL } from "../../urls"
import AskForSend from "./icons/askforsend"
import lock from "../../assets/svg/lock.svg"
import PinInput from "react-pin-input"

function Signup(props) {
  const [signedIn, setSignedIn] = useState(false)
  const [name, setName] = useState("")
  const [waitConfirm, setWaitConfirm] = useState(false)
  const [resetPass, setResetPass] = useState(false)
  const [resetUser, setResetUser] = useState(null)
  const [errorMessage, setErrorMessage] = useState("")
  const [enterCode, setEnterCode] = useState(false)
  const [code, setCode] = useState("")
  const [password, setPassword] = useState("")
  const [passwordConfirm, setPasswordConfirm] = useState("")
  const [counterCode, setCounterCode] = useState(0)
  const [initialCode, setInitialCode] = useState(true)
  const [initialPassword, setInitialPassword] = useState(true)
  const [initialPasswordConfirm, setInitialPasswordConfirm] = useState(true)
  const [attemptCode, setAttemptCode] = useState(3)
  const [sessionToken, setSessionToken] = useState(null)
  const [enterPass, setEnterPass] = useState(false)
  const [idToken, setIdToken] = useState("")

  const noMatchPassword = () => {
    document.querySelector(".pin-input").classList.add("shake-modals");
    document.querySelectorAll(".absolute-child").forEach((elem) => {
      elem.classList.add("red-background");
      elem.classList.remove("green-background");
    });
    setTimeout(() => {
      document.querySelector(".pin-input").classList.remove("shake-modals");
      document.querySelectorAll(".absolute-child").forEach((elem) => {
        elem.classList.remove("red-background");
      });
    }, 400);
    setTimeout(() => {
      setInitialPassword(false);
      setPassword("");
      setInitialPassword(true);
      document.querySelector(".pincode-input-text")?.focus();
    }, 400);
    setTimeout(() => {
      setInitialPasswordConfirm(false);
      setPasswordConfirm("");
      setInitialPasswordConfirm(true);
    }, 400);
  }
  const onChange = () => {
    document.querySelectorAll(".absolute-child")?.forEach((elem) => {
      elem.classList.remove("red-background");
      elem.classList.remove("green-background");
    });
  }
  const shakePasswordConfirm = async (value) => {
    if (value === password) {
      await axios.post(REACT_APP_USER_SERVICE_URL + "/api/v1/auth/resetPasswordUsingOTP", {
        id_token: idToken,
        password: value
      }).then((res) => {
        localStorage.setItem("user", JSON.stringify(res.data?.data))
        setSignedIn(true)
      }).catch((e) => {
        console.log(e)
      })
    } else {
      noMatchPassword()
    }
  }
  const shakeCode = async (value) => {
    await axios.post(REACT_APP_USER_SERVICE_URL + "/api/v1/auth/password_reset/check_token", {
      username: name,
      token: value,
      session_token: sessionToken
    }).then((res) => {
      setTimeout(() => {
        setEnterPass(true);
        setIdToken(res.data?.data?.id_token)
      }, 1000);
      document.querySelectorAll(".absolute-child").forEach((elem) => {
        elem.classList.remove("red-background");
        elem.classList.add("green-background");
      });
    }).catch((e) => {
      setAttemptCode(attemptCode - 1);
      document.querySelector(".pin-input").classList.add("shake-modals");
      document.querySelectorAll(".absolute-child").forEach((elem) => {
        elem.classList.add("red-background");
        elem.classList.remove("green-background");
      });
      setTimeout(() => {
        document.querySelector(".pin-input").classList.remove("shake-modals");
        document.querySelectorAll(".absolute-child").forEach((elem) => {
          elem.classList.remove("red-background");
        });
      }, 400);
    })
  }
  const handleSendCodeByEmail = async (name) => {
    await axios.post(REACT_APP_USER_SERVICE_URL + "/api/v1/auth/password_reset/send_token", { username: name }).then((res) => {
      setSessionToken(res.data?.data)
      setEnterCode(true)
    }).catch((e) => {
      console.log(e)
    })
  }

  const checkusername = async (name) => {
    setWaitConfirm(true)
    await axios.get(REACT_APP_USER_SERVICE_URL + "/api/v1/users/profile_by_username/" + name).then((res) => {
      if (res.data?.data?.is_blocked_by_admin) {
        setErrorMessage("the selected user is blocked by admin")
      } else if (res.data?.data?.account_status === "password_expired") {
        setErrorMessage("the selected user password is expired")
        setResetUser(res.data?.data)
        setResetPass(true)
      } else if (res.data?.data?.account_status === "password_missed") {
        setErrorMessage("the selected user password is missed")
        setResetUser(res.data?.data)
        setResetPass(true)
      } else {
        localStorage.setItem("user", JSON.stringify(res.data?.data))
        setSignedIn(true)
      }
      setWaitConfirm(false)
    }).catch((e) => {
      setErrorMessage("the selected user is invalid")
      setWaitConfirm(false)
    })
  }

  useEffect(() => {
    setTimeout(() => {
      setErrorMessage("")
    }, 3000)
  }, [errorMessage])

  useEffect(() => {
    if (
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user")).id &&
      !props.reset_password
    ) {
      setSignedIn(true);
    } else {
      if (props.reset_password) {
        setResetUser(JSON.parse(localStorage.getItem("user")))
        setName(JSON.parse(localStorage.getItem("user")).username)
        setResetPass(true)
      }
      setSignedIn(false);
    }
  }, [])
  useEffect(() => {
    if (attemptCode === 0) {
      setCounterCode(10);
    }
    if (attemptCode !== 3) {
      setTimeout(() => {
        setInitialCode(false);
        setCode("");
        setInitialCode(true);
        document.querySelector(".pincode-input-text")?.focus();
      }, 400);
    }
  }, [attemptCode]);
  useEffect(() => {
    if (counterCode > 0) setTimeout(() => setCounterCode(counterCode - 1), 1000);
    else {
      document.querySelector(".pincode-input-text")?.focus();
      document.querySelectorAll(".absolute-child")?.forEach((elem) => {
        elem.classList.remove("red-background");
      });
      setAttemptCode(3);
    }
  }, [counterCode]);
  useEffect(() => {
    let arr = document.querySelectorAll(".absolute-child");
    arr.forEach((elem) => {
      elem.addEventListener(
        "select",
        function (e) {
          e.selecionStart = e.selecionEnd;
        },
        false
      );
    });
  }, [])
  if (signedIn) {
    return <Signedup />
  } else
    return (
      <div className="main full-page sign">
        <Footer />
        <div className="signup-main">
          {signedIn && <Avatar />}
          {/* <Logo/> */}
          <div className="signup-container">
            <div className="relative-input">
              <BigLock2 />
              <input
                onKeyDown={(e) => {
                  if (e.code === "Enter" && !resetPass)
                    checkusername(name)
                }}
                disabled={waitConfirm || resetPass}
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="username"
                placeholder="User Name"
              />
              {(name.length && !resetPass) ? <div className="enter_user_name_button" onClick={() => {
                checkusername(name)
              }}>
                <EnterUser />
              </div> : ""}
            </div>{
              enterPass ? <>
                <div className="reset_password_container_2">
                  <div className="reset_password_container_main_icon"> <AskForSend /></div>
                  <div className="reset_password_container_main_welcome">Set Your Own Password</div>
                </div>
                <div className="pin-input">
                  <div className="absolute-container">
                    <div className="absolute-child">
                      {password.length > 0 && <img src={lock} />}
                    </div>
                    <div className="absolute-child">
                      {password.length > 1 && <img src={lock} />}
                    </div>
                    <div className="absolute-child">
                      {password.length > 2 && <img src={lock} />}
                    </div>
                    <div className="absolute-child">
                      {password.length > 3 && <img src={lock} />}
                    </div>
                    <div className="absolute-child">
                      {password.length > 4 && <img src={lock} />}
                    </div>
                    <div className="absolute-child">
                      {password.length > 5 && <img src={lock} />}
                    </div>
                  </div>
                  {initialPassword && (
                    <PinInput
                      length={6}
                      initialValue=""
                      onChange={(value, index) => {
                        setPassword(value);
                        // onChange();
                      }}
                      type="numeric"
                      inputMode="number"
                      style={{ marginTop: 0 }}
                      inputStyle={{
                        borderRadius: 15,
                        backgroundColor: "#F7F7F7",
                        margin: "initial",
                        color: "transparent",
                        border: "#ddddddc5 0.5px solid",
                        width: 50,
                        height: 50,
                      }}
                      // onComplete={(value, index) => setCode(value)}
                      autoSelect={true}
                      regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}

                    // disabled={disablePin}
                    />
                  )}
                </div>
                <div className="reset_password_container_2">
                  <div className="reset_password_container_main_welcome">Confirm Password</div>
                </div>
                <div className="pin-input">
                  <div className="absolute-container">
                    <div className="absolute-child">
                      {passwordConfirm.length > 0 && <img src={lock} />}
                    </div>
                    <div className="absolute-child">
                      {passwordConfirm.length > 1 && <img src={lock} />}
                    </div>
                    <div className="absolute-child">
                      {passwordConfirm.length > 2 && <img src={lock} />}
                    </div>
                    <div className="absolute-child">
                      {passwordConfirm.length > 3 && <img src={lock} />}
                    </div>
                    <div className="absolute-child">
                      {passwordConfirm.length > 4 && <img src={lock} />}
                    </div>
                    <div className="absolute-child">
                      {passwordConfirm.length > 5 && <img src={lock} />}
                    </div>
                  </div>
                  {initialPasswordConfirm && (
                    <PinInput
                      length={6}
                      initialValue=""
                      onChange={(value, index) => {
                        setPasswordConfirm(value);
                        // onChange();
                      }}
                      type="numeric"
                      // disabled={counterPasswordConfirm > 0}
                      inputMode="number"
                      style={{ marginTop: 0 }}
                      onComplete={(value, index) => shakePasswordConfirm(value)}
                      inputStyle={{
                        borderRadius: 15,
                        backgroundColor: "#F7F7F7",
                        margin: "initial",
                        color: "transparent",
                        border: "#ddddddc5 0.5px solid",
                        width: 50,
                        height: 50,
                      }}
                      // onComplete={(value, index) => setCode(value)}
                      autoSelect={true}
                      regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}

                    // disabled={disablePin}
                    />
                  )}
                </div>
              </> :
                <>{enterCode ? <div>
                  <div className="pin-input">
                    <div className="absolute-container">
                      <div className="absolute-child">
                        {code.length > 0 && <img src={lock} />}
                      </div>
                      <div className="absolute-child">
                        {code.length > 1 && <img src={lock} />}
                      </div>
                      <div className="absolute-child">
                        {code.length > 2 && <img src={lock} />}
                      </div>
                      <div className="absolute-child">
                        {code.length > 3 && <img src={lock} />}
                      </div>
                      <div className="absolute-child">
                        {code.length > 4 && <img src={lock} />}
                      </div>
                      <div className="absolute-child">
                        {code.length > 5 && <img src={lock} />}
                      </div>
                    </div>
                    {initialCode && (
                      <PinInput
                        length={6}
                        initialValue=""
                        onChange={(value, index) => {
                          setCode(value);
                          onChange();
                        }}
                        type="numeric"
                        disabled={counterCode > 0}
                        inputMode="number"
                        style={{ marginTop: 0 }}
                        onComplete={(value, index) => shakeCode(value)}
                        inputStyle={{
                          borderRadius: 15,
                          backgroundColor: "#F7F7F7",
                          margin: "initial",
                          color: "transparent",
                          border: "#ddddddc5 0.5px solid",
                          width: 50,
                          height: 50,
                        }}
                        // onComplete={(value, index) => setCode(value)}
                        autoSelect={true}
                        regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}

                      // disabled={disablePin}
                      />
                    )}
                  </div>
                  <div className="text-container">
                    {attemptCode < 3 && (
                      <>
                        {attemptCode !== 0 ? (
                          <div className="attempt">You Have {attemptCode} Attempts </div>
                        ) : (
                          <div className="counter">
                            Try After {counterCode}s
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <div className="reset_password_container_2">
                    <div className="reset_password_container_main_icon"> <AskForSend /></div>
                    <div className="reset_password_container_main_welcome">Please Enter The Verification Code Sent To “ {resetUser?.masked_email} “</div>
                  </div>
                </div> : <>{resetPass ? <div className="reset_password_container">
                  <div className="reset_password_container_main_icon"> <AskForSend /></div>
                  <div className="reset_password_container_main_welcome"> <div className="reset_password_container_main_welcome_user">Welcome {resetUser?.full_name} </div> {props.reset_password ? "" : "To Our Team"}</div>
                  <div className="reset_password_container_main_welcome">To {props.reset_password ? "Reset" : "Set"} Your Own Password We Will Send A Verification Code To Your Email Registered In The System</div>
                  <div className="reset_password_container_main_welcome">{resetUser?.masked_email}</div>
                  <div className="reset_password_container_main_welcome_send" onClick={() => {
                    handleSendCodeByEmail(name)
                  }}>send</div>
                </div> :
                  <div className="error_message_user_input">
                    {errorMessage}
                  </div>}</>}</>}
          </div>
        </div>
      </div>
    );
}

export default withRouter(Signup);
