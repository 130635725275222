export const mainStatuses = [
    {
        id: 1,
        sequence: 1,
        parent_id: null,
        color1: '#F2FFF7',
        color2: null,
        icons_color: '#8E8E8E',
        details_color: null,
        icon_has_check: 0,
        pallets_count: 8,
        orders_count: 16,
        translations: [
            {
                id: 1,
                language_code: 'en',
                name: 'Deposit',
                pallet_status_id: 1,
                waiting_status_name: null
            },
            {
                id: 2,
                language_code: 'ar',
                name: 'إيداع',
                pallet_status_id: 1,
                waiting_status_name: null
            }
        ],
        sub_statuses: [
            {
                id: 5,
                sequence: null,
                parent_id: 1,
                color1: '#404040',
                color2: 'none',
                icons_color: '#FFF7F4',
                details_color: '#FFEAEA',
                main_icon_color: "#8E8E8E",
                icon_has_check: 0,
                pallets_count: 1,
                orders_count: 6,
                translations: [
                    {
                        id: 9,
                        language_code: 'en',
                        name: 'Requested',
                        pallet_status_id: 5,
                        waiting_status_name: 'Request A Correction'
                    },
                    {
                        id: 10,
                        language_code: 'ar',
                        name: 'تم الطلب',
                        pallet_status_id: 5,
                        waiting_status_name: 'Request A Correction'
                    }
                ]
            },
            {
                id: 6,
                sequence: 6,
                parent_id: 1,
                color1: '#404040',
                color2: '#404040',
                icons_color: '#F7F7F7',
                details_color: '#EFEFEF',
                main_icon_color: "#5D5D5D",
                icon_has_check: 0,
                pallets_count: 1,
                orders_count: 2,
                translations: [
                    {
                        id: 11,
                        language_code: 'en',
                        name: 'Approved',
                        pallet_status_id: 6,
                        waiting_status_name: 'waiting pickup'
                    },
                    {
                        id: 12,
                        language_code: 'ar',
                        name: 'موافق عليه',
                        pallet_status_id: 6,
                        waiting_status_name: 'waiting pickup'
                    }
                ]
            },
            {
                id: 7,
                sequence: 7,
                parent_id: 1,
                color1: '#007CFF',
                color2: '#007CFF',
                icons_color: '#F4F8FF',
                details_color: '#DDFFF8',
                main_icon_color: "#007CFF",
                icon_has_check: 0,
                pallets_count: 2,
                orders_count: 8,
                translations: [
                    {
                        id: 13,
                        language_code: 'en',
                        name: 'Notified',
                        pallet_status_id: 7,
                        waiting_status_name: 'Waiting Transfer Center'
                    },
                    {
                        id: 14,
                        language_code: 'ar',
                        name: 'تم الإشعار به',
                        pallet_status_id: 7,
                        waiting_status_name: 'Waiting Transfer Center'
                    }
                ]
            },
            {
                id: 8,
                sequence: 8,
                parent_id: 1,
                color1: '#00C408',
                color2: '#00C408',
                icons_color: '#F9FFF4',
                details_color: '#F1FDDA',
                main_icon_color: "#00C408",
                icon_has_check: 0,
                pallets_count: 0,
                orders_count: 0,
                translations: [
                    {
                        id: 15,
                        language_code: 'en',
                        name: 'Payment Done',
                        pallet_status_id: 8,
                        waiting_status_name: 'waiting progress'
                    },
                    {
                        id: 16,
                        language_code: 'ar',
                        name: 'تم الدفع ',
                        pallet_status_id: 8,
                        waiting_status_name: 'waiting progress'
                    }
                ]
            }
        ]
    },
    {
        id: 2,
        sequence: 2,
        parent_id: null,
        color1: '#FFF8F2',
        color2: null,
        icons_color: '#5D5D5D',
        details_color: null,
        icon_has_check: 1,
        pallets_count: 0,
        orders_count: 0,
        translations: [
            {
                id: 3,
                language_code: 'en',
                name: 'Withdrawal',
                pallet_status_id: 2,
                waiting_status_name: null
            },
            {
                id: 4,
                language_code: 'ar',
                name: 'سحب',
                pallet_status_id: 2,
                waiting_status_name: null
            }
        ],
        sub_statuses: [
            {
                id: 9,
                sequence: null,
                parent_id: 1,
                color1: '#404040',
                color2: 'none',
                icons_color: '#FFF7F4',
                details_color: '#FFEAEA',
                main_icon_color: "#8E8E8E",
                icon_has_check: 0,
                pallets_count: 1,
                orders_count: 6,
                translations: [
                    {
                        id: 9,
                        language_code: 'en',
                        name: 'Requested',
                        pallet_status_id: 5,
                        waiting_status_name: 'Request A Correction'
                    },
                    {
                        id: 10,
                        language_code: 'ar',
                        name: 'تم الطلب',
                        pallet_status_id: 5,
                        waiting_status_name: 'Request A Correction'
                    }
                ]
            },
            {
                id: 10,
                sequence: 6,
                parent_id: 1,
                color1: '#404040',
                color2: '#404040',
                icons_color: '#F7F7F7',
                details_color: '#EFEFEF',
                main_icon_color: "#5D5D5D",
                icon_has_check: 0,
                pallets_count: 0,
                orders_count: 2,
                translations: [
                    {
                        id: 11,
                        language_code: 'en',
                        name: 'Approved',
                        pallet_status_id: 6,
                        waiting_status_name: 'waiting pickup'
                    },
                    {
                        id: 12,
                        language_code: 'ar',
                        name: 'موافق عليه',
                        pallet_status_id: 6,
                        waiting_status_name: 'waiting pickup'
                    }
                ]
            },
            {
                id: 11,
                sequence: 7,
                parent_id: 1,
                color1: '#007CFF',
                color2: '#007CFF',
                icons_color: '#F4F8FF',
                details_color: '#DDFFF8',
                main_icon_color: "#007CFF",
                icon_has_check: 0,
                pallets_count: 0,
                orders_count: 8,
                translations: [
                    {
                        id: 13,
                        language_code: 'en',
                        name: 'Notified',
                        pallet_status_id: 7,
                        waiting_status_name: 'Waiting Transfer Center'
                    },
                    {
                        id: 14,
                        language_code: 'ar',
                        name: 'تم الإشعار به',
                        pallet_status_id: 7,
                        waiting_status_name: 'Waiting Transfer Center'
                    }
                ]
            },
            {
                id: 12,
                sequence: 8,
                parent_id: 1,
                color1: '#00C408',
                color2: '#00C408',
                icons_color: '#F9FFF4',
                details_color: '#F1FDDA',
                main_icon_color: "#00C408",
                icon_has_check: 0,
                pallets_count: 1,
                orders_count: 0,
                translations: [
                    {
                        id: 15,
                        language_code: 'en',
                        name: 'Payment Done',
                        pallet_status_id: 8,
                        waiting_status_name: 'waiting progress'
                    },
                    {
                        id: 16,
                        language_code: 'ar',
                        name: 'تم الدفع ',
                        pallet_status_id: 8,
                        waiting_status_name: 'waiting progress'
                    }
                ]
            }
        ]
    }
]
export const fakeFilter = [
    {
        id: 0,
        color1: "#404040",
        color2: "#404040",
        count: 1,
        name: "Waiting",
        bulk_cart_color: "#FFF7F4",
        bulk_detail_cart_color: "#FFEAEA"
    },
    {
        id: 1,
        color1: "#404040",
        color2: "#404040",
        count: 5,
        name: "Approved",
        bulk_cart_color: "#F7F7F7",
        bulk_detail_cart_color: "#EFEFEF"
    },
    {
        id: 2,
        color1: "#404040",
        color2: "#007CFF",
        count: 3,
        name: "Picked",
        bulk_cart_color: "#F4F8FF",
        bulk_detail_cart_color: "#DDFFF8"
    },
    {
        id: 3,
        color1: "#404040",
        color2: "#00C408",
        count: 2,
        name: "Transfer Center",
        bulk_cart_color: "#F9FFF4",
        bulk_detail_cart_color: "#F1FDDA"
    }
]
export const bulks = [
    {
        id: 66,
        is_encapsulated: 0,
        is_completed: 0,
        pallet_status_id: 7,
        ShipmentNumber: 'RF 10012',
        is_approved_by_system: 1,
        employee_user_id: null,
        barcode: null,
        main_status_id: 1,
        city_id: 6,
        country_id: 4,
        v_weight: 11,
        weight: 7,
        products_count: '17',
        tracking_details: [
            {
                id: 75,
                pallet_id: 66,
                pallet_status_id: 6,
                next_pallet_status_id: 7,
                changed_by_user_id: 1,
                start_pallet_status_time: '2023-08-21 22:17:33',
                end_pallet_status_time: '2023-09-02 17:43:57',
                TrackingCountry: 'Iraq',
                last_pallet_status_from: null,
                TrackingNotes: 'waiting pickup',
                notify_to_user_id: 13,
                start_notify_time: '2023-08-22 07:52:57',
                ready_for_next_pallet_status: 1,
                last_pallet_status: {
                    id: 6,
                    sequence: 6,
                    parent_id: 1,
                    color1: '#404040',
                    color2: '#404040',
                    icons_color: '#F7F7F7',
                    details_color: '#EFEFEF',
                    icon_has_check: 0,
                    pallets_count: 1,
                    orders_count: 2,
                    translations: [
                        {
                            id: 11,
                            language_code: 'en',
                            name: 'approved',
                            pallet_status_id: 6,
                            waiting_status_name: 'waiting pickup'
                        },
                        {
                            id: 12,
                            language_code: 'ar',
                            name: 'موافق عليه',
                            pallet_status_id: 6,
                            waiting_status_name: 'waiting pickup'
                        }
                    ]
                },
                last_pallet_status_user: {
                    id: 1,
                    username: 'admin',
                    email: 'admin@shipping.com',
                    avatar: null,
                    mobile_phone: '+963936997540',
                    work_phone: null,
                    surname: null,
                    birthdate: null,
                    gender: null,
                    address: null,
                    verified: 1,
                    last_active_at: null,
                    is_blocked_by_admin: 0,
                    website_url: null,
                    facebook_account: null,
                    instagram_account: null,
                    telegram_account: null,
                    tiktok_account: null,
                    postal_code: null,
                    can_change_password: 1,
                    updated_at: '2023-09-19T07:23:38.000000Z',
                    user_status_id: null,
                    num_of_failed_attempts: 0,
                    default_language_id: null,
                    role_id: null,
                    followers_number: 0,
                    following_number: 0,
                    products_number: 0,
                    services_number: 0,
                    is_locked_by_admin_for_delete: 0,
                    is_locked_by_admin_for_update: 0,
                    last_login_date: null,
                    preferred_currency_id: null,
                    mobile_verification_token: null,
                    mobile_verified_at: null,
                    business_document: null,
                    first_name: null,
                    last_name: null,
                    store_name: null,
                    secondary_email: null,
                    whatsapp_number: null,
                    twitter_url: null,
                    vat_number: null,
                    company_contact_phone_number: null,
                    full_address: null,
                    is_active: 0,
                    region_id: null,
                    manager_id: null,
                    is_admin: 0,
                    passport_number: null,
                    bank_account_details: null,
                    full_name: null,
                    citizen_country_id: null,
                    birth_city_id: null,
                    id_no: null,
                    passport_photo_path: null,
                    id_photo_path: null,
                    driving_license_no: null,
                    driving_license_photo_path: null,
                    id_no_region: null,
                    driver_license_region: null,
                    city: null,
                    birth_country: null,
                    driving_license_issued_from: null,
                    residence: null,
                    id_issued_from: null,
                    correlationId: null,
                    is_available_for_chat: 0,
                    role_names: 'admin',
                    current_username: '',
                    roles: [
                        {
                            is_signup_allowed: 0,
                            id: 1,
                            icon_path: null,
                            parent_id: null,
                            is_locked_by_admin_for_delete: 0,
                            is_locked_by_admin_for_update: 0,
                            insurance_amount: 0,
                            programming_name: null,
                            pivot: {
                                user_id: 1,
                                role_id: 1,
                                id: 1,
                                is_insured: 0
                            },
                            role_translations: {
                                id: 1,
                                role_id: 1,
                                language_code: 'en',
                                name: 'admin',
                                description: 'admin',
                                notes: null,
                                is_locked_by_admin_for_delete: 0,
                                is_locked_by_admin_for_update: 0
                            }
                        }
                    ]
                },
                current_pallet_status: {
                    id: 7,
                    sequence: 7,
                    parent_id: 1,
                    color1: '#404040',
                    color2: '#007CFF',
                    icons_color: '#F4F8FF',
                    details_color: '#DDFFF8',
                    icon_has_check: 0,
                    pallets_count: 2,
                    orders_count: 8,
                    translations: [
                        {
                            id: 13,
                            language_code: 'en',
                            name: 'picked',
                            pallet_status_id: 7,
                            waiting_status_name: 'Waiting Transfer Center'
                        },
                        {
                            id: 14,
                            language_code: 'ar',
                            name: 'تم التقاطه',
                            pallet_status_id: 7,
                            waiting_status_name: 'Waiting Transfer Center'
                        }
                    ]
                },
                notify_to_user: {
                    id: 13,
                    username: 'louai-13',
                    email: null,
                    avatar: '/storage/users/avatars/avatar.png',
                    mobile_phone: null,
                    work_phone: null,
                    surname: null,
                    birthdate: '1997-10-30T00:00:00.000000Z',
                    gender: null,
                    address: null,
                    verified: 1,
                    last_active_at: null,
                    is_blocked_by_admin: 0,
                    website_url: null,
                    facebook_account: null,
                    instagram_account: null,
                    telegram_account: null,
                    tiktok_account: null,
                    postal_code: null,
                    can_change_password: 1,
                    updated_at: '2023-08-21T21:57:40.000000Z',
                    user_status_id: null,
                    num_of_failed_attempts: 0,
                    default_language_id: null,
                    role_id: 2,
                    followers_number: 0,
                    following_number: 0,
                    products_number: 0,
                    services_number: 0,
                    is_locked_by_admin_for_delete: 0,
                    is_locked_by_admin_for_update: 0,
                    last_login_date: null,
                    preferred_currency_id: null,
                    mobile_verification_token: null,
                    mobile_verified_at: null,
                    business_document: null,
                    first_name: null,
                    last_name: null,
                    store_name: null,
                    secondary_email: null,
                    whatsapp_number: null,
                    twitter_url: null,
                    vat_number: null,
                    company_contact_phone_number: null,
                    full_address: 'syria damascus',
                    is_active: 0,
                    region_id: null,
                    manager_id: null,
                    is_admin: 0,
                    passport_number: null,
                    bank_account_details: null,
                    full_name: 'louai',
                    citizen_country_id: null,
                    birth_city_id: null,
                    id_no: null,
                    passport_photo_path: null,
                    id_photo_path: null,
                    driving_license_no: null,
                    driving_license_photo_path: null,
                    id_no_region: null,
                    driver_license_region: null,
                    city: 'syria homs',
                    birth_country: null,
                    driving_license_issued_from: null,
                    residence: null,
                    id_issued_from: null,
                    correlationId: 'Shipping-users-13',
                    is_available_for_chat: 0,
                    role_names: '',
                    current_username: '',
                    roles: []
                }
            },
            {
                id: 76,
                pallet_id: 66,
                pallet_status_id: 7,
                next_pallet_status_id: 8,
                changed_by_user_id: 13,
                start_pallet_status_time: '2023-09-02 17:43:57',
                end_pallet_status_time: null,
                TrackingCountry: 'Iraq',
                last_pallet_status_from: 17006,
                TrackingNotes: 'Waiting Transfer Center',
                notify_to_user_id: null,
                start_notify_time: null,
                ready_for_next_pallet_status: 1,
                last_pallet_status: {
                    id: 7,
                    sequence: 7,
                    parent_id: 1,
                    color1: '#404040',
                    color2: '#007CFF',
                    icons_color: '#F4F8FF',
                    details_color: '#DDFFF8',
                    icon_has_check: 0,
                    pallets_count: 2,
                    orders_count: 8,
                    translations: [
                        {
                            id: 13,
                            language_code: 'en',
                            name: 'picked',
                            pallet_status_id: 7,
                            waiting_status_name: 'Waiting Transfer Center'
                        },
                        {
                            id: 14,
                            language_code: 'ar',
                            name: 'تم التقاطه',
                            pallet_status_id: 7,
                            waiting_status_name: 'Waiting Transfer Center'
                        }
                    ]
                },
                last_pallet_status_user: {
                    id: 13,
                    username: 'louai-13',
                    email: null,
                    avatar: '/storage/users/avatars/avatar.png',
                    mobile_phone: null,
                    work_phone: null,
                    surname: null,
                    birthdate: '1997-10-30T00:00:00.000000Z',
                    gender: null,
                    address: null,
                    verified: 1,
                    last_active_at: null,
                    is_blocked_by_admin: 0,
                    website_url: null,
                    facebook_account: null,
                    instagram_account: null,
                    telegram_account: null,
                    tiktok_account: null,
                    postal_code: null,
                    can_change_password: 1,
                    updated_at: '2023-08-21T21:57:40.000000Z',
                    user_status_id: null,
                    num_of_failed_attempts: 0,
                    default_language_id: null,
                    role_id: 2,
                    followers_number: 0,
                    following_number: 0,
                    products_number: 0,
                    services_number: 0,
                    is_locked_by_admin_for_delete: 0,
                    is_locked_by_admin_for_update: 0,
                    last_login_date: null,
                    preferred_currency_id: null,
                    mobile_verification_token: null,
                    mobile_verified_at: null,
                    business_document: null,
                    first_name: null,
                    last_name: null,
                    store_name: null,
                    secondary_email: null,
                    whatsapp_number: null,
                    twitter_url: null,
                    vat_number: null,
                    company_contact_phone_number: null,
                    full_address: 'syria damascus',
                    is_active: 0,
                    region_id: null,
                    manager_id: null,
                    is_admin: 0,
                    passport_number: null,
                    bank_account_details: null,
                    full_name: 'louai',
                    citizen_country_id: null,
                    birth_city_id: null,
                    id_no: null,
                    passport_photo_path: null,
                    id_photo_path: null,
                    driving_license_no: null,
                    driving_license_photo_path: null,
                    id_no_region: null,
                    driver_license_region: null,
                    city: 'syria homs',
                    birth_country: null,
                    driving_license_issued_from: null,
                    residence: null,
                    id_issued_from: null,
                    correlationId: 'Shipping-users-13',
                    is_available_for_chat: 0,
                    role_names: '',
                    current_username: '',
                    roles: []
                },
                current_pallet_status: {
                    id: 8,
                    sequence: 8,
                    parent_id: 1,
                    color1: '#404040',
                    color2: '#00C408',
                    icons_color: '#F9FFF4',
                    details_color: '#F1FDDA',
                    icon_has_check: 0,
                    pallets_count: 0,
                    orders_count: 0,
                    translations: [
                        {
                            id: 15,
                            language_code: 'en',
                            name: 'transfer center',
                            pallet_status_id: 8,
                            waiting_status_name: 'waiting progress'
                        },
                        {
                            id: 16,
                            language_code: 'ar',
                            name: 'مركز النقل',
                            pallet_status_id: 8,
                            waiting_status_name: 'waiting progress'
                        }
                    ]
                },
                notify_to_user: null
            }
        ],
        pallet_status: {
            id: 7,
            sequence: 7,
            parent_id: 1,
            color1: '#007CFF',
            color2: '#007CFF',
            icons_color: '#F4F8FF',
            details_color: '#DDFFF8',
            icon_has_check: 0,
            pallets_count: 2,
            orders_count: 8,
            translations: [
                {
                    id: 13,
                    language_code: 'en',
                    name: 'picked',
                    pallet_status_id: 7,
                    waiting_status_name: 'Waiting Transfer Center'
                },
                {
                    id: 14,
                    language_code: 'ar',
                    name: 'تم التقاطه',
                    pallet_status_id: 7,
                    waiting_status_name: 'Waiting Transfer Center'
                }
            ]
        }
    },
    {
        id: 65,
        is_encapsulated: 0,
        is_completed: 0,
        pallet_status_id: 6,
        ShipmentNumber: 'RF 10013',
        is_approved_by_system: 1,
        employee_user_id: null,
        barcode: null,
        main_status_id: 1,
        city_id: 6,
        country_id: 4,
        v_weight: 11,
        weight: 7,
        products_count: '17',
        tracking_details: [
            {
                id: 73,
                pallet_id: 65,
                pallet_status_id: 6,
                next_pallet_status_id: 7,
                changed_by_user_id: 1,
                start_pallet_status_time: '2023-08-19 14:58:56',
                end_pallet_status_time: null,
                TrackingCountry: 'Iraq',
                last_pallet_status_from: null,
                TrackingNotes: 'waiting pickup',
                notify_to_user_id: 6,
                start_notify_time: '2023-08-20 00:49:55',
                ready_for_next_pallet_status: 1,
                last_pallet_status: {
                    id: 6,
                    sequence: 6,
                    parent_id: 1,
                    color1: '#404040',
                    color2: '#404040',
                    icons_color: '#F7F7F7',
                    details_color: '#EFEFEF',
                    icon_has_check: 0,
                    pallets_count: 1,
                    orders_count: 2,
                    translations: [
                        {
                            id: 11,
                            language_code: 'en',
                            name: 'approved',
                            pallet_status_id: 6,
                            waiting_status_name: 'waiting pickup'
                        },
                        {
                            id: 12,
                            language_code: 'ar',
                            name: 'موافق عليه',
                            pallet_status_id: 6,
                            waiting_status_name: 'waiting pickup'
                        }
                    ]
                },
                last_pallet_status_user: {
                    id: 1,
                    username: 'admin',
                    email: 'admin@shipping.com',
                    avatar: null,
                    mobile_phone: '+963936997540',
                    work_phone: null,
                    surname: null,
                    birthdate: null,
                    gender: null,
                    address: null,
                    verified: 1,
                    last_active_at: null,
                    is_blocked_by_admin: 0,
                    website_url: null,
                    facebook_account: null,
                    instagram_account: null,
                    telegram_account: null,
                    tiktok_account: null,
                    postal_code: null,
                    can_change_password: 1,
                    updated_at: '2023-09-19T07:23:38.000000Z',
                    user_status_id: null,
                    num_of_failed_attempts: 0,
                    default_language_id: null,
                    role_id: null,
                    followers_number: 0,
                    following_number: 0,
                    products_number: 0,
                    services_number: 0,
                    is_locked_by_admin_for_delete: 0,
                    is_locked_by_admin_for_update: 0,
                    last_login_date: null,
                    preferred_currency_id: null,
                    mobile_verification_token: null,
                    mobile_verified_at: null,
                    business_document: null,
                    first_name: null,
                    last_name: null,
                    store_name: null,
                    secondary_email: null,
                    whatsapp_number: null,
                    twitter_url: null,
                    vat_number: null,
                    company_contact_phone_number: null,
                    full_address: null,
                    is_active: 0,
                    region_id: null,
                    manager_id: null,
                    is_admin: 0,
                    passport_number: null,
                    bank_account_details: null,
                    full_name: null,
                    citizen_country_id: null,
                    birth_city_id: null,
                    id_no: null,
                    passport_photo_path: null,
                    id_photo_path: null,
                    driving_license_no: null,
                    driving_license_photo_path: null,
                    id_no_region: null,
                    driver_license_region: null,
                    city: null,
                    birth_country: null,
                    driving_license_issued_from: null,
                    residence: null,
                    id_issued_from: null,
                    correlationId: null,
                    is_available_for_chat: 0,
                    role_names: 'admin',
                    current_username: '',
                    roles: [
                        {
                            is_signup_allowed: 0,
                            id: 1,
                            icon_path: null,
                            parent_id: null,
                            is_locked_by_admin_for_delete: 0,
                            is_locked_by_admin_for_update: 0,
                            insurance_amount: 0,
                            programming_name: null,
                            pivot: {
                                user_id: 1,
                                role_id: 1,
                                id: 1,
                                is_insured: 0
                            },
                            role_translations: {
                                id: 1,
                                role_id: 1,
                                language_code: 'en',
                                name: 'admin',
                                description: 'admin',
                                notes: null,
                                is_locked_by_admin_for_delete: 0,
                                is_locked_by_admin_for_update: 0
                            }
                        }
                    ]
                },
                current_pallet_status: {
                    id: 7,
                    sequence: 7,
                    parent_id: 1,
                    color1: '#404040',
                    color2: '#007CFF',
                    icons_color: '#F4F8FF',
                    details_color: '#DDFFF8',
                    icon_has_check: 0,
                    pallets_count: 2,
                    orders_count: 8,
                    translations: [
                        {
                            id: 13,
                            language_code: 'en',
                            name: 'picked',
                            pallet_status_id: 7,
                            waiting_status_name: 'Waiting Transfer Center'
                        },
                        {
                            id: 14,
                            language_code: 'ar',
                            name: 'تم التقاطه',
                            pallet_status_id: 7,
                            waiting_status_name: 'Waiting Transfer Center'
                        }
                    ]
                },
                notify_to_user: {
                    id: 6,
                    username: 'kinan-sad-28',
                    email: null,
                    avatar: '/storage/users/avatars/avatar.png',
                    mobile_phone: '+963998856217',
                    work_phone: null,
                    surname: null,
                    birthdate: null,
                    gender: null,
                    address: null,
                    verified: 1,
                    last_active_at: null,
                    is_blocked_by_admin: 0,
                    website_url: null,
                    facebook_account: null,
                    instagram_account: null,
                    telegram_account: null,
                    tiktok_account: null,
                    postal_code: null,
                    can_change_password: 1,
                    updated_at: '2023-08-21T00:45:11.000000Z',
                    user_status_id: null,
                    num_of_failed_attempts: 0,
                    default_language_id: null,
                    role_id: 3,
                    followers_number: 0,
                    following_number: 0,
                    products_number: 0,
                    services_number: 0,
                    is_locked_by_admin_for_delete: 0,
                    is_locked_by_admin_for_update: 0,
                    last_login_date: null,
                    preferred_currency_id: null,
                    mobile_verification_token: null,
                    mobile_verified_at: null,
                    business_document: null,
                    first_name: null,
                    last_name: null,
                    store_name: null,
                    secondary_email: null,
                    whatsapp_number: null,
                    twitter_url: null,
                    vat_number: null,
                    company_contact_phone_number: null,
                    full_address: null,
                    is_active: 0,
                    region_id: null,
                    manager_id: null,
                    is_admin: 0,
                    passport_number: null,
                    bank_account_details: null,
                    full_name: null,
                    citizen_country_id: null,
                    birth_city_id: null,
                    id_no: null,
                    passport_photo_path: null,
                    id_photo_path: null,
                    driving_license_no: null,
                    driving_license_photo_path: null,
                    id_no_region: null,
                    driver_license_region: null,
                    city: null,
                    birth_country: null,
                    driving_license_issued_from: null,
                    residence: null,
                    id_issued_from: null,
                    correlationId: 'Shipping-users-6',
                    is_available_for_chat: 0,
                    role_names: 'order collection employee',
                    current_username: 'kinan-sad-28',
                    roles: [
                        {
                            is_signup_allowed: 1,
                            id: 3,
                            icon_path: null,
                            parent_id: 2,
                            is_locked_by_admin_for_delete: 0,
                            is_locked_by_admin_for_update: 0,
                            insurance_amount: 0,
                            programming_name: 'collection_employee',
                            pivot: {
                                user_id: 6,
                                role_id: 3,
                                id: 6,
                                is_insured: 0
                            },
                            role_translations: {
                                id: 3,
                                role_id: 3,
                                language_code: 'en',
                                name: 'order collection employee',
                                description: null,
                                notes: null,
                                is_locked_by_admin_for_delete: 0,
                                is_locked_by_admin_for_update: 0
                            }
                        }
                    ]
                }
            }
        ],
        pallet_status: {
            id: 6,
            sequence: 6,
            parent_id: 1,
            color1: '#404040',
            color2: 'none',
            icons_color: '#F7F7F7',
            details_color: '#EFEFEF',
            icon_has_check: 0,
            pallets_count: 1,
            orders_count: 2,
            translations: [
                {
                    id: 11,
                    language_code: 'en',
                    name: 'approved',
                    pallet_status_id: 6,
                    waiting_status_name: 'waiting pickup'
                },
                {
                    id: 12,
                    language_code: 'ar',
                    name: 'موافق عليه',
                    pallet_status_id: 6,
                    waiting_status_name: 'waiting pickup'
                }
            ]
        }
    },
    {
        id: 61,
        is_encapsulated: 0,
        is_completed: 0,
        pallet_status_id: 7,
        ShipmentNumber: 'RF 10014',
        is_approved_by_system: 1,
        employee_user_id: null,
        barcode: null,
        main_status_id: 1,
        city_id: 6,
        country_id: 4,
        v_weight: 11,
        weight: 7,
        products_count: '17',
        tracking_details: [
            {
                id: 66,
                pallet_id: 61,
                pallet_status_id: 6,
                next_pallet_status_id: 7,
                changed_by_user_id: 1,
                start_pallet_status_time: '2023-08-19 12:19:13',
                end_pallet_status_time: '2023-08-19 15:05:23',
                TrackingCountry: 'Iraq',
                last_pallet_status_from: null,
                TrackingNotes: 'waiting pickup',
                notify_to_user_id: 6,
                start_notify_time: '2023-08-19 12:34:11',
                ready_for_next_pallet_status: 1,
                last_pallet_status: {
                    id: 6,
                    sequence: 6,
                    parent_id: 1,
                    color1: '#404040',
                    color2: '#404040',
                    icons_color: '#F7F7F7',
                    details_color: '#EFEFEF',
                    icon_has_check: 0,
                    pallets_count: 1,
                    orders_count: 2,
                    translations: [
                        {
                            id: 11,
                            language_code: 'en',
                            name: 'approved',
                            pallet_status_id: 6,
                            waiting_status_name: 'waiting pickup'
                        },
                        {
                            id: 12,
                            language_code: 'ar',
                            name: 'موافق عليه',
                            pallet_status_id: 6,
                            waiting_status_name: 'waiting pickup'
                        }
                    ]
                },
                last_pallet_status_user: {
                    id: 1,
                    username: 'admin',
                    email: 'admin@shipping.com',
                    avatar: null,
                    mobile_phone: '+963936997540',
                    work_phone: null,
                    surname: null,
                    birthdate: null,
                    gender: null,
                    address: null,
                    verified: 1,
                    last_active_at: null,
                    is_blocked_by_admin: 0,
                    website_url: null,
                    facebook_account: null,
                    instagram_account: null,
                    telegram_account: null,
                    tiktok_account: null,
                    postal_code: null,
                    can_change_password: 1,
                    updated_at: '2023-09-19T07:23:38.000000Z',
                    user_status_id: null,
                    num_of_failed_attempts: 0,
                    default_language_id: null,
                    role_id: null,
                    followers_number: 0,
                    following_number: 0,
                    products_number: 0,
                    services_number: 0,
                    is_locked_by_admin_for_delete: 0,
                    is_locked_by_admin_for_update: 0,
                    last_login_date: null,
                    preferred_currency_id: null,
                    mobile_verification_token: null,
                    mobile_verified_at: null,
                    business_document: null,
                    first_name: null,
                    last_name: null,
                    store_name: null,
                    secondary_email: null,
                    whatsapp_number: null,
                    twitter_url: null,
                    vat_number: null,
                    company_contact_phone_number: null,
                    full_address: null,
                    is_active: 0,
                    region_id: null,
                    manager_id: null,
                    is_admin: 0,
                    passport_number: null,
                    bank_account_details: null,
                    full_name: null,
                    citizen_country_id: null,
                    birth_city_id: null,
                    id_no: null,
                    passport_photo_path: null,
                    id_photo_path: null,
                    driving_license_no: null,
                    driving_license_photo_path: null,
                    id_no_region: null,
                    driver_license_region: null,
                    city: null,
                    birth_country: null,
                    driving_license_issued_from: null,
                    residence: null,
                    id_issued_from: null,
                    correlationId: null,
                    is_available_for_chat: 0,
                    role_names: 'admin',
                    current_username: '',
                    roles: [
                        {
                            is_signup_allowed: 0,
                            id: 1,
                            icon_path: null,
                            parent_id: null,
                            is_locked_by_admin_for_delete: 0,
                            is_locked_by_admin_for_update: 0,
                            insurance_amount: 0,
                            programming_name: null,
                            pivot: {
                                user_id: 1,
                                role_id: 1,
                                id: 1,
                                is_insured: 0
                            },
                            role_translations: {
                                id: 1,
                                role_id: 1,
                                language_code: 'en',
                                name: 'admin',
                                description: 'admin',
                                notes: null,
                                is_locked_by_admin_for_delete: 0,
                                is_locked_by_admin_for_update: 0
                            }
                        }
                    ]
                },
                current_pallet_status: {
                    id: 7,
                    sequence: 7,
                    parent_id: 1,
                    color1: '#404040',
                    color2: '#007CFF',
                    icons_color: '#F4F8FF',
                    details_color: '#DDFFF8',
                    icon_has_check: 0,
                    pallets_count: 2,
                    orders_count: 8,
                    translations: [
                        {
                            id: 13,
                            language_code: 'en',
                            name: 'picked',
                            pallet_status_id: 7,
                            waiting_status_name: 'Waiting Transfer Center'
                        },
                        {
                            id: 14,
                            language_code: 'ar',
                            name: 'تم التقاطه',
                            pallet_status_id: 7,
                            waiting_status_name: 'Waiting Transfer Center'
                        }
                    ]
                },
                notify_to_user: {
                    id: 6,
                    username: 'kinan-sad-28',
                    email: null,
                    avatar: '/storage/users/avatars/avatar.png',
                    mobile_phone: '+963998856217',
                    work_phone: null,
                    surname: null,
                    birthdate: null,
                    gender: null,
                    address: null,
                    verified: 1,
                    last_active_at: null,
                    is_blocked_by_admin: 0,
                    website_url: null,
                    facebook_account: null,
                    instagram_account: null,
                    telegram_account: null,
                    tiktok_account: null,
                    postal_code: null,
                    can_change_password: 1,
                    updated_at: '2023-08-21T00:45:11.000000Z',
                    user_status_id: null,
                    num_of_failed_attempts: 0,
                    default_language_id: null,
                    role_id: 3,
                    followers_number: 0,
                    following_number: 0,
                    products_number: 0,
                    services_number: 0,
                    is_locked_by_admin_for_delete: 0,
                    is_locked_by_admin_for_update: 0,
                    last_login_date: null,
                    preferred_currency_id: null,
                    mobile_verification_token: null,
                    mobile_verified_at: null,
                    business_document: null,
                    first_name: null,
                    last_name: null,
                    store_name: null,
                    secondary_email: null,
                    whatsapp_number: null,
                    twitter_url: null,
                    vat_number: null,
                    company_contact_phone_number: null,
                    full_address: null,
                    is_active: 0,
                    region_id: null,
                    manager_id: null,
                    is_admin: 0,
                    passport_number: null,
                    bank_account_details: null,
                    full_name: null,
                    citizen_country_id: null,
                    birth_city_id: null,
                    id_no: null,
                    passport_photo_path: null,
                    id_photo_path: null,
                    driving_license_no: null,
                    driving_license_photo_path: null,
                    id_no_region: null,
                    driver_license_region: null,
                    city: null,
                    birth_country: null,
                    driving_license_issued_from: null,
                    residence: null,
                    id_issued_from: null,
                    correlationId: 'Shipping-users-6',
                    is_available_for_chat: 0,
                    role_names: 'order collection employee',
                    current_username: 'kinan-sad-28',
                    roles: [
                        {
                            is_signup_allowed: 1,
                            id: 3,
                            icon_path: null,
                            parent_id: 2,
                            is_locked_by_admin_for_delete: 0,
                            is_locked_by_admin_for_update: 0,
                            insurance_amount: 0,
                            programming_name: 'collection_employee',
                            pivot: {
                                user_id: 6,
                                role_id: 3,
                                id: 6,
                                is_insured: 0
                            },
                            role_translations: {
                                id: 3,
                                role_id: 3,
                                language_code: 'en',
                                name: 'order collection employee',
                                description: null,
                                notes: null,
                                is_locked_by_admin_for_delete: 0,
                                is_locked_by_admin_for_update: 0
                            }
                        }
                    ]
                }
            },
            {
                id: 74,
                pallet_id: 61,
                pallet_status_id: 7,
                next_pallet_status_id: 8,
                changed_by_user_id: 6,
                start_pallet_status_time: '2023-08-19 15:05:23',
                end_pallet_status_time: null,
                TrackingCountry: 'Iraq',
                last_pallet_status_from: 166,
                TrackingNotes: 'Waiting Transfer Center',
                notify_to_user_id: null,
                start_notify_time: null,
                ready_for_next_pallet_status: 1,
                last_pallet_status: {
                    id: 7,
                    sequence: 7,
                    parent_id: 1,
                    color1: '#404040',
                    color2: '#007CFF',
                    icons_color: '#F4F8FF',
                    details_color: '#DDFFF8',
                    icon_has_check: 0,
                    pallets_count: 2,
                    orders_count: 8,
                    translations: [
                        {
                            id: 13,
                            language_code: 'en',
                            name: 'picked',
                            pallet_status_id: 7,
                            waiting_status_name: 'Waiting Transfer Center'
                        },
                        {
                            id: 14,
                            language_code: 'ar',
                            name: 'تم التقاطه',
                            pallet_status_id: 7,
                            waiting_status_name: 'Waiting Transfer Center'
                        }
                    ]
                },
                last_pallet_status_user: {
                    id: 6,
                    username: 'kinan-sad-28',
                    email: null,
                    avatar: '/storage/users/avatars/avatar.png',
                    mobile_phone: '+963998856217',
                    work_phone: null,
                    surname: null,
                    birthdate: null,
                    gender: null,
                    address: null,
                    verified: 1,
                    last_active_at: null,
                    is_blocked_by_admin: 0,
                    website_url: null,
                    facebook_account: null,
                    instagram_account: null,
                    telegram_account: null,
                    tiktok_account: null,
                    postal_code: null,
                    can_change_password: 1,
                    updated_at: '2023-08-21T00:45:11.000000Z',
                    user_status_id: null,
                    num_of_failed_attempts: 0,
                    default_language_id: null,
                    role_id: 3,
                    followers_number: 0,
                    following_number: 0,
                    products_number: 0,
                    services_number: 0,
                    is_locked_by_admin_for_delete: 0,
                    is_locked_by_admin_for_update: 0,
                    last_login_date: null,
                    preferred_currency_id: null,
                    mobile_verification_token: null,
                    mobile_verified_at: null,
                    business_document: null,
                    first_name: null,
                    last_name: null,
                    store_name: null,
                    secondary_email: null,
                    whatsapp_number: null,
                    twitter_url: null,
                    vat_number: null,
                    company_contact_phone_number: null,
                    full_address: null,
                    is_active: 0,
                    region_id: null,
                    manager_id: null,
                    is_admin: 0,
                    passport_number: null,
                    bank_account_details: null,
                    full_name: null,
                    citizen_country_id: null,
                    birth_city_id: null,
                    id_no: null,
                    passport_photo_path: null,
                    id_photo_path: null,
                    driving_license_no: null,
                    driving_license_photo_path: null,
                    id_no_region: null,
                    driver_license_region: null,
                    city: null,
                    birth_country: null,
                    driving_license_issued_from: null,
                    residence: null,
                    id_issued_from: null,
                    correlationId: 'Shipping-users-6',
                    is_available_for_chat: 0,
                    role_names: 'order collection employee',
                    current_username: 'kinan-sad-28',
                    roles: [
                        {
                            is_signup_allowed: 1,
                            id: 3,
                            icon_path: null,
                            parent_id: 2,
                            is_locked_by_admin_for_delete: 0,
                            is_locked_by_admin_for_update: 0,
                            insurance_amount: 0,
                            programming_name: 'collection_employee',
                            pivot: {
                                user_id: 6,
                                role_id: 3,
                                id: 6,
                                is_insured: 0
                            },
                            role_translations: {
                                id: 3,
                                role_id: 3,
                                language_code: 'en',
                                name: 'order collection employee',
                                description: null,
                                notes: null,
                                is_locked_by_admin_for_delete: 0,
                                is_locked_by_admin_for_update: 0
                            }
                        }
                    ]
                },
                current_pallet_status: {
                    id: 8,
                    sequence: 8,
                    parent_id: 1,
                    color1: '#404040',
                    color2: '#00C408',
                    icons_color: '#F9FFF4',
                    details_color: '#F1FDDA',
                    icon_has_check: 0,
                    pallets_count: 0,
                    orders_count: 0,
                    translations: [
                        {
                            id: 15,
                            language_code: 'en',
                            name: 'transfer center',
                            pallet_status_id: 8,
                            waiting_status_name: 'waiting progress'
                        },
                        {
                            id: 16,
                            language_code: 'ar',
                            name: 'مركز النقل',
                            pallet_status_id: 8,
                            waiting_status_name: 'waiting progress'
                        }
                    ]
                },
                notify_to_user: null
            }
        ],
        pallet_status: {
            id: 7,
            sequence: 7,
            parent_id: 1,
            color1: '#007CFF',
            color2: '#007CFF',
            icons_color: '#F4F8FF',
            details_color: '#DDFFF8',
            icon_has_check: 0,
            pallets_count: 2,
            orders_count: 8,
            translations: [
                {
                    id: 13,
                    language_code: 'en',
                    name: 'picked',
                    pallet_status_id: 7,
                    waiting_status_name: 'Waiting Transfer Center'
                },
                {
                    id: 14,
                    language_code: 'ar',
                    name: 'تم التقاطه',
                    pallet_status_id: 7,
                    waiting_status_name: 'Waiting Transfer Center'
                }
            ]
        }
    },
    {
        id: 50,
        is_encapsulated: 0,
        is_completed: 0,
        pallet_status_id: 5,
        ShipmentNumber: 'RF 10015',
        is_approved_by_system: 0,
        employee_user_id: null,
        barcode: null,
        main_status_id: 1,
        city_id: null,
        country_id: null,
        v_weight: 11,
        weight: 7,
        products_count: '17',
        tracking_details: [
            {
                id: 52,
                pallet_id: 52,
                pallet_status_id: 5,
                next_pallet_status_id: null,
                changed_by_user_id: 1,
                start_pallet_status_time: '2023-08-09 16:44:42',
                end_pallet_status_time: null,
                TrackingCountry: 'Syria',
                last_pallet_status_from: null,
                TrackingNotes: 'Request A Correction',
                notify_to_user_id: null,
                start_notify_time: null,
                ready_for_next_pallet_status: 0,
                last_pallet_status: {
                    id: 5,
                    sequence: null,
                    parent_id: 1,
                    color1: '#404040',
                    color2: '#404040',
                    icons_color: '#FFF7F4',
                    details_color: '#FFEAEA',
                    icon_has_check: 0,
                    pallets_count: 3,
                    orders_count: 6,
                    translations: [
                        {
                            id: 9,
                            language_code: 'en',
                            name: 'waiting',
                            pallet_status_id: 5,
                            waiting_status_name: 'Request A Correction'
                        },
                        {
                            id: 10,
                            language_code: 'ar',
                            name: 'قيد الانتظار',
                            pallet_status_id: 5,
                            waiting_status_name: 'Request A Correction'
                        }
                    ]
                },
                last_pallet_status_user: {
                    id: 1,
                    username: 'admin',
                    email: 'admin@shipping.com',
                    avatar: null,
                    mobile_phone: '+963936997540',
                    work_phone: null,
                    surname: null,
                    birthdate: null,
                    gender: null,
                    address: null,
                    verified: 1,
                    last_active_at: null,
                    is_blocked_by_admin: 0,
                    website_url: null,
                    facebook_account: null,
                    instagram_account: null,
                    telegram_account: null,
                    tiktok_account: null,
                    postal_code: null,
                    can_change_password: 1,
                    updated_at: '2023-09-19T07:23:38.000000Z',
                    user_status_id: null,
                    num_of_failed_attempts: 0,
                    default_language_id: null,
                    role_id: null,
                    followers_number: 0,
                    following_number: 0,
                    products_number: 0,
                    services_number: 0,
                    is_locked_by_admin_for_delete: 0,
                    is_locked_by_admin_for_update: 0,
                    last_login_date: null,
                    preferred_currency_id: null,
                    mobile_verification_token: null,
                    mobile_verified_at: null,
                    business_document: null,
                    first_name: null,
                    last_name: null,
                    store_name: null,
                    secondary_email: null,
                    whatsapp_number: null,
                    twitter_url: null,
                    vat_number: null,
                    company_contact_phone_number: null,
                    full_address: null,
                    is_active: 0,
                    region_id: null,
                    manager_id: null,
                    is_admin: 0,
                    passport_number: null,
                    bank_account_details: null,
                    full_name: null,
                    citizen_country_id: null,
                    birth_city_id: null,
                    id_no: null,
                    passport_photo_path: null,
                    id_photo_path: null,
                    driving_license_no: null,
                    driving_license_photo_path: null,
                    id_no_region: null,
                    driver_license_region: null,
                    city: null,
                    birth_country: null,
                    driving_license_issued_from: null,
                    residence: null,
                    id_issued_from: null,
                    correlationId: null,
                    is_available_for_chat: 0,
                    role_names: 'admin',
                    current_username: '',
                    roles: [
                        {
                            is_signup_allowed: 0,
                            id: 1,
                            icon_path: null,
                            parent_id: null,
                            is_locked_by_admin_for_delete: 0,
                            is_locked_by_admin_for_update: 0,
                            insurance_amount: 0,
                            programming_name: null,
                            pivot: {
                                user_id: 1,
                                role_id: 1,
                                id: 1,
                                is_insured: 0
                            },
                            role_translations: {
                                id: 1,
                                role_id: 1,
                                language_code: 'en',
                                name: 'admin',
                                description: 'admin',
                                notes: null,
                                is_locked_by_admin_for_delete: 0,
                                is_locked_by_admin_for_update: 0
                            }
                        }
                    ]
                },
                current_pallet_status: null,
                notify_to_user: null
            }
        ],
        pallet_status: {
            id: 5,
            sequence: null,
            parent_id: 1,
            color1: '#404040',
            color2: 'none',
            icons_color: '#FFF7F4',
            details_color: '#FFEAEA',
            icon_has_check: 0,
            pallets_count: 3,
            orders_count: 6,
            translations: [
                {
                    id: 9,
                    language_code: 'en',
                    name: 'waiting',
                    pallet_status_id: 5,
                    waiting_status_name: 'Request A Correction'
                },
                {
                    id: 10,
                    language_code: 'ar',
                    name: 'قيد الانتظار',
                    pallet_status_id: 5,
                    waiting_status_name: 'Request A Correction'
                }
            ]
        }
    },
    {
        id: 51,
        is_encapsulated: 0,
        is_completed: 0,
        pallet_status_id: 9,
        ShipmentNumber: 'RF 10016',
        is_approved_by_system: 0,
        employee_user_id: null,
        barcode: null,
        main_status_id: 2,
        city_id: null,
        country_id: null,
        v_weight: 11,
        weight: 7,
        products_count: '17',
        tracking_details: [
            {
                id: 50,
                pallet_id: 50,
                pallet_status_id: 9,
                next_pallet_status_id: null,
                changed_by_user_id: 1,
                start_pallet_status_time: '2023-08-09 16:42:38',
                end_pallet_status_time: null,
                TrackingCountry: 'Syria',
                last_pallet_status_from: null,
                TrackingNotes: 'Request A Correction',
                notify_to_user_id: null,
                start_notify_time: null,
                ready_for_next_pallet_status: 0,
                last_pallet_status: {
                    id: 5,
                    sequence: null,
                    parent_id: 1,
                    color1: '#404040',
                    color2: '#404040',
                    icons_color: '#FFF7F4',
                    details_color: '#FFEAEA',
                    icon_has_check: 0,
                    pallets_count: 3,
                    orders_count: 6,
                    translations: [
                        {
                            id: 9,
                            language_code: 'en',
                            name: 'waiting',
                            pallet_status_id: 5,
                            waiting_status_name: 'Request A Correction'
                        },
                        {
                            id: 10,
                            language_code: 'ar',
                            name: 'قيد الانتظار',
                            pallet_status_id: 5,
                            waiting_status_name: 'Request A Correction'
                        }
                    ]
                },
                last_pallet_status_user: {
                    id: 1,
                    username: 'admin',
                    email: 'admin@shipping.com',
                    avatar: null,
                    mobile_phone: '+963936997540',
                    work_phone: null,
                    surname: null,
                    birthdate: null,
                    gender: null,
                    address: null,
                    verified: 1,
                    last_active_at: null,
                    is_blocked_by_admin: 0,
                    website_url: null,
                    facebook_account: null,
                    instagram_account: null,
                    telegram_account: null,
                    tiktok_account: null,
                    postal_code: null,
                    can_change_password: 1,
                    updated_at: '2023-09-19T07:23:38.000000Z',
                    user_status_id: null,
                    num_of_failed_attempts: 0,
                    default_language_id: null,
                    role_id: null,
                    followers_number: 0,
                    following_number: 0,
                    products_number: 0,
                    services_number: 0,
                    is_locked_by_admin_for_delete: 0,
                    is_locked_by_admin_for_update: 0,
                    last_login_date: null,
                    preferred_currency_id: null,
                    mobile_verification_token: null,
                    mobile_verified_at: null,
                    business_document: null,
                    first_name: null,
                    last_name: null,
                    store_name: null,
                    secondary_email: null,
                    whatsapp_number: null,
                    twitter_url: null,
                    vat_number: null,
                    company_contact_phone_number: null,
                    full_address: null,
                    is_active: 0,
                    region_id: null,
                    manager_id: null,
                    is_admin: 0,
                    passport_number: null,
                    bank_account_details: null,
                    full_name: null,
                    citizen_country_id: null,
                    birth_city_id: null,
                    id_no: null,
                    passport_photo_path: null,
                    id_photo_path: null,
                    driving_license_no: null,
                    driving_license_photo_path: null,
                    id_no_region: null,
                    driver_license_region: null,
                    city: null,
                    birth_country: null,
                    driving_license_issued_from: null,
                    residence: null,
                    id_issued_from: null,
                    correlationId: null,
                    is_available_for_chat: 0,
                    role_names: 'admin',
                    current_username: '',
                    roles: [
                        {
                            is_signup_allowed: 0,
                            id: 1,
                            icon_path: null,
                            parent_id: null,
                            is_locked_by_admin_for_delete: 0,
                            is_locked_by_admin_for_update: 0,
                            insurance_amount: 0,
                            programming_name: null,
                            pivot: {
                                user_id: 1,
                                role_id: 1,
                                id: 1,
                                is_insured: 0
                            },
                            role_translations: {
                                id: 1,
                                role_id: 1,
                                language_code: 'en',
                                name: 'admin',
                                description: 'admin',
                                notes: null,
                                is_locked_by_admin_for_delete: 0,
                                is_locked_by_admin_for_update: 0
                            }
                        }
                    ]
                },
                current_pallet_status: null,
                notify_to_user: null
            }
        ],
        pallet_status: {
            id: 5,
            sequence: null,
            parent_id: 1,
            color1: '#404040',
            color2: 'none',
            icons_color: '#FFF7F4',
            details_color: '#FFEAEA',
            icon_has_check: 0,
            pallets_count: 3,
            orders_count: 6,
            translations: [
                {
                    id: 9,
                    language_code: 'en',
                    name: 'waiting',
                    pallet_status_id: 5,
                    waiting_status_name: 'Request A Correction'
                },
                {
                    id: 10,
                    language_code: 'ar',
                    name: 'قيد الانتظار',
                    pallet_status_id: 5,
                    waiting_status_name: 'Request A Correction'
                }
            ]
        }
    },
    {
        id: 52,
        is_encapsulated: 0,
        is_completed: 0,
        pallet_status_id: 12,
        ShipmentNumber: 'RF 10017',
        is_approved_by_system: 0,
        employee_user_id: null,
        barcode: null,
        main_status_id: 2,
        city_id: null,
        country_id: null,
        v_weight: 11,
        weight: 7,
        products_count: '17',
        tracking_details: [
            {
                id: 50,
                pallet_id: 50,
                pallet_status_id: 9,
                next_pallet_status_id: null,
                changed_by_user_id: 1,
                start_pallet_status_time: '2023-08-09 16:42:38',
                end_pallet_status_time: null,
                TrackingCountry: 'Syria',
                last_pallet_status_from: null,
                TrackingNotes: 'Request A Correction',
                notify_to_user_id: null,
                start_notify_time: null,
                ready_for_next_pallet_status: 0,
                last_pallet_status: {
                    id: 5,
                    sequence: null,
                    parent_id: 1,
                    color1: '#404040',
                    color2: '#404040',
                    icons_color: '#FFF7F4',
                    details_color: '#FFEAEA',
                    icon_has_check: 0,
                    pallets_count: 3,
                    orders_count: 6,
                    translations: [
                        {
                            id: 9,
                            language_code: 'en',
                            name: 'waiting',
                            pallet_status_id: 5,
                            waiting_status_name: 'Request A Correction'
                        },
                        {
                            id: 10,
                            language_code: 'ar',
                            name: 'قيد الانتظار',
                            pallet_status_id: 5,
                            waiting_status_name: 'Request A Correction'
                        }
                    ]
                },
                last_pallet_status_user: {
                    id: 1,
                    username: 'admin',
                    email: 'admin@shipping.com',
                    avatar: null,
                    mobile_phone: '+963936997540',
                    work_phone: null,
                    surname: null,
                    birthdate: null,
                    gender: null,
                    address: null,
                    verified: 1,
                    last_active_at: null,
                    is_blocked_by_admin: 0,
                    website_url: null,
                    facebook_account: null,
                    instagram_account: null,
                    telegram_account: null,
                    tiktok_account: null,
                    postal_code: null,
                    can_change_password: 1,
                    updated_at: '2023-09-19T07:23:38.000000Z',
                    user_status_id: null,
                    num_of_failed_attempts: 0,
                    default_language_id: null,
                    role_id: null,
                    followers_number: 0,
                    following_number: 0,
                    products_number: 0,
                    services_number: 0,
                    is_locked_by_admin_for_delete: 0,
                    is_locked_by_admin_for_update: 0,
                    last_login_date: null,
                    preferred_currency_id: null,
                    mobile_verification_token: null,
                    mobile_verified_at: null,
                    business_document: null,
                    first_name: null,
                    last_name: null,
                    store_name: null,
                    secondary_email: null,
                    whatsapp_number: null,
                    twitter_url: null,
                    vat_number: null,
                    company_contact_phone_number: null,
                    full_address: null,
                    is_active: 0,
                    region_id: null,
                    manager_id: null,
                    is_admin: 0,
                    passport_number: null,
                    bank_account_details: null,
                    full_name: null,
                    citizen_country_id: null,
                    birth_city_id: null,
                    id_no: null,
                    passport_photo_path: null,
                    id_photo_path: null,
                    driving_license_no: null,
                    driving_license_photo_path: null,
                    id_no_region: null,
                    driver_license_region: null,
                    city: null,
                    birth_country: null,
                    driving_license_issued_from: null,
                    residence: null,
                    id_issued_from: null,
                    correlationId: null,
                    is_available_for_chat: 0,
                    role_names: 'admin',
                    current_username: '',
                    roles: [
                        {
                            is_signup_allowed: 0,
                            id: 1,
                            icon_path: null,
                            parent_id: null,
                            is_locked_by_admin_for_delete: 0,
                            is_locked_by_admin_for_update: 0,
                            insurance_amount: 0,
                            programming_name: null,
                            pivot: {
                                user_id: 1,
                                role_id: 1,
                                id: 1,
                                is_insured: 0
                            },
                            role_translations: {
                                id: 1,
                                role_id: 1,
                                language_code: 'en',
                                name: 'admin',
                                description: 'admin',
                                notes: null,
                                is_locked_by_admin_for_delete: 0,
                                is_locked_by_admin_for_update: 0
                            }
                        }
                    ]
                },
                current_pallet_status: null,
                notify_to_user: null
            }
        ],
        pallet_status: {
            id: 5,
            sequence: null,
            parent_id: 1,
            color1: '#404040',
            color2: 'none',
            icons_color: '#FFF7F4',
            details_color: '#FFEAEA',
            icon_has_check: 0,
            pallets_count: 3,
            orders_count: 6,
            translations: [
                {
                    id: 9,
                    language_code: 'en',
                    name: 'waiting',
                    pallet_status_id: 5,
                    waiting_status_name: 'Request A Correction'
                },
                {
                    id: 10,
                    language_code: 'ar',
                    name: 'قيد الانتظار',
                    pallet_status_id: 5,
                    waiting_status_name: 'Request A Correction'
                }
            ]
        }
    }
]
export const countries = [
    {
        id: 1,
        is_verified_from_us: 0,
        region_type_id: 1,
        parent_region_id: null,
        country_id: null,
        govern_id: null,
        city_id: null,
        street_id: null,
        is_country: 1,
        is_govern: 0,
        is_city: 0,
        is_street: 0,
        district_id: null,
        building_id: null,
        floor_id: null,
        flat_id: null,
        is_district: 0,
        is_building: 0,
        is_floor: 0,
        is_flat: 0,
        is_locked_by_admin_for_delete: 0,
        is_locked_by_admin_for_update: 0,
        outline_photo_path_1: null,
        outline_photo_path_2: null,
        flag_photo_path: null,
        map_photo_path: null,
        phone_code: null,
        gmt_difference: null,
        region_code: null,
        is_office: 0,
        location_description: null,
        office_website: null,
        office_mail: null,
        office_id: null,
        is_available_to_market: 0,
        post_code: null,
        building_name: null,
        phone_number_1: null,
        phone_number_2: null,
        gsm_1: null,
        gsm_2: null,
        tower: null,
        blok: null,
        floor: null,
        No: null,
        office_post_code: null,
        pallets_count: 0,
        region_translations: [
            {
                id: 1,
                region_id: 1,
                language_code: 'en',
                region_name: 'lebanon',
                region_description: null,
                is_locked_by_admin_for_delete: 0,
                is_locked_by_admin_for_update: 0
            },
            {
                id: 2,
                region_id: 1,
                language_code: 'ar',
                region_name: 'لبنان',
                region_description: null,
                is_locked_by_admin_for_delete: 0,
                is_locked_by_admin_for_update: 0
            }
        ],
        region_latlongs: [
            {
                id: 1,
                region_lat: 33.866344,
                region_long: 35.567562,
                region_id: 1,
                is_locked_by_admin_for_delete: 0,
                is_locked_by_admin_for_update: 0
            }
        ],
        region_type: {
            id: 1,
            verified_by_user_id: null,
            is_locked_by_admin_for_delete: 0,
            is_locked_by_admin_for_update: 0,
            parent_region_type_id: null
        },
        sub_regions: [
            {
                id: 2,
                is_verified_from_us: 0,
                region_type_id: 2,
                parent_region_id: 1,
                country_id: 1,
                govern_id: null,
                city_id: null,
                street_id: null,
                is_country: 0,
                is_govern: 0,
                is_city: 1,
                is_street: 0,
                district_id: null,
                building_id: null,
                floor_id: null,
                flat_id: null,
                is_district: 0,
                is_building: 0,
                is_floor: 0,
                is_flat: 0,
                is_locked_by_admin_for_delete: 0,
                is_locked_by_admin_for_update: 0,
                outline_photo_path_1: null,
                outline_photo_path_2: null,
                flag_photo_path: null,
                map_photo_path: null,
                phone_code: null,
                gmt_difference: null,
                region_code: null,
                is_office: 0,
                location_description: null,
                office_website: null,
                office_mail: null,
                office_id: null,
                is_available_to_market: 0,
                post_code: null,
                building_name: null,
                phone_number_1: null,
                phone_number_2: null,
                gsm_1: null,
                gsm_2: null,
                tower: null,
                blok: null,
                floor: null,
                No: null,
                office_post_code: null,
                pallets_count: 0,
                region_translations: [
                    {
                        id: 3,
                        region_id: 2,
                        language_code: 'en',
                        region_name: 'beirut',
                        region_description: null,
                        is_locked_by_admin_for_delete: 0,
                        is_locked_by_admin_for_update: 0
                    },
                    {
                        id: 4,
                        region_id: 2,
                        language_code: 'ar',
                        region_name: 'بيروت',
                        region_description: null,
                        is_locked_by_admin_for_delete: 0,
                        is_locked_by_admin_for_update: 0
                    }
                ],
                region_latlongs: [
                    {
                        id: 2,
                        region_lat: 33.866344,
                        region_long: 35.567562,
                        region_id: 2,
                        is_locked_by_admin_for_delete: 0,
                        is_locked_by_admin_for_update: 0
                    }
                ],
                region_type: {
                    id: 2,
                    verified_by_user_id: null,
                    is_locked_by_admin_for_delete: 0,
                    is_locked_by_admin_for_update: 0,
                    parent_region_type_id: null,
                    translations: [
                        {
                            id: 2,
                            region_type_id: 2,
                            language_code: 'en',
                            region_type_name: 'city',
                            is_locked_by_admin_for_delete: 0,
                            is_locked_by_admin_for_update: 0
                        }
                    ]
                }
            },
            {
                id: 3,
                is_verified_from_us: 0,
                region_type_id: 2,
                parent_region_id: 1,
                country_id: 1,
                govern_id: null,
                city_id: null,
                street_id: null,
                is_country: 0,
                is_govern: 0,
                is_city: 1,
                is_street: 0,
                district_id: null,
                building_id: null,
                floor_id: null,
                flat_id: null,
                is_district: 0,
                is_building: 0,
                is_floor: 0,
                is_flat: 0,
                is_locked_by_admin_for_delete: 0,
                is_locked_by_admin_for_update: 0,
                outline_photo_path_1: null,
                outline_photo_path_2: null,
                flag_photo_path: null,
                map_photo_path: null,
                phone_code: null,
                gmt_difference: null,
                region_code: null,
                is_office: 0,
                location_description: null,
                office_website: null,
                office_mail: null,
                office_id: null,
                is_available_to_market: 0,
                post_code: null,
                building_name: null,
                phone_number_1: null,
                phone_number_2: null,
                gsm_1: null,
                gsm_2: null,
                tower: null,
                blok: null,
                floor: null,
                No: null,
                office_post_code: null,
                pallets_count: 0,
                region_translations: [
                    {
                        id: 5,
                        region_id: 3,
                        language_code: 'en',
                        region_name: 'Tripoli',
                        region_description: null,
                        is_locked_by_admin_for_delete: 0,
                        is_locked_by_admin_for_update: 0
                    },
                    {
                        id: 6,
                        region_id: 3,
                        language_code: 'ar',
                        region_name: 'طرابلس',
                        region_description: null,
                        is_locked_by_admin_for_delete: 0,
                        is_locked_by_admin_for_update: 0
                    }
                ],
                region_latlongs: [
                    {
                        id: 3,
                        region_lat: 34.427776,
                        region_long: 35.839302,
                        region_id: 3,
                        is_locked_by_admin_for_delete: 0,
                        is_locked_by_admin_for_update: 0
                    }
                ],
                region_type: {
                    id: 2,
                    verified_by_user_id: null,
                    is_locked_by_admin_for_delete: 0,
                    is_locked_by_admin_for_update: 0,
                    parent_region_type_id: null,
                    translations: [
                        {
                            id: 2,
                            region_type_id: 2,
                            language_code: 'en',
                            region_type_name: 'city',
                            is_locked_by_admin_for_delete: 0,
                            is_locked_by_admin_for_update: 0
                        }
                    ]
                }
            }
        ],
        currencies: [],
        languages: []
    },
    {
        id: 4,
        is_verified_from_us: 0,
        region_type_id: 1,
        parent_region_id: null,
        country_id: null,
        govern_id: null,
        city_id: null,
        street_id: null,
        is_country: 1,
        is_govern: 0,
        is_city: 0,
        is_street: 0,
        district_id: null,
        building_id: null,
        floor_id: null,
        flat_id: null,
        is_district: 0,
        is_building: 0,
        is_floor: 0,
        is_flat: 0,
        is_locked_by_admin_for_delete: 0,
        is_locked_by_admin_for_update: 0,
        outline_photo_path_1: null,
        outline_photo_path_2: null,
        flag_photo_path: null,
        map_photo_path: null,
        phone_code: null,
        gmt_difference: null,
        region_code: null,
        is_office: 0,
        location_description: null,
        office_website: null,
        office_mail: null,
        office_id: null,
        is_available_to_market: 0,
        post_code: null,
        building_name: null,
        phone_number_1: null,
        phone_number_2: null,
        gsm_1: null,
        gsm_2: null,
        tower: null,
        blok: null,
        floor: null,
        No: null,
        office_post_code: null,
        pallets_count: 0,
        region_translations: [
            {
                id: 7,
                region_id: 4,
                language_code: 'en',
                region_name: 'Iraq',
                region_description: null,
                is_locked_by_admin_for_delete: 0,
                is_locked_by_admin_for_update: 0
            },
            {
                id: 8,
                region_id: 4,
                language_code: 'ar',
                region_name: 'العراق',
                region_description: null,
                is_locked_by_admin_for_delete: 0,
                is_locked_by_admin_for_update: 0
            }
        ],
        region_latlongs: [
            {
                id: 4,
                region_lat: 33.324018,
                region_long: 44.421131,
                region_id: 4,
                is_locked_by_admin_for_delete: 0,
                is_locked_by_admin_for_update: 0
            }
        ],
        region_type: {
            id: 1,
            verified_by_user_id: null,
            is_locked_by_admin_for_delete: 0,
            is_locked_by_admin_for_update: 0,
            parent_region_type_id: null
        },
        sub_regions: [
            {
                id: 5,
                is_verified_from_us: 0,
                region_type_id: 2,
                parent_region_id: 4,
                country_id: 4,
                govern_id: null,
                city_id: null,
                street_id: null,
                is_country: 0,
                is_govern: 0,
                is_city: 1,
                is_street: 0,
                district_id: null,
                building_id: null,
                floor_id: null,
                flat_id: null,
                is_district: 0,
                is_building: 0,
                is_floor: 0,
                is_flat: 0,
                is_locked_by_admin_for_delete: 0,
                is_locked_by_admin_for_update: 0,
                outline_photo_path_1: null,
                outline_photo_path_2: null,
                flag_photo_path: null,
                map_photo_path: null,
                phone_code: null,
                gmt_difference: null,
                region_code: null,
                is_office: 0,
                location_description: null,
                office_website: null,
                office_mail: null,
                office_id: null,
                is_available_to_market: 0,
                post_code: null,
                building_name: null,
                phone_number_1: null,
                phone_number_2: null,
                gsm_1: null,
                gsm_2: null,
                tower: null,
                blok: null,
                floor: null,
                No: null,
                office_post_code: null,
                pallets_count: 1,
                region_translations: [
                    {
                        id: 9,
                        region_id: 5,
                        language_code: 'en',
                        region_name: 'Baghdad',
                        region_description: null,
                        is_locked_by_admin_for_delete: 0,
                        is_locked_by_admin_for_update: 0
                    },
                    {
                        id: 10,
                        region_id: 5,
                        language_code: 'ar',
                        region_name: 'بغداد',
                        region_description: null,
                        is_locked_by_admin_for_delete: 0,
                        is_locked_by_admin_for_update: 0
                    }
                ],
                region_latlongs: [
                    {
                        id: 5,
                        region_lat: 33.324018,
                        region_long: 44.421131,
                        region_id: 5,
                        is_locked_by_admin_for_delete: 0,
                        is_locked_by_admin_for_update: 0
                    }
                ],
                region_type: {
                    id: 2,
                    verified_by_user_id: null,
                    is_locked_by_admin_for_delete: 0,
                    is_locked_by_admin_for_update: 0,
                    parent_region_type_id: null,
                    translations: [
                        {
                            id: 2,
                            region_type_id: 2,
                            language_code: 'en',
                            region_type_name: 'city',
                            is_locked_by_admin_for_delete: 0,
                            is_locked_by_admin_for_update: 0
                        }
                    ]
                }
            },
            {
                id: 6,
                is_verified_from_us: 0,
                region_type_id: 2,
                parent_region_id: 4,
                country_id: 4,
                govern_id: null,
                city_id: null,
                street_id: null,
                is_country: 0,
                is_govern: 0,
                is_city: 1,
                is_street: 0,
                district_id: null,
                building_id: null,
                floor_id: null,
                flat_id: null,
                is_district: 0,
                is_building: 0,
                is_floor: 0,
                is_flat: 0,
                is_locked_by_admin_for_delete: 0,
                is_locked_by_admin_for_update: 0,
                outline_photo_path_1: null,
                outline_photo_path_2: null,
                flag_photo_path: null,
                map_photo_path: null,
                phone_code: null,
                gmt_difference: null,
                region_code: null,
                is_office: 0,
                location_description: null,
                office_website: null,
                office_mail: null,
                office_id: null,
                is_available_to_market: 0,
                post_code: null,
                building_name: null,
                phone_number_1: null,
                phone_number_2: null,
                gsm_1: null,
                gsm_2: null,
                tower: null,
                blok: null,
                floor: null,
                No: null,
                office_post_code: null,
                pallets_count: 2,
                region_translations: [
                    {
                        id: 11,
                        region_id: 6,
                        language_code: 'en',
                        region_name: 'Erbil',
                        region_description: null,
                        is_locked_by_admin_for_delete: 0,
                        is_locked_by_admin_for_update: 0
                    },
                    {
                        id: 12,
                        region_id: 6,
                        language_code: 'ar',
                        region_name: 'أربيل',
                        region_description: null,
                        is_locked_by_admin_for_delete: 0,
                        is_locked_by_admin_for_update: 0
                    }
                ],
                region_latlongs: [
                    {
                        id: 6,
                        region_lat: 36.191172,
                        region_long: 44.009653,
                        region_id: 6,
                        is_locked_by_admin_for_delete: 0,
                        is_locked_by_admin_for_update: 0
                    }
                ],
                region_type: {
                    id: 2,
                    verified_by_user_id: null,
                    is_locked_by_admin_for_delete: 0,
                    is_locked_by_admin_for_update: 0,
                    parent_region_type_id: null,
                    translations: [
                        {
                            id: 2,
                            region_type_id: 2,
                            language_code: 'en',
                            region_type_name: 'city',
                            is_locked_by_admin_for_delete: 0,
                            is_locked_by_admin_for_update: 0
                        }
                    ]
                }
            }
        ],
        currencies: [],
        languages: []
    },
    {
        id: 7,
        is_verified_from_us: 0,
        region_type_id: 1,
        parent_region_id: null,
        country_id: null,
        govern_id: null,
        city_id: null,
        street_id: null,
        is_country: 1,
        is_govern: 0,
        is_city: 0,
        is_street: 0,
        district_id: null,
        building_id: null,
        floor_id: null,
        flat_id: null,
        is_district: 0,
        is_building: 0,
        is_floor: 0,
        is_flat: 0,
        is_locked_by_admin_for_delete: 0,
        is_locked_by_admin_for_update: 0,
        outline_photo_path_1: null,
        outline_photo_path_2: null,
        flag_photo_path: null,
        map_photo_path: null,
        phone_code: null,
        gmt_difference: null,
        region_code: null,
        is_office: 0,
        location_description: null,
        office_website: null,
        office_mail: null,
        office_id: null,
        is_available_to_market: 0,
        post_code: null,
        building_name: null,
        phone_number_1: null,
        phone_number_2: null,
        gsm_1: null,
        gsm_2: null,
        tower: null,
        blok: null,
        floor: null,
        No: null,
        office_post_code: null,
        pallets_count: 0,
        region_translations: [
            {
                id: 13,
                region_id: 7,
                language_code: 'en',
                region_name: 'Syria',
                region_description: null,
                is_locked_by_admin_for_delete: 0,
                is_locked_by_admin_for_update: 0
            },
            {
                id: 14,
                region_id: 7,
                language_code: 'ar',
                region_name: 'سوريا',
                region_description: null,
                is_locked_by_admin_for_delete: 0,
                is_locked_by_admin_for_update: 0
            }
        ],
        region_latlongs: [
            {
                id: 7,
                region_lat: 33.513566,
                region_long: 36.479689,
                region_id: 7,
                is_locked_by_admin_for_delete: 0,
                is_locked_by_admin_for_update: 0
            }
        ],
        region_type: {
            id: 1,
            verified_by_user_id: null,
            is_locked_by_admin_for_delete: 0,
            is_locked_by_admin_for_update: 0,
            parent_region_type_id: null
        },
        sub_regions: [
            {
                id: 8,
                is_verified_from_us: 0,
                region_type_id: 2,
                parent_region_id: 7,
                country_id: 7,
                govern_id: null,
                city_id: null,
                street_id: null,
                is_country: 0,
                is_govern: 0,
                is_city: 1,
                is_street: 0,
                district_id: null,
                building_id: null,
                floor_id: null,
                flat_id: null,
                is_district: 0,
                is_building: 0,
                is_floor: 0,
                is_flat: 0,
                is_locked_by_admin_for_delete: 0,
                is_locked_by_admin_for_update: 0,
                outline_photo_path_1: null,
                outline_photo_path_2: null,
                flag_photo_path: null,
                map_photo_path: null,
                phone_code: null,
                gmt_difference: null,
                region_code: null,
                is_office: 0,
                location_description: null,
                office_website: null,
                office_mail: null,
                office_id: null,
                is_available_to_market: 0,
                post_code: null,
                building_name: null,
                phone_number_1: null,
                phone_number_2: null,
                gsm_1: null,
                gsm_2: null,
                tower: null,
                blok: null,
                floor: null,
                No: null,
                office_post_code: null,
                pallets_count: 0,
                region_translations: [
                    {
                        id: 15,
                        region_id: 8,
                        language_code: 'en',
                        region_name: 'Damascus',
                        region_description: null,
                        is_locked_by_admin_for_delete: 0,
                        is_locked_by_admin_for_update: 0
                    },
                    {
                        id: 16,
                        region_id: 8,
                        language_code: 'ar',
                        region_name: 'دمشق',
                        region_description: null,
                        is_locked_by_admin_for_delete: 0,
                        is_locked_by_admin_for_update: 0
                    }
                ],
                region_latlongs: [
                    {
                        id: 8,
                        region_lat: 33.513566,
                        region_long: 36.479689,
                        region_id: 8,
                        is_locked_by_admin_for_delete: 0,
                        is_locked_by_admin_for_update: 0
                    }
                ],
                region_type: {
                    id: 2,
                    verified_by_user_id: null,
                    is_locked_by_admin_for_delete: 0,
                    is_locked_by_admin_for_update: 0,
                    parent_region_type_id: null,
                    translations: [
                        {
                            id: 2,
                            region_type_id: 2,
                            language_code: 'en',
                            region_type_name: 'city',
                            is_locked_by_admin_for_delete: 0,
                            is_locked_by_admin_for_update: 0
                        }
                    ]
                }
            }
        ],
        currencies: [],
        languages: []
    }
]