import React from 'react'

function Sort() {
  return (
    <>
    <svg className='normal' id="_15x15_photo_back" data-name="15x15 photo back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
  <g id="Mask_Group_162" data-name="Mask Group 162">
    <g id="Group_7734" data-name="Group 7734" transform="translate(-801.939 -61.355)">
      <g id="Group_7733" data-name="Group 7733" transform="translate(-0.188 0.013)">
        <path id="Path_19051" data-name="Path 19051" d="M14.162,7.994c.335.028.591.428.573.9v7.165a.943.943,0,0,1-.265.775.444.444,0,0,1-.616,0,.943.943,0,0,1-.265-.775V8.889c-.018-.468.239-.867.574-.9Z" transform="translate(799.768 58.961)" fill="#8e8e8e" fillRule="evenodd"/>
        <path id="Path_19052" data-name="Path 19052" d="M11.426,12.225a.8.8,0,0,1,1.13,0l1.833,1.833,1.833-1.833a.8.8,0,1,1,1.13,1.13l-2.4,2.4a.8.8,0,0,1-1.13,0l-2.4-2.4a.8.8,0,0,1,0-1.131Z" transform="translate(799.54 59.929)" fill="#8e8e8e" fillRule="evenodd"/>
      </g>
      <line id="Line_1009" data-name="Line 1009" x2="10.125" transform="translate(802.445 63.284)" fill="none" stroke="#8e8e8e" strokeLinecap="round" strokeWidth="0.8"/>
      <line id="Line_1010" data-name="Line 1010" x2="8.1" transform="translate(802.445 68.347)" fill="none" stroke="#8e8e8e" strokeLinecap="round" strokeWidth="0.8"/>
      <line id="Line_1011" data-name="Line 1011" x2="5.063" transform="translate(802.445 73.41)" fill="none" stroke="#8e8e8e" strokeLinecap="round" strokeWidth="0.8"/>
    </g>
  </g>
</svg>
<svg className='active-svg' id="_15x15_photo_back" data-name="15x15 photo back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
  <g id="Mask_Group_163" data-name="Mask Group 163">
    <g id="Group_7734" data-name="Group 7734" transform="translate(-764.744 -90.568)">
      <g id="Group_7733" data-name="Group 7733">
        <path id="Path_19051" data-name="Path 19051" d="M13.9,7.724a.823.823,0,0,1,.773.87v6.923a.773.773,0,0,1-1.546,0V8.588a.823.823,0,0,1,.773-.87Z" transform="translate(762.726 87.561)" fill="#8e8e8e" fillRule="evenodd"/>
        <path id="Path_19052" data-name="Path 19052" d="M11.04,11.812a.773.773,0,0,1,1.092,0L13.9,13.583l1.771-1.771A.773.773,0,1,1,16.765,12.9l-2.319,2.319a.773.773,0,0,1-1.092,0L11.036,12.9a.773.773,0,0,1,0-1.093Z" transform="translate(762.726 88.491)" fill="#8e8e8e" fillRule="evenodd"/>
        <line id="Line_1009" data-name="Line 1009" x2="9.783" transform="translate(765.711 92.687)" fill="none" stroke="#8e8e8e" strokeLinecap="round" strokeWidth="1.6"/>
        <line id="Line_1010" data-name="Line 1010" x2="7.826" transform="translate(765.711 97.577)" fill="none" stroke="#8e8e8e" strokeLinecap="round" strokeWidth="1.6"/>
        <line id="Line_1011" data-name="Line 1011" x2="4.892" transform="translate(765.711 102.469)" fill="none" stroke="#8e8e8e" strokeLinecap="round" strokeWidth="1.6"/>
        <rect id="Rectangle_4561-2" data-name="Rectangle 4561" width="14.493" height="14.493" transform="translate(765.222 90.822)" fill="none"/>
      </g>
    </g>
  </g>
</svg>


    </>
  )
}

export default Sort