export default function AskForSend() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14.997" height="14.996" viewBox="0 0 14.997 14.996">
            <g id="Group_12040" data-name="Group 12040" transform="translate(0)">
                <path id="Subtraction_1" data-name="Subtraction 1" d="M.327,12.041a.322.322,0,0,1-.195-.068A.362.362,0,0,1,.013,11.6l.83-2.735A5.989,5.989,0,0,1,0,5.793,5.691,5.691,0,0,1,5.571,0,5.691,5.691,0,0,1,11.14,5.793a5.692,5.692,0,0,1-5.569,5.795A5.425,5.425,0,0,1,2.446,10.6L.512,11.983A.3.3,0,0,1,.327,12.041ZM5.519,8.725a.714.714,0,1,0,.7.714A.7.7,0,0,0,5.519,8.725Zm.144-5.686a1.1,1.1,0,0,1,1.192,1.1c0,.539-.229.874-.873,1.278A1.791,1.791,0,0,0,4.96,7.027v.127c0,.4.214.647.558.647.319,0,.5-.2.532-.586.026-.556.226-.836.893-1.244A2.1,2.1,0,0,0,5.71,2.021a2.245,2.245,0,0,0-2.2,1.228A1.482,1.482,0,0,0,3.37,3.9a.482.482,0,0,0,.517.542c.28,0,.436-.135.537-.465A1.188,1.188,0,0,1,5.662,3.039Z" transform="translate(0 2.953)" fill="#cecdcd" />
                <path id="Path_21380" data-name="Path 21380" d="M12.561,12.053a.324.324,0,0,1-.2.068.3.3,0,0,1-.183-.059l-1.935-1.386-.022.014a6.641,6.641,0,0,0,.5-2.545A6.36,6.36,0,0,0,4.5,1.67a5.9,5.9,0,0,0-1.367.159A5.406,5.406,0,0,1,7.122.079a5.692,5.692,0,0,1,5.57,5.794,5.971,5.971,0,0,1-.843,3.067l.83,2.736a.359.359,0,0,1-.118.377Z" transform="translate(1.068 0.602)" fill="#cecdcd" />
                <rect id="Rectangle_4714" data-name="Rectangle 4714" width="14.417" height="14.996" transform="translate(0.58)" fill="none" />
            </g>
        </svg>

    )
}