import React from 'react'

function Info() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
    <g id="_15x15_photo_back" data-name="15x15 photo back">
      <g id="info-2" transform="translate(4.186)">
        <path id="Path_18958" data-name="Path 18958" d="M10.615,13.381l-.21.857q-.943.372-1.505.567A3.97,3.97,0,0,1,7.6,15a2.605,2.605,0,0,1-1.776-.557,1.8,1.8,0,0,1-.633-1.415,5,5,0,0,1,.047-.681q.048-.348.153-.786l.786-2.781q.1-.4.177-.757a3.291,3.291,0,0,0,.072-.652,1.008,1.008,0,0,0-.22-.743,1.242,1.242,0,0,0-.84-.21,2.2,2.2,0,0,0-.625.1c-.214.063-.4.124-.55.181l.21-.857q.772-.314,1.477-.538A4.419,4.419,0,0,1,7.21,5.075a2.542,2.542,0,0,1,1.749.548,1.819,1.819,0,0,1,.614,1.424c0,.121-.013.334-.042.638a4.269,4.269,0,0,1-.157.839l-.782,2.77a7.358,7.358,0,0,0-.172.763,3.928,3.928,0,0,0-.076.647.933.933,0,0,0,.246.752,1.391,1.391,0,0,0,.856.2,2.472,2.472,0,0,0,.647-.1A3.619,3.619,0,0,0,10.615,13.381Zm.2-11.63a1.621,1.621,0,0,1-.547,1.234,1.863,1.863,0,0,1-1.318.51,1.884,1.884,0,0,1-1.325-.51,1.617,1.617,0,0,1-.553-1.234A1.633,1.633,0,0,1,7.624.514a1.955,1.955,0,0,1,2.643,0A1.637,1.637,0,0,1,10.814,1.752Z" transform="translate(-4.186)" fill="#8e8e8e"/>
      </g>
    </g>
  </svg>
  
  )
}

export default Info