import React from 'react'
import { useSelector } from 'react-redux'
import DepartmentItem from './DepartmentItem'

function DeptChildreen(props) {
  const { lang_id, setLang_id } = props
  const data = useSelector((state) => state.department.newDepartment)
  const mode = useSelector((state) => state.department.mode)
  const requested_data = useSelector((state) => state.department.request_data)

  const Active_Ids = useSelector((state) => state.department.mode)

  return (<>
    <div className='lang-childreen department'>
      {props.child.son_departments.map((child) => (
        child.translations && child.translations.length > 0 &&
        <DepartmentItem languageFollower={props.languageFollower}
          level={props.level}
          ActiveAdd={(e) => props.ActiveAdd(e)}
          makeFollower={(e, w, id) => props.makeFollower(e, w, id)}
          accent={"accent"}
          lang_id={lang_id}
          Active_Ids={Active_Ids}
          setModalData={(e) => props.setModalData(e)}
          setLang_id={(e) => setLang_id(e)}
          child={child}
          open={props.open}
          lang={props.lang}
          Close={() => props.Close()}
          Add={(e) => props.Add(e)}
          Update={(e) => props.Update(e)}
          Read={(e) => props.Read(e)}
          setOpen={(e) => props.setOpen(e)} />
      ))}
      {((((props.open) && props.lang_id === props.child.id && mode === "add") || (requested_data && requested_data.parent_department_id === props.child.id)) && mode !== "update" && mode !== "read") && <DepartmentItem lang={props.lang} {...props} languageFollower={props.languageFollower} new={"new"} level={props.level} makeFollower={(e, w, id) => props.makeFollower(e, w, id)} accent={"accent"} lang_id={lang_id} setLang_id={(e) => setLang_id(e)} child={requested_data ? requested_data : data} />}
    </div>
    {/* ATTENTION!! ADDING PROPS TO THE NEXT LINE languageFollower */}

  </>

  )
}

export default DeptChildreen