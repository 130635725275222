import { useEffect, useState } from "react"
import OdooTicket from "./tickets_types/odoo_ticket"
import WaitingIcon from "./svg/usdt_right"
import UpArrow from "./svg/upArrow"
import IsMore from "./svg/isMore"
import IndiaTicket from "./tickets_types/india"
import HashTicket from "./tickets_types/hash_ticket"
import OdooOnly from "./tickets_types/odoo_only"

export default function BulkList(props) {
    const [list, setList] = useState([])
    const [hasMore, sethasMore] = useState(false)
    const detectMissedElement = ({ newData, oldData }) => {
        let missed = null
        oldData.map(item => {
            if (newData.filter(one => one.id === item.id).length === 0) {
                missed = item
            }
        })
        return missed
    }
    const [renders, setRenders] = useState(0)
    useEffect(() => {
        if (renders > 0) {
            if (props.data?.length < list.length) {
                let element = detectMissedElement({
                    newData: props.data,
                    oldData: list
                })
                if (document.getElementById(`${element?.id}-list-item`)) {
                    let height = document.getElementById(`${element?.id}-list-item`).clientHeight
                    if (height < 150) {
                        document.getElementById(`${element?.id}-list-item`).style.animationName = "collapseItem100"
                    } else if (height >= 150 && height < 250) {
                        document.getElementById(`${element?.id}-list-item`).style.animationName = "collapseItem200"
                    } else if (height >= 250 && height < 350) {
                        document.getElementById(`${element?.id}-list-item`).style.animationName = "collapseItem300"
                    } else if (height >= 350 && height <= 450) {
                        document.getElementById(`${element?.id}-list-item`).style.animationName = "collapseItem400"
                    } else if (height > 450) {
                        document.getElementById(`${element?.id}-list-item`).style.animationName = "collapseItem500"
                    }
                    document.getElementById(`${element?.id}-list-item`)?.classList?.add("removedItem")
                }
                setTimeout(() => {
                    setList(props.data)
                }, [1100])
            } else if (props.data?.length > list.length) {
                setTimeout(() => {
                    let element = detectMissedElement({
                        newData: list,
                        oldData: props.data
                    })
                    setList(props.data)
                    if (document.getElementById(`${element?.id}-list-item`)) {
                        document.getElementById(`${element?.id}-list-item`)?.classList?.add("addedItem")
                        document.getElementById(`${element?.id}-list-item`)?.classList?.add("addedItemGrean")
                        setTimeout(() => {
                            document.getElementById(`${element?.id}-list-item`)?.classList?.remove("addedItemGrean")
                        }, [10000])
                    }
                }, [1200])
            } else {
                setList(props.data)
            }
        } else {
            setList(props.data)
            setRenders(renders + 1)
        }
    }, [props.data])
    useEffect(() => {
        if (document.getElementById(`${props.index}-list-tickets`).clientHeight < document.getElementById(`${props.index}-list-tickets`).scrollHeight) {
            sethasMore(true)
        } else {
            sethasMore(false)
        }
    }, [document.getElementById(`${props.index}-list-tickets`)?.scrollHeight])
    const getByTicketableType = (ticket) => {
        if (ticket.ticketable_type === "india_ticket") {
            return <IndiaTicket color1={props.color1} color2={props.color2} ticket={ticket} ticketColor={props.ticketColor} ticket_status_id={props.ticket_status_id} editTicket={props.editTicket} finishEditTicket={props.finishEditTicket} />
        } else if (ticket.ticketable_type === "hash_ticket" || ticket.ticketable_type === "broker_deposit_odoo_ticket") {
            return <HashTicket color1={props.color1} color2={props.color2} ticket={ticket} ticketColor={props.ticketColor} ticket_status_id={props.ticket_status_id} editTicket={props.editTicket} finishEditTicket={props.finishEditTicket} />
        } else if (ticket.ticketable_type === "odoo_ticket") {
            return <OdooOnly color1={props.color1} color2={props.color2} ticket={ticket} ticketColor={props.ticketColor} ticket_status_id={props.ticket_status_id} editTicket={props.editTicket} finishEditTicket={props.finishEditTicket} />
        } else {
            return <OdooTicket color1={props.color1} color2={props.color2} ticket={ticket} ticketColor={props.ticketColor} ticket_status_id={props.ticket_status_id} editTicket={props.editTicket} finishEditTicket={props.finishEditTicket} />
        }
    }
    const [loading, setLoading] = useState(false)
    const [filter, setFilter] = useState(null)
    return (<>
        <div className='employee_bulk_list_parent' id={`${props.index}-list-tickets`}>
            {props.chat.tickets_count > list.length && <div className="emp_load_more_tickets_container">
                <div className={loading ? "emp_load_more_tickets_loading" : "emp_load_more_tickets"} onClick={() => {
                    if (!loading) {
                        setLoading(true)
                        props.getPage({
                            chat_id: props.chat.whatsapp_chat_id,
                            last_ticket_id: list[list.length - 1]?.id ?? null,
                            status: props.status ? props.status : null
                        }).then((e) => {
                            setLoading(false)
                        }).catch(e => {
                            setLoading(false)
                            console.log(e)
                        })
                    }
                }}>
                    {loading ? <WaitingIcon /> : <UpArrow />}
                </div>
            </div>}
            {list.map((ticket, index) => {
                return (<div key={ticket.id} id={`${ticket.id}-list-item`} className="employee_big_parent_ticket">
                    {getByTicketableType(ticket)}
                </div>)
            })}
            {hasMore && <div className="is_there_a_list_of"> <IsMore color={props.ticketColor} /></div>}
        </div>
    </>)
}