import { GetTransValidate } from "../../constants";


export  function languagesReducer(state = {
    data: [    {id:1,name:"Arabic",icon:"AR",childs:[{id:11,name:"Syria",icon:"SY",childs:[{id:111, name:"Damascus",icon:"DA",childs:[]},{id:112, name:"Aleppo",icon:"AL",childs:[]},{id:113, name:"Latakia",icon:"LA",childs:[]}]},{id:12,name:"Iraq",icon:"IQ",childs:[]},{id:12,name:"Lebanon",icon:"LB",childs:[]}]},
    {id:2,name:"Turkish",icon:"TR",childs:[]},
    {id:3,name:"English",icon:"EN",childs:[]}],
    allLanguages: [],
    done:false,
    loading:false,
    SelectedLanguage:{name:"",icon:"",childs:[],trans:[{name:"",lang:"en"},{name:"",lang:"tr"},{name:"",lang:"ar"}]},
    ButtonValidate:false,
    lang_id:false,
    accent:{name:"",icon:"",childs:[]},
    lngcode:"",
    openAccent:false,
    expand:false,
    detailsModal:false,
    edit:true,
    open:false,
    detailsModalL:false,
    languageFollower:[],
    img:false,
    imgOut:false,
    lang:{name:"",icon:"",childs:[]},
    langcode:"",
    langId:null

}, action) {
    switch (action.type) {

        case 'loading':{
            return({
                ...state,
                loading:true,
                ref:0,
            })
        }
        case 'SET_LANGUAGES_REDUCER':{
            return({
                ...state,
                allLanguages: action.payload
            })
        }
        case 'load':{
            return({
                ...state,
                loading:false,

            })}
            case "LANGUAGE_SELECT":{
                return({
                    ...state,
                    SelectedLanguage:{...action.language,trans:[{name:"",lang:"en"},{name:"",lang:"tr"},{name:"",lang:"ar"}]},
                    ButtonValidate:action.language.name.length>0&&action.language.icon.length===2&&GetTransValidate([{name:"",lang:"en"},{name:"",lang:"tr"},{name:"",lang:"ar"}])
                })
            }
            case "LANGUAGE_EDIT":{
                return({
                    ...state,
                    SelectedLanguage:{...action.language},
                    ButtonValidate:action.language.name.length>0&&action.language.icon.length===2&&GetTransValidate(action.language.trans)

                })
            }
            case "setLang_id":{
                return({
                    ...state,
                    lang_id:action.payload
                })
            }
            case "setAccent":{
                return({
                    ...state,
                    accent:action.payload
                })
            }
            case "setLangCod":{
                return({
                    ...state,
                    lngcode:action.payload
                })
            }
            case "setopenAccent":{
                return({
                    ...state,
                    openAccent:action.payload
                })
            }
            case "setExpand":{
                return({
                    ...state,
                    expand:action.payload
                })
            }
            case "setDetailModal":{
                return({
                    ...state,
                    detailsModal:action.payload
                })
            }
            case "setEdit":{
                return({
                    ...state,
                    edit:action.payload
                })
            }
            case "setOpenLang":{
                return({
                    ...state,
                    open:action.payload
                })
            }
            case "setDetailModall":{
                return({
                    ...state,
                    detailsModalL:action.payload
                })
            }
            case "setLanguageFollower":{
                return({
                    ...state,
                    languageFollower:action.payload
                })
            }
            case "setImg":{
                return({
                    ...state,
                    img:action.payload
                })
            }
            case "setLangCode":{
                return({
                    ...state,
                    langcode:action.payload
                })
            }
            case "setLangId":{
                return({
                    ...state,
                    langId:action.payload
                })
            }
            case "setLang":{
                return({
                    ...state,
                    lang:action.payload
                })
            }
        default:
    }
    return state;
}