export default function Filter(props) {
  return (<svg id="_15x15_photo_back" data-name="15x15 photo back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
    <g id="Mask_Group_309" data-name="Mask Group 309" >
      <g id="sort-ascending" transform="translate(-2.368 -2.764)">
        <path id="Path_21340" data-name="Path 21340" d="M2.368,13.421a.789.789,0,0,1,.789-.789H7.895a.789.789,0,0,1,0,1.579H3.158A.789.789,0,0,1,2.368,13.421Z" fill={props.active ? "#0de567" : "#095cf7"} fillRule="evenodd" />
        <path id="Path_21341" data-name="Path 21341" d="M2.368,9.474a.789.789,0,0,1,.789-.789h7.105a.789.789,0,0,1,0,1.579H3.158A.789.789,0,0,1,2.368,9.474Z" fill={props.active ? "#0de567" : "#095cf7"} fillRule="evenodd" />
        <path id="Path_21342" data-name="Path 21342" d="M14.211,7.895A.789.789,0,0,1,15,8.684V15a.789.789,0,0,1-1.579,0V8.684A.789.789,0,0,1,14.211,7.895Z" fill={props.active ? "#0de567" : "#095cf7"} fillRule="evenodd" />
        <path id="Path_21343" data-name="Path 21343" d="M11.284,12.073a.789.789,0,0,1,1.116,0l1.81,1.81,1.81-1.81a.789.789,0,0,1,1.116,1.116l-2.368,2.368a.789.789,0,0,1-1.116,0L11.284,13.19A.789.789,0,0,1,11.284,12.073Z" fill={props.active ? "#0de567" : "#095cf7"} fillRule="evenodd" />
        <path id="Path_21344" data-name="Path 21344" d="M2.368,5.526a.789.789,0,0,1,.789-.789h9.474a.789.789,0,0,1,0,1.579H3.158A.789.789,0,0,1,2.368,5.526Z" fill={props.active ? "#0de567" : "#095cf7"} fillRule="evenodd" />
      </g>
    </g>
  </svg>)
}

