export default function Rs() {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
        <g id="Group_10493" data-name="Group 10493" transform="translate(-1123 -379)">
            <circle id="Ellipse_413" data-name="Ellipse 413" cx="7.5" cy="7.5" r="7.5" transform="translate(1123 379)" fill="#404040" />
            <path id="Path_21408" data-name="Path 21408" d="M1.5-1.078a.632.632,0,0,1-.474-.192.659.659,0,0,1-.186-.478V-6.292a.659.659,0,0,1,.186-.479A.632.632,0,0,1,1.5-6.963a.632.632,0,0,1,.473.192.659.659,0,0,1,.187.479v1.034l-.076-.736A1.714,1.714,0,0,1,2.4-6.463,1.858,1.858,0,0,1,2.834-6.8a1.973,1.973,0,0,1,.517-.2,2.35,2.35,0,0,1,.55-.066.84.84,0,0,1,.556.187.558.558,0,0,1,.226.44.652.652,0,0,1-.187.522.615.615,0,0,1-.407.16.912.912,0,0,1-.38-.077.939.939,0,0,0-.391-.077.956.956,0,0,0-.4.093,1.106,1.106,0,0,0-.374.291,1.535,1.535,0,0,0-.275.49,2.039,2.039,0,0,0-.1.686v2.61a.659.659,0,0,1-.187.478.632.632,0,0,1-.473.192Zm3.772-.913a.615.615,0,0,1-.1-.4.531.531,0,0,1,.286-.418A.6.6,0,0,1,5.836-2.9a.624.624,0,0,1,.385.2,2.6,2.6,0,0,0,.671.495,1.959,1.959,0,0,0,.88.177,2.94,2.94,0,0,0,.363-.05.7.7,0,0,0,.346-.177.551.551,0,0,0,.149-.424.483.483,0,0,0-.165-.385A1.39,1.39,0,0,0,8.031-3.3q-.269-.1-.6-.187t-.7-.221a2.917,2.917,0,0,1-.655-.314A1.507,1.507,0,0,1,5.6-4.532a1.568,1.568,0,0,1-.188-.8,1.45,1.45,0,0,1,.31-.935,2.012,2.012,0,0,1,.8-.594,2.592,2.592,0,0,1,1.028-.209,3.321,3.321,0,0,1,.715.083,2.8,2.8,0,0,1,.715.265,1.769,1.769,0,0,1,.572.479.781.781,0,0,1,.143.4.472.472,0,0,1-.209.418.531.531,0,0,1-.385.115.582.582,0,0,1-.363-.149,1.3,1.3,0,0,0-.5-.385,1.719,1.719,0,0,0-.72-.146,1.831,1.831,0,0,0-.357.038.708.708,0,0,0-.336.165.51.51,0,0,0-.143.4.513.513,0,0,0,.165.4,1.249,1.249,0,0,0,.445.242q.28.093.6.181t.671.209a2.656,2.656,0,0,1,.627.319,1.514,1.514,0,0,1,.462.512,1.615,1.615,0,0,1,.177.8,1.446,1.446,0,0,1-.33.957,2.089,2.089,0,0,1-.826.6,2.639,2.639,0,0,1-1.012.2,3.934,3.934,0,0,1-1.3-.226,2.247,2.247,0,0,1-1.079-.8Z" transform="translate(1124.988 391.85)" fill="#fff" />
            <g id="Group_10427" data-name="Group 10427" transform="translate(1125.832 381.093)">
                <path id="Path_16118" data-name="Path 16118" d="M-.451-8.4a.088.088,0,0,1-.064-.027.088.088,0,0,1,0-.124,8.193,8.193,0,0,1,1.2-.9,6.9,6.9,0,0,1,1.241-.616,4.227,4.227,0,0,1,1.452-.282,4.417,4.417,0,0,1,1.474.281,7.6,7.6,0,0,1,1.29.615,9.4,9.4,0,0,1,1.269.894.088.088,0,0,1,.008.123A.088.088,0,0,1,7.3-8.42,9.221,9.221,0,0,0,6.052-9.3a7.418,7.418,0,0,0-1.259-.6,4.242,4.242,0,0,0-1.415-.271A4.053,4.053,0,0,0,1.985-9.9a6.73,6.73,0,0,0-1.208.6,8.165,8.165,0,0,0-1.169.875A.088.088,0,0,1-.451-8.4Z" transform="translate(1.037 10.342)" fill="#fff" />
                <path id="Path_19225" data-name="Path 19225" d="M.586.177A.41.41,0,1,0,1,.587a.41.41,0,0,0-.41-.41M.586,0A.586.586,0,1,1,0,.586.586.586,0,0,1,.586,0Z" transform="translate(0 1.759)" fill="#fff" />
                <path id="Path_19226" data-name="Path 19226" d="M.586,0A.586.586,0,1,1,0,.586.586.586,0,0,1,.586,0Z" transform="translate(7.806 1.759)" fill="#fff" />
                <path id="Path_19226_-_Outline" data-name="Path 19226 - Outline" d="M.586.177A.41.41,0,1,0,1,.587a.41.41,0,0,0-.41-.41M.586,0A.586.586,0,1,1,0,.586.586.586,0,0,1,.586,0Z" transform="translate(7.806 1.759)" fill="#fff" />
            </g>
        </g>
    </svg>
    )
}