import React, { useState } from "react"
// import Iraq from "./svg/iraq"
// import Lebanon from "./svg/lebanon"
import Line from "./svg/line"
import Search from "./svg/search"
import CancelSearch from "./svg/cancel_search"
import InnerSearch from "./svg/innerSearch"
import InnerSearchLine from "./svg/innerSearchLine"

export default function Filter(props) {
    const countries = props.countries
    const cities = props.cities
    const [openSearch, setSearch] = useState(false)
    const [searchValue, setSearchValue] = useState("")
    return (<div className="ship_filter_area">
        <div className="filters_icons_area">
            <div className="ship_filter_area_countries" style={{
                width: "194px",
                overflowX: "scroll",
                overflowY: "hidden"
            }}>
                {countries.map((country, index) => <div key={index}
                    onClick={() => {
                        if (props.countriesFilter === country.id) {
                            props.setCountriesFilter(null)
                        } else {
                            props.setCountriesFilter(country.id)
                        }
                    }}
                    style={props.countriesFilter === country.id ? {
                        fontFamily: "Noto Emoji",
                        width: "25px",
                        height: "40px",
                        fontSize: "20px",
                        marginRight: "5px",
                        cursor: "pointer",
                        backgroundColor: "#E3FDED",
                        display: "flex",
                        alignItems: "center"
                    } : {
                        fontFamily: "Noto Emoji",
                        width: "25px",
                        height: "40px",
                        fontSize: "20px",
                        marginRight: "5px",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center"
                    }}>
                    {country.flag}
                </div>)}
            </div>
            <Line />
            {!openSearch ?
                <>
                    <div className="ship_filter_area_cities" style={{
                        width: "550px",
                        overflowX: "scroll",
                        overflowY: "hidden"
                    }}>
                        {cities.map((city, ind) => {
                            if (ind === 0) {
                                return (<div key={ind} className={props.citiesFilter === city.id ? "city_item_selected" : "city_item"} onClick={() => {
                                    if (props.citiesFilter === city.id) {
                                        props.setCitiesFilter(null)
                                    } else {
                                        props.setCitiesFilter(city.id)
                                    }
                                }}>
                                    <div className="city_name">{city.name}</div>
                                    <div className="city_count"> {city.tickets_count}</div>
                                </div>)
                            } else {
                                return (<div className="with_line">
                                    <div className="line" />
                                    <div className={props.citiesFilter === city.id ? "city_item_selected" : "city_item"} onClick={() => {
                                        if (props.citiesFilter === city.id) {
                                            props.setCitiesFilter(null)
                                        } else {
                                            props.setCitiesFilter(city.id)
                                        }
                                    }}>
                                        <div className="city_name">{city.name}</div>
                                        <div className="city_count"> {city.tickets_count}</div>
                                    </div>
                                </div>)
                            }
                        })
                        }
                    </div>
                    <div className="ship_filter_area_cities" style={{ marginLeft: "70px" }}>
                        <div className={props.usdFilter === 1 ? "city_item_selected" : "city_item"} onClick={() => {
                            if (props.usdFilter === 1) {
                                props.setUsdFilter(0)
                            } else {
                                props.setUsdFilter(1)
                            }
                        }}>
                            <div className="city_name">USDT</div>
                            <div className="city_count"> 5</div>
                        </div>

                        <div className="with_line">
                            <div className="line" />
                            <div className={props.usdFilter === 2 ? "city_item_selected" : "city_item"} onClick={() => {
                                if (props.usdFilter === 2) {
                                    props.setUsdFilter(0)
                                } else {
                                    props.setUsdFilter(2)
                                }
                            }}>
                                <div className="city_name">USD</div>
                                <div className="city_count"> 5</div>
                            </div>
                        </div>
                    </div>
                </> : <></>}
        </div>
        {!openSearch ?
            <div className="search_icon" onClick={() => { setSearch(true) }}>
                <Search />
            </div> : <div className="search_bar_ship">
                <div className="search_area">
                    <div onClick={() => {
                        props.search(searchValue)
                    }}>
                        <InnerSearch />
                    </div>
                    <InnerSearchLine />
                    <input
                        value={searchValue}
                        onChange={(e) => {
                            if (!e.target.value) {
                                props.search("")
                            }
                            setSearchValue(e.target.value)
                        }}
                        onKeyUp={(e) => {
                            if (e.key === "Enter" || e.keyCode === 13) {
                                props.search(searchValue)
                            }
                        }}
                        className="input_placeholder_ship"
                        placeholder="Search In Table, Rf/XXXXX, Name, Ect"
                        autoFocus
                    />
                </div>
                <div className="cancel_search_area" onClick={() => {
                    props.search("")
                    setSearchValue("")
                    setSearch(false)
                }}>
                    <CancelSearch />
                </div>
            </div>}
    </div>)
}