export default function CashType() {
  return (
    <svg id="different-currencies" xmlns="http://www.w3.org/2000/svg" width="9.727" height="10" viewBox="0 0 9.727 10">
      <g id="Layer_1_129_">
        <g id="Group_7861" data-name="Group 7861">
          <g id="Group_7856" data-name="Group 7856">
            <path id="Path_19145" data-name="Path 19145" d="M4.406,3.366a3.012,3.012,0,0,1,.536-1.457,2.431,2.431,0,1,0-2.881,2.9A3.645,3.645,0,0,1,4.406,3.366ZM1.6,3.01a.154.154,0,0,1,.157-.157h.5V2.737L2.183,2.62H1.752a.157.157,0,0,1,0-.314H2l-.531-.872A.352.352,0,0,1,1.41,1.27.312.312,0,0,1,1.756.976a.307.307,0,0,1,.274.189L2.568,2.19l.538-1.025A.307.307,0,0,1,3.38.976a.312.312,0,0,1,.346.293.352.352,0,0,1-.056.165l-.531.873h.245a.157.157,0,0,1,0,.314h-.43l-.068.117v.117h.5a.157.157,0,0,1,0,.314h-.5v.4a.3.3,0,0,1-.317.322.3.3,0,0,1-.317-.322v-.4h-.5A.154.154,0,0,1,1.6,3.01Z" transform="translate(-0.137)" fill="#cecdcd" />
          </g>
          <g id="Group_7857" data-name="Group 7857" transform="translate(4.889 1.216)">
            <path id="Path_19146" data-name="Path 19146" d="M7.432,1.216a2.434,2.434,0,0,0-2.406,2.1,3.625,3.625,0,0,1,1.314.257.17.17,0,0,1,.17-.1h.084a.749.749,0,0,1-.12-.43.914.914,0,0,1,1.009-.848c.7,0,1.046.378,1.046.691a.272.272,0,0,1-.3.281c-.354,0-.137-.49-.679-.49a.421.421,0,0,0-.462.4.882.882,0,0,0,.133.394h.438c.165,0,.249.06.249.193s-.084.193-.249.193H7.337a.255.255,0,0,1,.021.1.519.519,0,0,1-.041.192,3.665,3.665,0,0,1,.368.355,1.588,1.588,0,0,0,.329.049c.068,0,.234-.044.3-.044a.233.233,0,0,1,.233.257c0,.227-.2.325-.412.339a3.678,3.678,0,0,1,.34.743A2.429,2.429,0,0,0,7.432,1.216Z" transform="translate(-5.026 -1.216)" fill="#cecdcd" />
          </g>
          <g id="Group_7858" data-name="Group 7858" transform="translate(5.024 7.25)">
            <path id="Path_19147" data-name="Path 19147" d="M5.161,7.25v.995c.3-.02.618-.161.618-.492S5.432,7.31,5.161,7.25Z" transform="translate(-5.161 -7.25)" fill="#cecdcd" />
          </g>
          <g id="Group_7859" data-name="Group 7859" transform="translate(4.16 5.632)">
            <path id="Path_19148" data-name="Path 19148" d="M4.3,6.059c0,.252.186.4.563.473v-.9C4.517,5.643,4.3,5.843,4.3,6.059Z" transform="translate(-4.296 -5.632)" fill="#cecdcd" />
          </g>
          <g id="Group_7860" data-name="Group 7860" transform="translate(1.823 3.921)">
            <path id="Path_19149" data-name="Path 19149" d="M5,3.921a3.039,3.039,0,1,0,3.04,3.04A3.043,3.043,0,0,0,5,3.921Zm.161,4.837V9.07a.152.152,0,1,1-.3,0V8.758c-.849-.02-1.271-.528-1.271-.925A.289.289,0,0,1,3.9,7.517c.563,0,.125.694.96.729V7.195c-.744-.136-1.2-.462-1.2-1.02a1.1,1.1,0,0,1,1.2-1.055V4.852a.152.152,0,1,1,.3,0V5.12c.391.01,1.2.256,1.2.749a.3.3,0,0,1-.317.311c-.327,0-.322-.537-.879-.547v.955c.663.141,1.251.337,1.251,1.111C6.412,8.371,5.909,8.713,5.161,8.758Z" transform="translate(-1.96 -3.921)" fill="#cecdcd" />
          </g>
        </g>
      </g>
    </svg>
  )
}

