export default function Deposit({ color }) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="19.999" height="19.999" viewBox="0 0 19.999 19.999">
        <g id="Group_11904" data-name="Group 11904" transform="translate(-242 -159)">
            <path id="Path_19277" data-name="Path 19277" d="M380.674,41.667a.17.17,0,0,1-.17-.17v-1.68a.168.168,0,0,1,.051-.122.181.181,0,0,1,.123-.049l1.878.043a.17.17,0,0,1,.166.174.174.174,0,0,1-.174.166l-1.7-.039V41.5A.17.17,0,0,1,380.674,41.667Zm0,0" transform="translate(-123.338 120.954)" fill={color ? color : "#fff"} />
            <path id="Path_19277-2" data-name="Path 19277" d="M382.553,39.646a.17.17,0,0,1,.17.17V41.5a.169.169,0,0,1-.051.122.181.181,0,0,1-.123.049l-1.878-.043a.17.17,0,0,1-.166-.174.174.174,0,0,1,.174-.166l1.7.039V39.815A.17.17,0,0,1,382.553,39.646Zm0,0" transform="translate(-135.891 135.733)" fill={color ? color : "#fff"} />
            <path id="Path_19268" data-name="Path 19268" d="M125.389,59.022c-1.834,0-3.3-.853-2.17-.578a9.942,9.942,0,0,0,10-3.51,9.518,9.518,0,0,0-.82-12.774,10.574,10.574,0,0,0-1.5-1.2.159.159,0,0,1-.041-.219.167.167,0,0,1,.221-.051,10.9,10.9,0,0,1,1.549,1.238,9.856,9.856,0,0,1,2.211,10.906,10.252,10.252,0,0,1-9.449,6.186Zm0,0" transform="translate(126.378 119.977)" fill={color ? color : "#fff"} />
            <path id="Path_19268-2" data-name="Path 19268" d="M133.054,40.67c1.834,0,3.3.853,2.17.578a9.942,9.942,0,0,0-10,3.51,9.518,9.518,0,0,0,.82,12.774,10.577,10.577,0,0,0,1.5,1.2.159.159,0,0,1,.041.219.167.167,0,0,1-.221.051,10.9,10.9,0,0,1-1.549-1.238A9.856,9.856,0,0,1,123.6,46.856a10.252,10.252,0,0,1,9.449-6.186Zm0,0" transform="translate(119.178 118.331)" fill={color ? color : "#fff"} />
            <path id="Path_19273" data-name="Path 19273" d="M1.67,1.568H4.8c3.4,0,5.4-2.115,5.4-5.72s-2-5.712-5.4-5.712H1.67a.676.676,0,0,0-.713.737V.831A.676.676,0,0,0,1.67,1.568ZM2.383.284V-8.581H4.7c2.6,0,3.979,1.569,3.979,4.429C8.683-1.316,7.295.284,4.7.284Z" transform="translate(247.442 173.148)" fill={color ? color : "#fff"} />
        </g>
    </svg>
    )
}