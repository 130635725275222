
export default function RS() {
  return (<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
    <g id="Group_10426" data-name="Group 10426" transform="translate(-465 -474)">
      <g id="Group_10428" data-name="Group 10428" transform="translate(-27.438 -79.704)">
        <path id="Path_21408" data-name="Path 21408" d="M2.05,3.89a1.155,1.155,0,0,1-.867-.352,1.206,1.206,0,0,1-.34-.874v-8.31a1.206,1.206,0,0,1,.34-.875,1.155,1.155,0,0,1,.865-.352,1.155,1.155,0,0,1,.865.352,1.206,1.206,0,0,1,.342.875v1.891L3.116-5.1a3.133,3.133,0,0,1,.574-.857,3.4,3.4,0,0,1,.794-.624,3.608,3.608,0,0,1,.945-.372,4.3,4.3,0,0,1,1.005-.121,1.537,1.537,0,0,1,1.016.342,1.02,1.02,0,0,1,.412.8,1.193,1.193,0,0,1-.342.955,1.124,1.124,0,0,1-.744.292,1.667,1.667,0,0,1-.694-.141,1.718,1.718,0,0,0-.714-.141,1.749,1.749,0,0,0-.734.171,2.022,2.022,0,0,0-.684.533,2.807,2.807,0,0,0-.5.9,3.728,3.728,0,0,0-.19,1.254V2.665a1.206,1.206,0,0,1-.342.873,1.155,1.155,0,0,1-.865.352Zm6.9-1.669a1.125,1.125,0,0,1-.181-.728A.971.971,0,0,1,9.29.729,1.09,1.09,0,0,1,9.974.558a1.141,1.141,0,0,1,.7.372,4.746,4.746,0,0,0,1.227.905,3.583,3.583,0,0,0,1.61.324,5.377,5.377,0,0,0,.664-.091,1.273,1.273,0,0,0,.633-.324A1.007,1.007,0,0,0,15.083.97a.884.884,0,0,0-.3-.7,2.541,2.541,0,0,0-.794-.442q-.493-.181-1.1-.342-.624-.181-1.277-.4a5.333,5.333,0,0,1-1.2-.573,2.756,2.756,0,0,1-.884-.93A2.867,2.867,0,0,1,9.19-3.894,2.651,2.651,0,0,1,9.756-5.6,3.678,3.678,0,0,1,11.212-6.69a4.74,4.74,0,0,1,1.88-.383,6.072,6.072,0,0,1,1.308.151,5.116,5.116,0,0,1,1.308.485,3.235,3.235,0,0,1,1.046.875,1.428,1.428,0,0,1,.261.728.864.864,0,0,1-.383.764.971.971,0,0,1-.7.211,1.064,1.064,0,0,1-.664-.272,2.376,2.376,0,0,0-.916-.7A3.144,3.144,0,0,0,13.034-5.1a3.348,3.348,0,0,0-.654.07,1.294,1.294,0,0,0-.614.3.933.933,0,0,0-.261.734.938.938,0,0,0,.3.734,2.283,2.283,0,0,0,.814.442q.513.171,1.1.332.6.162,1.227.383a4.857,4.857,0,0,1,1.147.583,2.768,2.768,0,0,1,.845.936A2.954,2.954,0,0,1,17.259.873a2.644,2.644,0,0,1-.6,1.75,3.82,3.82,0,0,1-1.511,1.093,4.826,4.826,0,0,1-1.851.372,7.193,7.193,0,0,1-2.374-.412A4.109,4.109,0,0,1,8.947,2.221Z" transform="translate(491.596 568.312)" fill="#404040" />
        <g id="Group_10427" data-name="Group 10427" transform="translate(492.439 553.704)">
          <path id="Path_16118" data-name="Path 16118" d="M-.378-6.787a.162.162,0,0,1-.116-.05.162.162,0,0,1,.006-.226A14.982,14.982,0,0,1,1.7-8.7,12.625,12.625,0,0,1,3.968-9.827a7.729,7.729,0,0,1,2.655-.515,8.077,8.077,0,0,1,2.695.514A13.893,13.893,0,0,1,11.678-8.7,17.19,17.19,0,0,1,14-7.069a.162.162,0,0,1,.015.226.162.162,0,0,1-.226.015,16.861,16.861,0,0,0-2.278-1.6,13.564,13.564,0,0,0-2.3-1.1,7.757,7.757,0,0,0-2.588-.495,7.412,7.412,0,0,0-2.544.5,12.306,12.306,0,0,0-2.208,1.1A14.93,14.93,0,0,0-.27-6.831a.162.162,0,0,1-.108.044Z" transform="translate(1.451 10.342)" fill="#404040" />
          <path id="Path_19225" data-name="Path 19225" d="M1.071.324a.751.751,0,1,0,.751.751A.751.751,0,0,0,1.071.324m0-.324A1.071,1.071,0,1,1,0,1.071,1.071,1.071,0,0,1,1.071,0Z" transform="translate(0 3.217)" fill="#404040" />
          <path id="Path_19226" data-name="Path 19226" d="M1.071,0A1.071,1.071,0,1,1,0,1.071,1.071,1.071,0,0,1,1.071,0Z" transform="translate(14.274 3.217)" fill="#404040" />
          <path id="Path_19226_-_Outline" data-name="Path 19226 - Outline" d="M1.071.324a.751.751,0,1,0,.751.751A.751.751,0,0,0,1.071.324m0-.324A1.071,1.071,0,1,1,0,1.071,1.071,1.071,0,0,1,1.071,0Z" transform="translate(14.274 3.217)" fill="#404040" />
        </g>
      </g>
      <path id="Rectangle_4718" data-name="Rectangle 4718" d="M5,0H20a5,5,0,0,1,5,5V15A10,10,0,0,1,15,25H5a5,5,0,0,1-5-5V5A5,5,0,0,1,5,0Z" transform="translate(465 474)" fill="none" />
    </g>
  </svg>)
}


