import { useSelector } from "react-redux";
import Deposit from "./svg/deposit";
import DownArrow from "./svg/downArrow";
import UpArrow from "./svg/upArrow";
import Withdrawal from "./svg/withdrowal";

export default function StaticsSquare(props) {
    let total_tickets_count = useSelector(state => state.ticketsReducer.total_tickets_count)
    let total_tickets_count_diff = useSelector(state => state.ticketsReducer.total_tickets_count_diff)
    let total_tickets_amount = useSelector(state => state.ticketsReducer.total_tickets_usdt_amount)
    let total_tickets_amount_diff = useSelector(state => state.ticketsReducer.total_tickets_usdt_amount_diff)

    let total_deposit_tickets_count = useSelector(state => state.ticketsReducer.total_deposit_tickets_count)
    let total_deposit_tickets_usdt_amount = useSelector(state => state.ticketsReducer.total_deposit_tickets_usdt_amount)
    let total_deposit_tickets_usd_amount = useSelector(state => state.ticketsReducer.total_deposit_tickets_usd_amount)
    let total_withdrawal_tickets_count = useSelector(state => state.ticketsReducer.total_withdrawal_tickets_count)
    let total_withdrawal_tickets_usdt_amount = useSelector(state => state.ticketsReducer.total_withdrawal_tickets_usdt_amount)
    let total_withdrawal_tickets_usd_amount = useSelector(state => state.ticketsReducer.total_withdrawal_tickets_usd_amount)

    return (<div className="statics_square_area">
        <div className="statics_square_filters_area">
            <div className={props.typeFilter === "deposit" ? 'status_normal_square_tic_selected' : 'status_normal_square_tic'} onClick={() => {
                if (props.typeFilter === "deposit") {
                    props.setTypeFilter(null)
                } else {
                    props.setTypeFilter("deposit")
                }
            }}>
                <div className='status_icon_square_tic'>
                    <Deposit />
                    <div className='status_info_name_square_tic'>Deposit</div>
                </div>
                <div className='status_info_info'>
                    <div className='status_info_info_packages'>
                        <div className='status_info_info_packages_num'>
                            {total_deposit_tickets_count}
                        </div>
                    </div>
                    <div className="status_inner_items_adjust_col">
                        <div className="status_inner_items_adjust">
                            <div className='status_info_info_products'>
                                <div className='status_info_info_products_num'>
                                    {total_deposit_tickets_usdt_amount}
                                </div>
                            </div>
                            <div className='status_info_info_products'>
                                <div className='status_info_info_products_num'>
                                    USDT
                                </div>
                            </div>
                        </div>
                        <div className="status_inner_items_adjust">
                            <div className='status_info_info_products'>
                                <div className='status_info_info_products_num'>
                                    {total_deposit_tickets_usd_amount}
                                </div>
                            </div>
                            <div className='status_info_info_products'>
                                <div className='status_info_info_products_num'>
                                    USD
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="0.5" height="25.5" viewBox="0 0 0.5 25.5" style={{
                marginLeft: '10px',
                marginRight: '10px'
            }}>
                <line id="Line_992" data-name="Line 992" y1="25" transform="translate(0.25 0.25)" fill="none" stroke="#8e8e8e" strokeLinecap="round" strokeWidth="0.5" />
            </svg>
            <div className={props.typeFilter === "withdrawal" ? 'status_normal_square_tic_selected' : 'status_normal_square_tic'} onClick={() => {
                if (props.typeFilter === "withdrawal") {
                    props.setTypeFilter(null)
                } else {
                    props.setTypeFilter("withdrawal")
                }
            }}>
                <div className='status_icon_square_tic'>
                    <Withdrawal />
                    <div className='status_info_name_square_tic'>Withdrowal</div>
                </div>
                <div className='status_info_info'>
                    <div className='status_info_info_packages'>
                        <div className='status_info_info_packages_num'>
                            {total_withdrawal_tickets_count}
                        </div>
                    </div>
                    <div className="status_inner_items_adjust_col">
                        <div className="status_inner_items_adjust">
                            <div className='status_info_info_products'>
                                <div className='status_info_info_products_num'>
                                    {total_withdrawal_tickets_usdt_amount}
                                </div>
                            </div>
                            <div className='status_info_info_products'>
                                <div className='status_info_info_products_num'>
                                    USDT
                                </div>
                            </div>
                        </div>
                        <div className="status_inner_items_adjust">
                            <div className='status_info_info_products'>
                                <div className='status_info_info_products_num'>
                                    {total_withdrawal_tickets_usd_amount}
                                </div>
                            </div>
                            <div className='status_info_info_products'>
                                <div className='status_info_info_products_num'>
                                    USD
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="statics_square_numbers_area">
            <div className="statics_square_numbers_area_tic">
                <div className="statics_square_numbers_area_tic_label">
                    Ticket
                </div>
                <div className="statics_square_numbers_area_tic_data">
                    {total_tickets_count}
                </div>
                {props.showDiffs && <div className="statics_square_numbers_area_tic_data_diff" style={total_tickets_count_diff > 0 ? { color: "#15A80F" } : { color: "#FF7272" }}>
                    {total_tickets_count_diff > 0 ? total_tickets_count_diff : -total_tickets_count_diff}
                    {total_tickets_count_diff > 0 ? <UpArrow /> : <DownArrow />}
                </div>}
            </div>
            <div className="statics_square_numbers_area_amm">
                <div className="statics_square_numbers_area_tic_label">
                    Total Ammount
                </div>
                <div className="statics_square_numbers_area_tic_data" style={{ fontWeight: "bold" }}>
                    {total_tickets_amount}
                </div>
                {props.showDiffs && <div className="statics_square_numbers_area_tic_data_diff" style={total_tickets_amount_diff > 0 ? { color: "#15A80F" } : { color: "#FF7272" }}>
                    {total_tickets_amount_diff > 0 ? total_tickets_amount_diff : -total_tickets_amount_diff}
                    {total_tickets_amount_diff > 0 ? <UpArrow /> : <DownArrow />}
                </div>}
            </div>
        </div>
    </div>)
}