import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { forwardToChat } from '../../../../../constants';
import ChatUser from './ChatUser';

function ChatUsers(props) {
  const chatsArray = useSelector((state) => state.chat.data)?.filter((s) => s?.channel_type?.slug !== "team")
  const emps = useSelector(state => state.employee.employee)
  const getChat = (em) => {
    if (chatsArray.filter((s) => s.channel_members.filter((m) => m.user_id === em.id).length > 0).length > 0) {
      return (chatsArray.filter((s) => s.channel_members.filter((m) => m.user_id === em.id).length > 0)[0])
    }
    else {
      return ({
        id: Math.random(),
        messages: [],
        total_unread_message_count: 0,
        channel_members: [{ user_id: em.id, role_id: em.role_id, user: em }, { user_id: JSON.parse(localStorage.getItem("user")).id, role_id: JSON.parse(localStorage.getItem("user")).role_id, user: JSON.parse(localStorage.getItem("user")) }],
        channel_type: { slug: "chat" }
      })
    }
  }
  const onlineUsers = useSelector(state => state.auth.onlineUsers)

  const getChats = () => {
    let sorted = []
    onlineUsers.map(one => {
      if (chatsArray.filter(chat => parseInt(one) === parseInt(chat.channel_members.filter((a) => parseInt(a.user_id) !== parseInt(JSON.parse(localStorage.getItem("user")).id))[0]?.user?.id ?? 0))?.length) {
        sorted.push(chatsArray.filter(chat => parseInt(one) === parseInt(chat.channel_members.filter((a) => parseInt(a.user_id) !== parseInt(JSON.parse(localStorage.getItem("user")).id))[0]?.user?.id ?? 0))[0])
      }
    })
    chatsArray.map(chat => {
      if (onlineUsers.filter(one => parseInt(one) === parseInt(chat.channel_members.filter((a) => parseInt(a.user_id) !== parseInt(JSON.parse(localStorage.getItem("user")).id))[0]?.user?.id ?? 0))?.length === 0) {
        sorted.push(chat)
      }
    })
    return (sorted.filter((s) => s.channel_type?.slug !== "team"))
  }
  let isDown = false;
  let startX;
  let scrollLeft;
  let slider;

  const end = () => {
    isDown = false;
    slider.classList.remove('active-chat-user');
    // slider.style.transform=`translateX(${scrollLeft}px)`
  }

  const start = (e) => {
    isDown = true;
    slider.classList.add('active-chat-user');
    startX = e.pageX || e.touches[0].pageX - slider.offsetLeft;
    scrollLeft = slider.scrollLeft;
  }
  const scrollHortiznal = (e) => {

    const delta = e.wheelDeltaY
    // console.log(slider.scrollLeft)
    slider.classList.add('active-chat-user');
    slider.scrollLeft = scrollLeft - (delta);
    scrollLeft = slider.scrollLeft
    e.preventDefault();
  }
  const move = (e) => {
    if (!isDown) return;

    e.preventDefault();

    const x = e.pageX || e.touches[0].pageX - slider.offsetLeft;
    const dist = (x - startX);
    slider.scrollLeft = scrollLeft - dist;
  }
  useEffect(() => {

    slider = document?.querySelector('.chat-users-container');
    slider.addEventListener('mousedown', start);
    slider.addEventListener('touchstart', start);

    slider.addEventListener('mousemove', move);
    slider.addEventListener('touchmove', move);
    slider.addEventListener('wheel', scrollHortiznal);

    slider.addEventListener('mouseleave', end);
    slider.addEventListener('mouseup', end);
    slider.addEventListener('touchend', end);
  }, [])
  const [open, setOpen] = useState(false)
  const [user, setUser] = useState(false)
  const dispatch = useDispatch()
  return (<>
    <div className="chat-users-container">
      {getChats().map((em, index) => (
        <ChatUser forwardChat={() => {
          if (props.NotForward) {
            dispatch({ type: "CHAT_ID", payload: null });
            dispatch({ type: "OPEN-CHAT", payload: em })
          }
          else if (props.forwarderMessage) {
            setOpen(true)
            setUser(em)
            dispatch({ type: "CHAT_ID", payload: null });
            dispatch({ type: "OPEN-CHAT", payload: em })
            forwardToChat(props.forwarderMessage, em)

          }
        }} key={index} chat={em} photo={em} />
      ))}
      {/* {getUnEmployeeChats().slice(0,8).map((em)=>(
           <ChatUser chat={em} photo={em}/>
        ))}
        {getUnEmployeeChats().length <8&&emps.filter((s)=>s.id!==JSON.parse(localStorage.getItem("user")).id).slice(0,(8-chatsArray.length)).map((em)=>(
       <ChatUser chat={getChat(em)} photo={getChat(em)}/>
        ))}
       
        {chatsArray.length<8&&emps.filter((s)=>s.id!==JSON.parse(localStorage.getItem("user")).id&&notInclude(s.id)).slice(0,8-(chatsArray.length+getUnEmployeeChats().length)).map((em)=>(
           (!getChat(em).channel_translations)&&<ChatUser chat={getChat(em)} photo={getChat(em)}/>
        ))} */}
    </div>
  </>

  )
}

export default memo(ChatUsers)