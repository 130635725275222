import axios from "axios";
import store from "../store";
import { toast } from "react-toastify";
import { REACT_APP_ACCOUNTING_URL } from "../../urls";

export const ticketChange = async (action) => {
    try {
        const token = store.getState()?.whatsapp?.userTokenWhatsapp;
        const base_user = REACT_APP_ACCOUNTING_URL;
        let AxiosInstace = axios.create({
            baseURL: `${base_user}`,
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
        })
        let res = await AxiosInstace.post(`/api/v1/tickets/${action.ticket_id}/edit_field`, JSON.stringify({ field_name: action.field_name, new_value: action.new_value }))
    }
    catch (e) {
        console.log(e)
        toast.error("network error!!")
    }
}
export const approveTicket = async (action) => {
    try {
        const token = store.getState()?.whatsapp?.userTokenWhatsapp;
        const base_user = REACT_APP_ACCOUNTING_URL;
        let AxiosInstace = axios.create({
            baseURL: `${base_user}`,
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
        })
        let res = await AxiosInstace.get(`/api/v1/tickets/${action.ticket_id}/approve`)
        // store.dispatch({
        //     type: "GET_UPDATED_EMP_STATICS_RED",
        //     payload: {
        //         data: res.data.data
        //     }
        // })
        /// send to server
    }
    catch (e) {
        console.log(e)
        toast.error("network error!!")
    }
}
export const applyTicket = async (action) => {
    try {
        const token = store.getState()?.whatsapp?.userTokenWhatsapp;
        const base_user = REACT_APP_ACCOUNTING_URL;
        let AxiosInstace = axios.create({
            baseURL: `${base_user}`,
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
        })
        let res = await AxiosInstace.get(`/api/v1/tickets/${action.ticket_id}/apply`)
        // store.dispatch({
        //     type: "GET_UPDATED_EMP_STATICS_RED",
        //     payload: {
        //         data: res.data.data
        //     }
        // })
        /// send to server
    }
    catch (e) {
        console.log(e)
        toast.error("network error!!")
    }
}
export const doneTicket = async (action) => {
    try {
        const token = store.getState()?.whatsapp?.userTokenWhatsapp;
        const base_user = REACT_APP_ACCOUNTING_URL;
        let AxiosInstace = axios.create({
            baseURL: `${base_user}`,
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
        })
        let res = await AxiosInstace.get(`/api/v1/tickets/${action.ticket_id}/done`)
        // store.dispatch({
        //     type: "GET_UPDATED_EMP_STATICS_RED",
        //     payload: {
        //         data: res.data.data
        //     }
        // })
        /// send to server
    }
    catch (e) {
        console.log(e)
        toast.error("network error!!")
    }
}
export const getRateOptions = async (action) => {
    const token = store.getState()?.whatsapp?.userTokenWhatsapp;
    const base_user = REACT_APP_ACCOUNTING_URL;
    let AxiosInstace = axios.create({
        baseURL: `${base_user}`,
        headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
        },
    })
    let res = await AxiosInstace.get(`/api/v1/tickets/${action.ticket_id}/rates`)
    return res.data?.data
}
/////////////////////////////////////////////////

export const getAdminChat = async ({ contact_id }) => {
    const token = store.getState()?.whatsapp?.userTokenWhatsapp;
    const base_user = REACT_APP_ACCOUNTING_URL;
    let AxiosInstace = axios.create({
        baseURL: `${base_user}`,
        headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
        },
    })
    let res = await AxiosInstace.get(`/api/v3/whatsapp/chats/getByContact/${contact_id}?limit=20`)
    return res.data?.data
}
export const deleteTicket = async (action) => {
    try {
        const token = store.getState()?.whatsapp?.userTokenWhatsapp;
        const base_user = REACT_APP_ACCOUNTING_URL;
        let AxiosInstace = axios.create({
            baseURL: `${base_user}`,
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
        })
        let res = await AxiosInstace.delete(`/api/v1/tickets/${action.ticket_id}`)
        // store.dispatch({
        //     type: "GET_UPDATED_EMP_STATICS_RED",
        //     payload: {
        //         data: res.data.data
        //     }
        // })
        /// send to server
    }
    catch (e) {
        console.log(e)
        toast.error("network error!!")
    }
}