import React from 'react'
import { trans } from '../../../../../Internationalization'

function LiscenseIcon(props) {
  return (
    <>
      <div style={{ marginRight: "20px" }} className='icon-lang' onClick={() => props.change()}>

        <div className='icon-lang-desc'>
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
            <defs>
              <filter id="Ellipse_319" x="0" y="0" width="24" height="24" filterUnits="userSpaceOnUse">
                <feOffset dy="3" input="SourceAlpha" />
                <feGaussianBlur stdDeviation="3" result="blur" />
                <feFlood floodOpacity="0.2" />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
              </filter>
            </defs>
            <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Ellipse_319)">
              <circle id="Ellipse_319-2" data-name="Ellipse 319" cx="3" cy="3" r="3" transform="translate(9 6)" fill="#404040" />
            </g>
          </svg>
          <div className='icon-lang-desc-bu hst contries'>{trans("lics")}</div>
        </div>
        <svg className={`${props.active && "activated-hrm"}`} id="_15x15_photo_back" data-name="15x15 photo back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
          <g id="Mask_Group_158" data-name="Mask Group 158">
            <g id="premium-badge">
              <g id="Layer_5_21_">
                <g id="Group_7731" data-name="Group 7731">
                  <path id="Path_19043" data-name="Path 19043" d="M13.85,12.745,11.886,9.371c.34-.1.7-.2.852-.455.251-.433-.2-1.142-.078-1.606s.833-.89.833-1.376-.737-1.025-.857-1.5.327-1.174.075-1.606-1.089-.4-1.434-.728S10.924.923,10.5.694s-1.128.234-1.6.125S8,0,7.506,0,6.211.805,6.086.834C5.61.945,4.911.482,4.481.713S4.057,1.778,3.7,2.12s-1.182.3-1.432.731.2,1.142.078,1.606-.855.937-.855,1.475c0,.485.714.9.836,1.374S2,8.48,2.254,8.912a1.237,1.237,0,0,0,.758.425c.036.011.1.051.058.119L1.15,12.77a.274.274,0,0,0,.245.447l1.325.055a1.055,1.055,0,0,1,.755.437l.707,1.122a.274.274,0,0,0,.51-.01L6.67,11.405c.04-.046.08-.037.1-.021a1.369,1.369,0,0,0,.77.368,1.185,1.185,0,0,0,.7-.366c.018-.016.063-.048.1.019l1.976,3.4a.274.274,0,0,0,.509.009l.7-1.123a1.055,1.055,0,0,1,.754-.439l1.325-.058A.274.274,0,0,0,13.85,12.745ZM9.7,9.673a4.391,4.391,0,1,1-.088-7.655l.033.018.046.026A4.4,4.4,0,0,1,9.7,9.673Z" fill="#8e8e8e" />
                  <path id="Path_19044" data-name="Path 19044" d="M9.36,2.68l-.018-.01A3.7,3.7,0,0,0,5.52,9.009c.042.027.085.053.128.078A3.7,3.7,0,0,0,9.36,2.68ZM9.8,5.567l-.619.6a1.039,1.039,0,0,0-.266.819l.146.851c.045.264-.111.378-.348.253l-.765-.4a1.039,1.039,0,0,0-.861,0l-.765.4c-.237.124-.394.011-.348-.253l.146-.851a1.039,1.039,0,0,0-.266-.819l-.619-.6c-.192-.187-.132-.371.133-.409l.855-.124a1.039,1.039,0,0,0,.7-.506l.382-.775c.118-.24.312-.24.431,0l.382.775a1.039,1.039,0,0,0,.7.506l.855.124C9.934,5.2,9.994,5.381,9.8,5.567Z" fill="#8e8e8e" />
                </g>
              </g>
            </g>
          </g>
        </svg>

      </div>


    </>
  )
}

export default LiscenseIcon