export default function Passup() {
  return (<svg xmlns="http://www.w3.org/2000/svg" width="60" height="41" viewBox="0 0 60 41">
    <g id="Group_9973" data-name="Group 9973" transform="translate(-908 -356)">
      <g id="Rectangle_4888" data-name="Rectangle 4888" transform="translate(908 356)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" stroke-dasharray="2 3">
        <rect width="60" height="41" rx="10" stroke="none" />
        <rect x="0.25" y="0.25" width="59.5" height="40.5" rx="9.75" fill="none" />
      </g>
      <g id="Group_9910" data-name="Group 9910" transform="translate(930.5 369)">
        <path id="portrait" d="M5.859,14.531A.469.469,0,0,1,5.391,15H2.148A2.15,2.15,0,0,1,0,12.852V9.609a.469.469,0,0,1,.938,0v3.243a1.212,1.212,0,0,0,1.21,1.21H5.391A.469.469,0,0,1,5.859,14.531ZM.469,5.859a.469.469,0,0,0,.469-.469V2.148A1.212,1.212,0,0,1,2.148.938H5.391a.469.469,0,0,0,0-.938H2.148A2.15,2.15,0,0,0,0,2.148V5.391A.469.469,0,0,0,.469,5.859ZM12.852,0H9.609a.469.469,0,0,0,0,.938h3.243a1.212,1.212,0,0,1,1.21,1.21V5.391a.469.469,0,0,0,.938,0V2.148A2.15,2.15,0,0,0,12.852,0Zm1.679,9.141a.469.469,0,0,0-.469.469v3.243a1.212,1.212,0,0,1-1.21,1.21H9.609a.469.469,0,0,0,0,.938h3.243A2.15,2.15,0,0,0,15,12.852V9.609A.469.469,0,0,0,14.531,9.141Z" fill="#8e8e8e" />
        <rect id="Rectangle_4561" data-name="Rectangle 4561" width="15" height="15" transform="translate(0.5)" fill="none" />
        <g id="passport" transform="translate(3.948 2.764)">
          <g id="Group_9909" data-name="Group 9909" transform="translate(0 0)">
            <g id="Group_9908" data-name="Group 9908">
              <path id="Path_21244" data-name="Path 21244" d="M225.011,257.58c.226,0,.544-.611.584-1.579h-1.168C224.467,256.968,224.785,257.58,225.011,257.58Z" transform="translate(-221.459 -251.265)" fill="#8e8e8e" />
              <path id="Path_21245" data-name="Path 21245" d="M161.078,157.774h.773a3.669,3.669,0,0,1,.326-1.449A1.772,1.772,0,0,0,161.078,157.774Z" transform="translate(-159.282 -153.433)" fill="#8e8e8e" />
              <path id="Path_21246" data-name="Path 21246" d="M291.516,156.325a3.669,3.669,0,0,1,.326,1.449h.773A1.772,1.772,0,0,0,291.516,156.325Z" transform="translate(-287.307 -153.433)" fill="#8e8e8e" />
              <path id="Path_21247" data-name="Path 21247" d="M225.011,149.334c-.226,0-.544.611-.584,1.579H225.6C225.555,149.945,225.237,149.334,225.011,149.334Z" transform="translate(-221.459 -146.571)" fill="#8e8e8e" />
              <path id="Path_21248" data-name="Path 21248" d="M161.078,256a1.772,1.772,0,0,0,1.1,1.449,3.669,3.669,0,0,1-.326-1.449Z" transform="translate(-159.282 -251.265)" fill="#8e8e8e" />
              <path id="Path_21249" data-name="Path 21249" d="M70.315,1.185H69.92V.776a.776.776,0,0,0-.966-.751l-4.21,1.164A.788.788,0,0,0,64,1.974v6.71a.79.79,0,0,0,.789.789h5.526a.79.79,0,0,0,.789-.789V1.974A.79.79,0,0,0,70.315,1.185ZM69.056.406a.385.385,0,0,1,.47.37v.408H66.242Zm-.517,7.883H66.368a.2.2,0,1,1,0-.395h2.171a.2.2,0,0,1,0,.395Zm.789-.789h-3.75a.2.2,0,0,1,0-.395h3.75a.2.2,0,0,1,0,.395Zm-1.776-.79A2.171,2.171,0,1,1,69.723,4.54,2.173,2.173,0,0,1,67.552,6.71Z" transform="translate(-64 -0.001)" fill="#8e8e8e" />
              <path id="Path_21250" data-name="Path 21250" d="M291.516,257.45a1.772,1.772,0,0,0,1.1-1.449h-.773A3.669,3.669,0,0,1,291.516,257.45Z" transform="translate(-287.307 -251.265)" fill="#8e8e8e" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
  )
}
