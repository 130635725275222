import React from 'react'

function Application() {
  return (
    <>
    <svg className='normal' id="_15x15_photo_back" data-name="15x15 photo back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
  <g id="Mask_Group_168" data-name="Mask Group 168">
    <g id="Group_7733" data-name="Group 7733" transform="translate(-903.215 -64.285)">
      <g id="Rectangle_4652" data-name="Rectangle 4652" transform="translate(903.214 64.286)" fill="none" stroke="#8e8e8e" strokeWidth="0.8">
        <rect width="6.429" height="6.429" rx="2.143" stroke="none"/>
        <rect x="0.4" y="0.4" width="5.629" height="5.629" rx="1.743" fill="none"/>
      </g>
      <g id="Rectangle_4655" data-name="Rectangle 4655" transform="translate(911.786 64.286)" fill="none" stroke="#8e8e8e" strokeWidth="0.8">
        <rect width="6.429" height="6.429" rx="2.143" stroke="none"/>
        <rect x="0.4" y="0.4" width="5.629" height="5.629" rx="1.743" fill="none"/>
      </g>
      <g id="Rectangle_4653" data-name="Rectangle 4653" transform="translate(903.214 72.857)" fill="none" stroke="#8e8e8e" strokeWidth="0.8">
        <rect width="6.429" height="6.429" rx="2.143" stroke="none"/>
        <rect x="0.4" y="0.4" width="5.629" height="5.629" rx="1.743" fill="none"/>
      </g>
      <g id="Rectangle_4654" data-name="Rectangle 4654" transform="translate(911.786 72.857)" fill="none" stroke="#8e8e8e" strokeWidth="0.8">
        <rect width="6.429" height="6.429" rx="2.143" stroke="none"/>
        <rect x="0.4" y="0.4" width="5.629" height="5.629" rx="1.743" fill="none"/>
      </g>
    </g>
  </g>
</svg>
<svg className='active-svg' xmlns="http://www.w3.org/2000/svg" width="15" height="15.001" viewBox="0 0 15 15.001">
  <g id="Group_7735" data-name="Group 7735" transform="translate(-842 -101)">
    <g id="Group_7734" data-name="Group 7734">
      <g id="Group_7733" data-name="Group 7733">
        <g id="Rectangle_4652" data-name="Rectangle 4652" transform="translate(842 101)" fill="#8e8e8e" stroke="#8e8e8e" strokeWidth="0.8">
          <rect width="6.429" height="6.429" rx="2.143" stroke="none"/>
          <rect x="0.4" y="0.4" width="5.629" height="5.629" rx="1.743" fill="none"/>
        </g>
        <g id="Rectangle_4655" data-name="Rectangle 4655" transform="translate(850.57 101)" fill="none" stroke="#8e8e8e" strokeWidth="0.8">
          <rect width="6.429" height="6.429" rx="2.143" stroke="none"/>
          <rect x="0.4" y="0.4" width="5.629" height="5.629" rx="1.743" fill="none"/>
        </g>
        <g id="Rectangle_4653" data-name="Rectangle 4653" transform="translate(842 109.572)" fill="none" stroke="#8e8e8e" strokeWidth="0.8">
          <rect width="6.429" height="6.429" rx="2.143" stroke="none"/>
          <rect x="0.4" y="0.4" width="5.629" height="5.629" rx="1.743" fill="none"/>
        </g>
        <g id="Rectangle_4654" data-name="Rectangle 4654" transform="translate(850.57 109.572)" fill="#8e8e8e" stroke="#8e8e8e" strokeWidth="0.8">
          <rect width="6.429" height="6.429" rx="2.143" stroke="none"/>
          <rect x="0.4" y="0.4" width="5.629" height="5.629" rx="1.743" fill="none"/>
        </g>
        <rect id="Rectangle_4561" data-name="Rectangle 4561" width="15" height="15" transform="translate(842 101)" fill="none"/>
      </g>
    </g>
  </g>
</svg>

    </>
  )
}

export default Application