export default function CopyHash() {
    return (<svg id="Group_11953" data-name="Group 11953" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
        <g id="Group_11952" data-name="Group 11952">
            <path id="Path_21886" data-name="Path 21886" d="M1.721,3.329H4a.7.7,0,0,0,.689-.689V.362A.349.349,0,0,0,4.336.013a.34.34,0,0,0-.244.1L1.474,2.733A.349.349,0,0,0,1.721,3.329Z" fill="#95bdff" />
            <path id="Path_21884" data-name="Path 21884" d="M12.812,1.7h-.439V12.473a1.3,1.3,0,0,1-1.3,1.3H3.1v.4A.829.829,0,0,0,3.925,15H12.81a.829.829,0,0,0,.829-.829V2.527A.828.828,0,0,0,12.812,1.7Z" fill="#95bdff" />
            <path id="Path_21885" data-name="Path 21885" d="M6.631,13.3h4.442a.829.829,0,0,0,.829-.829V.829A.829.829,0,0,0,11.073,0H5.154V.274a.805.805,0,0,1,0,.088v2.27A1.168,1.168,0,0,1,3.991,3.8H1.721c-.023,0-.049,0-.072,0H1.361v8.678a.829.829,0,0,0,.829.829H6.631ZM6.8,10.486H3.9a.392.392,0,0,1,0-.783H6.8a.392.392,0,1,1,0,.783Zm2.9-2H3.9a.392.392,0,1,1,0-.783H9.7a.392.392,0,1,1,0,.783ZM3.9,5.581H9.7a.392.392,0,1,1,0,.783H3.9a.392.392,0,0,1,0-.783Z" fill="#95bdff" />
        </g>
        <rect id="Rectangle_5463" data-name="Rectangle 5463" width="15" height="15" fill="none" />
    </svg>
    )
}