
export default function Time() {
  return (<svg id="timer" xmlns="http://www.w3.org/2000/svg" width="8.125" height="10" viewBox="0 0 8.125 10">
    <g id="Group_10342" data-name="Group 10342">
      <path id="Path_21385" data-name="Path 21385" d="M7.063,1.122,6.727,1.7l1.164.672.336-.581A.337.337,0,0,0,8.1,1.335L7.523,1A.336.336,0,0,0,7.063,1.122Z" transform="translate(-0.938)" fill="#cecdcd" />
      <path id="Path_21386" data-name="Path 21386" d="M5,1.512a4.4,4.4,0,0,1,.625.05V1.019l.43,0v-.7A.312.312,0,0,0,5.742,0H4.261a.312.312,0,0,0-.313.313v.7l.426,0v.544A4.4,4.4,0,0,1,5,1.512Z" transform="translate(-0.938)" fill="#cecdcd" />
      <path id="Path_21387" data-name="Path 21387" d="M5,1.875A4.063,4.063,0,1,0,9.063,5.938,4.062,4.062,0,0,0,5,1.875ZM6.9,7.847,4.531,6.208V3.894h.683V5.85L7.287,7.285Z" transform="translate(-0.938)" fill="#cecdcd" />
    </g>
  </svg>)
}

