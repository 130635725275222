export default function Rf() {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="15" height="14.594" viewBox="0 0 15 14.594">
        <g id="Group_11906" data-name="Group 11906" transform="translate(-943.719 -255.068)">
            <g id="Group_11905" data-name="Group 11905" transform="translate(944.242 255.068)">
                <g id="Group_7799" data-name="Group 7799" transform="translate(7.762 6.255)">
                    <g id="Group_7798" data-name="Group 7798">
                        <path id="Path_19114" data-name="Path 19114" d="M11.735,7.318l-.958-2.4-2.3,2.1,1.135-.378a.3.3,0,0,1,.373.157.259.259,0,0,1-.172.34l-1.447.487A.3.3,0,0,1,8,7.462V7.456L5.562,9.675l6-2.017A.258.258,0,0,0,11.735,7.318Z" transform="translate(-5.562 -4.916)" fill="#9af5a5" />
                    </g>
                </g>
                <path id="Path_19115" data-name="Path 19115" d="M13.863,4.318,9.213.077a.311.311,0,0,0-.411,0L.085,8.03a.249.249,0,0,0,0,.375l4.649,4.241a.3.3,0,0,0,.2.078.307.307,0,0,0,.205-.077l8.718-7.952a.249.249,0,0,0,0-.376ZM3.4,7.345,2.24,8.405a.309.309,0,0,1-.287.08.279.279,0,0,1-.212-.194.251.251,0,0,1,.088-.262L2.991,6.97a.312.312,0,0,1,.4.01A.249.249,0,0,1,3.4,7.345Zm5.021.339a1.158,1.158,0,0,1-.8.277,2.5,2.5,0,0,1-1.616-.7,2.393,2.393,0,0,1-.726-1.128,1.037,1.037,0,0,1,.259-1.072,1.3,1.3,0,0,1,1.175-.236,2.722,2.722,0,0,1,1.237.662c.8.733,1.01,1.7.464,2.2Zm3.7-2.988L10.96,5.757a.312.312,0,0,1-.4-.01.249.249,0,0,1-.011-.365l1.162-1.06a.312.312,0,0,1,.4.01A.249.249,0,0,1,12.123,4.7Z" transform="translate(0.004)" fill="#9af5a5" />
            </g>
            <rect id="Rectangle_5110" data-name="Rectangle 5110" width="15" height="13" transform="translate(943.719 256.662)" fill="none" />
        </g>
    </svg>

    )
}