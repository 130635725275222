import { useDispatch, useSelector } from "react-redux";

export default function SwitchArea() {
    var mode = useSelector((state) => state.whatsapp.mode)
    const dispatch = useDispatch();
    return (<div className="switch-area-plus">
        <input id="toggle-on" className="toggle toggle-left" name="toggle" value={mode === "customer_service"} type="radio" defaultChecked={mode === "customer_service"} />
        <label onClick={() => {
            dispatch({
                type: "change_mode_whatsapp", payload: {
                    mode: "customer_service"
                }
            })
        }} htmlFor="toggle-on" className="switch-area-btn">Customer Service</label>
        <input id="toggle-off" className="toggle toggle-right" name="toggle" value={mode === "operations"} type="radio" defaultChecked={mode === "operations"} />
        <label onClick={() => {
            dispatch({
                type: "change_mode_whatsapp", payload: {
                    mode: "operations"
                }
            })
        }} htmlFor="toggle-off" className="switch-area-btn">Operations</label>

    </div>)
}