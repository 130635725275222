import React from 'react'
import "./Loading.css"
function Loading() {
  return (
    <div className='absolute-loading'>
      <div className='loading-screen'>
        <div className='loading-percent'>

        </div>
        <div className="loading-overlay"></div>
      </div>
    </div>
  )
}

export default Loading