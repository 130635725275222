export default function CancelSearch() {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
        <g id="Group_10634" data-name="Group 10634">
            <g id="Group_7875" data-name="Group 7875" transform="translate(0 0)">
                <line id="Line_962" data-name="Line 962" x1="14.135" y1="0.001" transform="translate(0.002 0) rotate(45)" fill="none" stroke="#ff2e2e" stroke-linecap="round" stroke-width="1" />
                <line id="Line_963" data-name="Line 963" x1="14.135" y2="0.001" transform="translate(10 0.001) rotate(135)" fill="none" stroke="#ff2e2e" stroke-linecap="round" stroke-width="1" />
            </g>
        </g>
    </svg>
    )
}