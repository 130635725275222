import { createStore, applyMiddleware, compose } from 'redux'

// middlewares
//import thunkMiddleware from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'
import rootReducer from '.'
//import logger from 'redux-logger'
import thunkMiddleware from 'redux-thunk'
// Import custom components

import rootSaga from './sagas';



const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware, thunkMiddleware];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(...middlewares)))
sagaMiddleware.run(rootSaga)

export default store