export default function HashLastTrack({
    status
}) {
    if (status === 1) {
        return "Recieved"
    }
    else if (status === 2) {
        return "Waiting Approve"
    }
    else if (status === 3) {
        return "Waiting Apply"
    }
    else if (status === 5) {
        return "Rejected"
    }
    else if (status === 6) {
        return "Pending"
    }
    else {
        return "done"
    }
}