import React from 'react'
import X from "./xicon.svg";
import InputMask from 'react-input-mask';
function HoursInput(props) {
    const { lang, setLangStart,setLangEnd } = props;
  return (
    <>
    <div className='input-con'>
    <InputMask
        className={`${props.pad && "pad20"}`}
        style={props.pad ? { paddingLeft: "20px" } : { paddingLeft: "0px",width:"28px" }}
        readOnly={props.disabled}
        mask={"99:99"}
       id='date1d'
        maskPlaceholder="02-00"
        Placeholder="02-00"
        maskChar={null}
        onChange={(e) => {
          if (e.target.value.length <= 30) setLangStart(e);
    
        }}
        value={lang[0]}
        type="text"
      />
      <span style={{marginLeft:"0px",display:"flex",width:"12px",justifyContent:"center",color:"#CECDCD"}}> - </span>
       <InputMask
       id='date2d'
        className={`${props.pad && "pad20"}`}
        style={props.pad ? { paddingLeft: "20px" } : { paddingLeft: "0px",width:"28px",marginLeft:"4px" }}
        readOnly={props.disabled}
        mask={"99:99"}
        maskChar={null}

        Placeholder="02-00"
        onChange={(e) => {
    

          if (e.target.value.length <= 30) setLangEnd(e);
          if(e.target.value.length===0){

          }
        }}
        value={lang[1]}
        type="text"
      />
     <img
        style={{ bottom: "9px", right: "2px" }}
        onClick={() => props.clear()}
        alt="close"
        src={X}
      />
    </div>
    
    </>
  )
}

export default HoursInput