import axios from "axios";
import {
    all,
    fork,
    put,
    takeEvery
} from "redux-saga/effects";
import store from "../store"
import { toast } from 'react-toastify';
import { REACT_APP_ACCOUNTING_URL } from "../../urls";

function* getTicketsStatics(action) {
    try {
        yield put({ type: "GET_TICKETS_STATICS_WAIT" })
        const token = store.getState()?.whatsapp?.userTokenWhatsapp;
        const base_user = REACT_APP_ACCOUNTING_URL;
        let AxiosInstace = axios.create({
            baseURL: `${base_user}`,
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
        });
        let res = yield AxiosInstace.get(`/api/v1/tickets/statistics?limit=300${action.payload.search.length ? ("&filter[search]=" + action.payload.search) : ""}${action.payload.ticket_city_id ? ("&filter[ticket_city_id]=" + action.payload.ticket_city_id) : ""}${action.payload.ticket_country_id ? ("&filter[ticket_country_id]=" + action.payload.ticket_country_id) : ""}${action.payload.usdFilter ? ("&filter[currency]=" + action.payload.usdFilter) : ""}&filter[created_from]=${action.payload.created_from}&filter[created_to]=${action.payload.created_to}${action.payload.typeFilter ? ("&filter[type]=" + action.payload.typeFilter) : ""}`)
        yield put({
            type: "GET_TICKETS_STATICS_RED", payload: res?.data?.data
        })
        yield put({ type: "GET_TICKETS_STATICS_DONE" })
    }
    catch (e) {
        toast.error("network error !!")
        yield put({ type: "GET_TICKETS_STATICS_DONE" })
    }
}
function* watchgetTicketsStatics() {
    yield takeEvery("GET_TICKETS_STATICS_SAGA", getTicketsStatics)
}
function* getUpdatedStatics(action) {
    try {
        const token = store.getState()?.whatsapp?.userTokenWhatsapp;
        const base_user = REACT_APP_ACCOUNTING_URL;
        let AxiosInstace = axios.create({
            baseURL: `${base_user}`,
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
        });
        let res = yield AxiosInstace.get(`api/v1/tickets/statistics_with_one_chat/${action.payload.chat_id}?limit=300${action.payload.search.length ? ("&filter[search]=" + action.payload.search) : ""}${action.payload.ticket_city_id ? ("&filter[ticket_city_id]=" + action.payload.ticket_city_id) : ""}${action.payload.ticket_country_id ? ("&filter[ticket_country_id]=" + action.payload.ticket_country_id) : ""}${action.payload.usdFilter ? ("&filter[currency]=" + action.payload.usdFilter) : ""}&filter[created_from]=${action.payload.created_from}&filter[created_to]=${action.payload.created_to}${action.payload.typeFilter ? ("&filter[type]=" + action.payload.typeFilter) : ""}`)
        yield put({
            type: "GET_UPDATED_STATICS_RED", payload: res?.data?.data
        })
    }
    catch (e) {
        toast.error("error updating statistics!")
    }
}
function* watchgetUpdatedStatics() {
    yield takeEvery("GET_UPDATED_STATICS_SAGA", getUpdatedStatics)
}
function* getTicketsCountries(action) {
    try {
        const token = store.getState()?.whatsapp?.userTokenWhatsapp;
        const base_user = REACT_APP_ACCOUNTING_URL;
        let AxiosInstace = axios.create({
            baseURL: `${base_user}`,
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
        });
        let res = yield AxiosInstace.get(`/api/v1/ticket_countries`)
        yield put({
            type: "GET_TICKETS_COUNTRIES_RED", payload: res?.data?.data
        })
    }
    catch (e) {
        toast.error("network error !!")
    }
}
function* watchgetTicketsCountries() {
    yield takeEvery("GET_TICKETS_COUNTRIES_SAGA", getTicketsCountries)
}
function* getTicketsCities(action) {
    try {
        const token = store.getState()?.whatsapp?.userTokenWhatsapp;
        const base_user = REACT_APP_ACCOUNTING_URL;
        let AxiosInstace = axios.create({
            baseURL: `${base_user}`,
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
        });
        let res = yield AxiosInstace.get(`/api/v1/ticket_cities`)
        yield put({
            type: "GET_TICKETS_CITIES_RED", payload: res?.data?.data
        })
    }
    catch (e) {
        toast.error("network error !!")
    }
}
function* watchgetTicketsCities() {
    yield takeEvery("GET_TICKETS_CITIES_SAGA", getTicketsCities)
}
export default function* TicketsSaga() {
    yield all([
        fork(watchgetTicketsStatics),
        fork(watchgetTicketsCountries),
        fork(watchgetTicketsCities),
        fork(watchgetUpdatedStatics)
    ]);
}
