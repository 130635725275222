import React, { useState } from 'react'
import { TopNav } from "../shared/index"
import { useSelector, useDispatch } from "react-redux"
import Curre from "../../../../assets/svg/Transfer.svg"
import "./ship.css"
import "./ship2.css"
import "./bulks.css"
// import Filter from './filter'
// import FilterCount from './filter_count'
import MainStatuses from './main_statuses'
// import PackageFilter from './package_filter'
// import PackageFilterExpand from './package_filter_expand'
// import BulkList from './bulks_list'
import { useEffect } from 'react'
// import StaticsSquare from './staticssquare'
// import date from "date-and-time"
import { pusher } from '../../../../redux/chat/constants'
import axios from 'axios'
import { toast } from 'react-toastify'
import { REACT_APP_ACCOUNTING_URL } from '../../../../urls'
// import { fakeData } from './fakeData'

export default function Shipments(props) {
    const dispatch = useDispatch()
    const ticket_types = useSelector(state => state.empticketsReducer.tickets)
    // const statics_loading = useSelector((state) => state.empticketsReducer.loading)
    // const countries = useSelector(state => state.empticketsReducer.countries)
    // const cities = useSelector(state => state.empticketsReducer.cities)
    const [lang, setLang] = useState(null)
    const [activeStatus, setActiveStatus] = useState(null)
    // const [dateFilter, setDateFilter] = useState(5)
    // const [usdFilter, setUsdFilter] = useState(0)
    // const [typeFilter, setTypeFilter] = useState(null)
    // const [countriesFilter, setCountriesFilter] = useState(null)
    // const [citiesFilter, setCitiesFilter] = useState(null)
    // const [fakeFilterSelected, setFakeFilterSelected] = useState([null, null, null, null])
    const translated_lang = useSelector((state) => state.auth.lang)
    let user = useSelector(state => state.auth.user)
    let chs = pusher.subscribe(`employee-${user.id}-channel`)
    let channel = pusher.subscribe(`presence-editing-ticket-rate`);
    const handleTicket = (data) => {
        dispatch({
            type: "GET_UPDATED_EMP_STATICS_RED",
            payload: {
                data: data?.ticket
            }
        })
        dispatch({ type: "GET_EMP_TICKETS_STATICS_ONLY_SAGA" })
    }
    useEffect(() => {
        chs.bind("EmployeeTicketEvent", handleTicket)
        return (() => {
            chs.unbind("EmployeeTicketEvent", handleTicket)
        }
        )
    }, [chs])
    useEffect(() => {
        channel.bind("client-edit-ticket", (data) => {
            dispatch({
                type: "ticketLockedByUser",
                payload: data
            })
        })
        return (() => {
            chs.unbind("client-edit-ticket")
        }
        )
    }, [channel])
    useEffect(() => {
        channel.bind("client-finish-edit-ticket", (data) => {
            dispatch({
                type: "ticketUnLockedByUser",
                payload: data
            })
        })
        return (() => {
            chs.unbind("client-edit-ticket")
        }
        )
    }, [channel])
    const getLang = () => {
        if (lang) {
            return (lang)
        }
        else {
            return translated_lang
        }
    }
    const editTicket = (ticket_id) => {
        channel.trigger(`client-edit-ticket`, {
            ticket_id: ticket_id,
            user_id: user.id,
            username: user.username
        })
    }
    const finishEditTicket = (ticket_id) => {
        channel.trigger(`client-finish-edit-ticket`, {
            ticket_id: ticket_id,
            user_id: user.id,
            username: user.username
        })
    }
    const token = useSelector(state => state.whatsapp?.userTokenWhatsapp)
    const getPage = async (action) => {
        try {
            const base_user = REACT_APP_ACCOUNTING_URL;
            let AxiosInstace = axios.create({
                baseURL: `${base_user}`,
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "application/json",
                },
            });
            let res = await AxiosInstace.get(`api/v1/tickets/employees_view?filter[last_ticket_id]=${action.last_ticket_id}`)
            dispatch({
                type: "GET_PAGE_TICKETS_RED",
                payload: {
                    data: res.data.data
                }
            })
        }
        catch (e) {
            toast.error("error getting statistics!")
        }
    }
    useEffect(() => {
        dispatch({ type: "GET_TICKETS_COUNTRIES_SAGA" })
        dispatch({ type: "GET_TICKETS_CITIES_SAGA" })
    }, [dispatch])
    useEffect(() => {
        dispatch({
            type: "GET_EMP_TICKETS_STATICS_SAGA"
        })
    }, [lang])

    return (<div className={`languages-page ${props.active && "Active-page"} tickets_page`} style={{ paddingLeft: "28px" }}>
        <textarea style={{ opacity: 0, height: '0px', position: "absolute" }} id="hash-copy" />
        <TopNav
            table_icon={Curre}
            table_name="Tickets"
            top_name_clk={() => { }}
            setLang={(e) => setLang(e)}
            disableAdd={true}
            lang={getLang()}
            hasChild={true}
            child={() => <></>}
        />
        <MainStatuses
            getPage={(arg) => {
                return getPage({
                    ...arg
                })
            }}
            ticket_types={ticket_types}
            activeStatus={activeStatus}
            setActiveStatus={(e) => setActiveStatus(e)}
            editTicket={(ticket_id) => editTicket(ticket_id)}
            finishEditTicket={(ticket_id) => finishEditTicket(ticket_id)}
        />
    </div>)
}