import React, { useEffect, useState, useMemo } from 'react'
import Time from "../svg/time"
import Person from '../svg/person'
import Tether from '../svg/tether'
import WaitingIcon from '../svg/usdt_right'
import Location from '../svg/location'
import HashTrack from '../hash_track'
// import WhatsTen from './svg/whats_ten.'
import ID2 from '../svg/id2'
import Rf from '../svg/rf'
import Docum from '../svg/document'
// import SmallCheck from './svg/smallCheck'
import HashLastTrack from '../hash_last'
// import BigCheck from './svg/bigCheck'
// import BigFail from './svg/bigFail'
// import SmallFail from './svg/smallFail'
import Withdrawal from '../svg/withdrowal'
import Deposit from '../svg/deposit'
import PackageCart from '../svg/package_cart'
import EditIcon from '../svg/edit'
import { useDispatch, useSelector } from 'react-redux'
import ApproveTicket from '../svg/approveTicket'
import ProfileNo from "../../../WhatsApp/assets/images/profileNo.png"
import AdminChat from '../svg/adminChat'
import SendIcon from '../svg/sendIcon'
import { approveTicket, ticketChange, doneTicket, getRateOptions, getAdminChat, applyTicket } from '../../../../../redux/statics_emp/actions'
import CheckBig from '../svg/check15'
import MaskedPhone from '../svg/maskedPhone'
import Insructions from '../insructions'
import { toast } from 'react-toastify'
// import ID from '../svg/id'

export default function OdooOnly(props) {
    const roles = useSelector(state => state.auth.roles)
    let lockedTickets = useSelector(state => state.empticketsReducer.lockedTickets)
    const ticket = props.ticket
    const [open, setOpen] = useState(false)
    const [openDetail, setOpenDetail] = useState(false)
    const [openOrders, setOpenOrders] = useState(false)
    const [editRate, setEditRate] = useState(false)
    const [rateOptions, setRateOptions] = useState([])
    var lookup = require('country-data').lookup;
    const editTicket = (id) => {
        props.editTicket(id)
    }
    const dispatch = useDispatch()
    const getCountry = (val) => {
        let data = lookup.countries({ name: val })[0];
        if (data) {
            return data
        }
    }
    const getTime = (t) => {
        let diff = new Date().getTime() - new Date(t).getTime()
        if (diff / 1000 < 60) {
            return (`${(diff / 1000).toFixed(0)} s`)
        } else if (diff / 1000 < 3600) {
            return (`${(diff / 60000).toFixed(0)} m`)
        } else if (diff / 1000 < (3600 * 24)) {
            return (`${(diff / 3600000).toFixed(0)} h`)
        } else if (diff / 1000 > (3600 * 24)) {
            return (`${(diff / (3600000 * 24)).toFixed(0)} d`)
        }
    }
    const [update, setUpdate] = useState(1)
    const [confirmRate, setConfirmRate] = useState(false)
    const [confirmDocs, setConfirmDocs] = useState(false)
    const [mouseOnButton, setMouseOnButton] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingApprove, setLoadingApprove] = useState(false)
    const [loadingAdminChat, setLoadingAdminChat] = useState(false)
    const [openInstructions, setOpenInstructions] = useState(false)
    const [loadingDocs, setLoadingDocs] = useState(false)

    useEffect(() => {
        const interval = setInterval(() => setUpdate(update + 1), 1000);
        return () => clearInterval(interval);
    }, [update])
    useEffect(() => {
        if (editRate === true) {
            document.getElementById(`${ticket.id}-ticket-rate-input`)?.focus()
        }
    }, [editRate])
    const getLastTime = ({
        approved_at,
        received_at,
        applied_at
    }) => {
        if (applied_at) {
            return getTime(applied_at)
        } else if (approved_at) {
            return getTime(approved_at)
        } else {
            return getTime(received_at)
        }
    }
    const getIconColor = (status) => {
        if (status === 1) {
            return "#FFFFFF"
        } else if (status === 2) {
            return "#84C0FF"
        } else {
            return "#00C408"
        }
    }
    const [height, setHeight] = useState(82)
    useEffect(() => {
        let heightVar = 82
        if ((open === true) && (openOrders === true)) {
            let height1 = document?.getElementById(`${ticket.id}-ticket-details`)?.clientHeight || 0
            let height2 = document?.getElementById(`${ticket.id}-ticket-comment`)?.clientHeight || 10
            let height3 = document?.getElementById(`${ticket.id}-ticket-status`)?.clientHeight || 0
            let height4 = document?.getElementById(`${ticket.id}-ticket-chat`)?.clientHeight || 0
            setHeight(heightVar + height3 + height1 + height2 + height4 - 32)
        } else if (open === true) {
            let height1 = document?.getElementById(`${ticket.id}-ticket-status`)?.clientHeight || 0
            setHeight(heightVar + height1 - 14)
        } else {
            setHeight(heightVar)
        }
    }, [open, openOrders, openDetail, ticket, editRate, confirmRate])
    return (<div className={`${open ? "employee_bulk_element_with_status_selected" : "employee_bulk_element_with_status"}`}
        onMouseEnter={() => {
            setOpen(true)
        }}
        onMouseLeave={() => {
            if (open && !openOrders) {
                setOpenDetail(false)
                setOpen(false)
                setOpenInstructions(false)
            }
        }}
        onClick={() => {
            setOpen(true)
        }}
    // style={{ height: `${height}px` }}
    >
        {(openInstructions && ticket?.ticket_processing_flow && ticket?.ticket_processing_flow?.flow_steps) ? <><Insructions ticket_processing_flow={ticket.ticket_processing_flow} setOpenInstructions={(value) => setOpenInstructions(value)} /></> : <><div className={`employee_bulk_element_usdt`}
            style={{ backgroundColor: props.ticketColor }}>
            <div className={`employee_bulk_element_name ${openOrders ? "withmargin" : ""}`}
                onClick={() => {
                    setOpenOrders(!openOrders)
                }}>
                <div className="employee_bulk_element_name_icon">
                    <div className="employee_bulk_icon" onMouseEnter={() => setOpenInstructions(true)}>
                        {ticket?.type === "deposit" ? <Deposit /> : <Withdrawal />}
                    </div>
                    <div className="employee_bulk_name_tether">
                        {(ticket?.request_number || ticket?.reference) ? (ticket?.request_number || ticket?.reference) : "Waiting"}
                    </div>
                </div>
                <div className="employee_bulk_element_right_icon">
                    <div className="employee_bulk_element_time">
                        <div className="employee_bulk_element_time">
                            <div className="employee_bulk_element_time_unit" style={{ textTransform: "lowercase" }}> {getTime(ticket?.rejected_at ? ticket?.rejected_at : ticket?.received_at)} </div>
                        </div>
                    </div>
                    <Time color="#FFFFFF" />
                    <PackageCart color1={props.color1} color2={props.color2} />
                </div>
            </div>
            <div className="employee_bulk_element_data"
                onClick={() => {
                    setOpenOrders(!openOrders)
                }}>
                <div className="employee_bulk_element_country_data">
                    <div className="employee_bulk_element_country_icon_odoo">
                        {((ticket?.destinationCountry === "Crypto Currency") || (ticket?.destinationCity === "Crypto Currency") || (ticket?.destinationCountry === "USDT") || (ticket?.destinationCity === "USDT")) ? <Tether /> : getCountry(ticket?.destinationCountry)?.emoji}
                    </div>
                    <div className="employee_bulk_element_country_name_odoo">
                        {ticket?.destinationCountry}, {ticket?.destinationCity}
                    </div>
                </div>
            </div>
            {open && openOrders ? <><div id={`${ticket.id}-ticket-details`} className='employee_bulk_orders_area'>
                {<div className='employee_bulk_orders_area_element'>
                    <div className='employee_bulk_orders_area_element_icon'><Person /></div>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginRight: "10px",
                        width: "-webkit-fill-available"
                    }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginRight: "10px"
                        }}>
                            <div className='employee_bulk_orders_area_element_text' style={{
                                textDecoration: "auto",
                                color: "#FFFFFF",
                                marginBottom: "7.5px"
                            }}>
                                {(ticket?.name) ? (ticket?.name) : "-"}  {ticket.ticket_logs?.filter(one => parseInt(one.event) === 2).length > 0 && "*"}
                            </div>
                        </div>
                    </div>
                </div>}
                {<div className='employee_bulk_orders_area_element'>
                    <div className='employee_bulk_orders_area_element_icon'><ID2 /></div>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginRight: "10px",
                        width: "-webkit-fill-available"
                    }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginRight: "10px"
                        }}>
                            <div className='employee_bulk_orders_area_element_text' style={{
                                textDecoration: "auto",
                                color: "#FFFFFF",
                                marginBottom: "7.5px"
                            }}>
                                {(ticket?.trading_number) ? (ticket?.trading_number) : "-"}  {ticket.ticket_logs?.filter(one => parseInt(one.event) === 6).length > 0 && "*"}
                            </div>
                        </div>
                    </div>
                </div>}
                <div className='employee_bulk_orders_area_element'>
                    <div className='employee_bulk_orders_area_element_icon'><Location /></div>
                    <div className='employee_bulk_orders_area_element_text'>{ticket?.operatingUnit || ticket?.company}</div>
                </div>

                {ticket.payment_type &&
                    <div className='employee_bulk_orders_area_element'>
                        <div className='employee_bulk_orders_area_element_icon'><svg id="_10x10_flag_photo" data-name="10x10 flag photo" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
                            <g id="Mask_Group_195" data-name="Mask Group 195">
                                <g id="options" transform="translate(0 0.011)">
                                    <path id="Path_19175" data-name="Path 19175" d="M2.346,5.313V3.125a1.867,1.867,0,0,1-.585,0V5.314A2.652,2.652,0,0,1,2.054,5.3a2.683,2.683,0,0,1,.292.016Z" transform="translate(0.935 1.989)" fill="#95bdff" />
                                    <path id="Path_19176" data-name="Path 19176" d="M4.115,6.288,6.282,4.121a1.877,1.877,0,0,1-.413-.413L3.7,5.875a2.665,2.665,0,0,1,.413.413Z" transform="translate(2.494 2.488)" fill="#95bdff" />
                                    <path id="Path_19177" data-name="Path 19177" d="M6.885,7.643H4.676a2.684,2.684,0,0,1,.016.292,2.656,2.656,0,0,1-.016.292H6.885a1.868,1.868,0,0,1,0-.585Z" transform="translate(3.001 4.052)" fill="#95bdff" />
                                    <circle id="Ellipse_335" data-name="Ellipse 335" cx="1.277" cy="1.277" r="1.277" transform="translate(0.777)" fill="#95bdff" />
                                    <circle id="Ellipse_336" data-name="Ellipse 336" cx="1.277" cy="1.277" r="1.277" transform="translate(11.098 1.328)" fill="#95bdff" />
                                    <circle id="Ellipse_337" data-name="Ellipse 337" cx="1.277" cy="1.277" r="1.277" transform="translate(12.447 11.649)" fill="#95bdff" />
                                    <path id="Path_19178" data-name="Path 19178" d="M4.108,7.936A2.054,2.054,0,1,0,2.054,9.989,2.041,2.041,0,0,0,4.108,7.936Z" transform="translate(0 4.989)" fill="#95bdff" />
                                </g>
                            </g>
                        </svg></div>
                        <div className='employee_bulk_orders_area_element_text'>{ticket?.payment_type}</div>
                    </div>}
                {ticket?.maskedPhoneNumber ?
                    <div className='employee_bulk_orders_area_element'>
                        <div className='employee_bulk_orders_area_element_icon'><MaskedPhone /></div>
                        <div className='employee_bulk_orders_area_element_text'>{ticket?.maskedPhoneNumber}</div>
                    </div> : <></>}
                {<div className='employee_bulk_orders_area_element'>
                    <div className='employee_bulk_orders_area_element_icon'><Rf /></div>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginRight: "10px",
                        width: "-webkit-fill-available"
                    }}>
                        <div className='employee_bulk_orders_area_element_text' style={{ color: (confirmRate || (ticket.ticket_status_id !== 2)) ? "#FFFFFF" : "#95BDFF" }}>
                            {ticket?.amount} {ticket?.currency} / {ticket?.rate ? (parseFloat(ticket?.amount) * parseFloat(ticket?.rate)).toFixed(2) : "-"} {ticket?.localCurrency}
                        </div>
                        {props.ticket_status_id === 2 ? <div className='approveIcon' onClick={() => {
                            if (!confirmRate) {
                                setConfirmRate(true)
                            } else {
                                setConfirmRate(false)
                            }
                        }}><ApproveTicket color={confirmRate ? "#93D16B" : "#95BDFF"} /></div> : <div />}
                    </div>
                </div>}
                <div className='employee_bulk_orders_area_element'>
                    <div className='employee_bulk_orders_area_element_icon'><Docum /></div>
                    <div style={{ width: "-webkit-fill-available" }}>
                        {editRate ?
                            <div className='employee_bulk_orders_area_element_text'>
                                <input id={`${ticket.id}-ticket-rate-input`} type='number' className='edit_rate_employee_ticket_input'
                                    defaultValue={ticket?.rate}
                                    onKeyUp={(e) => {
                                        if ((e.key === 'Enter' || e.keyCode === 13) && e.target?.value?.length > 0) {
                                            if (e.target?.value !== ticket.rate) {
                                                props.finishEditTicket(ticket.id)
                                                setEditRate(false)
                                                setLoading(true)
                                                ticketChange({
                                                    ticket_id: ticket.id,
                                                    field_name: "rate",
                                                    new_value: e.target?.value
                                                }).then(() => {
                                                    setLoading(false)
                                                })
                                            } else {
                                                setEditRate(false)
                                            }
                                        }
                                    }}
                                    onBlur={(e) => {
                                        if (e.target?.value?.length > 0 && (parseInt(e.target?.value) !== parseInt(ticket.rate))) {
                                            props.finishEditTicket(ticket.id)
                                            setEditRate(false)
                                            setLoading(true)
                                            ticketChange({
                                                ticket_id: ticket.id,
                                                field_name: "rate",
                                                new_value: e.target?.value
                                            }).then(() => {
                                                setLoading(false)
                                            })
                                        } else {
                                            setEditRate(false)
                                        }
                                    }} />
                                {(rateOptions?.length > 0) && <div className='rates_menu_absulute'>
                                    {rateOptions?.map((rate, index) => <div key={index} onClick={() => {
                                        document.getElementById(`${ticket.id}-ticket-rate-input`).value = ticket?.type === "deposit" ? rate.deposit_rate : rate.withdrawal_rate
                                        document.getElementById(`${ticket.id}-ticket-rate-input`)?.focus()
                                    }}
                                        onMouseEnter={() => {
                                            document.getElementById(`${ticket.id}-ticket-rate-input`).placeholder = ticket?.type === "deposit" ? rate.deposit_rate : rate.withdrawal_rate
                                        }}
                                        onMouseLeave={() => {
                                            document.getElementById(`${ticket.id}-ticket-rate-input`).placeholder = ""
                                        }}
                                    >
                                        {ticket?.type === "deposit" ? rate.deposit_rate : rate.withdrawal_rate}
                                    </div>)}
                                </div>}
                            </div> : <div style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginRight: "10px"
                            }}>
                                <div className='employee_bulk_orders_area_element_text' style={{
                                    textDecoration: "auto",
                                    color: "#FFFFFF",
                                    marginBottom: "7.5px"
                                }}>
                                    {ticket.rate ? ticket.rate : "-"} {ticket.ticket_logs?.filter(one => parseInt(one.event) === 3).length > 0 && "*"}
                                    {((props.ticket_status_id === 2) && !confirmRate) && <div className='edit_rate_employee_ticket'
                                        style={{ cursor: lockedTickets.filter(one => one.ticket_id === ticket.id).length === 0 ? "pointer" : "initial" }}
                                        onClick={() => {
                                            if (lockedTickets.filter(one => one.ticket_id === ticket.id).length === 0) {
                                                getRateOptions({ ticket_id: ticket.id }).then((data) => {
                                                    setRateOptions(data)
                                                }).catch((e) => {
                                                    toast.info("error while getting rate options!")
                                                })
                                                editTicket(ticket.id)
                                                setEditRate(true)
                                            }
                                            if (editRate) {
                                                setEditRate(false)
                                                props.finishEditTicket(ticket.id)
                                            }
                                        }}>
                                        <div className='rep-descs-emp'>{lockedTickets.filter(one => one.ticket_id === ticket.id).length > 0 ? `${lockedTickets.filter(one => one.ticket_id === ticket.id)[0].username} editing` :
                                            (editRate ? "exit" : "Add New Rate")}</div>
                                        {loading ? <WaitingIcon size={10} /> : <EditIcon color={lockedTickets.filter(one => one.ticket_id === ticket.id).length === 0 ? "#fff" : "#FF4141"} />}
                                    </div>}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div></> : <></>}
        </div>
            {open && openOrders ? <>
                <div id={`${ticket.id}-ticket-comment`} className='employee_bulk_orders_area_comment' style={{ backgroundColor: "#325590" }}>
                    <div className='employee_bulk_orders_area_comment_inner'>
                        {ticket?.comment ? ticket?.comment : "no comment"}
                    </div>
                </div>
            </> : <></>}</>}
        {
            open ? (!openDetail ? <div id={`${ticket.id}-ticket-status`} className={`${openOrders ? "employee_status_area_element_with_order" : "employee_status_area_element"}`}
                style={{
                    backgroundColor: "#EFEFEF"
                }}
                onClick={() => setOpenDetail(true)}>
                <div className='employee_status_area_element_child'>
                    <div className="employee_status_area_element_name">
                        <div className="employee_status_area_element_name_icon">

                        </div>
                        <div className="employee_status_area_element_name_name">
                            <WaitingIcon size={10} />
                            <HashLastTrack
                                status={ticket?.ticket_status_id}
                            />
                        </div>
                    </div>
                    <div className="employee_status_area_element_time">
                        <div className="employee_status_area_element_time_time">
                            {getLastTime({
                                approved_at: ticket?.approved_at,
                                received_at: ticket?.received_at,
                                applied_at: ticket?.applied_at
                            })}
                        </div>
                        <div className="employee_status_area_element_time_icon">
                            <Time color="#5D5D5D" />
                        </div>
                    </div>
                </div>
            </div> : <div id={`${ticket.id}-ticket-status`} className={`${openOrders ? "employee_status_area_element_with_order" : "employee_status_area_element_full_options"}`}
                style={{
                    backgroundColor: "#EFEFEF"
                }}
                onClick={() => setOpenDetail(false)}>
                <HashTrack
                    created_at={ticket?.created_at}
                    rejected_at={ticket?.rejected_at}
                    rejected_reason={ticket?.rejected_reason}
                    approved_at={ticket?.approved_at}
                    approved_by_user_id={ticket?.approved_by_user_id}
                    approved_by_user_full_name={ticket?.approved_by_user_full_name}
                    approved_by_contact_id={ticket?.approved_by_contact_id}
                    approved_by_contact_name={ticket?.approved_by_contact_name}
                    approved_by_contact_profile_photo={ticket?.approved_by_contact_profile_photo}
                    received_at={ticket?.received_at}
                    received_by_user_id={ticket?.received_by_user_id}
                    received_by_user_full_name={ticket?.received_by_user_full_name}
                    applied_at={ticket?.applied_at}
                    applied_by_contact_id={ticket?.applied_by_contact_id}
                    applied_by_contact_name={ticket?.applied_by_contact_name}
                    applied_by_contact_profile_photo={ticket?.applied_by_contact_profile_photo}
                    openOrders={openOrders}
                    setOpenDetail={setOpenDetail}
                    ticket_status_id={props.ticket_status_id}
                    ticket_logs={ticket.ticket_logs}
                    comment={ticket?.comment}
                />
            </div>) : <></>
        }
        {
            open && openOrders ? <>
                <div id={`${ticket.id}-ticket-chat`} className='employee_status_area_element_with_order_chat' style={{ backgroundColor: "#F4F5F5" }}>
                    <div className='employee_status_area_element_with_order_chat_container'>
                        <div className='employee_status_area_element_with_order_chat_container_inner'>
                            <img className='employee_status_area_element_with_order_chat_img' src={ticket?.admin_whatsapp_contact?.profile_photo || ProfileNo} alt='ticket admin' />
                            {((!confirmRate) && ticket.ticket_status_id === 2) ? <div className='chat_with_admin_tickets_button' onClick={() => {
                                setLoadingAdminChat(true)
                                getAdminChat({ contact_id: ticket.admin_whatsapp_contact?.id }).then((chat) => {
                                    dispatch({ type: "OPEN-WA", payload: true })
                                    dispatch({ type: "CHAT_ID", payload: chat })
                                    setLoadingAdminChat(false)
                                }).catch((e) => {
                                    toast.info("cannot get the admin for this ticket please check configurations!")
                                    setLoadingAdminChat(false)
                                })
                            }}> {loadingAdminChat ? <WaitingIcon /> : <AdminChat />} Chat With Admin “ <div className='chat_with_admin_tickets_button_admin_name'>{ticket?.admin_whatsapp_contact?.name || ticket?.admin_whatsapp_contact?.pushname}</div>”</div> : <>{loadingAdminChat ? <WaitingIcon /> : <AdminChat onClick={
                                () => {
                                    setLoadingAdminChat(true)
                                    getAdminChat({ contact_id: ticket.admin_whatsapp_contact?.id }).then((chat) => {
                                        dispatch({ type: "OPEN-WA", payload: true })
                                        dispatch({ type: "CHAT_ID", payload: chat })
                                        setLoadingAdminChat(false)
                                    }).catch((e) => {
                                        toast.info("cannot get the admin for this ticket please check configurations!")
                                        setLoadingAdminChat(false)
                                    })
                                }} />}</>}
                        </div>
                        {ticket.ticket_status_id === 2 && <>{(confirmRate) ? <div className={mouseOnButton ? 'send_ticket_button_active' : 'send_ticket_button'}
                            onClick={() => {
                                setLoadingApprove(true)
                                approveTicket({
                                    ticket_id: ticket?.id
                                }).then(() => {
                                    setLoadingApprove(false)
                                    dispatch({ type: "GET_EMP_TICKETS_STATICS_ONLY_SAGA" })
                                })
                            }}
                            onMouseEnter={() => setMouseOnButton(true)} onMouseLeave={() => setMouseOnButton(false)}>
                            {mouseOnButton && <>{loadingApprove ? <WaitingIcon /> : <SendIcon color="#404040" />}</>}
                            {mouseOnButton ? "Approve & Send" : "Send"}
                            {!mouseOnButton && <>{loadingApprove ? <WaitingIcon /> : <SendIcon color="#404040" />}</>} </div> : (<SendIcon color="#8E8E8E" disabled={true} />)}
                        </>}
                        {(ticket.ticket_status_id === 3 && roles.includes("APPLY_TICKETS_PERMISSION")) && <div className={mouseOnButton ? 'send_ticket_button_active' : 'send_ticket_button'}
                            onClick={() => {
                                setLoadingApprove(true)
                                applyTicket({
                                    ticket_id: ticket?.id
                                }).then(() => {
                                    setLoadingApprove(false)
                                    dispatch({ type: "GET_EMP_TICKETS_STATICS_ONLY_SAGA" })
                                })
                            }}
                            onMouseEnter={() => setMouseOnButton(true)} onMouseLeave={() => setMouseOnButton(false)}>
                            {mouseOnButton && <>{loadingApprove ? <WaitingIcon /> : <SendIcon color="#404040" />}</>}
                            {mouseOnButton ? "Apply Ticket" : "Apply"}
                            {!mouseOnButton && <>{loadingApprove ? <WaitingIcon /> : <SendIcon color="#404040" />}</>} </div>
                        }
                        {ticket.ticket_status_id === 4 && <><div className={mouseOnButton ? 'send_ticket_button_active' : 'send_ticket_button'}
                            onClick={() => {
                                setLoadingApprove(true)
                                doneTicket({
                                    ticket_id: ticket?.id
                                }).then(() => {
                                    setLoadingApprove(false)
                                    dispatch({ type: "GET_EMP_TICKETS_STATICS_ONLY_SAGA" })
                                })
                            }}
                            onMouseEnter={() => setMouseOnButton(true)} onMouseLeave={() => setMouseOnButton(false)}>
                            {mouseOnButton && <>{loadingApprove ? <WaitingIcon /> : <CheckBig color="#404040" />}</>}
                            {mouseOnButton ? "Done" : "Done"}
                            {!mouseOnButton && <>{loadingApprove ? <WaitingIcon /> : <CheckBig color="#404040" />}</>} </div>
                        </>}
                    </div>
                </div>
            </> : <></>
        }
    </div >
    )
}