import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import ability from './config/acl/ability'
import { AbilityContext } from './config/acl/Can'
// Import custom components
import store from './redux/store';
import * as serviceWorker from './serviceWorker';
import { getLanguagesFiles } from './redux/translate/actions'
import * as Sentry from "@sentry/react";

getLanguagesFiles()

Sentry.init({
    dsn: "https://298510f5137b87249bbe73b5f0815489@o4506711087579136.ingest.sentry.io/4506711089676288",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", "https://staging.ramaaz.store", "https://ramaaz.online"],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <AbilityContext.Provider value={ability}>
                <App />
            </AbilityContext.Provider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
