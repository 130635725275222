import { all, fork, put, takeEvery } from "@redux-saga/core/effects";
import store from "../store";
import axios from "axios";
import { REACT_APP_ACCOUNTING_URL } from "../../urls";

function* getAllNotificationTemps(action) {
  let response;
  try {

    const token = store.getState()?.whatsapp?.userTokenWhatsapp;
    const base_user = REACT_APP_ACCOUNTING_URL;
    let AxiosInstace = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    response = yield AxiosInstace.get(`/api/v1/notification_templates`)
    yield put({ type: "GET_TEMPLATES_REDUCER", payload: response.data?.data })
  } catch (e) {

  }
}

function* watchGetAllNotificationTemps() {
  yield takeEvery("GET-NOT-TEMP", getAllNotificationTemps);
}

function* UpdateNotificationTemp(action) {
  let response;
  try {

    const token = store.getState()?.whatsapp?.userTokenWhatsapp;
    const base_user = REACT_APP_ACCOUNTING_URL;
    let AxiosInstace = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    response = yield AxiosInstace.put(`/api/v1/notification_templates/${action.payload.id}`, JSON.stringify({ template: action.payload.value }))
    yield put({ type: "UPD_TEMPLATES_REDUCER", payload: response.data?.data })
  } catch (e) {

  }
}

function* watchUpdateNotificationTemp() {
  yield takeEvery("EDIT_NOTIFICATION_TEMP", UpdateNotificationTemp);
}

export default function* NotificationTemplateSaga() {
  yield all([
    fork(watchGetAllNotificationTemps),
    fork(watchUpdateNotificationTemp)
  ]);
}
