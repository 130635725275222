const initialState = {
    settings: [],
    modalData: {
        ticket_country_id: null,
        rules: [
            {
                time_range_start_at: "00:00",
                time_range_end_at: "23:59",
                min_amount: "1",
                max_amount: "1000",
                ticket_currency_ids: [],
                ticket_commission_type_ids: [],
                whatsapp_mobile_phone_number_id: null,
                ticket_operating_unit_ids: [],
                target_chats: [],
                ticket_city_ids: [],
                users: []
            }
        ]
    },
    create_data: null,
    loading: false
}

export const TicketSetting = (state = initialState, { type, payload }) => {
    switch (type) {
        case "LOADING_TICKETS": {
            return {
                ...state,
                loading: true
            }
        }
        case "DONE_TICKETS": {
            return {
                ...state,
                loading: false
            }
        }
        case "GET_TICKET_SETTING_REDUCER": {
            return {
                ...state,
                settings: payload
            }
        }
        case "add_ticket_setting_new_red": {
            return {
                ...state,
                settings: state.settings.concat(payload)
            }
        }
        case "edit_ticket_setting_new_red": {
            return {
                ...state,
                settings: state.settings.map(one => {
                    if (one.id === payload.id) {
                        return payload
                    } else {
                        return one
                    }
                })
            }
        }
        case "delete_ticket_setting_new_red": {
            return {
                ...state,
                settings: state.settings.filter(one => one.id !== payload)
            }
        }
        case "GET_SETTINGS_CREATE_DATA_REDUCER": return {
            ...state,
            create_data: payload
        }
        case "SET_MODAL_DATA_TICKET": return {
            ...state,
            modalData: {
                ...state.modalData,
                ...payload
            }
        }
        case "SET_MODAL_DATA_TICKET_EDIT": return {
            ...state,
            modalData: {
                ...payload,
                id: payload.id,
                ticket_country_id: payload.ticket_country_id,
                rules: payload.ticket_rules?.map(one => {
                    return ({
                        ...one,
                        ticket_currency_ids: one.ticket_currencies.map(cur => cur.id),
                        ticket_commission_type_ids: one.ticket_commission_types.map(com => com.id),
                        ticket_operating_unit_ids: one.ticket_operating_units.map(op => op.id),
                        ticket_city_ids: one.ticket_cities.map(city => city.id),
                        users: one.users.map(user => user.id)
                    })
                })
            }
        }
        case "RESET_ADD_MODAL_DATA": return {
            ...state,
            modalData: {
                ticket_country_id: null,
                rules: [
                    {
                        time_range_start_at: "00:00",
                        time_range_end_at: "23:59",
                        min_amount: "1",
                        max_amount: "1000",
                        ticket_currency_ids: [],
                        ticket_commission_type_ids: [],
                        whatsapp_mobile_phone_number_id: null,
                        ticket_operating_unit_ids: [],
                        target_chats: [],
                        ticket_city_ids: [],
                        users: []
                    }
                ]
            },
            create_data: {
                ...state.create_data,
                ticket_cities: []
            }
        }
        case "set_edit_data_ticket": return {
            ...state,
            create_data: {
                ...state.create_data,
                ticket_cities: payload.ticket_cities
            }
        }
        default:
            return state
    }
}
