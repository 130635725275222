export default function Time(props) {
    return (<svg id="_10x10_flag_photo" data-name="10x10 flag photo" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="10" height="10" viewBox="0 0 10 10">
        <g id="Mask_Group_191" data-name="Mask Group 191">
            <g id="deadline" transform="translate(0.424 0)">
                <path id="Path_19158" data-name="Path 19158" d="M7.832,3.473a1.336,1.336,0,0,0,.208-.883.519.519,0,0,0-.265-.334A.464.464,0,0,0,7.4,2.221a.944.944,0,0,0-.487.542,3.945,3.945,0,0,0-2.132-.514L4.73,1.717c.065-.013.135-.027.212-.04a2.935,2.935,0,0,0,.91-.265A.744.744,0,0,0,6,1.3.507.507,0,0,0,6.158.867.723.723,0,0,0,5.8.346,1.36,1.36,0,0,0,4.86.284L3.24.6,3.107.626c-.495.089-1.32.238-1.235.981a.582.582,0,0,0,.25.436.773.773,0,0,0,.43.113,1.71,1.71,0,0,0,.469-.074c.181-.054.363-.1.55-.133l.1.512A3.957,3.957,0,0,0,2.03,3.527a.168.168,0,0,0-.054-.062,1.537,1.537,0,0,0-.805-.222.511.511,0,0,0-.48.244.416.416,0,0,0,.068.441,1.368,1.368,0,0,0,.506.3c.068.03.136.062.192.09a3.991,3.991,0,0,0,3.487,5.914,4.1,4.1,0,0,0,.8-.079A3.993,3.993,0,0,0,7.832,3.473ZM7.5,2.517a.148.148,0,0,1,.127.016.2.2,0,0,1,.109.136.968.968,0,0,1-.139.582,3.835,3.835,0,0,0-.42-.323.1.1,0,0,0,.014-.021.7.7,0,0,1,.309-.39ZM2.931,1.782a.84.84,0,0,1-.639,0,.272.272,0,0,1-.113-.212c-.048-.418.417-.537.981-.639L3.3.907,4.918.59A2.15,2.15,0,0,1,5.313.544a.663.663,0,0,1,.328.071A.421.421,0,0,1,5.849.9a.2.2,0,0,1-.067.176.515.515,0,0,1-.089.065,2.7,2.7,0,0,1-.807.227c-.108.019-.208.038-.292.057-.217.051-.442.092-.661.132a9.4,9.4,0,0,0-1,.222Zm.943.1.114-.021c.143-.027.287-.054.433-.084l.052.495c-.1.013-.206.029-.309.049-.065.013-.13.029-.193.044ZM1.393,3.943a1.924,1.924,0,0,1-.384-.2C.973,3.691.959,3.649.972,3.625s.087-.071.2-.071a1.241,1.241,0,0,1,.642.176.116.116,0,0,0,.03.011c-.079.1-.155.2-.225.309-.067-.033-.143-.068-.227-.106Zm4.286,5.9A3.68,3.68,0,1,1,4.226,2.631a3.717,3.717,0,0,1,.734-.073,3.68,3.68,0,0,1,.72,7.288Z" transform="translate(-0.655 -0.231)" fill={props.color} />
                <path id="Path_19159" data-name="Path 19159" d="M6.394,3.8a.156.156,0,0,0-.212.055L5.108,5.7a.567.567,0,0,0-.287-.016.588.588,0,0,0,.116,1.165.572.572,0,0,0,.116-.011.588.588,0,0,0,.461-.693.574.574,0,0,0-.143-.279L6.451,4.015A.157.157,0,0,0,6.394,3.8Zm-1.4,2.731a.277.277,0,0,1-.109-.544.328.328,0,0,1,.055,0,.276.276,0,0,1,.152.046.277.277,0,0,1-.1.5Z" transform="translate(-0.655 -0.231)" fill={props.color} />
                <path id="Path_19160" data-name="Path 19160" d="M3.883,8.573A2.592,2.592,0,0,1,2.325,5.731,2.859,2.859,0,0,1,4.834,3.424.155.155,0,1,0,4.8,3.115,3.17,3.17,0,0,0,2.019,5.676,2.924,2.924,0,0,0,3.745,8.851a.155.155,0,0,0,.07.016.154.154,0,0,0,.068-.293Z" transform="translate(-0.655 -0.231)" fill={props.color} />
                <path id="Path_19161" data-name="Path 19161" d="M9.2,5.14a3.188,3.188,0,0,0,.027.734,3.3,3.3,0,0,0,.089.729,2.042,2.042,0,0,0,.021-.739A2.044,2.044,0,0,0,9.2,5.14Z" transform="translate(-0.655 -0.231)" fill={props.color} />
                <path id="Path_19162" data-name="Path 19162" d="M9.8,6.055a1.979,1.979,0,0,0-.147-.808,6.61,6.61,0,0,0,.036.812,6.957,6.957,0,0,0,.022.812A1.969,1.969,0,0,0,9.8,6.055Z" transform="translate(-0.655 -0.231)" fill={props.color} />
                <path id="Path_19163" data-name="Path 19163" d="M1.222,8.776c-.03,0-.029.174.09.341s.282.223.293.2-.1-.117-.2-.262S1.254,8.771,1.222,8.776Z" transform="translate(-0.655 -0.231)" fill={props.color} />
                <path id="Path_19164" data-name="Path 19164" d="M.884,8.749a.9.9,0,0,0,.1.613.893.893,0,0,0,.4.476c.022-.024-.165-.222-.3-.521s-.168-.569-.2-.567Z" transform="translate(-0.655 -0.231)" fill={props.color} />
            </g>
        </g>
    </svg>
    )
}