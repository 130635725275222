import store from '../store';
import AxiosInstance from "../../helpers/axiosInstance";
import { GET_LANGS_URL } from "../LinkedLanguages/constants";
import { REACT_APP_BASE_FILE_URL } from '../../urls';

const getFile = async (file_path) => {
    const response = await fetch(file_path);
    const parsed = await response.json();
    return (parsed);
}

export const getLanguagesFiles = async () => {
    await AxiosInstance.get(GET_LANGS_URL).then(function (response) {
        response.data.data.map(async (lang, index) => {
            if (lang.file_path) {
                await getFile(`${REACT_APP_BASE_FILE_URL}${lang.file_path}`).then(file => {
                    store.dispatch({ type: "store_lang_file", payload: { lang: lang.language_code?.toLowerCase(), file } })
                })
            }
        })
    })
}