
import React from 'react'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import edit from "../Routes/Languages/svgs/edit.svg"
import CanCall from "../../CanCall"
import { trans } from '../../../Internationalization'

function NavItem(props) {
  const { child, main, AddLayout, ActiveLink } = props
  const categories = useSelector(state => state.categories.categories)
  const news = useSelector(state => state.empticketsReducer.news)
  return (<>
    {
      <div className={`parent-info ${!main ? "enable_edit" : ""} ${!main ? "rx10" : ""} ${(ActiveLink && (ActiveLink.name === child.name)) ? "active-nav" : ""}`} onClick={(e) => { if (ActiveLink && ActiveLink.name === child.name) { props.history.push("/"); AddLayout({ name: child.name, icon: child.icon, childreen: categories }, 0, e); } else { props.history.push(`/${child.name}`); AddLayout({ name: child.name, icon: child.icon, childreen: categories }, 0, e) } }}>
        <div className={`parent-icon ${(ActiveLink && (ActiveLink.name !== child.name)) ? "minfos" : ""}`}><svg width={"20"} height="20" data-src={child.icon}></svg></div>
        <div className={`parent-name ${(ActiveLink && (ActiveLink.name !== child.name)) ? "minfos" : ""}`}>{trans(child.key)}
          {(child.name === "Employee Transfer" && news > 0) && <div className='new_nav_item_count'> {news > 0 && `${news}`}</div>} </div>

        <CanCall action='USER_EDIT_CATEGORY' id='editCategoryBtn'>
          <div className='plus-con edit_icon'> <img className='disable_click' alt='edit' src={edit} /></div>
        </CanCall>
      </div>
    }
  </>)
}
export default withRouter(NavItem)