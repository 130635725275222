export default function Categ() {
  return (<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
    <g id="Mask_Group_265" data-name="Mask Group 265">
      <g id="menu-2">
        <path id="Path_21184" data-name="Path 21184" d="M3.437,6.875C.607,6.875,0,6.268,0,3.437S.607,0,3.437,0,6.875.607,6.875,3.437,6.268,6.875,3.437,6.875Z" fill="#404040" />
        <path id="Path_21185" data-name="Path 21185" d="M3.437,15C.607,15,0,14.393,0,11.563S.607,8.125,3.437,8.125s3.437.607,3.437,3.437S6.268,15,3.437,15Z" fill="#404040" />
        <path id="Path_21186" data-name="Path 21186" d="M8.125,11.563c0,2.831.607,3.437,3.438,3.437S15,14.393,15,11.563s-.607-3.437-3.437-3.437S8.125,8.732,8.125,11.563Z" fill="#404040" />
        <path id="Path_21187" data-name="Path 21187" d="M12.188,1.25a.625.625,0,0,0-1.25,0V2.813H9.375a.625.625,0,1,0,0,1.25h1.562V5.625a.625.625,0,0,0,1.25,0V4.063H13.75a.625.625,0,0,0,0-1.25H12.188Z" fill="#404040" />
      </g>
    </g>
  </svg>)
}

