import React from 'react'

function InputText(props) {
    const { value, setValue } = props
    const changeName = (e) => {
        if (e.target.value.length <= 15)
            setValue(e.target.value)
    }
    return (
        <>
            <input type={props.type ? props.type : "text"} onFocus={() => {
                if (!props.disabled) {
                    props.setFocuse(true)
                } else {
                    props.setFocuse(false)
                }
            }} placeholder={props.placeholder} className={`${props.pad ? "pad20 " : ""} ${props.isSelect ? (props.selectedValue ? "modal_input_with_select_selected_item" : "") : ""}`} style={props.pad35 ? { paddingLeft: "35px" } : { paddingLeft: "15px" }} readOnly={props.disabled} onChange={(e) => { setValue(e) }} value={value} />
        </>
    )
}

export default InputText