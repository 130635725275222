import mens from "../../components/Dashboard/Routes/Languages/svgs/mens.svg"
import women from "../../components/Dashboard/Routes/Languages/svgs/women.svg"
import kids from "../../components/Dashboard/Routes/Languages/svgs/kids.svg"
import home from "../../components/Dashboard/Routes/Languages/svgs/home.svg"
import elec from "../../components/Dashboard/Routes/Languages/svgs/electronics.svg"
const initialState = {
    categories: [
        { id: 3, name: "Men", routes: "category", icon: mens, childreen: [{ id: 8, name: "Dress", icon: mens, childreen: [] }, { id: 9, name: "T-Shert", icon: mens, childreen: [] }] },
        { id: 4, name: "Women", routes: "category", icon: women, childreen: [{ id: 8, name: "Dress", icon: women, childreen: [] }, { id: 9, name: "T-Shert", icon: women, childreen: [] }] },
        { id: 5, name: "Children", routes: "category", icon: kids, childreen: [{ id: 8, name: "Dress", icon: kids, childreen: [] }, { id: 9, name: "T-Shert", icon: kids, childreen: [] }] },
        { id: 6, name: "Home", routes: "category", icon: home, childreen: [{ id: 8, name: "Dress", icon: home, childreen: [] }, { id: 9, name: "T-Shert", icon: home, childreen: [] }] },
        { id: 7, name: "Electronic", routes: "category", icon: elec, childreen: [{ id: 8, name: "Dress", icon: elec, childreen: [] }, { id: 9, name: "T-Shert", icon: elec, childreen: [] }] }
    ],
    viewedList: [],
    ref: false

};

const categoriesReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_CATEGORIES_REDUCER": {
            return ({
                ...state,
                categories: action.payload
            })
        }
        case "ADD_LAYOUT": {

            return ({
                ...state,
                viewedList: [...action.layout],
                ref: !state.ref
            })
        }
        case "CLEAR_LAYOUT": {
            return ({
                ...state,
                viewedList: [],
                ref: !state.ref
            })
        }
        default:
            return { ...state };
    }

};

export default categoriesReducer;



