export default function Docum() {
    return (<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
        <g id="Mask_Group_188" data-name="Mask Group 188">
            <g id="different-currencies" transform="translate(0.206)">
                <g id="Layer_1_129_">
                    <g id="Group_7861" data-name="Group 7861">
                        <g id="Group_7856" data-name="Group 7856">
                            <path id="Path_19145" data-name="Path 19145" d="M6.578,5.078a4.544,4.544,0,0,1,.808-2.2A3.668,3.668,0,1,0,3.041,7.256,5.5,5.5,0,0,1,6.578,5.078ZM2.338,4.542a.233.233,0,0,1,.236-.237h.753V4.129l-.1-.176h-.65a.237.237,0,0,1,0-.473h.37l-.8-1.316a.531.531,0,0,1-.085-.249.471.471,0,0,1,.521-.443.463.463,0,0,1,.413.285L3.805,3.3l.812-1.547a.464.464,0,0,1,.412-.285.471.471,0,0,1,.522.443.532.532,0,0,1-.084.248l-.8,1.316h.37a.237.237,0,0,1,0,.473H4.387l-.1.176v.176h.752a.237.237,0,0,1,0,.473H4.284v.6a.448.448,0,0,1-.479.485.447.447,0,0,1-.479-.485v-.6H2.574A.232.232,0,0,1,2.338,4.542Z" transform="translate(-0.137)" fill="#8e8e8e" />
                        </g>
                        <g id="Group_7857" data-name="Group 7857" transform="translate(7.376 1.834)">
                            <path id="Path_19146" data-name="Path 19146" d="M8.656,1.216a3.672,3.672,0,0,0-3.63,3.166,5.47,5.47,0,0,1,1.982.387.256.256,0,0,1,.256-.152h.127a1.13,1.13,0,0,1-.182-.649,1.379,1.379,0,0,1,1.522-1.28c1.063,0,1.577.57,1.577,1.043a.41.41,0,0,1-.455.425c-.534,0-.206-.74-1.025-.74a.635.635,0,0,0-.7.606,1.33,1.33,0,0,0,.2.595h.661c.248,0,.376.091.376.291s-.127.291-.376.291h-.48a.385.385,0,0,1,.031.146.782.782,0,0,1-.062.29,5.53,5.53,0,0,1,.555.535,2.4,2.4,0,0,0,.5.073c.1,0,.352-.067.448-.067a.351.351,0,0,1,.352.388c0,.342-.306.491-.622.511a5.55,5.55,0,0,1,.512,1.12,3.665,3.665,0,0,0-1.568-6.98Z" transform="translate(-5.026 -1.216)" fill="#8e8e8e" />
                        </g>
                        <g id="Group_7858" data-name="Group 7858" transform="translate(7.58 10.939)">
                            <path id="Path_19147" data-name="Path 19147" d="M5.161,7.25v1.5c.454-.03.933-.242.933-.743C6.094,7.493,5.569,7.341,5.161,7.25Z" transform="translate(-5.161 -7.25)" fill="#8e8e8e" />
                        </g>
                        <g id="Group_7859" data-name="Group 7859" transform="translate(6.276 8.497)">
                            <path id="Path_19148" data-name="Path 19148" d="M4.3,6.277c0,.38.281.6.849.713V5.632C4.629,5.648,4.3,5.951,4.3,6.277Z" transform="translate(-4.296 -5.632)" fill="#8e8e8e" />
                        </g>
                        <g id="Group_7860" data-name="Group 7860" transform="translate(2.751 5.916)">
                            <path id="Path_19149" data-name="Path 19149" d="M6.546,3.921a4.586,4.586,0,1,0,4.587,4.586A4.592,4.592,0,0,0,6.546,3.921Zm.243,7.3v.47a.23.23,0,1,1-.456,0v-.47c-1.281-.03-1.918-.8-1.918-1.4a.436.436,0,0,1,.47-.477c.849,0,.189,1.046,1.448,1.1V8.86c-1.123-.2-1.8-.7-1.8-1.539a1.659,1.659,0,0,1,1.8-1.592v-.4a.23.23,0,1,1,.456,0v.4c.59.016,1.8.386,1.8,1.13a.455.455,0,0,1-.478.469c-.493,0-.486-.81-1.325-.826v1.44c1,.213,1.887.508,1.887,1.676C8.677,10.635,7.918,11.151,6.789,11.219Z" transform="translate(-1.96 -3.921)" fill="#8e8e8e" />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>

    )
}