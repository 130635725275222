import { all, fork, put, takeEvery } from "@redux-saga/core/effects";
import store from "../store";
import axios from "axios";
import { toast } from "react-toastify";
import { REACT_APP_ACCOUNTING_URL } from "../../urls";

function* getAllSetting(action) {
    let response;
    try {
        yield put({ type: "LOADING_TICKETS" })
        const token = store.getState()?.whatsapp?.userTokenWhatsapp;
        const base_user = REACT_APP_ACCOUNTING_URL;
        let AxiosInstace = axios.create({
            baseURL: `${base_user}`,
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
        });
        response = yield AxiosInstace.get(`/api/v1/ticket_routers`)
        yield put({ type: "GET_TICKET_SETTING_REDUCER", payload: response.data?.data })
        yield put({ type: "DONE_TICKETS" })
    } catch (e) {
        yield put({ type: "DONE_TICKETS" })
        if (e.message) {
            toast.error(e.message);
        } else toast.error("failed! please check conection");
    }
}

function* watchGetAllSetting() {
    yield takeEvery("GET_TICKET_SETTING_SAGA", getAllSetting);
}
function* getCreateData(action) {
    let response;
    try {
        yield put({ type: "LOADING_TICKETS" })
        const token = store.getState()?.whatsapp?.userTokenWhatsapp;
        const base_user = REACT_APP_ACCOUNTING_URL;
        let AxiosInstace = axios.create({
            baseURL: `${base_user}`,
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
        });
        response = yield AxiosInstace.get(`/api/v1/ticket_routers/create`)
        yield put({ type: "GET_SETTINGS_CREATE_DATA_REDUCER", payload: response.data?.data })
        yield put({ type: "DONE_TICKETS" })
    } catch (e) {
        yield put({ type: "DONE_TICKETS" })
        if (e.message) {
            toast.error(e.message);
        } else toast.error("failed! please check conection");
    }
}

function* watchGetCreateData() {
    yield takeEvery("GET_TICKET_SETTING_CREATE_DATA_SAGA", getCreateData);
}
function* createnew(action) {
    try {
        yield put({ type: "LOADING_TICKETS" })
        const token = store.getState()?.whatsapp?.userTokenWhatsapp;
        const base_user = REACT_APP_ACCOUNTING_URL;
        let AxiosInstace = axios.create({
            baseURL: `${base_user}`,
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
        });
        let res = yield AxiosInstace.post(`/api/v1/ticket_routers`, JSON.stringify(action.payload))
        yield put({ type: "add_ticket_setting_new_red", payload: res.data.data })
        yield put({ type: "GET_TICKET_SETTING_CREATE_DATA_SAGA" })
        yield put({ type: "DONE_TICKETS" })
    } catch (e) {
        yield put({ type: "DONE_TICKETS" })
        if (e.message) {
            toast.error(e.message);
        } else toast.error("failed! please check conection");
    }
}

function* watchCreateNew() {
    yield takeEvery("add_ticket_setting_new", createnew);
}
function* edit(action) {
    try {
        yield put({ type: "LOADING_TICKETS" })
        const token = store.getState()?.whatsapp?.userTokenWhatsapp;
        const base_user = REACT_APP_ACCOUNTING_URL;
        let AxiosInstace = axios.create({
            baseURL: `${base_user}`,
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
        });
        let res = yield AxiosInstace.put(`/api/v1/ticket_routers/${action.payload.id}`, JSON.stringify(action.payload))
        yield put({ type: "edit_ticket_setting_new_red", payload: res.data.data })
        yield put({ type: "GET_TICKET_SETTING_CREATE_DATA_SAGA" })
        yield put({ type: "DONE_TICKETS" })
    } catch (e) {
        yield put({ type: "DONE_TICKETS" })
        if (e.message) {
            toast.error(e.message);
        } else toast.error("failed! please check conection");
    }
}

function* watchEdit() {
    yield takeEvery("edit_ticket_setting_new", edit);
}

function* deleteSetting(action) {
    try {
        yield put({ type: "LOADING_TICKETS" })
        const token = store.getState()?.whatsapp?.userTokenWhatsapp;
        const base_user = REACT_APP_ACCOUNTING_URL;
        let AxiosInstace = axios.create({
            baseURL: `${base_user}`,
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
        });
        yield AxiosInstace.delete(`/api/v1/ticket_routers/${action.payload.id}`)
        yield put({ type: "delete_ticket_setting_new_red", payload: action.payload.id })
        yield put({ type: "GET_TICKET_SETTING_CREATE_DATA_SAGA" })
        yield put({ type: "DONE_TICKETS" })
    } catch (e) {
        yield put({ type: "DONE_TICKETS" })
        if (e.message) {
            toast.error(e.message);
        } else toast.error("failed! please check conection");
    }
}

function* watchDeleteSetting() {
    yield takeEvery("delete_ticket_setting_new", deleteSetting);
}
//delete_ticket_setting_new
export default function* TicketSettingSaga() {
    yield all([
        fork(watchGetAllSetting),
        fork(watchGetCreateData),
        fork(watchCreateNew),
        fork(watchEdit),
        fork(watchDeleteSetting)
    ]);
}
