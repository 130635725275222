import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CountriesModal from './CountriesModal'
import "../countries.css"
import CountryItem from './CountryItem'
function CompaniesList(props) {
  const dispatch = useDispatch()
  const ActiveAdd = (e) => {
    dispatch({ type: "Active-add", payload: e })
  }
  const { lang_id, setLang_id } = props
  const countries = useSelector(state => state.companies.countries)
  const { makeFollower } = props
  const data = useSelector(state => state.companies.newDepartment)
  const Active_Ids = useSelector(state => state.companies.Active_Ids)

  return (
    <>

      {lang_id === 0 || lang_id === null && props.open && <CountriesModal Active_Ids={Active_Ids} ActiveAdd={() => props.ActiveAdd(data.id)} languageFollower={props.languageFollower} setExpand={(e) => { }} data={data} {...props} />}
      <div className='lang-body countries'>
        {
          countries.map((dept) => (
            <CountryItem
              lang={props.lang}
              ActiveAdd={(e) => ActiveAdd(e)}
              open={props.open}
              Close={() => props.Close()}
              Add={(e) => props.Add(e)}
              Update={(e) => props.Update(e)}
              Read={(e) => props.Read(e)}
              setModalData={(e) => props.setModalData(e)}
              setOpen={(e) => props.setOpen(e)}
              child={dept}
              Active_Ids={Active_Ids}
              languageFollower={props.languageFollower}
              level={1}
              makeFollower={(level, value, id) => makeFollower(level, value, id)}
              lang_id={lang_id}
              setLang_id={(e) => setLang_id(e)}
            />
          ))
        }

      </div>
    </>
  )
}

export default CompaniesList