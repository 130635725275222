import Rep from "./svgs/rep"
import Copy from "./svgs/copy"
import Categ from "./svgs/categ"
import Delt from "./svgs/delt"
import Edit from "./svgs/edit"
import Forward from "./svgs/forward"
import Remind from "./svgs/remind"
import { useSelector } from 'react-redux'
import Restore from './svgs/restore'
function OptionsMenu(props) {
    console.log(props)
    const roles = useSelector(state => state.auth.roles)
    return (
        <div className="abs-menu">
            {((props.ack !== "fail") && !props.deleted && !props.message_id?.toString()?.includes("true")) ? <div className='reply-but' onClick={() => props.click()}>
                <Rep />
                <div className='rep-descs' style={{ bottom: "-34px" }}>Reply</div>
            </div> : <></>}
            <div className='message-ops'>
                {((props.ack !== "fail") && !props.deleted && !props.message_id?.toString()?.includes("true")) ? <div className='message-opt' onClick={() => props.forward()}>
                    <Forward />
                    <div className='rep-descs'>Forward</div>
                </div> : <></>}
                <div className='message-opt' onClick={() => props.copy()}>
                    <Copy />
                    <div className='rep-descs' >Copy</div>
                </div>
                <div className='message-opt'>
                    <Categ />
                    <div className='rep-descs' >Category</div>
                </div>
                {roles.includes("USER_FULL_ACCESS") ? <div className='message-opt' onClick={() => {
                    props.delete()
                }}>
                    {props.deleted ? <Restore /> : <Delt />}
                    <div className='rep-descs' >{props.deleted ? "Restore" : "Delete"}</div>
                </div> : <></>}
                <div className='message-opt'>
                    <Edit />
                    <div className='rep-descs' >Edit</div>
                </div>
                <div className='message-opt'>
                    <Remind />
                    <div className='rep-descs' >Re-Remind</div>
                </div>
            </div>
        </div>
    )
}

export default OptionsMenu