import React from 'react'
import { trans } from "../../../../../Internationalization"
function Employee(props) {
  return (
    <div style={{ marginRight: "20px" }} className='icon-lang' onClick={() => props.change()}>
      <div className='icon-lang-desc'>
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
          <defs>
            <filter id="Ellipse_319" x="0" y="0" width="24" height="24" filterUnits="userSpaceOnUse">
              <feOffset dy="3" input="SourceAlpha" />
              <feGaussianBlur stdDeviation="3" result="blur" />
              <feFlood floodOpacity="0.2" />
              <feComposite operator="in" in2="blur" />
              <feComposite in="SourceGraphic" />
            </filter>
          </defs>
          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Ellipse_319)">
            <circle id="Ellipse_319-2" data-name="Ellipse 319" cx="3" cy="3" r="3" transform="translate(9 6)" fill="#8E8E8E" />
          </g>
        </svg>
        <div className='icon-lang-desc-bu hst'> {trans("emps")}</div>
      </div>

      <svg className={`${props.active && "activated-hrm"}`} id="_15x15_photo_back" data-name="15x15 photo back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
        <defs>

        </defs>
        <g id="Mask_Group_120" data-name="Mask Group 120" >
          <g id="Mask_Group_112" data-name="Mask Group 112" >
            <path id="teamwork" d="M1.926,9.48A1.071,1.071,0,1,1,.855,10.552,1.071,1.071,0,0,1,1.926,9.48Zm2.216,2.565-.353-.353,1.281-1.28.353.353Zm6.716,0,.353-.353L9.93,10.412l-.353.353ZM9.715,7.3l-.353-.353,1.28-1.281L11,6.024Zm-4.431,0,.353-.353L4.358,5.671,4,6.024ZM7.5,4.74A1.071,1.071,0,1,1,6.429,5.811,1.071,1.071,0,0,1,7.5,4.74Zm1.926,5.52V7.98a.675.675,0,0,0-.675-.675H8.47l-.035.02a1.946,1.946,0,0,1-1.868,0l-.035-.02H6.25a.675.675,0,0,0-.675.675v2.28ZM1.926,0A1.071,1.071,0,1,1,.855,1.071,1.071,1.071,0,0,1,1.926,0ZM3.853,5.52H0V3.24a.675.675,0,0,1,.675-.675H.956l.035.02a1.946,1.946,0,0,0,1.868,0l.035-.02h.281a.675.675,0,0,1,.675.675V5.52ZM13.074,0A1.071,1.071,0,1,1,12,1.071,1.071,1.071,0,0,1,13.074,0ZM15,5.52H11.147V3.24a.675.675,0,0,1,.675-.675H12.1l.035.02a1.946,1.946,0,0,0,1.868,0l.035-.02h.283A.675.675,0,0,1,15,3.24ZM13.074,9.48A1.071,1.071,0,1,1,12,10.552,1.071,1.071,0,0,1,13.074,9.48ZM15,15H11.147V12.72a.675.675,0,0,1,.675-.675H12.1l.035.019a1.946,1.946,0,0,0,1.868,0l.035-.019h.283A.675.675,0,0,1,15,12.72ZM3.853,15V12.72a.675.675,0,0,0-.675-.675H2.9l-.035.019a1.946,1.946,0,0,1-1.868,0l-.035-.019H.675A.675.675,0,0,0,0,12.72V15Z" fill="#8E8E8E" fillRule="evenodd" />
          </g>
        </g>
      </svg>




    </div>
  )
}

export default Employee