import React from 'react'

function Fire(props) {
  return (
    <>
    <svg className={props.active&&"acv"} id="_15x15_photo_back" data-name="15x15 photo back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
  <g id="Mask_Group_145" data-name="Mask Group 145">
    <g id="hot-deal" transform="translate(-1.389 -1.389)">
      <g id="Group_7711" data-name="Group 7711">
        <path id="Path_19001" data-name="Path 19001" d="M7.672,16.389a.277.277,0,0,1-.227-.117,3.675,3.675,0,0,1-.357-3.612.278.278,0,1,1,.507.227,3.117,3.117,0,0,0,.3,3.064.278.278,0,0,1-.226.439Z" fill="#8e8e8e"/>
      </g>
      <g id="Group_7712" data-name="Group 7712">
        <path id="Path_19002" data-name="Path 19002" d="M9.96,16.389a.278.278,0,0,1-.233-.428,5.173,5.173,0,0,0-.962-6.684.278.278,0,0,1,.363-.421,5.732,5.732,0,0,1,1.066,7.4A.276.276,0,0,1,9.96,16.389Z" fill="#8e8e8e"/>
      </g>
      <g id="Group_7713" data-name="Group 7713">
        <path id="Path_19003" data-name="Path 19003" d="M7.341,13.051a.269.269,0,0,1-.113-.024.278.278,0,0,1-.14-.367,5.06,5.06,0,0,0,.443-2.078.278.278,0,0,1,.556,0,5.616,5.616,0,0,1-.492,2.3.278.278,0,0,1-.254.164Z" fill="#8e8e8e"/>
      </g>
      <g id="Group_7714" data-name="Group 7714">
        <path id="Path_19004" data-name="Path 19004" d="M8.416,13.013H8.387a.279.279,0,0,1-.248-.3,2.755,2.755,0,0,0-.552-1.957.278.278,0,0,1,.442-.337,3.313,3.313,0,0,1,.662,2.35.277.277,0,0,1-.276.25Z" fill="#8e8e8e"/>
      </g>
      <g id="Group_7715" data-name="Group 7715">
        <path id="Path_19005" data-name="Path 19005" d="M8.415,13.013a.274.274,0,0,1-.171-.059.277.277,0,0,1-.047-.39A3.445,3.445,0,0,0,8.688,9.17.278.278,0,0,1,9.2,8.965a4,4,0,0,1-.57,3.943.278.278,0,0,1-.219.106Z" fill="#8e8e8e"/>
      </g>
      <g id="Group_7716" data-name="Group 7716">
        <path id="Path_19006" data-name="Path 19006" d="M11.46,6.979a.278.278,0,0,1-.219-.448A2.148,2.148,0,0,0,11.5,4.32a.278.278,0,0,1,.506-.229,2.7,2.7,0,0,1-.327,2.782.278.278,0,0,1-.22.107Z" fill="#8e8e8e"/>
      </g>
      <g id="Group_7717" data-name="Group 7717">
        <path id="Path_19007" data-name="Path 19007" d="M11.46,6.979a.277.277,0,0,1-.273-.229A6.917,6.917,0,0,0,7.754,1.909a.278.278,0,0,1,.272-.484,7.47,7.47,0,0,1,3.709,5.229.277.277,0,0,1-.225.322.244.244,0,0,1-.049,0Z" fill="#8e8e8e"/>
      </g>
      <g id="Group_7718" data-name="Group 7718">
        <path id="Path_19008" data-name="Path 19008" d="M5.995,7.6a.278.278,0,0,1-.272-.223,3.7,3.7,0,0,0-.758-1.6A.278.278,0,1,1,5.4,5.418a4.267,4.267,0,0,1,.872,1.845.278.278,0,0,1-.217.327.315.315,0,0,1-.055.005Z" fill="#8e8e8e"/>
      </g>
      <g id="Group_7719" data-name="Group 7719">
        <path id="Path_19009" data-name="Path 19009" d="M5.995,7.6a.278.278,0,0,1-.222-.445A9.2,9.2,0,0,0,7.612,1.667a.277.277,0,0,1,.278-.277h0a.278.278,0,0,1,.277.278,9.759,9.759,0,0,1-1.95,5.817A.279.279,0,0,1,5.995,7.6Z" fill="#8e8e8e"/>
      </g>
      <g id="Group_7720" data-name="Group 7720">
        <path id="Path_19010" data-name="Path 19010" d="M14.15,10.67h-.012a.278.278,0,0,1-.266-.289A8.048,8.048,0,0,0,11.557,4.4a.278.278,0,0,1,.395-.391A8.607,8.607,0,0,1,14.427,10.4.277.277,0,0,1,14.15,10.67Z" fill="#8e8e8e"/>
      </g>
      <g id="Group_7721" data-name="Group 7721">
        <path id="Path_19011" data-name="Path 19011" d="M9.96,16.389a.278.278,0,0,1-.094-.539,6.066,6.066,0,0,0,4.007-5.469.274.274,0,0,1,.289-.266.277.277,0,0,1,.266.289,6.62,6.62,0,0,1-4.373,5.968.284.284,0,0,1-.095.017Z" fill="#8e8e8e"/>
      </g>
      <g id="Group_7722" data-name="Group 7722">
        <path id="Path_19012" data-name="Path 19012" d="M7.672,16.389a.282.282,0,0,1-.062-.007A5.489,5.489,0,0,1,4.022,8.374a.278.278,0,0,1,.486.269,4.932,4.932,0,0,0,3.224,7.2.278.278,0,0,1-.061.549Z" fill="#8e8e8e"/>
      </g>
      <g id="Group_7723" data-name="Group 7723">
        <path id="Path_19013" data-name="Path 19013" d="M4.265,8.786a.278.278,0,0,1-.243-.412A7.326,7.326,0,0,0,4.9,5.565a.278.278,0,1,1,.553.057,7.879,7.879,0,0,1-.948,3.02.279.279,0,0,1-.244.144Z" fill="#8e8e8e"/>
      </g>
    </g>
  </g>
</svg>

    </>
  )
}

export default Fire