import React, { memo } from "react";
import Icon from "../../../components/Icon";
import ProfileNo from "../../../assets/images/profileNo.png";
import OptionsBtn from "../../../components/OptionsButton";
import { useSelector } from "react-redux";
import TicketIcon from "./TicketIcon";
import TopSpinner from "./topSpinner";

const Header = ({ user, openProfileSidebar, openSearchSidebar, close, id, openStaticsSidebar, is_hash_check_enabled }) => {
  const chats = useSelector((state) => state.whatsapp.chats);
  const contacts = useSelector((state) => state.whatsapp.contacts);
  const roles = useSelector((state) => state.auth.roles);
  const is_fetch = useSelector((state) => state.whatsapp.is_fetch);

  const getNewMessage = () => {

    return chats.filter((ch) => ch?.unread > 0).length;
  };
  return (
    <header className="header chat__header cht-hed">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
        className="back_icon"
        onClick={() => close()}
      >
        <path
          fill="#aeb4b7"
          d="M12 4l1.4 1.4L7.8 11H20v2H7.8l5.6 5.6L12 20l-8-8 8-8z"
        ></path>
      </svg>
      {getNewMessage() > 0 && <span className="newed">{getNewMessage()}</span>}
      <div className="chat__avatar-wrapper" onClick={openProfileSidebar}>
        <img
          src={user?.profile_photo || ProfileNo}
          alt={user?.name}
          className="avatar"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = ProfileNo;
          }}
        />
      </div>

      <div className="chat__contact-wrapper" onClick={openProfileSidebar}>
        <h2 className="chat__contact-name">
          {" "}

          {contacts.filter((s) => s.id === user?.id)[0]?.id ?
            (contacts.filter((s) => s.id === user?.id)[0]?.name?.length > 0 ?
              contacts.filter((s) => s.id === user?.id)[0]?.name : ((contacts.filter((s) => s.id === user?.id)[0]?.pushname?.length > 0
                && contacts.filter((s) => s.id === user?.id)[0]?.pushname)
                || "+" + contacts.filter((s) => s.id === user?.id)[0]?.number) || ("+" + id))
            : (user?.name || user?.number || ("+" + id))
          }
        </h2>
        <div className="chat__contact-desc">
          {user?.typing ? "typing..." : "online"}
        </div>
      </div>
      <div className="chat__actions">
        {(is_fetch) && <TopSpinner no={true} />}
        {((roles?.includes("USER_FULL_ACCESS") || roles?.includes("VIEW_HASH_CHECK_STATISTICS")) && is_hash_check_enabled) ? <button
          className="chat__action"
          aria-label="Search"
          onClick={openStaticsSidebar}
        >
          <TicketIcon />
        </button> : <></>}
        <button
          className="chat__action"
          aria-label="Search"
          onClick={openSearchSidebar}
        >
          <Icon
            id="search"
            className="chat__action-icon chat__action-icon--search"
          />
        </button>
        <OptionsBtn
          className="chat__action"
          ariaLabel="Menu"
          iconId="menu"
          iconClassName="chat__action-icon"
          options={[
            "Contact Info",
            "Select Messages",
            "Mute notifications",
            "Clear messages",
            "Delete chat",
          ]}
        />
      </div>
    </header>
  );
};

export default memo(Header);
