export default function User() {
  return (<svg id="avatar" xmlns="http://www.w3.org/2000/svg" width="7.895" height="10" viewBox="0 0 7.895 10">
    <g id="Group_7853" data-name="Group 7853" transform="translate(1.711)">
      <g id="Group_7852" data-name="Group 7852">
        <circle id="Ellipse_329" data-name="Ellipse 329" cx="2.237" cy="2.237" r="2.237" fill="#cecdcd" />
      </g>
    </g>
    <g id="Group_7855" data-name="Group 7855" transform="translate(0 5)">
      <g id="Group_7854" data-name="Group 7854">
        <path id="Path_19144" data-name="Path 19144" d="M5,5A3.947,3.947,0,0,0,1.053,8.947,1.053,1.053,0,0,0,2.105,10H7.895A1.053,1.053,0,0,0,8.947,8.947,3.947,3.947,0,0,0,5,5Z" transform="translate(-1.053 -5)" fill="#cecdcd" />
      </g>
    </g>
  </svg>)
}

