import axios from "axios";
import { store } from "../../store";
import { REACT_APP_ACCOUNTING_URL } from "../../../urls";
import { toast } from "react-toastify";

export const asyncSearch = async (search) => {
  const token = store.getState()?.whatsapp?.userTokenWhatsapp;
  const base_user = REACT_APP_ACCOUNTING_URL;
  let AxiosInstace = axios.create({
    baseURL: `${base_user}`,
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
  store.dispatch({ type: "SEARCH_PROGRESS", payload: true })
  await AxiosInstace.get(
    `/api/v1/whatsapp/get_chats_with_latest_messages?search=${search}&chats_limit=15`,
  ).then((res) => {
    if (search === store.getState().whatsapp?.searchInput || search === store.getState().whatsapp?.wordsearch) {
      store.dispatch({
        type: "SEARCH_WA", payload: {
          search: search,
          data: res.data.data
        }
      });
      store.dispatch({ type: "SEARCH_PROGRESS", payload: false })
    }
  })

}

export const asyncSearchByContact = async (search) => {
  const token = store.getState()?.whatsapp?.userTokenWhatsapp;
  const base_user = REACT_APP_ACCOUNTING_URL;
  let AxiosInstace = axios.create({
    baseURL: `${base_user}`,
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
  store.dispatch({ type: "SEARCH_PROGRESS_CONTACT", payload: true })
  await AxiosInstace.get(
    `/api/v1/whatsapp/get_chats_with_latest_messages?contact_search=${search}&chats_limit=15&limit=20`,
  ).then((res) => {
    if (search === store.getState().whatsapp?.searchInputContact) {
      store.dispatch({
        type: "SEARCH_WA_CONTACT", payload: {
          search: search,
          data: res.data.data
        }
      });
      store.dispatch({ type: "SEARCH_PROGRESS_CONTACT", payload: false })
    }
  })
}
export const asyncSearchByContactForward = async (search) => {
  const token = store.getState()?.whatsapp?.userTokenWhatsapp;
  const base_user = REACT_APP_ACCOUNTING_URL;
  let AxiosInstace = axios.create({
    baseURL: `${base_user}`,
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
  let chats = []
  await AxiosInstace.get(
    `/api/v1/whatsapp/get_chats_with_latest_messages?contact_search=${search}&chats_limit=20&limit=20`,
  ).then((res) => {
    chats = res.data.data
  }).catch((e) => toast.error("error finding contacts"))
  return chats
}