import { all, fork, put, takeEvery } from "@redux-saga/core/effects";
import AxiosInstance from "../../../helpers/axiosInstance";
import { toast } from "react-toastify";
import { ADD_DEPT_URL, GET_DEPT_URL, UPD_DEPT_URL } from "./constants";
import store from "../../store";
import { GET_LANGS_URL } from "../../LinkedLanguages/constants";
import axios from "axios";
import { REACT_APP_CATEGORIES_SERVICE_URL } from "../../../urls";
function* getAllDepartments(action) {
  let response;
  try {
    yield put({ type: "GET-CUR" })
    yield put({ type: "GENERAL-LOADING-REG" });

    response = yield AxiosInstance.get(REACT_APP_CATEGORIES_SERVICE_URL +
      GET_DEPT_URL
    );

    let langs = yield AxiosInstance.get(GET_LANGS_URL);
    yield put({ type: "GET-LANG-REDUCER", payload: langs.data.data });
    yield put({ type: "GENERAL-DONE" });
    yield put({ type: "GET-REG-REDUCER", payload: response.data.data });
    yield put({ type: "GENERAL-DONE-REG" });
  } catch (e) {
    yield put({ type: "GENERAL-DONE-REG" });

    if (e.message) {
      toast.error(e.message);
    } else toast.error("failed! please check conection");
  }
}

function* watchGetAllDepartments() {
  yield takeEvery("GET-REG", getAllDepartments);
}
function* AddDepartments(action) {
  let responses;
  try {
    yield put({ type: "GENERAL-DONE-REG" });
    yield put({ type: "request-running-REG", payload: action.payload });
    yield AxiosInstance.post(REACT_APP_CATEGORIES_SERVICE_URL +
      ADD_DEPT_URL,
      JSON.stringify(action.payload)
    )
    yield put({ type: "request-running-REG-red", payload: action.payload })
    setTimeout(() => {
      store.dispatch({ type: "request-running-REG-red", payload: null })
    }, 4000)
    yield put({ type: "GENERAL-DONE-REG" });
    responses = yield AxiosInstance.get(REACT_APP_CATEGORIES_SERVICE_URL +
      GET_DEPT_URL
    )
    yield put({ type: "GET-REG-REDUCER", payload: responses.data.data })
  } catch (e) {
    yield put({
      type: "GENERAL-ERR-REG",
      payload: { data: action.payload, message: e.message },
    });
    if (e.message) {
      toast.error(e.message);
    } else toast.error("failed! please check conection");
  }
}

function* watchAddDepartments() {
  yield takeEvery("ADD-REG", AddDepartments);
}
function* UpdateDepartments(action) {
  let responses;
  try {
    yield put({ type: "GENERAL-DONE-REG" });
    yield put({ type: "request-running-REG", payload: action.payload });
    yield AxiosInstance.post(REACT_APP_CATEGORIES_SERVICE_URL +
      UPD_DEPT_URL,
      JSON.stringify(action.payload)
    );
    yield put({ type: "request-running-REG-red", payload: action.payload });
    setTimeout(() => {
      store.dispatch({ type: "request-running-REG-red", payload: null });
    }, 4000);
    yield put({ type: "GENERAL-DONE-REG" });
    responses = yield AxiosInstance.get(REACT_APP_CATEGORIES_SERVICE_URL +
      GET_DEPT_URL
    );

    yield put({ type: "GET-REG-REDUCER", payload: responses.data.data });
    yield put({ type: "GENERAL-DONE-REG" });
  } catch (e) {
    yield put({
      type: "GENERAL-ERR-REG",
      payload: { data: action.payload, message: e.message },
    });
    if (e.message) {
      toast.error(e.message);
    } else toast.error("failed! please check conection");
  }
}

function* watchUpdDepartments() {
  yield takeEvery("UPD-REG", UpdateDepartments);
}
function* getCords(action) {
  try {
    const loc = yield AxiosInstance.post(
      "/api/v2/website/expand_url_link",
      JSON.stringify({ short_url: action.payload })
    );

    yield axios.get(
      "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode?f=pjson&langCode=en&location=" +
      loc.data.data.expanded_url
        .split("@")[1]
        .split("/data")[0]
        .split(",")[1] +
      "," +
      loc.data.data.expanded_url.split("@")[1].split("/data")[0].split(",")[0]
    );
    store.dispatch({
      type: "CORDES",
      payload: {
        lat: loc.data.data.expanded_url
          .split("@")[1]
          .split("/data")[0]
          .split(",")[0],
        lng: loc.data.data.expanded_url
          .split("@")[1]
          .split("/data")[0]
          .split(",")[1],
      },
    });
  } catch (e) { }
}
function* watchGetCords() {
  yield takeEvery("CORDS-SAGA", getCords);
}
export function* CountriesSaga() {
  yield all([
    fork(watchGetAllDepartments),
    fork(watchUpdDepartments),
    fork(watchAddDepartments),
    fork(watchGetCords),
  ]);
}
