import React, { useEffect, useState } from 'react'
import generatePdfThumbnails from 'pdf-thumbnails-generator';
import X from "./xicon.svg"
function PDFElem(props) {
  const [imgs, setimgs] = useState(null)
  async function generateThumbnails(d) {
    try {
      const thumbnails = await generatePdfThumbnails(d, 50);
      setimgs(thumbnails[0].thumbnail)
    } catch (err) {
      console.error(err);
    }
  }
  useEffect(() => {
    generateThumbnails(props.path)
  }, [])
  return (
    <div className='pdf-loading'>
      <span onClick={() => props.clear()} className="abs-delt"><svg data-src={X}></svg></span>
      {imgs && <a target={'_blank'} href={props.path} ><img src={imgs} /></a>}
      <svg id="bord" xmlns="http://www.w3.org/2000/svg" width="50" height="70" viewBox="0 0 50 70">
        <g id="Rectangle_4658" data-name="Rectangle 4658" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" stroke-dasharray="2 3">

          <rect x="0.25" y="0.25" width="49.5" height="69.5" rx="9.75" fill="none" />
        </g>
      </svg>
    </div>


  )
}

export default PDFElem