import React from 'react'
import "./ActiveIcon.css"
function ActiveIcon(props) {
  return (
    <svg onClick={()=>props.setActive()} className={`sys_lang ${props.active&&"active_sys"}`} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
  <g id="_15x15_photo_back" data-name="15x15 photo back">
    <g id="radio">
      <g id="Group_4765" data-name="Group 4765">
        <g id="Group_4764" data-name="Group 4764">
          <path id="Path_16083" data-name="Path 16083" d="M3.379,11.92a6.631,6.631,0,0,1,0-10.375A.439.439,0,0,0,2.832.858a7.51,7.51,0,0,0,0,11.75.439.439,0,0,0,.548-.687Z" fill="#aab4c5"/>
        </g>
      </g>
      <g id="Group_4767" data-name="Group 4767">
        <g id="Group_4766" data-name="Group 4766">
          <path id="Path_16084" data-name="Path 16084" d="M12.168.858a.439.439,0,0,0-.548.687,6.631,6.631,0,0,1,0,10.375.439.439,0,0,0,.548.687,7.51,7.51,0,0,0,0-11.75Z" fill="#aab4c5"/>
        </g>
      </g>
      <g id="Group_4769" data-name="Group 4769">
        <g id="Group_4768" data-name="Group 4768">
          <path id="Path_16085" data-name="Path 16085" d="M4.844,10.086a4.279,4.279,0,0,1,0-6.707A.439.439,0,0,0,4.3,2.691a5.158,5.158,0,0,0,0,8.082.439.439,0,0,0,.547-.688Z" fill="#aab4c5"/>
        </g>
      </g>
      <g id="Group_4771" data-name="Group 4771">
        <g id="Group_4770" data-name="Group 4770">
          <path id="Path_16086" data-name="Path 16086" d="M10.7,2.691a.439.439,0,0,0-.547.688,4.279,4.279,0,0,1,0,6.707.439.439,0,0,0,.547.688,5.158,5.158,0,0,0,0-8.082Z" fill="#aab4c5"/>
        </g>
      </g>
      <g id="Group_4773" data-name="Group 4773">
        <g id="Group_4772" data-name="Group 4772">
          <path id="Path_16087" data-name="Path 16087" d="M7.5,4.541a2.2,2.2,0,0,0-.439,4.35V13.8a.439.439,0,0,0,.879,0V8.891A2.2,2.2,0,0,0,7.5,4.541Z" fill="#aab4c5"/>
        </g>
      </g>
    </g>
  </g>
</svg>

  )
}

export default ActiveIcon