import { useEffect } from "react";
import Check from "./check"
import X from "./xicon.svg"
import date from "date-and-time"
import store from "../../../../../../redux/store";
import axios from "axios";
import { useState } from "react";
import Spinner from "./Spinner";
import { REACT_APP_ACCOUNTING_URL } from "../../../../../../urls";

export default function TronScan(props) {
    const getDiff = (dat) => {
        let no = new Date();
        let d = new Date(dat);
        let hrs = parseInt((no.getTime() - d.getTime()) / (1000 * 60 * 60)).toFixed(0)
        let min = parseInt(((no.getTime() - d.getTime()) / (1000 * 60) - hrs * 60)).toFixed(0)
        return (`${hrs} Hrs ${min} Mins Ago`)
    }
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true)
    const [top, setTop] = useState(`${document.getElementById(`s${props.ids}`)?.getBoundingClientRect().top}px`)
    const [left, setLeft] = useState(`${document.getElementById(`s${props.ids}`)?.getBoundingClientRect().left - 425}px`)
    const getTronScan = async (id) => {
        const token = store.getState()?.whatsapp?.userTokenWhatsapp;
        const base_user = REACT_APP_ACCOUNTING_URL;
        let AxiosInstace = axios.create({
            baseURL: `${base_user}`,
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
        })
        let response = await AxiosInstace.get(`/api/v1/hash_transactions/${id}`)
        setData(response.data.data)
        setLoading(false)
    }
    useEffect(() => {
        setLoading(true)
        getTronScan(props.hash_transaction_id)
    }, [props.hash_transaction_id])
    const setTopEle = () => {
        setTop(document.getElementById(`s${props.ids}`)?.getBoundingClientRect().top)
    }
    useEffect(() => {
        document.getElementById(`XD-1`).addEventListener("scroll", setTopEle)
        // return document.getElementById(`XD-1`).removeEventListener("scroll", setTopEle)
    }, [])

    return (<div className="hash_scan_detail_container" style={{
        zIndex: 9999999999,
        left: left,
        top: document.getElementById(`s${props.ids}`)?.getBoundingClientRect().top
    }}> <img
            style={{
                position: "absolute",
                right: "10px",
                top: "10px"
            }}
            onClick={() => {
                props.setOpenPreview(false)
                props.setOpenPreview2(false)
            }} alt="close" src={X} />
        {loading ? <div className="hash_scan_loading_pos"><Spinner /></div> : <>
            <div className="hash_scan_title"><Check /> Confirmed</div>
            <div className="hash_scan_details">
                <div className="hash_scan_details_title">
                    {data?.confirmed !== null ? <div className="hash_scan_details_element">Result:</div> : <></>}
                    <div className="hash_scan_details_element">Status:</div>
                    <div className="hash_scan_details_element">Block: </div>
                    <div className="hash_scan_details_element">From:</div>
                    <div className="hash_scan_details_element">To:</div>
                    <div className="hash_scan_details_element">Time:</div>
                    <div className="hash_scan_details_element">Token Transfer:</div>
                    <div className="hash_scan_details_element">Visit Link:</div>
                </div>
                <div className="hash_scan_details_data">
                    {data?.confirmed !== null ? <div className="hash_scan_details_element_green">{data?.confirmed ? "SUCCESS" : "FAILED"}</div> : <></>}
                    <div className={data.status ? "hash_scan_details_element_green" : "hash_scan_details_element_red"}>{data.status ? 'Confirmed' : 'Rejected'}</div>
                    <div className="hash_scan_details_element">{data?.block} </div>
                    <div className="hash_scan_details_element">{data?.from_address}</div>
                    <div className="hash_scan_details_element">{data?.to_address}</div>
                    <div className="hash_scan_details_element">{date.format(new Date(data?.created_at), "YYYY-MM-DD HH:mm:ss")} (Local) {getDiff(data?.created_at)}</div>
                    <div className="hash_scan_details_element">{data?.amount + " " + data?.symbol}</div>
                    <div className="hash_scan_details_element">
                        <a href={data?.preview_url} target="_blank" rel="noopener noreferrer">
                            {data?.preview_url}
                        </a>
                    </div>
                </div>
            </div>
        </>
        }
        <span className="chat__msg-footer" style={{ color: "#000000" }}>
            {props.getMessageTime()}
        </span></div>)
}