import React from 'react'

function FollowerIcon() { 
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="4.354" height="10.998" viewBox="0 0 4.354 10.998">
    <path id="Path_16038" data-name="Path 16038" d="M0,0,3.069,4.728,0,9.617" transform="translate(0.692 0.692)" fill="none" stroke="#404040" strokeLinecap="round" strokeWidth="1"/>
  </svg>
  
  )
}

export default FollowerIcon