import { useCallback, useEffect, useRef, useState } from "react";
import "./styles/main.css";
import EmojiTray from "./components/EmojiTray";
import ChatInput from "./components/ChatInput";
import Header from "./components/Header";
import ChatSidebar from "./components/ChatSidebar";
import Icon from "../../components/Icon";
import Search from "./components/Search";
import Profile from "./components/Profile";
import Convo from "./components/Convo";
import Cam from "../../assets/images/cam";
import out from "../../assets/images/output.png";
import Voc from "../../assets/images/voc";
import Vide from "../../assets/images/vid";
import Recorder from "../../components/recorder";
import { useDispatch, useSelector } from "react-redux";
import { upload } from "../../../../../uploadServiceChat";
import {
  FILE_SERVER,
} from "../../../../../constants";
import ForwardList from "../../components/Sidebar/ForwardList";
import ContactsList from "../../components/Sidebar/ContactsList";
import { asyncSearch } from "../../../../../redux/whatsapp/actions";
// import Statics from "./components/statics";
import { toast } from "react-toastify";

const Chat = ({ openContacts, setOpen, viewDisplay, id, user, setShowStaticsSidebar }) => {
  const search = useSelector((state) => state.whatsapp.wordsearch)
  let forwarderMessage = useSelector(state => state?.whatsapp?.forwarderMessage) || null;
  let vcard = useSelector(state => state?.whatsapp?.vcard) || null;
  let contacts = useSelector(state => state?.whatsapp?.contacts) || null;
  let searchMode = useSelector(state => state.whatsapp?.searchMode)
  let afterDone = useSelector(state => state.whatsapp?.afterDone)
  let reachedEnd = useSelector(state => state?.whatsapp?.reachedEnd)
  let [scrollVal, setScrollVal] = useState(0)
  let founds = useSelector(state => state?.whatsapp?.founds) || []
  let timeout;
  var mode = useSelector((state) => state.whatsapp.mode)

  const getChatsByMode = (chats) => {
    return chats.filter(chat => chat.instance_name === mode)
  }
  const roles = useSelector((state) => state.auth.roles)
  const setSearch = (a) => {
    if (a?.length > 0) {
      asyncSearch(a)
    }

  }
  function debounce(func, timeout = 300) {
    let timer;
    return () => {
      clearTimeout(timer);
      timer = setTimeout(() => { func() }, timeout);
    };
  }
  const processChange = debounce(() => setSearch());
  const searchedMessages = () => {
    return search === "" ? [] : founds.filter((s) => s.whatsapp_chat_id === activeChat?.id) || []
  }
  const getAudioMessages = useCallback(() => {
    let arr = document.querySelectorAll(".aud-cont .aud audio")
    return arr
  }, [])
  const goToMessage = useCallback((i) => {
    var numb = i.id
    let ids = numb
    setShowSearchSidebar(false)
    document.querySelector("#s" + ids)?.scrollIntoView({ block: "center" })
    document.querySelector("#s" + ids)?.scrollIntoView({ block: "center" })
    setTimeout(() => {
      document.querySelector("#s" + ids)?.classList.add("backdrop_msg")
    }, 300)
    setTimeout(() => {
      showButt()
      document.querySelector("#s" + ids)?.classList.remove("backdrop_msg")
    }, 1200)

    dispatch({ type: "SET-REPLY-MESSAGE", payload: null })
  }, [])
  const [isMention, setIsMention] = useState([])
  const [replyMessage, setReplyMessage] = useState(null);
  const is_fetch = useSelector((state) => state.whatsapp.is_fetch);
  const chats = useSelector((state) => state.whatsapp.chats);
  const forwardedList = useSelector((state) => state.whatsapp.forwardedList);
  const [withBody, setWithBody] = useState(true)
  const lastMsgRef = useRef();
  const [downButton, showDownButton] = useState(false)
  const showButt = () => {
    let element = document.getElementById("XD-1");
    if (element) {
      // console.log(element?.scrollTop)
      let scrollTop = -parseInt(element?.scrollTop);
      // element.offsetHeight + element?.scrollTop === element?.scrollHeight
      if (scrollTop < 250) {
        if (activeChat?.unread > 0) {
          dispatch({ type: "READ_CHAT", id: activeChat?.id })
        }
      }

      if (scrollTop < 20) {
        showDownButton(false)
      }
      else {
        showDownButton(true)
      };
    }
  };
  const qoutedMessage = useSelector((state) => state.whatsapp.qoutedMessage)
  useEffect(() => {
    setShowSearchSidebar(false)
  }, [qoutedMessage])
  const dispatch = useDispatch();
  const [showAttach, setShowAttach] = useState(false);
  const [rec, setRec] = useState(false);

  const [img, setImg] = useState(false);
  const [showEmojis, setShowEmojis] = useState(false);
  const [showProfileSidebar, setShowProfileSidebar] = useState(false);
  const [showSearchSidebar, setShowSearchSidebar] = useState(false);
  const activeChat = useSelector(state => state?.whatsapp?.activeChat);
  const [newMessage, setNewMessage] = useState(activeChat?.input_value || "");
  const [caretPossition, setCaretPossition] = useState(0);
  const replyMainMessage = useSelector(state => state?.whatsapp?.replyMainMessage);
  const first = useSelector(state => state?.whatsapp?.first);
  const messagesAll = useSelector(state => state?.whatsapp?.activeChat?.messages) || [];
  useEffect(() => {
    setNewMessage(activeChat?.input_value || "")
  }, [activeChat?.id])
  useEffect(() => {
    if (id) {
      scrollToLastMsg()
      document?.querySelector(".chat__input")?.focus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [first, forwarderMessage])

  const [imgs, setImgs] = useState(null);
  const [vid, setVid] = useState(null);
  const [file, setFile] = useState(null);
  // var dateOfConversation = useRef(null);
  useEffect(() => {
    scrollToLastMsg()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rec]);
  useEffect(() => {
    showDownButton(true)
  }, [replyMainMessage])
  const openSidebar = (cb) => {
    // close any open sidebar first
    setShowProfileSidebar(false);
    setShowSearchSidebar(false);
    setShowStaticsSidebar(false);
    // call callback fn
    cb(true);
  };

  const scrollToLastMsg = useCallback(() => {
    showDownButton(false)
    let element = document.querySelector("#XD-1");
    if (element) {
      element.scrollTop = -0;
    }
  }, [])
  const scrollToMessage = () => {
    if (replyMainMessage) {
      goToMessage({ id: replyMainMessage });
    } else {
      dispatch({ type: "CHAT_ID", payload: chats.filter(chat => chat.id === activeChat.id)[0] });
      scrollToLastMsg()
    }
  }
  let members = activeChat?.whatsapp_contact?.sub_contacts || []
  const ImprovedsetReplyMessage = useCallback((value) => { setReplyMessage(value) }, [])

  const submitNewMessage = useCallback((value) => {
    try {
      dispatch({
        type: "CHAT-INPUT-FILED", payload: {
          text: "",
          chat_id: activeChat?.id
        }
      });
      // console.log(value)
      if (value?.length > 0 && value !== `\n` && value !== "" && value !== "\n\n") {
        let local_message_id = (Math.random() * 1000 * Math.random()).toString();
        let cid = activeChat?.id;
        members && members?.map((h) => {
          if (value?.includes("@" + h.number)) {
            if (isMention?.filter((s) => s.id === h.id)?.length === 0) {
              setIsMention(isMention?.concat([h]));
            }
          }
          else {
            setIsMention(isMention?.filter((s) => s.id !== h.id))
          }
        })
        // console.log(isMention)
        let obj = {
          body: value,
          sender_employee_user_id: JSON.parse(localStorage.getItem("user")).id,
          fromMe: 1,
          ack: "sent",
          sent_at: new Date().toISOString(),
          timestamp: Math.floor(Date.now() / 1000),
          type: "chat",
          cid: activeChat?.id,
          local_message_id: local_message_id,
          chat_id: activeChat?.id,
          quotedMsg: replyMessage
        };
        let edit_payload = activeChat?.isNew ?
          activeChat.isOddoClient ? {
            ticket_id: activeChat.ticket_id,
            number_type: activeChat.number_type
          } : {
            receiver_mobile_phone: !activeChat?.new ? `${!activeChat?.number?.includes("+") ? "+" : ""
              }${activeChat?.number}` : activeChat?.name
          } : { whatsapp_chat_id: activeChat?.id, ticket_id: activeChat?.ticket_id }
        let objs = {
          ...edit_payload,
          body: value,
          media: null,
          local_message_id: local_message_id,
          sent_at: new Date().toISOString(),
          type: "chat",
          // updated_at: new Date().toISOString(),
          msg_id: replyMessage?.id?.toString(),
        };
        let mentions_str = ''
        isMention?.map((s, index) => {
          if (index !== isMention?.length - 1) {
            mentions_str += `${s.number},`
          }
          else {
            mentions_str += `${s.number}`
          }
        })
        let groubObj = {
          whatsapp_chat_id: `${activeChat?.id}`,
          body: value,
          media: null,
          mentions: isMention?.length > 0 ? mentions_str : null,
          type: "chat",
          local_message_id: local_message_id,
          // updated_at: new Date().toISOString(),
          msg_id: replyMessage?.id?.toString(),
          sent_at: new Date().toISOString(),
        };


        dispatch({
          type: "CHANGE_IN_MESSAGES",
          payload: {
            type: "SEND_TEXT",
            payload: obj,
            isGroup: activeChat?.isGroup === 1,
            pay: activeChat?.isGroup === 1 ? groubObj : objs,
            local_message_id: local_message_id,
            cid: cid
          }
        });

        dispatch({
          type: "SEND_TEXT_SAGA",
          is_oddo: activeChat?.isOddoClient,
          ticket_id: activeChat?.ticket_id,
          isGroup: activeChat?.isGroup === 1,
          pay: activeChat?.isGroup === 1 ? groubObj : objs,
          local_message_id: local_message_id,
          cid: cid,
        });
        setIsMention([])
        setNewMessage("");
        setReplyMessage(null);
        ImprovedsetReplyMessage(null);
        scrollToLastMsg()
      }
    } catch (e) {
      console.log(e)
    }
  }, [newMessage, replyMessage, activeChat?.id])
  const uploadImage = useCallback(async () => {

    dispatch({ type: "SEND_P" });
    let local_message_id = (Math.random() * 1000 * Math.random()).toString();
    let cid = activeChat?.id;
    let a = newMessage;
    members?.map((h) => {
      if (newMessage?.includes("@" + h.number)) {
        if (isMention?.filter((s) => s.id === h.id)?.length === 0) {
          setIsMention(isMention?.concat([h]));
        }
      }
      else {
        setIsMention(isMention?.filter((s) => s.id !== h.id))
      }
    })
    dispatch({
      type: "CHANGE_IN_MESSAGES",
      payload: {
        type: "SEND_TEXT",
        cid: activeChat?.id,
        local_message_id: local_message_id,
        payload: {
          body: a,
          fromMe: 1,
          media: img,
          sender_employee_user_id: JSON.parse(localStorage.getItem("user")).id,
          ack: "sent",
          loading: true,
          sent_at: new Date().toISOString(),
          timestamp: Math.floor(Date.now() / 1000),
          type: "image",
          chat_id: activeChat?.id,
          cid: activeChat?.id,
          local_message_id: local_message_id,
          quotedMsg: replyMessage
        }
      }
    });

    dispatch({ type: "SEND_P" });
    setImg(null);
    setNewMessage("");
    ImprovedsetReplyMessage(null)
    await upload(file).then((pat) => {
      if (pat !== "") {
        if (activeChat?.isGroup === 0) {

          let edit_payload = activeChat?.isNew ?
            activeChat.isOddoClient ? {
              ticket_id: activeChat.ticket_id,
              number_type: activeChat.number_type
            } : {
              receiver_mobile_phone: !activeChat?.new ? `${!activeChat?.number?.includes("+") ? "+" : ""
                }${activeChat?.number}` : activeChat?.name
            } : { whatsapp_chat_id: activeChat?.id, ticket_id: activeChat?.ticket_id }
          let objs = {
            ...edit_payload,
            local_message_id: local_message_id,
            body: a,
            mentions: isMention?.length > 0 && `${isMention?.map((s, index) => (`${s.number}${index !== isMention?.length - 1 ? "," : ""}`))}`,
            media: FILE_SERVER + pat,
            msg_id: replyMessage?.id.toString(),
            type: "image",
            updated_at: new Date().toISOString(),
            sent_at: new Date().toISOString(),
          };
          dispatch({
            type: "SEND_TEXT_SAGA",
            pay: objs,
            is_oddo: activeChat?.isOddoClient,
            ticket_id: activeChat?.ticket_id,
            isGroup: activeChat?.isGroup === 1,
            cid: cid,
            local_message_id: local_message_id,
          });
        } else {
          let mentions_str = ''
          isMention?.map((s, index) => {
            if (index !== isMention?.length - 1) {
              mentions_str += `${s.number},`
            }
            else {
              mentions_str += `${s.number}`
            }
          })
          let groubObj = {
            whatsapp_chat_id: `${activeChat?.id}`,
            body: a,
            media: FILE_SERVER + pat,
            mentions: isMention?.length > 0 ? mentions_str : null,
            msg_id: replyMessage?.id.toString(),
            type: "image",
            updated_at: new Date().toISOString(),
            local_message_id: local_message_id,
            sent_at: new Date().toISOString(),
          };

          dispatch({
            type: "SEND_TEXT_SAGA",
            mentions: isMention?.length > 0 && `${isMention?.map((s, index) => (`${s.number}${index !== isMention?.length - 1 ? "," : ""}`))}`,
            pay: groubObj,
            is_oddo: activeChat?.isOddoClient,
            ticket_id: activeChat?.ticket_id,
            isGroup: activeChat?.isGroup === 1,
            cid: cid,
            local_message_id: local_message_id,
          });
        }
      } else {
        toast.error("error sending photo!!")
        setImg(null);
        setNewMessage("");
        ImprovedsetReplyMessage(null)
        dispatch({
          type: "CHANGE_IN_MESSAGES",
          payload: {
            type: "UPLOAD_SUC",
            payload: {
              local_message_id: local_message_id,
              cid: cid,
              ack: "fail"
            }
          }
        });
      }
    }).catch((e) => {
      console.log(e)
    })
  }, [img, newMessage])

  const handlePhoto = useCallback((e) => {
    setFile(e);
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(e);
      reader.onload = async () => {
        setImg(reader.result);
        setTimeout(() => {
          document.querySelector(".img__in")?.focus();
        }, 600);
      };
    });
  }, [showAttach])
  const sendVid = useCallback(async (f) => {
    if (f["type"].split("/")[0] === "video") {
      let type = "mp4";
      let name = f.name;
      let local_message_id = (Math.random() * 1000 * Math.random()).toString();
      let cid = activeChat?.id;
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(f);
        reader.onload = async () => {
          let payload = {
            media: reader.result,
            sender_employee_user_id: JSON.parse(localStorage.getItem("user")).id,
            fromMe: 1,
            file_name: name,
            body: name,
            file_ext: type,
            updated_at: new Date().toISOString(),
            loading: true,
            ack: "sent",
            sent_at: new Date().toISOString(),
            timestamp: Math.floor(Date.now() / 1000),
            type: "video",
            cid: activeChat?.id,
            local_message_id: local_message_id,
            chat_id: activeChat?.id,
            quotedMsg:
              replyMessage
          };
          dispatch({
            type: "CHANGE_IN_MESSAGES",
            payload: {
              type: "SEND_TEXT",
              payload: payload,
              local_message_id: local_message_id,
              cid: cid
            }
          });
          setTimeout(() => {
            scrollToLastMsg()
          }, 200);
        };
      });
      let pat = await upload(f);
      let edit_payload = activeChat?.isNew ?
        activeChat.isOddoClient ? {
          ticket_id: activeChat.ticket_id,
          number_type: activeChat.number_type
        } : {
          receiver_mobile_phone: !activeChat?.new ? `${!activeChat?.number?.includes("+") ? "+" : ""
            }${activeChat?.number}` : activeChat?.name
        } : { whatsapp_chat_id: activeChat?.id, ticket_id: activeChat?.ticket_id }
      let objs = {
        ...edit_payload,
        media: FILE_SERVER + pat,
        body: null,
        type: "video",
        updated_at: new Date().toISOString(),
        msg_id: replyMessage?.id.toString(),
        sent_at: new Date().toISOString(),
      };
      let mentions_str = ''
      isMention?.map((s, index) => {
        if (index !== isMention?.length - 1) {
          mentions_str += `${s.number},`
        }
        else {
          mentions_str += `${s.number}`
        }
      })
      let groubObj = {
        whatsapp_chat_id: `${activeChat?.id}`,
        body: newMessage,
        media: FILE_SERVER + pat,
        mentions: isMention?.length > 0 ? mentions_str : null,
        msg_id: replyMessage?.id.toString(),
        type: "video",
        updated_at: new Date().toISOString(),
        local_message_id: local_message_id,
        sent_at: new Date().toISOString(),
      };
      dispatch({
        type: "SEND_TEXT_SAGA",
        pay: activeChat?.isGroup === 1 ? groubObj : objs,
        isGroup: activeChat?.isGroup === 1,
        cid: activeChat?.id,
        is_oddo: activeChat?.isOddoClient,
        ticket_id: activeChat?.ticket_id,
        local_message_id: local_message_id,
      });
    }
  }, [activeChat])
  const clear = useCallback(() => {
    setNewMessage("");
    setImg(null);
    setFile(null);
  }, [])
  const sendAudio = async (e) => {
    handleAudio(e)
  };
  let cid = useSelector(state => state?.whatsapp?.activeChat?.id);
  let chat_id = useSelector(state => state?.whatsapp?.activeChat?.id);
  let chat = useSelector(state => state?.whatsapp?.activeChat);
  const handleAudio = useCallback(async (e) => {
    //useSelector(state => state?.whatsapp?.scr)
    var reader = new FileReader();
    reader.readAsDataURL(e);
    //for backend
    let local_message_id = (Math.random() * 1000 * Math.random()).toString();
    var file = new File([e], "wave-" + parseInt(local_message_id * 1000));
    reader.onloadend = async function () {
      var base64data = reader.result;


      dispatch({
        type: "CHANGE_IN_MESSAGES",
        payload: {
          type: "SEND_TEXT",
          payload: {
            body: null,
            ack: "sent",
            sender_employee_user_id: JSON.parse(localStorage.getItem("user")).id,
            cid: cid,
            fromMe: 1,
            local_message_id: local_message_id,
            chat_id: chat_id,
            loading: false,
            sent_at: new Date().toISOString(),
            timestamp: Math.floor(Date.now() / 1000),
            type: "audio",
            quotedMsg:
              replyMessage,
            media: base64data,
          }
        }
      });
      let pat = await upload(file);
      let edit_payload = activeChat?.isNew ?
        activeChat.isOddoClient ? {
          ticket_id: activeChat.ticket_id,
          number_type: activeChat.number_type
        } : {
          receiver_mobile_phone: !activeChat?.new ? `${!activeChat?.number?.includes("+") ? "+" : ""
            }${activeChat?.number}` : activeChat?.name
        } : { whatsapp_chat_id: activeChat?.id, ticket_id: activeChat?.ticket_id }
      let objs = {
        ...edit_payload,
        media: FILE_SERVER + pat,
        local_message_id: local_message_id,
        body: null,
        type: "audio",
        updated_at: new Date().toISOString(),
        msg_id: replyMessage?.id.toString(),
        sent_at: new Date().toISOString(),
      };

      let mentions_str = ''
      isMention?.map((s, index) => {
        if (index !== isMention?.length - 1) {
          mentions_str += `${s.number},`
        }
        else {
          mentions_str += `${s.number}`
        }
      })
      let groubObj = {
        whatsapp_chat_id: `${activeChat?.id}`,
        body: newMessage,
        local_message_id: local_message_id,
        media: FILE_SERVER + pat,
        mentions: isMention?.length > 0 ? mentions_str : null,
        msg_id: replyMessage?.id.toString(),
        type: "audio",
        updated_at: new Date().toISOString(),
        sent_at: new Date().toISOString(),
      };
      dispatch({
        type: "SEND_TEXT_SAGA",
        pay: chat.isGroup === 1 ? groubObj : objs,
        isGroup: chat.isGroup === 1,
        cid: activeChat?.id,
        is_oddo: activeChat?.isOddoClient,
        ticket_id: activeChat?.ticket_id,
        local_message_id: local_message_id,
      });
    };
  }, [activeChat])
  const sendDocument = useCallback(async (f) => {
    if (f["type"].split("/")[0] === "image") {
      handlePhoto(f);
    } else {
      let type = f.name.split(".")[1] ?? "FILE";
      let name = f.name;
      let local_message_id = (Math.random() * 1000 * Math.random()).toString();
      let cid = activeChat?.id;
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(f);
        reader.onload = async () => {
          let payload = {
            media: reader.result,
            sender_employee_user_id: JSON.parse(localStorage.getItem("user")).id,
            file_name: name,
            file_ext: type,
            updated_at: new Date().toISOString(),
            loading: true,
            ack: "sent",
            sent_at: new Date().toISOString(),
            timestamp: Math.floor(Date.now() / 1000),
            type: "document",
            cid: activeChat?.id,
            local_message_id: local_message_id,
            chat_id: activeChat?.id,
            quotedMsg:
              replyMessage
          };
          dispatch({
            type: "CHANGE_IN_MESSAGES",
            payload: {
              type: "SEND_TEXT",
              payload: payload,
              local_message_id: local_message_id,
              cid: cid
            }
          });
          setTimeout(() => {
            scrollToLastMsg()
          }, 200);
        };
      });
      let pat = await upload(f);
      let edit_payload = activeChat?.isNew ?
        activeChat.isOddoClient ? {
          ticket_id: activeChat.ticket_id,
          number_type: activeChat.number_type
        } : {
          receiver_mobile_phone: !activeChat?.new ? `${!activeChat?.number?.includes("+") ? "+" : ""
            }${activeChat?.number}` : activeChat?.name
        } : { whatsapp_chat_id: activeChat?.id, ticket_id: activeChat?.ticket_id }
      let objs = {
        ...edit_payload,
        media: FILE_SERVER + pat,
        local_message_id: local_message_id,
        body: newMessage,
        type: "document",
        updated_at: new Date().toISOString(),
        sent_at: new Date().toISOString(),
        filename: name,
        msg_id: replyMessage?.id.toString()
      };
      let mentions_str = ''
      isMention?.map((s, index) => {
        if (index !== isMention?.length - 1) {
          mentions_str += `${s.number},`
        }
        else {
          mentions_str += `${s.number}`
        }
      })
      let groubObj = {
        whatsapp_chat_id: `${activeChat?.id}`,
        body: newMessage,
        filename: name,
        media: FILE_SERVER + pat,
        mentions: isMention?.length > 0 ? mentions_str : null,
        msg_id: replyMessage?.id.toString(),
        type: "document",
        updated_at: new Date().toISOString(),
        local_message_id: local_message_id,
        sent_at: new Date().toISOString(),
      };
      dispatch({
        type: "SEND_TEXT_SAGA",
        pay: activeChat?.isGroup === 1 ? groubObj : objs,
        isGroup: activeChat?.isGroup === 1,
        cid: activeChat?.id,
        is_oddo: activeChat?.isOddoClient,
        ticket_id: activeChat?.ticket_id,
        local_message_id: local_message_id,
      });
    }
  }, [showAttach])
  const ImprovedSetImg = useCallback((s) => { setImgs(s) }, [])
  const ImprovedSetVid = useCallback((s) => { setVid(s) }, [])
  const [forwardValue, setForwardValue] = useState("");
  const isSearchForC = useCallback((ch) => {
    if (forwardValue?.length > 0) {
      if ((ch.whatsapp_contact?.name && ch.whatsapp_contact?.name?.toLowerCase()?.includes(forwardValue?.toLowerCase())) || (ch.whatsapp_contact?.pushname && ch.whatsapp_contact?.pushname?.toLowerCase()?.includes(forwardValue?.toLowerCase())) || (("+" + ch.whatsapp_contact?.number)?.includes(forwardValue)) || (ch.whatsapp_contact?.number?.toString().split("+")[ch.whatsapp_contact?.number?.toString().split("+")?.length - 1]?.includes(forwardValue.split("+")[forwardValue.split("+")?.length - 1]))) {
        return true
      }
      else {
        return false
      }
    }
    else {
      return true
    }
  }, [forwardValue])
  const divRef = useRef(null);
  const onScrollTop = (e) => {
    if (!is_fetch && divRef.current) {
      dispatch({ type: "fetch" });
      dispatch({
        type: "GET_PAGE_CHAT",
        id: activeChat?.id,
        page: activeChat?.messages[0].id
      })
    }
  };

  const onScrollBottom = (e) => {
    if (!is_fetch && divRef.current) {
      dispatch({ type: "fetch" });
      dispatch({
        type: "GET_MES_AFTER",
        sid: activeChat?.messages[activeChat?.messages?.length - 1].id,
        cid: activeChat?.id
      })
    }
  };
  //getMessagesBeforeSpecificMessage
  //getMessagesAfterSpecificMessage

  // const handleWheel = (e) => {
  //   showButt()
  //   let els = document.querySelector("#XD-1")
  //   if (!is_fetch && !reachedEnd && divRef.current && ((-els?.scrollTop * 100) / (els?.scrollHeight - els?.clientHeight)) > 99 && e.deltaY < 0) {
  //     showButt()
  //     onScrollTop(e)
  //   }

  //   if (!is_fetch && divRef.current && searchMode && ((-els?.scrollTop * 100) / (els?.scrollHeight - els?.clientHeight)) < 1 && e.deltaY > 0) {
  //     const scrollPos = document.querySelector("#XD-1")?.scrollTop
  //     onScrollBottom(e)
  //     const scrollHeight = document.querySelector("#XD-1")?.scrollHeight
  //     document.querySelector("#XD-1").style.flexDirection = "column"
  //     document.querySelector("#XD-1")?.scrollTop = (scrollHeight + scrollPos)
  //   }
  // }
  const handleScroll = (e) => {
    showButt()
    let els = document.querySelector("#XD-1")
    if (!is_fetch && els && !reachedEnd && divRef.current && ((-els?.scrollTop * 100) / (els?.scrollHeight - els?.clientHeight)) > 97) {
      showButt()
      onScrollTop(e)
    }

    if (!is_fetch && els && divRef.current && searchMode && ((-els?.scrollTop * 100) / (els?.scrollHeight - els?.clientHeight)) < 2) {
      const scrollPos = document.querySelector("#XD-1")?.scrollTop
      onScrollBottom(e)
      const scrollHeight = document.querySelector("#XD-1")?.scrollHeight
      document.querySelector("#XD-1").style.flexDirection = "column"
      if (scrollPos) {
        document.querySelector("#XD-1").scrollTop = (scrollHeight + scrollPos)
      }
    }
  }
  useEffect(() => {
    let element = document.querySelector("#XD-1")
    if (element) {
      const scrollPos = element?.scrollTop
      const scrollHeight = element?.scrollHeight
      const clientHeight = element?.clientHeight
      element.style.flexDirection = "column-reverse"
      element.scrollTop = -(scrollHeight - scrollPos) + clientHeight
    }
  }, [afterDone])

  return (<>
    {(imgs || vid) &&

      <div className="fixed-img-prev">
        <div className="bac-drop"></div>
        <div className="svv" onClick={() => { setVid(null); setImgs(null) }}> <Icon id="cancel" /></div>
        {vid ? <video src={vid} controls><source src={vid} /></video> : <img alt="" src={imgs} />}
      </div>}
    <div className={`chat ${(id || openContacts) && "activeC"}`}>
      {!openContacts ? <>

        {img ? (
          <div className="image_prev">
            <Icon
              id="cancel"
              style={{ position: "absolute", top: "20px", left: "20px" }}
              onClick={() => clear()}
            />

            <img alt="" src={img} />
            <div className="MessageCaption">
              <input
                className="chat__input img__in"
                placeholder="write a caption"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value || "")}
              />
              <div className="send_button_img" onClick={() => uploadImage()}>
                <Icon id="send" />
              </div>
            </div>
          </div>
        ) : (
          <>
            <div
              className={
                "chat__body " +
                `${showSearchSidebar || (showProfileSidebar && "wid0px")}`
              }
            >
              <div className="chat__bg"></div>

              <Header
                close={() => {
                  if (newMessage && newMessage?.length > 0)
                    dispatch({
                      type: "CHAT-INPUT-FILED", payload: {
                        text: newMessage,
                        chat_id: activeChat?.id
                      }
                    })
                  setShowStaticsSidebar(false)
                  dispatch({ type: "CHAT_ID", payload: null });
                  setNewMessage("");
                }}
                user={activeChat?.whatsapp_contact ?? activeChat}
                id={activeChat?.id}
                openProfileSidebar={() => openSidebar(setShowProfileSidebar)}
                openSearchSidebar={() => openSidebar(setShowSearchSidebar)}
                openStaticsSidebar={() => openSidebar(setShowStaticsSidebar)}
                is_hash_check_enabled={activeChat?.is_hash_check_enabled}
              />

              <div
                id="XD-1"
                tabIndex={"-1"}
                ref={divRef}
                style={{
                  flexDirection: "column-reverse",
                  overflowAnchor: "none",
                  zIndex: 23
                }}
                className="chat__content"
                // onWheel={(e) => {
                //   handleWheel(e)
                // }}
                onScroll={(e) => {
                  handleScroll(e)
                }}
              >
                {activeChat && (
                  <div style={
                    {
                      display: "flex",
                      flexDirection: "column",
                      overflowAnchor: "none"
                    }
                  }>
                    <Convo
                      getAudioMessages={() => getAudioMessages()}
                      img={imgs}
                      setImg={(s) => ImprovedSetImg(s)}
                      setVid={(s) => ImprovedSetVid(s)}
                      vid={vid}
                      // dateOfConversation={dateOfConversation}
                      setReplyMessage={(value) => ImprovedsetReplyMessage(value)}
                      lastMsgRef={lastMsgRef}
                      messages={messagesAll}
                      scroll={scrollVal}
                      submitNewMessage={submitNewMessage}
                    />

                    <div
                      ref={lastMsgRef}
                      className="ref-bot"
                      style={{
                        position: "static",
                        bottom: "0px",
                        opacity: "0",
                        height: "2px",
                      }}
                    ></div>
                  </div>
                )}
              </div>


              <footer className="chat__footer">
                {replyMessage && (
                  <div className="reply-sc">
                    <div
                      className="reply-cancel"
                      onClick={() => (setReplyMessage(null))}
                    >
                      <Icon id="cancel" />
                    </div>
                    <div className="replay-mes">
                      <div className="bar-c"></div>
                      <div className="reply-container">
                        <div className="group_name reply-name">
                          {replyMessage?.fromMe === 1
                            ? "You"
                            : activeChat?.isGroup === 1 ? (
                              (activeChat?.whatsapp_contact.sub_contacts.filter((s) => s.id === replyMessage.received_from_contact_id)[0]?.name || activeChat?.whatsapp_contact.sub_contacts.filter((s) => s.id === replyMessage.received_from_contact_id)[0]?.pushname)
                                ? (activeChat?.whatsapp_contact.sub_contacts.filter((s) => s.id === replyMessage.received_from_contact_id)[0]?.name || activeChat?.whatsapp_contact.sub_contacts.filter((s) => s.id === replyMessage.received_from_contact_id)[0]?.pushname)
                                : "+" + activeChat?.whatsapp_contact.sub_contacts.filter((s) => s.id === replyMessage.received_from_contact_id)[0]?.number
                            )
                              :
                              ((activeChat?.whatsapp_contact?.name || activeChat?.whatsapp_contact?.pushname)
                                ? (activeChat?.whatsapp_contact?.name || activeChat?.whatsapp_contact?.pushname)
                                : "+" + activeChat?.whatsapp_contact?.number)}
                          {/* {replyMessage?.fromMe === 1
                            ? "You"
                            : contacts.filter((s)=>s.id===replyMessage?.received_from_contact_id)[0]?.name
                              ? contacts.filter((s)=>s.id===replyMessage?.received_from_contact_id)[0]?.name
                              : "+" + contacts.filter((s)=>s.id===replyMessage?.received_from_contact_id)[0].number} */}
                        </div>
                        <div className="reply-desc">
                          {replyMessage.is_ticket === 1 && <>Ticket Message {replyMessage.ticket?.request_number}</>}
                          {(replyMessage?.type === "image" || replyMessage?.type === "ptt" || replyMessage?.type === "audio" || replyMessage?.type === "sticker" || replyMessage?.type === "video") && (
                            replyMessage?.type === "image" ? <Cam /> : (replyMessage?.type === "ptt" || replyMessage?.type === "audio") ? <Voc /> : <Vide />
                          )}
                          {replyMessage?.type === "document" && (
                            <img alt="" width={14} height={16} src={out} />
                          )}
                          {" "}
                          {replyMessage.is_ticket !== 1 && replyMessage?.body}
                        </div>
                      </div>
                      {replyMessage?.media && (
                        <div className="replay-img">
                          {replyMessage?.type === "image" && <img alt="" src={replyMessage.media} />}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {rec &&
                  <Recorder
                    send={(e) => {

                      if (e) sendAudio(e);
                      setRec(false);
                    }}
                    style={{ display: "block" }}
                    rec={rec}
                    setRec={setRec}
                  />
                }
                {!rec && (
                  <>
                    {(downButton || searchMode) && (
                      <button
                        className="chat__scroll-btn"
                        aria-label="scroll down"
                        onClick={() => { scrollToMessage() }}
                      >
                        {activeChat?.unread > 0 && <span className="sidebar-contact__unread unre">{activeChat?.unread}</span>}
                        <Icon id="downArrow" />
                      </button>
                    )}
                    {showEmojis && <EmojiTray
                      showEmojis={showEmojis}
                      newMessage={newMessage}
                      setNewMessage={setNewMessage}
                      caretPossition={caretPossition}
                    />}
                    <ChatInput
                      replyMessage={replyMessage}
                      sendAud={(e) => sendAudio(e)}
                      showEmojis={showEmojis}
                      sendDocument={(e) => sendDocument(e)}
                      sendVid={(e) => sendVid(e)}
                      onFocus={(e) => { setCaretPossition(e.target?.selectionStart); }}
                      onBlur={() => { ; }}
                      setRec={setRec}

                      handlePhoto={(e) => handlePhoto(e)}
                      img={img}
                      rec={rec}
                      setShowEmojis={setShowEmojis}
                      showAttach={showAttach}
                      isMention={isMention}
                      setIsMention={(e) => setIsMention(e)}
                      setShowAttach={setShowAttach}
                      newMessage={newMessage}
                      setNewMessage={(val) => {
                        setNewMessage(val || "");
                      }}
                      submitNewMessage={submitNewMessage}
                      caretPossition={caretPossition}
                    />
                  </>
                )}
              </footer>
            </div>
            {showSearchSidebar && <ChatSidebar
              searchedMessages={searchedMessages()}
              search={search}
              goToMessage={(iden) => goToMessage(iden)}
              onChange={(e) => processChange(e.target.value)}
              heading="Search Messages"
              active={showSearchSidebar}
              closeSidebar={() => { setShowSearchSidebar(false); dispatch({ type: "WORD_SEARCH", payload: "" }); }}
            >
              <Search close={() => setShowSearchSidebar(false)} c={activeChat} searchedMessages={searchedMessages()} search={search} onChange={(e) => {
                dispatch({ type: "WORD_SEARCH", payload: e.target.value });
                setTimeout(() => {
                  setSearch(e.target.value);
                }, 700);
              }} />
            </ChatSidebar>}
            {activeChat?.id && !activeChat?.new && forwarderMessage?.id && <ChatSidebar
              searchedMessages={forwarderMessage}
              isForward={true}
              value={forwardValue}
              onChange={(e) => setForwardValue(e.target.value)}
              heading="Forward Message"
              active={forwarderMessage?.id}
              setWithBody={(e) => setWithBody(e)}
              withBody={withBody}
              message={forwarderMessage}
              closeSidebar={() => dispatch({ type: "FORWARD-MESSAGE", payload: null })}>
              <ForwardList isSearchFor={(c) => isSearchForC(c)} value={forwardValue} closeSidebar={() => dispatch({ type: "FORWARD-MESSAGE", payload: null })}
                chatss={forwardedList}
                withBody={withBody}
                setWithBody={(e) => setWithBody(e)}
                chats={roles?.includes("USER_FULL_ACCESS") ? getChatsByMode(chats).filter((cha) => cha.id !== activeChat?.id) : chats.filter((cha) => cha.id !== activeChat?.id)} />
            </ChatSidebar>}
            {showProfileSidebar && <ChatSidebar
              heading="Contact Info"
              active={showProfileSidebar}
              closeSidebar={() => setShowProfileSidebar(false)}
            >
              <Profile id_f={activeChat?.id} isGroup={activeChat?.isGroup === 1} activeChat={activeChat} id={activeChat?.id} user={activeChat?.whatsapp_contact ?? user} />
            </ChatSidebar>}
          </>
        )}
      </> :
        !vcard ? <ContactsList data={contacts} small={!viewDisplay} close={() => setOpen(false)} /> :
          <ChatSidebar
            searchedMessages={vcard}
            sendVcard={true}
            isForward={true}
            value={forwardValue}
            onChange={(e) => setForwardValue(e.target.value)}
            heading="Forward Message"
            active={vcard?.length > 0}
            closeSidebar={() => dispatch({ type: "VCARD-MESSAGE", payload: null })}>
            <ForwardList
              isSearchFor={(c) => isSearchForC(c)}
              value={forwardValue}
              sendVcard={true}
              closeSidebar={() => {
                dispatch({ type: "VCARD-MESSAGE", payload: null });
                setOpen(false);
              }}
              chatss={forwardedList}
              chats={roles?.includes("USER_FULL_ACCESS") ? getChatsByMode(chats) : chats} />
          </ChatSidebar>}
    </div>
  </>
  );
};

export default Chat;
