export default function Edit() {
  return (<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
    <g id="Mask_Group_251" data-name="Mask Group 251" >
      <g id="Layer_92" data-name="Layer 92" transform="translate(-1.508 -1.506)">
        <path id="Path_20958" data-name="Path 20958" d="M15.831,2.176a2.251,2.251,0,0,0-3.2,0L11.187,3.639,14.368,6.82,15.808,5.38a2.251,2.251,0,0,0,.023-3.2Z" fill="#404040" />
        <path id="Path_20959" data-name="Path 20959" d="M10.121,4.7,3.826,11a.863.863,0,0,0-.143.2L1.6,15.4a.747.747,0,0,0,.983,1.028L6.79,14.345a.863.863,0,0,0,.2-.143L13.28,7.908Z" fill="#404040" />
        <path id="Path_20960" data-name="Path 20960" d="M15.756,16.506H9a.75.75,0,0,1,0-1.5h6.753a.75.75,0,1,1,0,1.5Z" fill="#404040" />
      </g>
    </g>
  </svg>)
}

