import { memo, useEffect } from "react";
import Icon from "../Icon";
import ProfileNo from "../../../WhatsApp/assets/images/profileNo.png";
import Group from "../../../WhatsApp/assets/images/group";
import Cam from "../../../WhatsApp/assets/images/cam";
import Voc from "../../../WhatsApp/assets/images/voc";
import Wai from "../../../WhatsApp/assets/images/wai.js";
import Vid from "../../../WhatsApp/assets/images/vid";
import Cons from "../../../WhatsApp/assets/images/cons";
import File from "../../../WhatsApp/assets/images/fl";
import parse from 'html-react-parser';
import date from "date-and-time";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import store from "../../../../../redux/store";
import axios from "axios";
import missedCall from "../../pages/Chat/components/missedCall.jpg";
import { REACT_APP_ACCOUNTING_URL } from "../../../../../urls.js";
const Contact = ({ isSelected, onClick, contact, newChat, style, innerKey }) => {
  const sub_contacts = useSelector((state) => state.whatsapp.activeChat?.whatsapp_contact?.sub_contacts) || []
  const roles = useSelector(state => state.auth.roles)
  const getLastMessage = () => {
    var maxValue = Number.MIN_VALUE;
    var lst

    if (contact && contact?.messages?.length > 0)
      lst = contact.messages.filter((s) => ((s?.whatsapp_chat_id === contact?.id) || (s?.cid === contact.id) || (s?.chat_id === contact?.id)))[0];
    for (var i = 0; i < contact?.messages?.length; i++) {
      if (new Date(contact?.messages[i]?.sent_at).getTime() > maxValue) {
        maxValue = new Date(contact?.messages[i]?.sent_at).getTime();
        lst = contact?.messages[i];
      }
    }
    return lst;
  };
  let now = new Date();
  const showDate = (d) => {
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    let day = new Date(d)
    day = days[day.getDay()]
    let da = new Date(d)
    da = `${da.getFullYear()}-${(da.getMonth() + 1) > 9 ? (da.getMonth() + 1).toString() : ("0" + (da.getMonth() + 1).toString())}-${(da.getDate()) > 9 ? da.getDate() : "0" + parseInt(da.getDate()).toString()}`
    if (da === nowString)
      return date.format(new Date(d), "HH:mm");
    else if ((new Date(nowString) - new Date(d)) <= 86400000) {
      return ("Yesterday")
    }
    else if ((new Date(nowString) - new Date(d)) < (86400000 * 6))
      return (day)
    else
      return (date.format(new Date(d), "MMM DD"))
  }
  let nowString = `${now.getFullYear()}-${(now.getMonth() + 1) > 9 ? (now.getMonth() + 1).toString() : ("0" + (now.getMonth() + 1).toString())}-${(now.getDate()) > 9 ? now.getDate() : "0" + parseInt(now.getDate()).toString()}`
  const contacts = useSelector((state) => state.whatsapp.contacts)
  const activeChat = useSelector((state) => state.whatsapp.activeChat)
  const getText = (sds) => {
    let str = sds;
    let mentions = [];
    if (str?.includes("@")) {
      const regex = /@\d+/g;
      let ser = str?.match(regex)
      mentions = ser

      // console.log(ser)
      if (ser && ser.length > 0) {

        ser.map((s) => {
          str = str.replace(s, `<span class='blue_tag'> @${(contacts.filter((con) => ("@" + con.number) === s)[0]?.name || contacts.filter((con) => ("@" + con.number) === s)[0]?.pushname || sub_contacts.filter((con) => ("@" + con.number) === s)[0]?.name || sub_contacts.filter((con) => ("@" + con.number) === s)[0]?.pushname) || s}</span>`)
        })
      }

    }
    let regex = /\*[A-Z]+\*/g
    let ser = str?.match(regex)
    if (ser && ser.length > 0) {

      ser.forEach((s) => {
        str = str.replace(s, `<span class='BIG_tag'>${s.replace("*", '').replace("*", '')}</span>`)
      })

    }
    if (str !== null) {
      return parse(str?.toString())

    }
    else {
      return sds
    }

  }
  const getMessage = () => {
    if (getLastMessage()?.delete_status && !roles.includes("USER_FULL_ACCESS")) {
      return (<>Deleted Message</>)
    }
    else if (getLastMessage()?.type === "ticket_hint") {
      return (<>Hint Message {getLastMessage()?.ticket?.request_number}</>)
    } else
      if (getLastMessage()?.type === "ticket") {
        return (<>Ticket Message {getLastMessage()?.ticket?.request_number}</>)
      } else
        if (getLastMessage()?.type === "image" || getLastMessage()?.type === "sticker") {
          return (
            <>
              <Cam /> Image
            </>
          );
        } else if (getLastMessage()?.type === "ptt" || getLastMessage()?.type === "audio") {
          return (
            <>
              <Voc /> Voice
            </>
          );
        } else if (getLastMessage()?.type === "chat") {
          return <> {getLastMessage()?.body}</>;
        } else if (getLastMessage()?.type === "document") {
          return (
            <>
              <File />File
            </>
          );
        }
        else if (getLastMessage()?.type === "vcard") {
          return (<>
            <Cons /> Contact
          </>)
        }
        else if (getLastMessage()?.type === "video") {
          return (<div style={{ marginRight: "4px" }}>
            <Vid /> Video
          </div>)
        }
        else if (getLastMessage()?.type === "call_log") {
          return (
            <>
              <img src={missedCall} height={15} width={15} style={{ marginRight: "10px" }} />
              Missed Call
            </>
          )
        }
  };
  useEffect(() => {
    // 
  }, [contact]);
  const getMessageTime = () => {
    if (getLastMessage()?.sent_at) {
      let d = new Date(getLastMessage()?.sent_at);
      return showDate(d)
    }
  };
  const detectWAContact = async () => {
    const token = store.getState()?.whatsapp?.userTokenWhatsapp;
    const base_user = REACT_APP_ACCOUNTING_URL;
    let AxiosInstance = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    if (newChat) {
      if (contacts.filter((s) => "+" + s.number === contact.number).length > 0) {
        onClick()
      }
      else {
        let a
        const id = toast.loading("Please wait...")
        try {
          a = await AxiosInstance.get(`/api/v1/whatsapp/check_phone_number/${contact.number}`);
          // console.log(a.data)
        } catch (e) {
          toast.update(id, { render: "this number dosen't have active whatsapp account", type: "error", isLoading: false, autoClose: true });
        }

        if (a?.data.data.status === "valid") {
          onClick()
          toast.dismiss(id)
        }
        else {
          toast.update(id, { render: "this number dosen't have active whatsapp account", type: "error", isLoading: false, autoClose: true, closeOnClick: true });

        }
      }
    }
    else {
      onClick()
    }
  }
  return (
    <div
      key={innerKey}
      style={style}
      className={activeChat?.id === contact?.id ? "sidebar-contact-active" : "sidebar-contact"}
      onClick={() => detectWAContact()}
    >
      <div className="sidebar-contact__avatar-wrapper">
        {contact?.isGroup && contact.isGroup === 1 ? (
          <Group />
        ) : (
          <img
            src={contact?.whatsapp_contact?.profile_photo ? contact?.whatsapp_contact.profile_photo : ProfileNo}
            alt={""}
            className="avatar"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = ProfileNo;
            }}
          />
        )}
      </div>
      <div className="sidebar-contact__content">
        <div className="sidebar-contact__top-content">
          <h2 className="sidebar-contact__name">
            {" "}{
              contacts.filter((s) => s.id === contact.whatsapp_contact?.id)[0]?.name ||
              contacts.filter((s) => s.id === contact.whatsapp_contact?.id)[0]?.pushname ||
              (contacts.filter((s) => s.id === contact.whatsapp_contact?.id)[0]?.number && ("+" + contacts.filter((s) => s.id === contact.whatsapp_contact?.id)[0]?.number)) ||
              contact.name ||
              contact?.whatsapp_contact?.name ||
              (contact.whatsapp_contact?.number ? "+" + contact.whatsapp_contact?.number : "unable to get contact")
            }

          </h2>
          <span className="sidebar-contact__time">
            {/*{formatTime(lastMessage?.time)}*/}
          </span>
        </div>
        <div className="sidebar-contact__bottom-content">
          <div className="sidebar-contact__message-wrapper">
            {(getLastMessage()?.ack && getLastMessage()?.fromMe) ? (
              <>
                {(getLastMessage()?.ack === "device" || getLastMessage()?.ack === "server" || getLastMessage()?.ack === "read" || getLastMessage()?.ack === "fail") ?
                  <Icon
                    id={
                      getLastMessage()?.ack === "server"
                        ? "singleTick"
                        : (getLastMessage()?.ack === "device" ? "doubleTick" : (
                          getLastMessage()?.ack === "read" ? "doubleTick" : (
                            getLastMessage()?.ack === "fail" ? "failed" : ""
                          )
                        ))
                    }
                    aria-label={getLastMessage()?.ack}
                    className={`sidebar-contact__message-icon ${getLastMessage()?.ack === "read"
                      ? "sidebar-contact__message-icon--blue"
                      : ""
                      }`}
                  /> :
                  <div className={`sidebar-contact__message-icon`}>
                    <Wai />
                  </div>}
              </>

            ) : null}
            <span
              className={`sidebar-contact__message ${contact?.unread ? "sidebar-contact__message--unread" : ""
                }`}
            >
              {contact?.typing ? <i> typing...</i> : getMessage()}
            </span>
          </div>
          <div className="sidebar-contact__icons">
            {contact?.pinned === 1 && (
              <Icon id="pinned" className="sidebar-contact__icon" />
            )}
            {contact?.unread > 0 && (
              <span className="sidebar-contact__unread">{contact?.unread}</span>
            )}
            {
              <span className="Dvjym">
                {isSelected ? <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 15"
                  width="16"
                  height="15"
                  className="sidebar-contact__message-icon--blue blue-tick-for"
                >
                  <path
                    fill="currentColor"
                    d="M10.91 3.316l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"
                  ></path>
                </svg> :
                  <>

                    {getLastMessage() &&
                      getLastMessage()?.sent_at &&
                      getMessageTime()}</>}
              </span>
            }
            <button aria-label="sidebar-contact__btn">
              <Icon
                id="downArrow"
                className="sidebar-contact__icon sidebar-contact__icon--dropdown"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Contact);
