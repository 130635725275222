import React from 'react'

function Water(props) {
  return (
    <svg className={props.active&&"acv"} id="_15x15_photo_back" data-name="15x15 photo back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
  <g id="Mask_Group_157" data-name="Mask Group 157">
    <g id="water-drop">
      <g id="Group_7729" data-name="Group 7729">
        <path id="Path_19037" data-name="Path 19037" d="M5.852,12.909A3.444,3.444,0,0,1,4.1,7.579a.3.3,0,0,0,.027-.043.294.294,0,0,0-.5-.3,4.031,4.031,0,0,0,.018,4.8A4.064,4.064,0,0,0,5.678,13.47a.294.294,0,1,0,.174-.561Z" fill="#8e8e8e"/>
        <path id="Path_19038" data-name="Path 19038" d="M13.381,6.864a2.586,2.586,0,0,0-.5-1.541l-1.859-2.76a.294.294,0,0,0-.487,0L9.747,3.726,7.325.13a.294.294,0,0,0-.487,0L2.679,6.3a5.463,5.463,0,1,0,9.865,3.235,5.514,5.514,0,0,0-.047-.718A2.6,2.6,0,0,0,13.381,6.864ZM10.774,3.252l1.616,2.4.01.015a2.019,2.019,0,1,1-3.252,0l.01-.014L9.99,4.416l0-.007Zm1.182,6.285a4.875,4.875,0,1,1-8.8-2.893l.01-.015L7.081.819,9.393,4.251,8.671,5.323a2.607,2.607,0,0,0,3.272,3.87q.012.172.012.345Z" fill="#8e8e8e"/>
        <path id="Path_19039" data-name="Path 19039" d="M9.739,6.792a1,1,0,0,1,.086-.408.294.294,0,0,0-.537-.239A1.6,1.6,0,0,0,9.86,8.117a.294.294,0,0,0,.327-.488,1.006,1.006,0,0,1-.448-.837Z" fill="#8e8e8e"/>
        <path id="Path_19040" data-name="Path 19040" d="M6.942,13.316a.294.294,0,1,0-.329.4A.3.3,0,0,0,6.942,13.316Z" fill="#8e8e8e"/>
        <path id="Path_19041" data-name="Path 19041" d="M10.62,8.385a.294.294,0,1,0,0-.488A.295.295,0,0,0,10.62,8.385Z" fill="#8e8e8e"/>
      </g>
    </g>
  </g>
</svg>

  )
}

export default Water