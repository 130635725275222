import { all, fork, put, takeEvery } from "@redux-saga/core/effects";
import AxiosInstance from "../../../helpers/axiosInstance";
import { toast } from "react-toastify";
import { ADD_DEPT_URL, GET_DEPT_URL, UPD_DEPT_URL } from "./constants";
import store from "../../store";
import { GET_LANGS_URL } from "../../LinkedLanguages/constants";
import { REACT_APP_CATEGORIES_SERVICE_URL } from "../../../urls";
function* getAllDepartments(action) {
  let response;
  try {
    yield put({ type: "GENERAL-LOADING-COM" });

    response = yield AxiosInstance.post(REACT_APP_CATEGORIES_SERVICE_URL +
      GET_DEPT_URL,
      JSON.stringify({
        company_type_id: action.id,
      })
    );
    let langs = yield AxiosInstance.get(GET_LANGS_URL);
    yield put({ type: "GET-LANG-REDUCER", payload: langs.data.data });
    yield put({ type: "GENERAL-DONE" });
    yield put({ type: "GET-COM-REDUCER", payload: response.data.data });
    yield put({ type: "GENERAL-DONE-COM" });
  } catch (e) {
    yield put({ type: "GENERAL-DONE-COM" });

    if (e.message) {
      toast.error(e.message);
    } else toast.error("failed! please check conection");
  }
}

function* watchGetAllDepartments() {
  yield takeEvery("GET-COM", getAllDepartments);
}
function* AddDepartments(action) {
  try {
    yield put({ type: "GENERAL-DONE-COM" });
    yield put({ type: "request-running-COM", payload: action.payload });
    let a = action.id;
    if (a) {
    }
    if (action.serv) {
      yield AxiosInstance.post(REACT_APP_CATEGORIES_SERVICE_URL +
        "/api/v1/service_subscriptions",
        JSON.stringify(action.payload)
      );
    } else if (action.id) {
      yield AxiosInstance.post(REACT_APP_CATEGORIES_SERVICE_URL +
        "/api/v1/services/create",
        JSON.stringify(action.payload)
      );
    } else {
      yield AxiosInstance.post(REACT_APP_CATEGORIES_SERVICE_URL +
        ADD_DEPT_URL,
        JSON.stringify(action.payload)
      );
    }
    yield put({ type: "request-running-COM-red", payload: action.payload });
    setTimeout(() => {
      store.dispatch({ type: "request-running-COM-red", payload: null });
    }, 4000);
    yield put({ type: "GENERAL-DONE-COM" });
    let id,
      view = action.view;
    if (view === "Communications Companies") id = 1;
    if (view === "Water Companies") id = 2;
    if (view === "Gas Companies") id = 3;
    if (view === "Electricty Companies") id = 4;

    yield put({ type: "GET-COM", id: id });
  } catch (e) {
    yield put({
      type: "GENERAL-ERR-COM",
      payload: { data: action.payload, message: e.message },
    });
    if (e.message) {
      toast.error(e.message);
    } else toast.error("failed! please check conection");
  }
}

function* watchAddDepartments() {
  yield takeEvery("ADD-COM", AddDepartments);
}
function* UpdateDepartments(action) {
  try {
    yield put({ type: "GENERAL-DONE-COM" });
    yield put({ type: "request-running-COM", payload: action.payload });
    if (action.serv) {
      yield AxiosInstance.put(REACT_APP_CATEGORIES_SERVICE_URL +
        "/api/v1/service_subscriptions/" +
        action.payload.id,
        JSON.stringify(action.payload)
      );
    } else if (action.id) {
      yield AxiosInstance.post(REACT_APP_CATEGORIES_SERVICE_URL +
        "/api/v1/services/update",
        JSON.stringify(action.payload)
      );
    } else {
      let ad;
      let regs = [];
      action.payload.regions.map((a) => {
        if (a && a.id) {
          regs.push(a.id);
        } else {
          regs.push(a);
        }
      });
      ad = { ...action.payload, regions: regs };
      yield AxiosInstance.post(REACT_APP_CATEGORIES_SERVICE_URL +
        UPD_DEPT_URL,
        JSON.stringify(ad)
      );
    }
    yield put({ type: "request-running-COM-red", payload: action.payload });
    setTimeout(() => {
      store.dispatch({ type: "request-running-COM-red", payload: null });
    }, 4000);
    yield put({ type: "GENERAL-DONE-COM" });
    let id,
      view = action.view;
    if (view === "Communications Companies") id = 1;
    if (view === "Water Companies") id = 2;
    if (view === "Gas Companies") id = 3;
    if (view === "Electricty Companies") id = 4;

    yield put({ type: "GET-COM", id: id });
    yield put({ type: "GENERAL-DONE-COM" });
  } catch (e) {
    yield put({
      type: "GENERAL-ERR-COM",
      payload: { data: action.payload, message: e.message },
    });
    if (e.message) {
      toast.error(e.message);
    } else toast.error("failed! please check conection");
  }
}

function* watchUpdDepartments() {
  yield takeEvery("UPD-COM", UpdateDepartments);
}

export function* CompaniesSaga() {
  yield all([
    fork(watchGetAllDepartments),
    fork(watchUpdDepartments),
    fork(watchAddDepartments),
  ]);
}
