import HashTicket from "./tickets_types/hash_ticket"
import OdooTicket from "./tickets_types/odoo_ticket"
import IndiaTicket from "./tickets_types/india"

export default function OdooHash({ ticket, whatsapp_chat_name, editTicket, finishEditTicket }) {
    const getTicketColor = (status) => {
        if (status === 2) {
            return "#F7F7F7"
        } else if (status === 3) {
            return "#F4F8FF"
        } else if (status === 7 || status === 4) {
            return "#F9FFF4"
        } else {
            return "#FFF7F4"
        }
    }
    const getIconColor1 = (status) => {
        if (status === 2) {
            return "#404040"
        } else if (status === 3) {
            return "#007CFF"
        } else if (status === 7 || status === 4) {
            return "#15A80F"
        } else {
            return "#404040"
        }
    }
    const getIconColor2 = (status) => {
        if (status === 2) {
            return "#404040"
        } else if (status === 3) {
            return "#007CFF"
        } else if (status === 7 || status === 4) {
            return "#15A80F"
        } else {
            return "#F7F8F3"
        }
    }
    // console.log(ticket)
    if (ticket.ticketable_type === "india_ticket") {
        return <IndiaTicket ticket={ticket} ticketColor={getTicketColor(ticket.ticket_status_id)} icon_color_1={getIconColor1(ticket.ticket_status_id)} icon_color_2={getIconColor2(ticket.ticket_status_id)} ticket_status_id={ticket.ticket_status_id} editTicket={editTicket} finishEditTicket={finishEditTicket} />
    } else if (ticket.ticketable_type === "hash_ticket" || ticket.ticketable_type === "broker_deposit_odoo_ticket") {
        return <HashTicket ticket={ticket} ticketColor={getTicketColor(ticket.ticket_status_id)} icon_color_1={getIconColor1(ticket.ticket_status_id)} icon_color_2={getIconColor2(ticket.ticket_status_id)} ticket_status_id={ticket.ticket_status_id} editTicket={editTicket} finishEditTicket={finishEditTicket} />
    } else {
        return <OdooTicket ticket={ticket} ticketColor={getTicketColor(ticket.ticket_status_id)} icon_color_1={getIconColor1(ticket.ticket_status_id)} icon_color_2={getIconColor2(ticket.ticket_status_id)} ticket_status_id={ticket.ticket_status_id} editTicket={editTicket} finishEditTicket={finishEditTicket} />
    }
}